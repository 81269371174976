import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { APP_CONSTANTS } from 'src/app/shared/constants';
import { UnsubscribeOnDestroyAdapter } from 'src/app/shared/services/UnsubscribeOnDestroyadapter';
import { updateQuestions } from 'src/app/store/actions/patient-response.actions';
import { AppState } from 'src/app/store/root.reducer';
import { getDaywiseAppointmentsCount } from 'src/app/store/selectors/home.selectors';
import { getPatientResponse } from 'src/app/store/selectors/patient-response.selector';
import { ComponentsService } from '../services/componets.service';

@Component({
  selector: 'app-questions-popup',
  templateUrl: './questions-popup.component.html',
  styleUrls: ['./questions-popup.component.scss']
})
export class QuestionsPopupComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  @Input() data1: any;
  @Input() appointmentId: any;
  @Input() appointmentData:any;
  questions = [];
  dispquestions: any;
  selected: boolean = false;
  disablebutton: boolean = true;
  questionLength: number = 1;
  PreScreeningAnswersDTO: any;
  loading: boolean;
  response: any;
  allQuestionsDone: boolean;
  questionNo: number;
  sum: number;
  total: number;
  constructor( private router: Router, private changeDetection: ChangeDetectorRef, private toastrService: NbToastrService, private dialogRef: NbDialogRef<QuestionsPopupComponent>, private store: Store<AppState>, private apiService: ComponentsService) {
    super()
   }

  ngOnInit() {
    if (this.data1 != null) {
      this.subs.sink=this.apiService.getPrescreeningQuestions(this.data1).subscribe(res => {
        var list=JSON.parse(JSON.stringify(res));
        if(list.length>0){
          this.store.dispatch(updateQuestions({ PatientResponselist: res }));
          this.getData();
        }else{
          this.router.navigate([APP_CONSTANTS.APP_ROUTE.AUTH + "/" + APP_CONSTANTS.LOGIN_ROUTE.PATIENTMEETVIEW], { state: { id: this.appointmentData.id, username: this.appointmentData.customerName } });
        }
      });
    }
  }

  //getting questions based on complaint
  getData() {
    this.store.select(getPatientResponse).subscribe(data => {
      this.response = JSON.parse(JSON.stringify(data.PatientResponselist));
      this.response = this.response.map((ele) => {
        return ele;
      })
      this.questions = [];
      this.questionNo=0;
      this.getAllChildQuestions(this.response,true);
      // this.dispquestions = this.questions.slice(0, this.questionLength);
    });
  }
  getAllChildQuestions(response,isParent) {
    response.forEach(parent => {
      if(isParent && this.questionNo==0){
        parent.showQuestion = true;
      }
      this.questionNo++;
      this.questions.push(parent);
      parent.preScreeningDetailsList.forEach(details => {
        if (details.childQuestions.length > 0) {
          this.getAllChildQuestions(details.childQuestions,false);
        }
      });
    });
  }
  showNextQuestion(response) {
    response.forEach(parent => {
      this.allQuestionsDone=true;
      var parentQuestion = this.questions.filter((e) => {
        if (e.id == parent.id) {
          return true;
        } else {
          return false;
        }
      });
      if (!parent.selectedOption) {
        this.questions = this.questions.map((e) => {
          if (e.id == parent.id && this.questionNo==0) {
            e.showQuestion = true;
          }
          return e
        });
        this.questionNo++;
        return false;
      }
      parent.preScreeningDetailsList.forEach(details => {
        if (details.options == parentQuestion[0].selectedOption) {
          if (details.childQuestions.length > 0) {
            this.allQuestionsDone=false;
            this.showNextQuestion(details.childQuestions);
          }
        }
      });
    });
  }
  updateData(option, ques) {
    console.log(option, ques);
    var i = 0;
    this.questions = this.questions.map((ele) => {
      if (ele.id == ques.id) {
        ele.selectedOption = option.options;
        ele.seviority = option.seviority;
      }
      return ele;
    })
    this.questionNo=0;
    this.showNextQuestion(this.response);
    if(this.questionNo==0){
      this.submit();
    }
  }
  submit(){
    this.sum=0;
    this.total=0;
    this.loading = true;
    var respon=this.saveResponse(this.response);
    var response={
      "appointmentId":this.appointmentId,
      "preScreeningDTOList":respon,
      "seviority":this.total==0?0:this.sum/this.total
    }

    this.subs.sink=this.apiService.savePrescreeningAnswers(response).subscribe((res: any) => {
        this.loading = false;
        this.toastrService.show('', 'Prescreening completed successfully.', { duration: APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON });
        this.dialogRef.close(false);
        this.router.navigate([APP_CONSTANTS.APP_ROUTE.AUTH + "/" + APP_CONSTANTS.LOGIN_ROUTE.PATIENTMEETVIEW], { state: { id: this.appointmentData.id, username: this.appointmentData.customerName } });
    });
  }
  saveResponse(response){
      return response.map((parent1) => {
        if(parent1.showQuestion){
          parent1=this.questions.filter((e)=>{
            return parent1.id==e.id;
          })[0];
          this.sum+=parent1.seviority;
          this.total++;
        }
        parent1.preScreeningDetailsList.forEach(details => {
          if (details.childQuestions.length > 0) {
            parent1.preScreeningDetailsList.childQuestions=this.saveResponse(details.childQuestions);
          }
        });
        delete parent1.showQuestion;
        return parent1;
      });
    }  
}
