import { createReducer, on } from "@ngrx/store"
import { updateDoctor, updateDoctorStatus } from "../actions/doctor-user.actions"
import { initialDoctorState } from "../state/doctor-user.state"

const _DoctorUserReducer = createReducer(
    initialDoctorState,
    on(updateDoctor, (state, action) => {
        return {
            ...state,
            id: action.id,
            given: action.given,
            username: action.username,
            consultationFee: action.consultationFee,
            consultationType: action.consultationType,

        }
    }),
    on(updateDoctorStatus, (state, action) => {
        return {
            ...state,
            checkin: action.checkin,
            status: action.status

        }
    }),
)

export function DoctorUserReducer(state, action) {
    return _DoctorUserReducer(state, action)
}