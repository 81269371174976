import { Directive, ElementRef, HostListener, Input} from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import {Location} from "@angular/common";
import { PatientService } from '../../services/patient.service';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { UnsubscribeOnDestroyAdapter } from '../../services/UnsubscribeOnDestroyadapter';
import { APP_CONSTANTS } from '../../constants';

@Component({
  selector: 'medicaldetailsdataentry',
  templateUrl: './medicaldetailsdataentry.component.html',
  styleUrls: ['./medicaldetailsdataentry.component.scss'],
  providers: [PatientService]
})

export class MedicalDetailsDataentryComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  personId : string;
  loading:boolean=false;
  vitalsForm: FormGroup;
  @Input() data: any;
  @Input() memberdata:any;
  @Input() show:boolean;
  tomorrow = new Date();
  fileToUpload: File = null;
  report: string = "";
  submitted: boolean = false;
  memberData: any;
  diabatic:string="NO";
  diabaticsince:any;
  thyroid:string="NO";
  thyroidsince:any;
  bloodPressure:string="NO"
  bloodpressuresince:any;
  olddata:any;
  bloodGroup:string='';
  memberData1:any;


  constructor(private toastrService: NbToastrService,private dialogRef:NbDialogRef<MedicalDetailsDataentryComponent>,private formBuilder: FormBuilder,
    private apiService:PatientService,private location : Location,
    )
   {
     super()
    
   }

  ngOnInit() {
    console.log("show -> ",this.show);
    this.memberData = this.data;
    this.memberData1=this.memberdata;
    this.personId = this.memberData.personId;
    this.olddata=this.memberData;
    if(this.memberData.userHealthDetailsDTO!=null)
    {
      this.diabatic=this.memberData.userHealthDetailsDTO.diabetic=="true"? 'YES':'NO';
      this.thyroid=this.memberData.userHealthDetailsDTO.thyroid=="true"? 'YES':'NO';
      this.bloodPressure=this.memberData.userHealthDetailsDTO.bloodPressure=="true"? 'YES':'NO';
      this.getBloodGroup();
      this.vitalsForm = this.formBuilder.group({
        bloodgroup: [this.bloodGroup],
        diabetic: [""],
        diabeticsince:["",Validators.pattern(APP_CONSTANTS.REGEX.ALPHANUMERIC_WITH_SPACE)],
        thyroid: [""],
        thyroidsince:["",Validators.pattern(APP_CONSTANTS.REGEX.ALPHANUMERIC_WITH_SPACE)],
        bloodpressure: [""],
        bloodpressuresince:["",Validators.pattern(APP_CONSTANTS.REGEX.ALPHANUMERIC_WITH_SPACE)],
        medicalhistory: [this.memberData.userHealthDetailsDTO.medicalHistory],
        allergies: [this.memberData.userHealthDetailsDTO.allergies],

      });
      this.vitalsForm.controls.diabetic.setValue(this.diabatic);
      if(this.diabatic=="YES")
      {
        this.vitalsForm.controls.diabeticsince.setValue(this.memberData.userHealthDetailsDTO.diabeticSince);
      }
      this.vitalsForm.controls.thyroid.setValue(this.thyroid);
      if(this.thyroid=="YES")
      {
        this.vitalsForm.controls.thyroidsince.setValue(this.memberData.userHealthDetailsDTO.thyroidSince);
      }
      this.vitalsForm.controls.bloodpressure.setValue(this.bloodPressure);
      if(this.bloodPressure=="YES")
      {
        this.vitalsForm.controls.bloodpressuresince.setValue(this.memberData.userHealthDetailsDTO.bloodPressureSince);
      }
    }
    else
    {
      this.vitalsForm = this.formBuilder.group({
        bloodgroup: [],
        diabetic: ["NO"],
        thyroid: ["NO"],
        thyroidsince:[],
        diabeticsince:[],
        bloodpressure: ["NO"],
        bloodpressuresince:[],
        medicalhistory: [],
        allergies: [],
      });

    }
    this.onChanges();

  }

  
  getBloodGroup()
  {
    if(this.memberData.userHealthDetailsDTO.bloodGroup=='B+')
    {
      this.bloodGroup='B+';
    }
    else if(this.memberData.userHealthDetailsDTO.bloodGroup=='A+')
    {
      this.bloodGroup='A+'
    }
    else if(this.memberData.userHealthDetailsDTO.bloodGroup=='O+')
    {
      this.bloodGroup='O+'
    }
    else if(this.memberData.userHealthDetailsDTO.bloodGroup=='AB+')
    {
      this.bloodGroup='AB+'
    }
    else if(this.memberData.userHealthDetailsDTO.bloodGroup=='A-')
    {
      this.bloodGroup='A-'
    }
    else if(this.memberData.userHealthDetailsDTO.bloodGroup=='B-')
    {
      this.bloodGroup='B-'
    }
    else if(this.memberData.userHealthDetailsDTO.bloodGroup=='O-')
    {
      this.bloodGroup='O-'
    }
    else if(this.memberData.userHealthDetailsDTO.bloodGroup=='AB-')
    {
      this.bloodGroup='AB-'
    }

  }

  get pdf() {
    return this.vitalsForm.controls;
  }

  onCancel(){
    this.dialogRef.close(false);
  }



  onSubmit(){
    this.loading=true;
    this.submitted = true;
    if (this.vitalsForm.invalid) {
      return;
    }


    if(this.memberData.userHealthDetailsDTO!=null)
    {
      this.diabatic=this.vitalsForm.controls['diabetic'].value=="YES"?'true':'false';
      this.diabaticsince=this.vitalsForm.controls['diabetic'].value=='YES'?this.vitalsForm.controls['diabeticsince'].value:null;
      this.thyroidsince=this.vitalsForm.controls['thyroid'].value=='YES'?this.vitalsForm.controls['thyroidsince'].value:null;
      this.bloodpressuresince=this.vitalsForm.controls['bloodpressure'].value=='YES'?this.vitalsForm.controls['bloodpressuresince'].value:null;
      this.thyroid=this.vitalsForm.controls['thyroid'].value=="YES"?'true':'false';
      this.bloodPressure=this.vitalsForm.controls['bloodpressure'].value==="YES"?'true':'false';
      var bloodgroup=this.vitalsForm.controls['bloodgroup'].value;
      var allergies=this.vitalsForm.controls['allergies'].value;
      var medicalhistory=this.vitalsForm.controls['medicalhistory'].value;
      const datepipe: DatePipe = new DatePipe('en-US');
      var updatetime=datepipe.transform(new Date(),"yyyy-MM-dd HH:mm:ss")
      var 	userHealthDetailsDTO1={
          "personId"	:	this.personId,
          "bloodGroup"	:	bloodgroup,
          "diabetic"	:	this.diabatic,
          "diabeticSince"	:this.diabaticsince,
          "thyroid"	:	this.thyroid,
          "thyroidSince"	:this.thyroidsince,
          "bloodPressure"	:	this.bloodPressure,
          "bloodPressureSince"	:	this.bloodpressuresince,
          "medicalHistory"	:	medicalhistory,
          "allergies"	:	allergies,
          "height"	:	this.memberData.height ? this.memberData.height : 0,
          "weight"	:	this.memberData.weight ? this.memberData.weight : 0,
          "bloodGroupUpdatedDate"	:	bloodgroup==this.olddata.userHealthDetailsDTO.bloodgroup?this.olddata.userHealthDetailsDTO.bloodGroupUpdatedDate:updatetime,
          "diabeticUpdatedDate"	:	this.diabatic==this.olddata.userHealthDetailsDTO.diabetic?this.olddata.userHealthDetailsDTO.diabeticUpdatedDate:updatetime,
          "thyroidUpdatedDate"	:	this.thyroid==this.olddata.userHealthDetailsDTO.thyroid?this.olddata.userHealthDetailsDTO.thyroidUpdatedDate:updatetime,
          "medicalHistoryUpdatedDate"	:	medicalhistory==this.olddata.userHealthDetailsDTO.medicalhistory?this.olddata.userHealthDetailsDTO.medicalHistoryUpdatedDate:updatetime,
          "allergiesUpdatedDate"	:	allergies==this.olddata.userHealthDetailsDTO.allergies?this.olddata.userHealthDetailsDTO.allergiesUpdatedDate:updatetime,
          "bloodPressureUpdatedDate"	:	this.bloodPressure==this.olddata.userHealthDetailsDTO.bloodPressure?this.olddata.userHealthDetailsDTO.bloodPressureUpdatedDate:updatetime,
          
        }

      var MedicalDTO={
        "userHealthDetailsDTO":userHealthDetailsDTO1,
        "memberDTO":this.memberData1,
        "updatedBy":this.show?"Care Manager":'Doctor',
      }
      this.subs.sink=this.apiService.saveCustomer(MedicalDTO).subscribe((resData: any) => {
      this.dialogRef.close({ event: 'close',"data":"reload"});
      this.toastrService.show('','Medical records has been uploaded successfully.',{duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});

      } , error => {
      var data=error;
      this.dialogRef.close({ event: 'close',"data":"reload"});
      this.toastrService.show('','Medical records has been uploaded successfully.',{duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
      });

    }
    else
    {
      this.diabatic=this.vitalsForm.controls['diabetic'].value=="YES"?'true':'false';
      this.thyroid=this.vitalsForm.controls['thyroid'].value=="YES"?'true':'false';
      this.bloodPressure=this.vitalsForm.controls['bloodpressure'].value==="YES"?'true':'false';
      var bloodgroup=this.vitalsForm.controls['bloodgroup'].value;
      var allergies=this.vitalsForm.controls['allergies'].value;
      var medicalhistory=this.vitalsForm.controls['medicalhistory'].value;
      // var updatetime=this.datepipe.transform(new Date(),"yyyy-MM-dd HH:mm:ss")
      this.diabaticsince=this.vitalsForm.controls['diabetic'].value=='YES'?this.vitalsForm.controls['diabeticsince'].value:null;
      this.thyroidsince=this.vitalsForm.controls['thyroid'].value=='YES'?this.vitalsForm.controls['thyroidsince'].value:null;
      this.bloodpressuresince=this.vitalsForm.controls['bloodpressure'].value=='YES'?this.vitalsForm.controls['bloodpressuresince'].value:null;

      var userHealthDetailsDTO2={
          "personId"	:	this.personId,
          "bloodGroup"	:	bloodgroup,
          "diabetic"	:	this.diabatic,
          "diabeticSince"	:this.diabaticsince,
          "thyroid"	:	this.thyroid,
          "thyroidSince"	:this.thyroidsince,
          "bloodPressure"	:	this.bloodPressure,
          "bloodPressureSince"	:	this.bloodpressuresince,
          "medicalHistory"	:	medicalhistory,
          "allergies"	:	allergies,
          "height"	:	this.memberData.height ? this.memberData.height : 0,
          "weight"	:	this.memberData.weight ? this.memberData.weight : 0,
         
          
      }

      var MedicalDTO1={
        "userHealthDetailsDTO":userHealthDetailsDTO2,
        "memberDTO":this.memberData1,
        "updatedBy":this.show?"Care Manager":'Doctor',
      }
      this.subs.sink=this.apiService.saveCustomer(MedicalDTO1).subscribe((resData: any) => {
        this.loading=false;

     
      } , error => {
      var data=error;
      
      });

    }

  }
  omitSpecialChar(event)
  {   
    var k;  
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
  }

  onChanges(): void {
    this.vitalsForm.get('diabetic').valueChanges.subscribe(val => {
      this.vitalsForm.controls.diabeticsince.setValue("");
    });

    this.vitalsForm.get('thyroid').valueChanges.subscribe(val => {
      this.vitalsForm.controls.thyroidsince.setValue("");
    });

    this.vitalsForm.get('bloodpressure').valueChanges.subscribe(val => {
      this.vitalsForm.controls.bloodpressuresince.setValue("");
    });
  }
}

@Directive({
  selector: '[numbersOnlywithDecimal]'
})
export class NumbersOnlywithDecimalsDirective {

  private regex: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab'];

  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

}
