import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { NbIconLibraries } from '@nebular/theme';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isLoggedIn = false;
  username?: string;

  constructor(public httpClient: HttpClient, @Inject(DOCUMENT) document,private router: Router,private iconLibraries: NbIconLibraries
  ) {
    this.iconLibraries.registerFontPack('fas', {packClass:'fas', iconClassPrefix: 'fa' });
    this.iconLibraries.registerFontPack('far', {packClass:'far', iconClassPrefix: 'fa' });
    this.iconLibraries.registerFontPack('fab', {packClass:'fab', iconClassPrefix: 'fa' });
    this.iconLibraries.setDefaultPack('fab');
  }
  ngOnInit() {
    // this.isLoggedIn = !!this.tokenStorageService.getUser().username;

    // if (this.isLoggedIn) {
    //   const user = this.tokenStorageService.getUser();
    //   this.username = user.username;
    // }
    // else{
    //   this.router.navigate(['/login']);
    // }
  }

  logout(): void {
    this.router.navigate(['/login']);
    window.location.reload();
  }

  navigate(){
    let tab: any = "dashboard";
    this.router.navigate(['/home'], {state: tab});
  }

}
