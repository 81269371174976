import { createAction, props } from "@ngrx/store";

export const loadAdminPreScreening = createAction(
    "[load admin questions] Load admin Details"
); 

export const updateAdminPreScreening = createAction(
    "[save admin Questions] Save admin Question Details",
    props<{
        adminPrescreeningList : any
      }>()
);
export const adminPrescreeningListLoading = createAction(
    "[adminPrescreeningListLoading admin pre] adminPrescreeningListLoading admin pre"
);