
<div class="row">
  <div class="col-12">
      <nb-card class="layout">
        <nb-card-header>
          <h4>Doctor Consultation</h4>
          
        </nb-card-header>
 

        <nb-card-body>
          <div>
            <div class="divrow">
              <div class="divleftcell">Complaint</div>
              <div class="divrightcell">{{appointmentData != null ? appointmentData.topic : ""}}</div>
            </div>
            <div class="divrow">
                <div class="divleftcell">Doctor</div>
                <div class="divrightcell">{{appointmentData != null ? appointmentData.doctorName : ""}}</div>
            </div>
            <div class="divrow">
                  <div class="divleftcell">Patient Name</div>
                  <div class="divrightcell">{{appointmentData != null ? appointmentData.customerName : ""}}</div>
            </div>
            <div class="divrow">
                  <div class="divleftcell">Patient Mobile</div>
                  <div class="divrightcell">{{appointmentData != null ? appointmentData.customerMobile : ""}}</div>
            </div>
          
            <div class="divrow">
                <div class="divleftcell">Date</div>
                <div class="divrightcell">{{appointmentData?.startTime != null ? (appointmentData?.startTime ) : ""}}</div>
            </div>
            <div class="divrow">
              <div class="divleftcell">Duration</div>
              <div class="divrightcell">{{appointmentData != null ? appointmentData?.duration+" Mins" : ""}}</div>
            </div> 
          </div> 
          <nb-checkbox [(ngModel)]="accept" class="divrow" color= "primary" style="margin: 10px;" > 
                I have read and accepted all the <a href="#" onClick="MyWindow=window.open('https://www.elro.ai/privacy','MyWindow','width=600,height=300'); return false;">terms and conditions</a>
          </nb-checkbox>
        
          <div class="divrow text-center">
              <button  nbButton status="primary" [disabled]="!checkJoin" (click)="onJoin()" type="submit">
                Join
            </button>
          </div>
        </nb-card-body>            
      </nb-card>   
  </div>
</div>
 
