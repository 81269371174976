<nb-card class="tinycard" [nbSpinner]="loading" nbSpinnerStatus="primary" nbSpinnerSize="giant" nbSpinnerMessage="Loading">
  <nb-card-header> Confirm </nb-card-header>
<nb-card-body>
  <span>
    {{title}}
  </span>

<div class="col-md-12 text-center" style="margin-top:20px;">
  <button type="button" nbButton status="primary"  (click)="onDismiss()">No</button>
  <button type="button" nbButton status="primary" style="margin-left: 20px;" (click)="onConfirm()">Yes</button>
</div>

</nb-card-body>
</nb-card>