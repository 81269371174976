<nb-card class="smallcard" [nbSpinner]="loading" nbSpinnerStatus="primary" nbSpinnerSize="giant"
  nbSpinnerMessage="Loading">
  <nb-card-header>
    Pre-Screening
  </nb-card-header>
  <nb-card-body>
    <div *ngFor="let ques of questions;let i = index">
      <div *ngIf="ques.showQuestion">
        <p>{{ques.question}}</p>
        <div class="row show-right">
          <!-- <div>
            <button  nbButton status="primary" class="m-10">
              {{ques.selectedOption}}
            </button>
          </div> -->
          <div >
            <button *ngFor="let option of ques.preScreeningDetailsList;let j = index" nbButton [status]="option.options==ques.selectedOption?'primary':'basic'" (click)="updateData(option,ques)" class="m-10">
              {{option.options}}
            </button>
          </div>
        </div>
      </div>
     
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="row show-right">
      <div class="col-6">
        <button nbButton status="primary" (click)="cancel()" class="m-10">
          Cancel
        </button>
      </div>
      <div class="col-6">
        <button nbButton status="primary" [disabled]="questionNo!=0" (click)="submit()" class="m-10">
          Submit
        </button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>