export interface roleDTO {
  id: number,
  role:string,
  roledescription:string
}
export interface UserState {
  id: number,
  username: string,
  firstname: string,
  lastname: string,
  mobileno: string,
  roleDTO:roleDTO,
  doctor:any;
}
export const initialUserState: UserState = null;