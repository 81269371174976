import { createAction, props } from "@ngrx/store";

export const loadDoctor=createAction(
    "[load Doctor Details] Load Doctor Details "
); 

export const updateDoctor = createAction(
    "[save Doctor] Save Doctor Details",
    props<{
        id:number,
        given:string,
        username:string,
        consultationFee:string,
        consultationType:string,
      }>()
);

export const updateDoctorStatus = createAction(
    "[save doctor Status] Save Doctor Status",
    props<{
        checkin : boolean,
        status : string
      }>()
);