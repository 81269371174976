import { createFeatureSelector, createSelector } from "@ngrx/store";
import { DoctorState} from "../state/doctor-user.state";

const getDoctorState = createFeatureSelector<DoctorState>('doctor')
export const getDoctor = createSelector(
    getDoctorState,
    state => state
);
export const getDoctorStatus = createSelector(
    getDoctorState,
    state => {
        state.checkin,
        state.status
        return state;
    }
);