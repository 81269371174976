import { createFeatureSelector, createSelector } from "@ngrx/store";
import { PatientResponseState } from "../state/patient-response.state";


const getPatientResponseState = createFeatureSelector<PatientResponseState>('patientresponse')
export const getPatientResponse = createSelector(
    getPatientResponseState,
    state => {
        return state;
    }
);
