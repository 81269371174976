import { MessagingService } from './services/messaging.service';
import { environment } from 'src/environments/environment';
import { NotificationsComponent } from './notifications/notifications.component';
import { ConfirmationDialogComponent } from './shared-dialogue-components/confirmation-dialogs/confirmation-dialog.component';
import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { GlobalErrorHandler } from './global-error-handler';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { NbActionsModule, NbContextMenuModule, NbDatepickerModule, NbDialogModule, NbGlobalLogicalPosition, NbLayoutModule, NbMenuModule, NbSidebarModule, NbTabsetModule, NbThemeModule, NbToastrModule, NbToggleModule, NbUserModule } from '@nebular/theme';
import { CommonModuleModule } from '../common-module/common-module.module';
import { HeaderComponent } from './header/header.component';
import { OneColumnLayoutComponent } from './one-column/one-column.layout';
import { StatusCardComponent } from './status-card/status-card.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ChartsModule } from 'ng2-charts';
import { UserBasicDetailsComponent } from './user-basic-details/user-basic-details.component';
import { DoctorScheduleComponent } from './doctor-schedule/doctor-schedule.component';
import { AvailableBlockStatusComponent } from './shared-dialogue-components/availableblockstatus/availableblockstatus.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { SchedulerModule } from 'angular-calendar-scheduler';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableFooterModule } from 'ngx-datatable-footer';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SortByPipe } from './shared-dialogue-components/bookappointment/sort.pipe';
import { SortByPipes } from './shared-dialogue-components/bookappointment/sort-by.pipe';
import { ReportsUploadDialogComponent } from './shared-dialogue-components/reportsupload/reportsupload.component';
import { CustomerProfileComponent} from './customerprofile/customerprofile.component';
import { VitalsDataentryComponent } from './shared-dialogue-components/vitalsdataentry/vitalsdataentry.component';
import { MedicalDetailsDataentryComponent } from './shared-dialogue-components/medicaldetailsdataentry/medicaldetailsdataentry.component';
import { ConfirmDialogComponent } from './shared-dialogue-components/confirmation-dialog/confirm-dialog.component';
import { BookAppointmentComponent } from './shared-dialogue-components/bookappointment/bookappointment.component';
import { RegisterComponent } from './register/register.component';
import { VideoSdkMeetingComponent } from './videosdkmeeting/videosdkmeeting.component';
import { NoHeaderLayoutComponent } from './no-header/no-header.layout';
import { EcgDialogComponent } from './shared-dialogue-components/ecgdilaog/ecgdialog.component';
import { VitalsgraphviewComponent } from './vitalsgraphview/vitalsgraphview.component';
import { AddprescriptionComponent } from './shared-dialogue-components/addprescription/addprescription.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CancelDialogComponent } from './shared-dialogue-components/cancel-dialog/cancel-dialog.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessaging, AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { PrescriptionRenderComponent } from './shared-render-components/patient-prescription-render-component';
import { ReportsRenderComponent } from './shared-render-components/cm-reportview-render-component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { DEFAULT_THEME } from '../../assets/styles/theme.default'
import { PrescriptionpdfComponent } from './shared-dialogue-components/prescriptionpdf/prescriptionpdf.component';
import { PortalModule } from '@angular/cdk/portal';
import { BrowserModule } from '@angular/platform-browser';
import { UrlSafePipe } from './pipes/urlsafe.pipe';
import { CustomRenderComponent } from './shared-render-components/cm-prescription-render-component';
import { ReportRenderComponent } from './shared-render-components/patient-reportview-render-component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { BpsAssesmentComponent } from './bps-assesment/bps-assesment.component';
import { PastMedicationComponent } from './shared-dialogue-components/pastMedications/pastMedication.component';
import { medicineViewComponent } from './shared-dialogue-components/medicineView-dialog/medicineView.component';

export function momentAdapterFactory() {
  return adapterFactory(moment);
};
const positions = NbGlobalLogicalPosition;

const NB_MODULES = [
  NbMenuModule.forRoot(),
  NbSidebarModule.forRoot(),
  NbDialogModule.forChild(),
  NbDialogModule.forRoot(),
  NgxDatatableFooterModule.forRoot(),
  CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
  SchedulerModule.forRoot({ locale: 'en', headerDateFormat: 'daysRange' }),
  NbToastrModule.forRoot({position:positions.BOTTOM_START}) ,
  NbDatepickerModule.forRoot(),
  AngularFireModule.initializeApp(environment.firebase),
  PortalModule
];

const COMPONENTS = [
 HeaderComponent,OneColumnLayoutComponent,
 StatusCardComponent,
 CustomRenderComponent,
 UserBasicDetailsComponent,
 DoctorScheduleComponent,
 AvailableBlockStatusComponent,
 PrescriptionpdfComponent,
 SortByPipe,
 SortByPipes,
 ReportsUploadDialogComponent,
 CustomerProfileComponent,
 PastMedicationComponent,
 medicineViewComponent,
 VitalsDataentryComponent,
 MedicalDetailsDataentryComponent, 
 ConfirmDialogComponent,
 BookAppointmentComponent,
 RegisterComponent,
 VideoSdkMeetingComponent,
 ReportRenderComponent,
 NoHeaderLayoutComponent,
 EcgDialogComponent,
 PrescriptionRenderComponent,
 ReportsRenderComponent,
 VitalsgraphviewComponent,
 ForgotPasswordComponent,
 CancelDialogComponent,
 ConfirmationDialogComponent,
 NotificationsComponent,
 UrlSafePipe,
 BpsAssesmentComponent
];
const NbModules = [
    NbMenuModule,
    NbLayoutModule,
    NbSidebarModule,
    NbUserModule,
    NbActionsModule,
    NbContextMenuModule,
    CommonModuleModule,
    Ng2SmartTableModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    NbDatepickerModule,
    NgbModule,
    NbToggleModule,
    NbTabsetModule,
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    AngularFireDatabaseModule,
    AngularFireMessagingModule,
    NgxExtendedPdfViewerModule
]
@NgModule({
  imports: [...NB_MODULES, ...NbModules,
  ],
  exports: [...COMPONENTS, ...NbModules],
  declarations: [...COMPONENTS, AddprescriptionComponent, ChangePasswordComponent],
  providers: [AngularFireMessaging,
    MessagingService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  entryComponents: [...COMPONENTS]
})
export class SharedModule { 
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },[DEFAULT_THEME]
        ).providers,
      ],
      
    };
  }
}
