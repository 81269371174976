import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { APP_CONSTANTS } from 'src/app/shared/constants';
import { AppState } from 'src/app/store/root.reducer';
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient, private store: Store<AppState>) { }

  getUserRoles() {
    return this.http.get<any>(APP_CONSTANTS.REST_API_URL.GET_USER_ROLES, { observe: 'body' });
  }
  login(username: string, password: string, role: string) {
    return this.http.post<any>(APP_CONSTANTS.REST_API_URL.ADMIN_LOGIN, { username: username, password: password, roleDTO: { id: role } }, { observe: 'response' });
  }
  loginDoctor(username: string, password: string) {
    // return this.http.post<any>('${environment.apiUrl}/users/authenticate', { username, password })
    return this.http.post<any>(APP_CONSTANTS.REST_API_URL.DOCTOR_LOGIN, { username, password }, { observe: 'response' as 'body' });
  }
  getDoctorDetails(userName) {
    return this.http.get(APP_CONSTANTS.REST_API_URL.GET_REGISTRATION + userName);
  }
  
  updateDoctorStatus(username){
    return this.http.get(APP_CONSTANTS.REST_API_URL.UPDATE_DOCTOR_STATUS + username);
  }
}
