<nb-card >
    <nb-card-body>
        <div class="row">
            <div class="col-lg-4 col-md-4">
                    <nb-card >
                        <nb-card-header>
                            <h5 class="zpC_head">{{vitalType}}</h5>
                        </nb-card-header>
                        <nb-card-body>
                            <ng2-smart-table [settings]="settings" [source]="source"  >
                            </ng2-smart-table>
                        </nb-card-body>
                    </nb-card>
                </div>
                <div class="col-lg-8 col-md-8">
                    <nb-card>
                        <nb-card-header>
                            <h5 class="zpC_head">{{vitalType}} Chart</h5>
                        </nb-card-header>
                        <nb-card-body>
                            <!-- <canvas baseChart height="400"  width="400" [datasets]="yearChartsData" [labels]="yearChartsLabels" [options]="chartsOptions" [legend]="lineChartLegend" [chartType]="lineChartstype"></canvas> -->
                            <nb-tabset>
                                <nb-tab tabTitle="Week">
                                    <canvas baseChart  height="150"
                                    [datasets]="chartsData"
                                    [labels]="chartsLabels"
                                    [legend]="lineChartLegend"
                                    [chartType]="lineChartType">
                                     </canvas>
                                </nb-tab>
                                <nb-tab tabTitle="Month">
                                    <canvas baseChart  height="150"
                                    [datasets]="monthChartsData"
                                    [labels]="monthChartsLabels"
                                    [legend]="lineChartLegend"
                                    [chartType]="lineChartType">
                                     </canvas>
                                </nb-tab>
                                <nb-tab tabTitle="Year">
                                    <canvas baseChart  height="150"
                                    [datasets]="yearChartsData"
                                    [labels]="yearChartsLabels"
                                    [legend]="lineChartLegend"
                                    [chartType]="lineChartType">
                                     </canvas>
                                </nb-tab>b>
                            </nb-tabset>
                            <!-- <nb-tabset>
                                <nb-tab tabTitle="Week">
                                    <div >
                                        <canvas baseChart  [datasets]="chartsData" [labels]="chartsLabels" [options]="chartsOptions" [legend]="lineChartLegend" [chartType]="lineChartstype"></canvas>
                                    </div>
                                </nb-tab>
                                <nb-tab tabTitle="Month">
                                    <div  >
                                        <canvas baseChart [datasets]="monthChartsData" [labels]="monthChartsLabels" [options]="chartsOptions" [legend]="lineChartLegend" [chartType]="lineChartstype"></canvas>
                                    </div>
                                </nb-tab>
                                <nb-tab tabTitle="Year">
                                    <div >
                                       
                                    </div>
                                </nb-tab>
                            </nb-tabset> -->
                        </nb-card-body>
                    </nb-card>
        
            </div>
        </div>
        
        
    </nb-card-body>
</nb-card>
