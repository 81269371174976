import { createAction, props } from "@ngrx/store";
import { PatientResponseState } from "../state/patient-response.state";


export const loadQuestions = createAction(
    "[load patient questions] Load Question Details"
); 

export const updateQuestions = createAction(
    "[save patient Questions] Save patient Question Details",
    props<{
        PatientResponselist : any
      }>()
);
