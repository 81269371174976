import { Directive, ElementRef, HostListener, Inject, Input, Optional } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validator, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import {Location} from "@angular/common";
import { PatientService } from '../../services/patient.service';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { APP_CONSTANTS } from '../../constants';
import { UnsubscribeOnDestroyAdapter } from '../../services/UnsubscribeOnDestroyadapter';

@Component({
  selector: 'pastmedication',
  templateUrl: './pastMedication.component.html',
  styleUrls: ['./pastMedication.component.scss'],
  providers: [PatientService]
})

export class PastMedicationComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  personId : string;
  loading:boolean=false;
  vitalsForm: FormGroup;

  tomorrow = new Date();
  medicationList:any=[];
  fileToUpload: File = null;
  report: string = "";
  submitted: boolean = false;
  memberData: any;
  @Input () data:any;
  constructor(private toastrService: NbToastrService,private dialogRef:NbDialogRef<PastMedicationComponent>,private formBuilder: FormBuilder,
    private apiService:PatientService,private location : Location,
    private datePipe:DatePipe)
   {
     super()
   }

  ngOnInit() {

    this.memberData = this.data;
    this.medicationList=this.data;
  

  }



  onCancel(){
    this.dialogRef.close(false);
  }


  Convert24hrto12hrs(date)
  {
    if(date.includes("Morning") || date.includes("Afternoon") || date.includes("Night") || date.includes("SOS") || date.includes("Other"))
    {
      return date;
    }
    else
    {
     var s=date.split(",");
     
     if(s.length==1)
     {
       s[0]=new Date("1-1-2022 "+s[0]);
       s[0]=this.datePipe.transform(s[0], 'h:mm a');
       return s[0];
     }
     else
     {
       var l='';
       for(var i=0;i<s.length;i++)
       {
        s[i]=new Date("1-1-2022 "+s[i]);
        s[i]=this.datePipe.transform(s[i], 'h:mm a');
        l=l+s[i];
        if(i!=s.length-1)
        {
          l=l+',';
        }
       }
       return l;
     }
    }
  }


  Convert24hrto12hrsDate(date)
  {
    if(date.includes("Morning") || date.includes("Afternoon") || date.includes("Night") || date.includes("SOS") || date.includes("Other"))
    {
      return date;
    }
    else
    {
     var s=date;
     var l='';
     s=new Date(s);
     l=this.datePipe.transform(s, 'MMMM d,y h:mm a');
     return l;
     }
     
  }

  

}
