export interface DashboardState {
  patientList: any,
  upcomingConsultationsList:any,
  doctorsList:any,
  paymentsList:any,
  paymentsFollowupList:any,
  refundsFollowupList:any,
  appointmentsFollowupList:any,
  getAppointmentsCount:any,
  getDaywiseAppointmentsCount:any,
  getConsultedPatientCountByDepartment:any,
  getRecentlyConsultedPatients:any,
  getRecentAvailableDoctors:any,
  AdminDashboardLoading:boolean,
  AdminUpcomingConsultationListLoading:boolean
}
export const DashboardInitialState: DashboardState = {
  patientList: null,
  upcomingConsultationsList:null,
  doctorsList:null,
  paymentsList:null
  ,paymentsFollowupList:null,
  refundsFollowupList:null,
  appointmentsFollowupList:null,
  getAppointmentsCount:null,
  getDaywiseAppointmentsCount:null,
  getConsultedPatientCountByDepartment:null,
  getRecentlyConsultedPatients:null,
  getRecentAvailableDoctors:null,
  AdminDashboardLoading:false,
  AdminUpcomingConsultationListLoading:false
};