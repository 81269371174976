import { createReducer, on } from "@ngrx/store"
import { updateQuestions } from "../actions/patient-response.actions"
import { initialPatientResponseState } from "../state/patient-response.state"

const _PatientResponseReducer = createReducer(
    initialPatientResponseState,
    on(updateQuestions, (state, action) => {
        return {
            ...state,
            PatientResponselist: action.PatientResponselist,

        }
    }),

)

export function PatientResponseReducer(state, action) {
    return _PatientResponseReducer(state, action)
}