<nb-card [nbSpinner]="loading" nbSpinnerStatus="primary" nbSpinnerSize="giant" nbSpinnerMessage="Loading">
  <nb-card-body>
    <div>
      <div style="text-align:center;" *ngIf="showText"><i>No Data Found</i></div>
    </div>
    <!-- <div *ngIf="!showText">
      <iframe type="application/pdf" [src]="viewUrl | urlsafe" width="100%" height="600vh" frameborder="2"></iframe>
    </div> -->
    <ngx-extended-pdf-viewer [showPrintButton]="false" [showBookmarkButton]="false" [showOpenFileButton]="false" [zoom]="'page-width'" *ngIf="!showText" [textLayer]="true" [src]="viewUrl"></ngx-extended-pdf-viewer>
  </nb-card-body>
</nb-card>