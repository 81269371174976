<nb-card [nbSpinner]="loading" nbSpinnerStatus="primary" nbSpinnerSize="giant" nbSpinnerMessage="">
    <nb-card-header>
        <div class="card-title text-left">
            Change Password
        </div>
    </nb-card-header>
    <nb-card-body>
        <form class="form form-horizontal" [formGroup]="personaldetailsForm" (ngSubmit)="onSubmit()">
            <div class="row" style="text-align: right;">

                <div class="form-group position-relative has-icon-left col-md-4">

                    <input type="password" autocomplete="off" [class.is-invalid]="pdf.oldpass.invalid && pdf.oldpass.touched" required
                        formControlName="oldpass" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && pdf.oldpass.errors }" placeholder="Old Password">
                    <div *ngIf="pdf.oldpass.errors && pdf.oldpass.invalid && pdf.oldpass.touched">
                        <small class="text-danger" *ngIf="pdf.oldpass.errors.required">Old Password is required</small>
                    </div>
                </div>

                <div class="form-group position-relative has-icon-left col-md-4">

                    <input type="password" autocomplete="off" [class.is-invalid]="pdf.password.invalid && pdf.password.touched" required
                        
                        formControlName="password" class="form-control pw" pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$"
                        [ngClass]="{ 'is-invalid': submitted && pdf.password.errors }" placeholder="New Password">
                    <div *ngIf="pdf.password.errors && pdf.password.invalid && pdf.password.touched">
                        <small class="text-danger" *ngIf="pdf.password.errors.required">New Password is required</small>
                    </div>
                    <div *ngIf="pdf.password.errors && (pdf.password.invalid || pdf.password.touched)">
                        <small class="text-danger" *ngIf="pdf.password.errors.pattern">password must be 8-16 characters
                            in length with at least 1 upper case, 1 lower case, 1 numeric and 1 special
                            character</small>
                    </div>
                </div>

                <div class="form-group position-relative has-icon-left col-md-4">

                    <input type="password" autocomplete="off"
                        [class.is-invalid]="pdf.confirmPassword.invalid && pdf.confirmPassword.touched" required
                        formControlName="confirmPassword" class="form-control pw"
                        [ngClass]="{ 'is-invalid': submitted && pdf.confirmPassword.errors }"
                        placeholder="Confirm New Password">
                    <div
                        *ngIf="pdf.confirmPassword.errors && pdf.confirmPassword.invalid && pdf.confirmPassword.touched">
                        <small class="text-danger" *ngIf="pdf.confirmPassword.errors.required">Confirm New Password is
                            required</small>
                        <small class="text-danger" *ngIf="pdf.confirmPassword.errors.mustMatch">Password didn't match</small>
                    </div>

                </div>
                <div class="form-group position-relative has-icon-left col-md-12">
                    <div class="row justify-content-center">
                        <div class="col-md-3" style="padding-top:10px;">
                            <button (click)="goBack()" nbButton status="primary" class=" btn-block">Cancel</button>
                        </div>
                        <div class="col-md-3" style="padding-top:10px;">
                            <button [disabled]="personaldetailsForm.invalid" type="submit" class="btn btn-block"
                                nbButton status="primary"
                                [ngClass]="personaldetailsForm.invalid ? 'btn-outline-secondary' : 'btn-outline-info'">
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </nb-card-body>
</nb-card>