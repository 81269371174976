<div>
  <div>
    <section>
      <div>

            <nb-card class="smallcard" [nbSpinner]="loading" nbSpinnerStatus="primary" nbSpinnerSize="giant" nbSpinnerMessage="Loading"> 
              <nb-card-header>Upload Vitals</nb-card-header>
              <div class="card-body">
                <div class="card-block" >

                  <form [formGroup]="vitalsForm">

                    <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-control-label" for="systolic">Systolic (mmHg)</label>
                            <input class="form-control" type="text" 
                               formControlName="systolic" />  
                               <small class="text-danger" *ngIf="pdf.systolic.touched  && pdf.systolic.invalid" >Invalid systolic</small>
                          </div> 
                          <div class="form-group">
                            <label class="form-control-label" for="daistolic">Diastolic (mmHg)</label>
                            <input class="form-control" type="text"
                               formControlName="daistolic" />  
                               <small class="text-danger" *ngIf="pdf.daistolic.touched  && pdf.daistolic.invalid" >Invalid daistolic</small>
                          </div>

                        </div>

                        <div class="col-md-6">

                          <div class="form-group">
                            <label class="form-control-label" for="pulse">Pulse (bpm)</label>
                            <input class="form-control" type="text"    
                               formControlName="pulse" />  
                               <small class="text-danger" *ngIf="pdf.pulse.touched  && pdf.pulse.invalid" >Invalid pulse</small>
                          </div>

                          <div class="form-group">
                            <label class="form-control-label" for="spo2">SpO2 (%)</label>
                            <input class="form-control" type="text"  
                               formControlName="spo2" />  
                               <small class="text-danger" *ngIf="pdf.spo2.touched  && pdf.spo2.invalid" >Invalid spo2</small>
                          </div>

                        </div>

                    </div>
                          <hr/>
                  <div class="row">
                      
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="form-control-label" for="height">Height (cm.)</label>
                        <input class="form-control" type="text" 
                           formControlName="height" />  
                           <small class="text-danger" *ngIf="pdf.height.touched  && pdf.height.invalid " > Height should be between 50-300 cm</small>
                      </div>
                    <div class="form-group">
                        <label class="form-control-label" for="weight">Weight (kg)</label>
                        <input class="form-control" type="text" 
                           formControlName="weight" />  
                        <small class="text-danger" *ngIf="pdf.weight.touched  && pdf.weight.invalid " > Weight should be between 0-300 kgs</small>
                      </div>                                
                    </div>

                    <div class="col-md-6" style="margin-top: 1px;">
                      <div class="form-group">
                        <label class="form-control-label" for="temp">Temperature (<span>&#176;</span>F)</label>
                        <input class="form-control" type="text" formControlName="temp" />  
                           <small class="text-danger" *ngIf="pdf.temp.touched  && pdf.temp.invalid " > Temperature should be between 85-125</small>
                      </div> 

                      <div class="form-group">
                        <label class="form-control-label" for="glucose">Glucose (mg/dL)</label>
                        <input class="form-control" type="text" formControlName="glucose" />  
                        <small class="text-danger" *ngIf="pdf.glucose.touched  && pdf.glucose.invalid " > Glucose should be between 0-999</small>
                      </div>
                    </div>

                  </div>

                  </form>
                  
                  <div class="card-body" style="text-align: center;margin: 10px;">
                    <button
                    nbButton status="primary" (click)="onCancel()">
                      Cancel
                    </button>

                    <button style="margin-left: 25px;"
                    nbButton status="primary" (click)="onSubmit()" [disabled]='!vitalsForm.valid || checkValid()'>
                      Submit
                    </button>

                </div>

                </div>
              </div>
              </nb-card>

        </div>
        </section>
        </div>

 </div>
