import { Input} from '@angular/core';
import { Component, OnInit} from '@angular/core';
import { lifeStyleModfications } from '../../_models/lifeStyleModificationsDetails';
import { PrescriptionPdfService } from '../../services/prescriptionpdf.service';
import { UnsubscribeOnDestroyAdapter } from '../../services/UnsubscribeOnDestroyadapter';
import { Store } from '@ngrx/store';
import { getDoctor } from 'src/app/store/selectors/doctor-user.selectors';
import { AppState } from 'src/app/store/root.reducer';
import { NbDialogRef } from '@nebular/theme';
import { APP_CONSTANTS } from '../../constants';

@Component({ templateUrl: 'prescriptionpdf.component.html' ,

styleUrls: ['prescriptionpdf.component.scss'],
providers: []
})
export class PrescriptionpdfComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
    @Input () from;
    patientName:string;
    patientNumber:string;
    patientMail:string;
    bloodGroup:string;
    bpCapturedTime:string;
    glucoCapturedTime:string;
    weightCapturedTime:string;
    tempCapturedTime:string;
    diseases:string;
    allergies:string;
    chiefComplaint:string;
    bloodPressure:string;
    temperature:string;
    pulse:string;
    bloodGlucose:string;
    weight:string="";
    pulseCapturedTime:string;
    height:string='';
    disease:string;
    age:string;
    address: string;
    gender:string;
    lmp: string;
    diabetic:any;
    thyroid:any;
    opened: boolean;
    clinicalnotes: any;
    prescriptionlist:any;
    lifeStyleModifications:lifeStyleModfications[]=[];
    newList:any;
    spO2:string;
    parentChannel:string="jojopoc"
    time1:string;
    reportno: string;
    mobileno: string;
    data: any;
    prescriptionid:string;
    buttontitle : string = "Go Home";
    meetingid : string;
    personid : string; 
    date : string; 
    currentUser: any;
    prescriptionhistory:boolean=false;
    doctorname : string; 
    doctorqualification : string=""; 
    doctormobile : string; 
    doctoremail : string; 
    doctoraddress : string; 
    doctorregno : string; 
    doctorspecalisation : string; 
    doctorsignature : string; 
    frequencyList:any=[];
    freq:any;
    isDisabled:boolean=false;
    prescriptionFrquency:any;
    showPublish : boolean =false;
    imagePath:string=APP_CONSTANTS.CONFIG_DETAILS.PRODUCT_LOGO_PATH;

    @Input() data1:any;
    
    output:string;
    @Input() name: string;
  username: string;
  consultation: boolean = true;
    constructor(private store:Store<AppState>,
      private apiService:PrescriptionPdfService,
      private dialogRef:NbDialogRef<PrescriptionpdfComponent>) {
      super()
    }
  
    ngOnInit() {
      this.username=this.name;
      if(this.from=='careManager'){
        this.prescriptionhistory=true;
      }
      else if(this.from=='consultation'){
        this.prescriptionhistory=false;
      }
      else if(this.from=='doctor' || this.from=='History')
        this.prescriptionhistory=true;
      else{}
      this.loadrescriptionDetails();
      this.loadUserDetails();
    }

    onCancel(){
      this.dialogRef.close(false);
    }
    
    loadUserDetails() {
      this.subs.sink=this.apiService.getUserDetails(this.username).subscribe((resData: any) => {
        let userData = resData;
        if(userData != null){
        this.doctorname = userData.given != null ? userData.given+" "+userData.family : "";
        this.doctorspecalisation = userData.practicing != null ? userData.practicing: "";
        this.doctormobile = userData.mobile != null ? userData.mobile: "";
        this.doctoremail = userData.email != null ? userData.email: "";
        this.doctorsignature = userData.signature != null ? userData.signature: "";
        this.doctorregno = userData.registrationNo != null ? userData.registrationNo: "";
        this.doctoraddress = userData.city != null ? userData.city +"-"+userData.postalcode: "";

        for(var i=0;i<userData.educationDTOList.length;i++){
          if(i==userData.educationDTOList.length-1)
            this.doctorqualification = this.doctorqualification+userData.educationDTOList[i].courseName;
          else
            this.doctorqualification = this.doctorqualification+userData.educationDTOList[i].courseName+", ";
        }
      }
  
      }, error => {
        var data = error;
      });
    }

    loadrescriptionDetails() {
        var resData =  this.data1.pageValue;
        this.data = resData;

      var profiledata = resData['allVitalsDTO'];

      this.patientName=profiledata.name;
      this.patientNumber=profiledata.mobile;
      this.weight=profiledata.weight != null && profiledata.weight != "undefined" ? profiledata.weight:"";
      this.height=profiledata.height != null && profiledata.height != "undefined" ? profiledata.height:"";
      this.age=profiledata.age;
      this.address = profiledata.hasOwnProperty("address") ? profiledata.address : "";
      this.gender = profiledata.gender != null ? profiledata.gender : "" ;
      this.bloodPressure= profiledata.highPressure != null ? profiledata.highPressure +"/" + profiledata.lowPressure : "--";
      this.temperature= profiledata.temperature != null ? profiledata.temperature : "--";
      this.pulse= profiledata.bpm != null ? profiledata.bpm : "--";
      this.spO2=profiledata.oxygen != null ? profiledata.oxygen : "--";
      this.bloodGlucose= profiledata.bloodSugar != null ? profiledata.bloodSugar : "--";
      if(profiledata['userHealthDetailsDTO']!= null) {
        this.bloodGroup = profiledata['userHealthDetailsDTO']['bloodGroup'];
        this.allergies=profiledata['userHealthDetailsDTO']['allergies'];
        this.lmp = profiledata['userHealthDetailsDTO']['lmp'] != null ? profiledata['userHealthDetailsDTO']['lmp'] : "" ;
        if(profiledata['userHealthDetailsDTO']['diabetic']!=null ){
        this.diabetic= this.stringToBoolean(profiledata['userHealthDetailsDTO']['diabetic']) ? "Yes" : "No";
        }
        if(profiledata['userHealthDetailsDTO']['thyroid'] !=null ){
          this.thyroid=this.stringToBoolean(profiledata['userHealthDetailsDTO']['thyroid']) ? "Yes" : "No";
        }
        this.disease= profiledata['userHealthDetailsDTO']['medicalHistory'];
        this.allergies=profiledata.userHealthDetailsDTO.allergies;
        this.disease=profiledata.userHealthDetailsDTO.medicalHistory;
      }
    
          this.time1 = resData['time'] != null ? resData['time'] : "" ;
          this.clinicalnotes = resData['clinicalNotes'];
          if(this.from=='History')
            this.lifeStyleModifications=resData['lifeStyleModificationsDTOList'];
          else 
            this.lifeStyleModifications=resData['lifeStyleModifications'];
          this.prescriptionlist = resData['prescriptionDetails'];
          // this.prescriptionlist.map(element=> {
          //   this.frequencyList.push(element.frequency);
          // });
          // this.newList=this.frequencyList.map(element=>{
          //   return this.medicineFrequency(element);
          // })
          this.prescriptionlist=this.prescriptionlist.map(element=>{
            return {
              ...element,name:this.medicineFrequency(element.frequency)
            }
          })
          if(this.prescriptionhistory){
          let userData = resData['registrationDTO'];
          if(userData != null){
            this.doctorname = userData.given != null ? userData.given+" "+userData.family : "";
            this.doctorspecalisation = userData.practicing != null ? userData.practicing: "";
            this.doctormobile = userData.mobile != null ? userData.mobile: "";
            this.doctoremail = userData.email != null ? userData.email: "";
            this.doctorsignature = userData.signature != null ? userData.signature: "";
            this.doctorregno = userData.registrationNo != null ? userData.registrationNo: "";
            this.doctoraddress = userData.city != null ? userData.city +"-"+userData.postalcode: "";
    
            for(var i=0;i<userData.educationDTOList.length;i++){
              if(i==userData.educationDTOList.length-1)
                this.doctorqualification = this.doctorqualification+userData.educationDTOList[i].courseName;
              else
                this.doctorqualification = this.doctorqualification+userData.educationDTOList[i].courseName+", ";
            }
          }
        }

    }
    
    private stringToBoolean(stringValue: string): boolean | undefined {  
      try {  
          return JSON.parse(stringValue);  
      }  
      catch (e) {  
          return undefined;  
      }  
  }  

    publish(){
      this.dialogRef.close({ event: 'close', data:"publish"});
    }
    close(){
      this.dialogRef.close(false);
    }

    medicineFrequency(frequency:any){
      var output:string='';
      if(frequency.includes('Morning')){
        output=output.concat('1');
      }
      else{
        output=output.concat('0');
      }
      if(frequency.includes('Afternoon')){
        output=output.concat('-1');
      }
      else{
        output=output.concat('-0');
      }
      if(frequency.includes('Night')){
        output=output.concat('-1');
      }
      else{
        output=output.concat('-0');
      }
      return output;
  }
  
}




    



  
