import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { PatientService } from 'src/app/shared/services/patient.service';
import { APP_CONSTANTS } from '../../constants';
import { UnsubscribeOnDestroyAdapter } from '../../services/UnsubscribeOnDestroyadapter';

@Component({
  selector: 'app-cancel-dialog',
  templateUrl: './cancel-dialog.component.html',
  styleUrls: ['./cancel-dialog.component.scss']
})
export class CancelDialogComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  reason: string;
  loading:boolean=false;
  @Input () data:any;
  @Input() username:any;
  @Input() from:any;
  constructor(private toastrService: NbToastrService,private apiservice:PatientService,private dialogRef:NbDialogRef<CancelDialogComponent>,private formBuilder:FormBuilder) {
    super()
  }
  ngOnInit(){
      
  }
  form:FormGroup = this.formBuilder.group({
    cancellationReason: ['',[Validators.required]],
  });
  get cancellationReason() {
    return this.form.get('cancellationReason') ;
  }
  get e() { return this.form.controls; }
  keyPressAlpha(event) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  onCancel(): void {
    this.dialogRef.close(false);
  }

  onSave() {
    this.loading=true;
    if(this.from=='Doctor')
      this.delete(this.data)
    else
      this.upcDelete(this.data)
  }

  delete(data1:any){
    this.reason=this.e.cancellationReason.value;
    let followupDTO: any = {};
    followupDTO.name = data1.customerName;
    followupDTO.contact = data1.customerMobile;
    followupDTO.appointmentDate = data1.startTime;
    followupDTO.appointmentId = data1.id;
    followupDTO.category = "Appointments";    
    followupDTO.reason = this.reason+ " by Dr." + this.username;
    this.subs.sink=this.apiservice.cancelAppointmentRequest(followupDTO).subscribe((resData: any) => {
      data1.cancelledBy = "Doctor";
      delete data1.toolTip;
      delete data1.showSuccess;
      delete data1.waitingTime;
      this.subs.sink=this.apiservice.rescheduleAppointment(data1).subscribe((resData: any) => {
        this.loading=false;
        this.dialogRef.close(true);
        this.toastrService.show('','Your appointment cancellation request has been sent successfully.',{duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
      }, error => {
        var data = error;
        this.loading=false;
      });
    }, error => {
      var data = error;
      this.loading=false;
    });
  }
  upcDelete(meeting: any) {
    this.reason=this.e.cancellationReason.value;
    meeting['status'] = "CANCELLED";
    meeting['cancelledBy'] = "Admin";
    meeting['cancellationReason'] = this.reason;
    delete meeting.waitingTime;
    this.subs.sink=this.apiservice.cancelAppointment(meeting).subscribe((data: any) => {
      this.loading = false;
      this.dialogRef.close(true);
      this.toastrService.show('', 'Appointment Cancelled Successfully.', { duration: APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON });

    }, error => {
      console.log(error);
      this.loading = false;
    });
  }

}
