export interface AdminHomeState {
    doctorsList:any,
    patientList: any,
    usersList:any,
    rolesList:any,
    AdminHomeDashboardLoading:boolean,
    adminUsersListLoading:boolean,
    adminDoctorListLoading:boolean,
    adminPatientListLoading:boolean,
    adminRolesListLoading:boolean
  }
  export const AdminDashboardInitialState: AdminHomeState = {
    doctorsList:null,
    patientList: null,
    usersList:null,
    rolesList:null,
    AdminHomeDashboardLoading:false,
    adminUsersListLoading:false,
    adminDoctorListLoading:false,
    adminPatientListLoading:false,
    adminRolesListLoading:false
  };