import { createReducer, on } from "@ngrx/store"
import { tokenUpdate } from "../actions/token.actions";
import { initialState } from '../state/token.state'
const _tokenReducer = createReducer(
    initialState,
    on(tokenUpdate, (state, action) => {
        return {
            ...state,
            token: action.token,
        }
    })
)

export function TokenReducer(state, action) {
    return _tokenReducer(state, action)
}