
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP_CONSTANTS } from 'src/app/shared/constants';
@Injectable({
    providedIn: 'root'
})
export class AdminService {

    constructor(private http: HttpClient) { }

    getUsers() {
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_ALL_USERS)
    }

    createRole(roleDTO) {
        let url = APP_CONSTANTS.REST_API_URL.SAVE_ROLES;
        return this.http.post(url, roleDTO)
    }
    getAllCustomers() {
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_CUSTOMER_DETAILS)
    }

    updateUserStatus(userId: string, status: boolean) {
        return this.http.get(APP_CONSTANTS.REST_API_URL.UPDATE_USER_STATUS + userId + "/" + status)
    }
  

    getDoctors() {
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_DOCTORS)
    }

    createUser(userDTO) {
        let url = APP_CONSTANTS.REST_API_URL.SAVE_ADMIN_USER;
        return this.http.post(url, userDTO)
    }

    getRoles() {
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_USER_ROLES)
    }

    saveCustomer(userDTO) {
        let url = APP_CONSTANTS.REST_API_URL.SAVE_PATIENT_REGISTRATION
        return this.http.post(url, userDTO)
    }
    validateMobile(mobile: any) {
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_MEMBERID_BY_PHONE + mobile)
    }

    registerMobile(userDTO) {
        let url = APP_CONSTANTS.REST_API_URL.REGISTER_MOBILE
        return this.http.post(url, userDTO)
    }

    pushFileToStorage(file: File, user: any) {
        const data: FormData = new FormData();
        data.append('file', file);
        let url = "";
        if (user == "patient")
            url = APP_CONSTANTS.REST_API_URL.IMPORT_PATIENT_DATA;
        else if (user == "refunds")
            url = APP_CONSTANTS.REST_API_URL.UPDATE_APPROVED_REFUNDS;
        else
            url = APP_CONSTANTS.REST_API_URL.SAVE_REG_FROM_EXCEL;
        return this.http.post(url, data, { responseType: 'text', observe: 'response' })
    }
    deleteUser(userDTO) {
        let url = APP_CONSTANTS.REST_API_URL.DELETE_USER_ROLES;
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            body: userDTO
        }
        return this.http.delete(url, options)
    }
    deletePrescreening(id) {
        return this.http.post(APP_CONSTANTS.REST_API_URL.DELETE_PRESCREENING +id,{})
    }

    deleteAppointment(id: string) {
        return this.http.get(APP_CONSTANTS.REST_API_URL.DELETE_APPOINTMENT + id)
    }
    deleteUserRole(roleId) {
        let url = APP_CONSTANTS.REST_API_URL.DELETE_ROLE
        return this.http.delete(url + roleId)
    }
    getAllAppointments() {
        let url = APP_CONSTANTS.REST_API_URL.GET_ALL_CUSTOMER_APPOINTMENTS;
        return this.http.get(url);
    }
    sendSms(smsParametersDTO: any) {
        let url = APP_CONSTANTS.REST_API_URL.SEND_OBJ_SMS;
        return this.http.post(url, smsParametersDTO)
    }

    regeneratePaymentLink(AppointmentDTO) {
        let url = APP_CONSTANTS.REST_API_URL.REGENERATE_PAYMENT_LINK;
        return this.http.post(url, AppointmentDTO)
    }

    resendPaymentLink(AppointmentDTO) {
        let url = APP_CONSTANTS.REST_API_URL.RESEND_PAYMENT_LINK + AppointmentDTO;
        return this.http.get(url)
    }

    intiateRefund(AppointmentDTO) {
        let url = APP_CONSTANTS.REST_API_URL.REFUND_REQUEST;
        return this.http.post(url, AppointmentDTO)
    }

    deleteCustomer(personId: any) {
        return this.http.delete(APP_CONSTANTS.REST_API_URL.DELETE_PROFILE + personId)
    }
    deleteDoctor(username: any) {
        return this.http.delete(APP_CONSTANTS.REST_API_URL.DELETE_DOCTOR + username)
    }
}

