import { Component, Input, OnInit, Pipe, PipeTransform } from '@angular/core';
import { PatientService } from '../services/patient.service';
import { NbDialogService } from '@nebular/theme';
import { ViewCell } from 'ng2-smart-table';
import { ConfirmDialogComponent } from '../shared-dialogue-components/confirmation-dialog/confirm-dialog.component';
import { UnsubscribeOnDestroyAdapter } from '../services/UnsubscribeOnDestroyadapter';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/root.reducer';
import { loadMedicationsList, LoadPrescriptionsList } from 'src/app/store/actions/cust-profile.actions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  template: `
      <div >
      <span (click)="viewPrescription(content,1)"><i class="fa fa-eye"  title="View"></i></span> &nbsp;
      <span 
        (click)="activateorDeactivatePresccription(1)" *ngIf="activated && prescriptionType!='manual'"><i class="fa fa-toggle-on" title="Deactivate" ></i>
      </span>
      <span 
        (click)="activateorDeactivatePresccription(1)" *ngIf="!activated && prescriptionType!='manual'"><i class="fa fa-toggle-off" title="Activate"></i>
      </span>
      </div>
      <div>
      <ng-template #content let-modal>
      <div class="modal-header">
      <h6 class="modal-title" id="modal-basic-title">Prescription View</h6>
    </div>
    <div class="modal-body">
    <div style="text-align:center;" *ngIf="showText"><i>No Data Found</i></div>
    <img class="image" *ngIf="!showText && con2" src="{{viewUrl}}" />
    <ngx-extended-pdf-viewer [showPrintButton]="false" [showBookmarkButton]="false" [showOpenFileButton]="false" [zoom]="'page-width'" *ngIf="!showText && !con2" [textLayer]="true" [src]="viewUrl"></ngx-extended-pdf-viewer>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">close</button>
    </div>
      </ng-template>
  </div>
      
    `,
})
export class CustomRenderComponent extends UnsubscribeOnDestroyAdapter implements ViewCell, OnInit {

  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;
  custromerData: any;
  mobileno: any;
  activated: boolean;
  id: any;
  prescriptionId: any;
  username: any;
  viewUrl: any;
  showText: boolean = true;
  con2: boolean;
  prescriptionType: any;

  constructor(private sanitizer: DomSanitizer,private modalService: NgbModal,private apiService: PatientService, private dialogService: NbDialogService, private store: Store<AppState>) {
    super()

  }

  ngOnInit() {
    this.renderValue = '';
    this.custromerData = this.rowData;
    this.activated = this.custromerData.activated;
    this.prescriptionType = this.custromerData.prescriptionType;
    this.mobileno = this.value;
    this.id = this.custromerData.prescriptionId;
    this.username = this.custromerData.doctorName;
  }
  viewPrescription(content,from) {
    var data1 = JSON.parse(JSON.stringify(this.rowData));
    this.viewUrl = data1.filePath;
    if (this.viewUrl == null){
      this.showText = true;
    }
    else{
      this.showText = false;
      if (this.viewUrl.includes('pdf')) {
        this.con2 = false;
      }else{
        this.con2 = true;
      }
    }
    this.viewUrl= this.viewUrl;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
  }
  activateorDeactivatePresccription(from) {
    var data1 = JSON.parse(JSON.stringify(this.rowData));
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      context: {
        title: `Are you sure, you want to ` + (data1.activated ? "deactivate" : "activate") + " this prescription?"
      },
      autoFocus: false
    })
    dialogRef.onClose.subscribe({
      next: (res) => {
        if (res) {
          this.subs.sink = this.apiService.updatePrescriptionStatus(this.id, !this.activated).subscribe((resData: any) => {
            this.store.dispatch(loadMedicationsList({"personId":this.custromerData?.personId}));
            this.loadPrescriptionhistory();
          }, error => {
            var data = error;
          });
        }
      }
    });
  }
  consultationList: any = [];
  loadPrescriptionhistory() {
    this.store.dispatch(LoadPrescriptionsList({ "mobileno": this.mobileno }));
  }
}