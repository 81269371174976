import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ngx-user-basic-details',
  styleUrls: ['./user-basic-details.component.scss'],
  templateUrl: './user-basic-details.component.html',
})
export class UserBasicDetailsComponent {
  @Output() openPopup=new EventEmitter(); 
  @Input() showEmail:boolean;
  @Input() showName:boolean;
  @Input() username: string;
  @Input() type: string;
  @Input() field1:string;
  @Input() field2:string;

  @Input() field3:string;
  @Input() field4:string;
  @Input() value1:string;
  @Input() value2:string;
  @Input() value3:string;
  @Input() value4:string;
  @Input() size:string;
  @Input() headers:any[];
  @Input() elements:any[];
  @Input() isPatient:boolean;
  
  editPopup(){
    this.openPopup.next('');
  }
}