import { createReducer, on } from "@ngrx/store"
import {PatientAppointmentListLoading, PrescriptionsListLoading,UpdateMedicationsList,UpdatePatientAppointmentList,UpdatePrescriptionsList, UpdateVitals, VitalsLoading } from "../actions/cust-profile.actions"
import { updateDoctor } from "../actions/doctor-user.actions"
import { CustProfileInitialState } from "../state/cust-profile.state"
import { initialDoctorState } from "../state/doctor-user.state"

const _CustProfileReducer = createReducer(
    CustProfileInitialState,
    on(UpdatePrescriptionsList, (state, action) => {
        console.log(action.prescriptionsList)
        return {
            ...state,
            prescriptionsList:action.prescriptionsList,
            prescriptionsListLoading:false
        }
    }),
    on(UpdateVitals, (state, action) => {
        return {
            ...state,
            vitals:action.vitals,
            VitalsLoading:false,
        }
    }),
    on(PrescriptionsListLoading, (state, action) => {
        return {
            ...state,
            prescriptionsListLoading:true,
        }
    }),
    on(VitalsLoading, (state, action) => {
        return {
            ...state,
            VitalsLoading:true,
        }
    }),
    on(UpdatePatientAppointmentList, (state, action) => {
        console.log(action.patientAppointmentList)
        return {
            ...state,
            patientAppointmentList:action.patientAppointmentList,
            PatientAppointmentListLoading:false
        }
    }),
    on(PatientAppointmentListLoading, (state, action) => {
        return {
            ...state,
            patientAppointmentListLoading:true,
        }
    }),
    on(PatientAppointmentListLoading, (state, action) => {
        return {
            ...state,
            patientAppointmentListLoading:true,
        }
    }),
    on(UpdateMedicationsList, (state, action) => {
        return {
            ...state,
            userMedicationList:action.userMedicationList,
            activeMedicationList:action.activeMedicationList
        }
    }),

)

export function CustProfileReducer(state, action) {
    return _CustProfileReducer(state, action)
}