<div>
  <div>
    <section>
      <div>

            <nb-card class="smallcard" [nbSpinner]="loading" nbSpinnerStatus="primary" nbSpinnerSize="giant" nbSpinnerMessage="Loading"> 
              <nb-card-header>Past Medications</nb-card-header>
              <div class="card-body">
                <div class="card-block" >
                  <div *ngIf="medicationList.length == 0">
                    <div class="col-12">
                      No past medications found.
                    </div>
                  </div>
                  <div class="divrow d-flex" *ngFor="let medication of medicationList">
                    <div class="col-md-3">
                      <div *ngIf="medication.uploadType=='AUTO'" class="stats-icon purple mt-2">
                        <i class="fa-solid fa-prescription fa-2x mx-2" style="font-size:24px"></i>
                      </div>
                      <div *ngIf="medication.uploadType=='MANUAL'" class="stats-icon purple mt-2">
                        <i class="fas fa-user-plus fa-2x mx-2" style="font-size:24px"></i>
                      </div>
                    </div>
                    <div class="col-md-9">
                      <div style="font-size: small;font-weight:700">{{medication.drugName | uppercase}}</div>
                      <p style="font-size: small;margin-bottom:1px;">Used till :{{Convert24hrto12hrsDate(medication.endTime)}}</p>
                      <h6 style="font-size: smaller;margin-top:0px;" class="font-weight-light "><span
                          style="font-size: smaller;" class="font-weight-light">
                          <div class="font-weight-light">
                            <p style="font-size: small;margin-bottom:1px;">Frequecy: {{Convert24hrto12hrs(medication.frequency)}} </p>
                            
                          </div>
    
                        </span></h6>
                       
                    </div>
                  </div>

                  
                  <div class="card-body" style="text-align: center;margin: 10px;">
                    <button
                    nbButton status="primary" (click)="onCancel()">
                      Close
                    </button>

                    

                </div>

                </div>
              </div>
              </nb-card>

        </div>
        </section>
        </div>

 </div>
