import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { APP_CONSTANTS } from '../shared/constants';
import { RegisterComponent } from '../shared/register/register.component';
import { ComponentsComponent } from './components.component';
import { LoginComponent } from './login/login.component';
import { LoginResolver } from './login/login.resolver';
import { PatientConsultationComponent } from './patientconsultation/patientconsultation.component';
import { PatientSdkComponent } from './patientvideosdk/patientvideosdk.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ForgotPasswordComponent } from '../shared/forgot-password/forgot-password.component';
import { PrescriptiontestComponent } from './prescriptiontest/prescriptiontest.component';
import { ReportsuploadComponent } from './reportsupload/reportsupload.component';
import { StatusPageComponent } from './statuspage/statuspage.component';
import { PatientConsultationAutoComponent } from './patientconsultation-auto/patientconsultation-auto.component';

const routes: Routes = [
  {
    path: '',
    component: ComponentsComponent,
    children: [
      {
        path: APP_CONSTANTS.LOGIN_ROUTE.LOGIN,
        resolve: {
          roles: LoginResolver
        },
        component: LoginComponent,
      },
      {
        path: APP_CONSTANTS.LOGIN_ROUTE.FORGOT_PASSWORD,
        component: ForgotPasswordComponent,
      },
      {
        path: APP_CONSTANTS.DOCTOR_ROUTE.REGISTER,
        component: RegisterComponent,
      },
      {
        path: APP_CONSTANTS.LOGIN_ROUTE.UPLOAD_REPORTS,
        component: ReportsuploadComponent,
      },
      {
        path: APP_CONSTANTS.LOGIN_ROUTE.PRESCRIPTIONPDF,
        component: PrescriptiontestComponent,
      },
      {
        path: APP_CONSTANTS.LOGIN_ROUTE.PATIENT,
        component: PatientConsultationComponent,
      },
      {
        path: APP_CONSTANTS.LOGIN_ROUTE.PATIENTMEETVIEW,
        component: PatientSdkComponent,
      },
      {
        path: APP_CONSTANTS.LOGIN_ROUTE.TERMS_CONDITIONS,
        component: TermsConditionComponent,
      },
      {
        path: APP_CONSTANTS.LOGIN_ROUTE.PRIVACY_POLICIES,
        component: PrivacyPolicyComponent,
      },
      {
        path: APP_CONSTANTS.LOGIN_ROUTE.STATUSMESSAGE,
        component: StatusPageComponent,
      },
      {
        path: APP_CONSTANTS.LOGIN_ROUTE.PATIENT_AUTO,
        component: PatientConsultationAutoComponent,
      },
      { path: '', redirectTo: APP_CONSTANTS.LOGIN_ROUTE.LOGIN, pathMatch: 'full' }
    ]
  },
  {
    path: APP_CONSTANTS.LOGIN_ROUTE.PATIENT,
    component: PatientConsultationComponent,
  },
  {
    path: APP_CONSTANTS.LOGIN_ROUTE.PATIENTMEETVIEW,
    component: PatientSdkComponent,
  },
  {
    path: APP_CONSTANTS.LOGIN_ROUTE.PRESCRIPTIONPDF,
    component: PrescriptiontestComponent,
  },
  {
    path: APP_CONSTANTS.LOGIN_ROUTE.UPLOAD_REPORTS,
    component: ReportsuploadComponent,
  },
  {
    path: APP_CONSTANTS.LOGIN_ROUTE.STATUSMESSAGE,
    component: StatusPageComponent,
  },


   { path: '', redirectTo: APP_CONSTANTS.APP_ROUTE.AUTH, pathMatch: 'full' }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ComponentsRoutingModule { }
