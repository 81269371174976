import { Component, OnInit, ViewChild, LOCALE_ID, Inject, Input, ElementRef } from '@angular/core';
import { CalendarDateFormatter, CalendarView, DateAdapter } from 'angular-calendar';
import { Subject } from 'rxjs';
import { endOfDay, addMonths } from 'date-fns';
import { addPeriod, CalendarSchedulerEvent, CalendarSchedulerEventAction, CalendarSchedulerViewComponent, DAYS_IN_WEEK, endOfPeriod, SchedulerDateFormatter, SchedulerEventTimesChangedEvent, SchedulerViewDay, SchedulerViewEvent, SchedulerViewHour, SchedulerViewHourSegment, startOfPeriod, subPeriod } from 'angular-calendar-scheduler';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { ScheduleService } from '../services/schedule.service';
import { AvailableBlockStatusComponent } from '../shared-dialogue-components/availableblockstatus/availableblockstatus.component';
import { NbDialogService, NbThemeService } from '@nebular/theme';
import { NbToastrService } from '@nebular/theme';
import { UnsubscribeOnDestroyAdapter } from '../services/UnsubscribeOnDestroyadapter';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/root.reducer';
import { ConfirmDialogComponent } from '../shared-dialogue-components/confirmation-dialog/confirm-dialog.component';
import { APP_CONSTANTS } from '../constants';
@Component({
  selector: 'app-doctor-schedule',
  templateUrl: './doctor-schedule.component.html',
  styleUrls: ['./doctor-schedule.component.scss'],
  providers: [{
    provide: CalendarDateFormatter,
    useClass: SchedulerDateFormatter
  }]
})
export class DoctorScheduleComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  temp = [];
  rows: any = [];
  CalendarView = CalendarView;
  @Input() username: any;
  @Input() doctorId: any;
  @Input() doctorName: any;
  @Input() text: any;
  view: CalendarView = CalendarView.Week;
  viewDate: Date = new Date();
  viewDays: number = DAYS_IN_WEEK;
  refresh: Subject<any> = new Subject();
  locale: string = 'en';
  hourSegments: number = 1;
  weekStartsOn: number = 1;
  startsWithToday: boolean = true;
  activeDayIsOpen: boolean = true;
  excludeDays: number[] = []; // [0];
  weekendDays: number[] = [0, 6];
  doctorsList = [];
  dayStartHour: number = 0;
  dayEndHour: number = 24;
  dataTableMessage: any = { emptyMessage: 'Loading...' };
  minDate: Date = new Date();
  maxDate: Date = endOfDay(addMonths(new Date(), 1));
  editId: any;
  isServiceEnded = false;
  dayModifier: Function;
  hourModifier: Function;
  segmentModifier: Function;
  eventModifier: Function;
  prevBtnDisabled: boolean = false;
  nextBtnDisabled: boolean = false;
  DoctorID: string;

  datetimerange: any;
  // username:string;

  actions: CalendarSchedulerEventAction[] = [
    {
      when: 'enabled',
      label: '<span class="valign-center"><i class="fa fa-x">cancel</i></span>',
      title: 'Delete',
      onClick: (event: CalendarSchedulerEvent): void => {
        this.deleteEvent(event.id);
      }
    }
  ];

  events: CalendarSchedulerEvent[] = [];
  @ViewChild(CalendarSchedulerViewComponent) calendarScheduler: CalendarSchedulerViewComponent;
  eventId: any;
  timeList: any;
  schedulerViewEvents: SchedulerViewEvent[];
  timer: any;
  actionViewChanged: boolean = false;
  primaryColor: any;
  constructor(private nbThemeService: NbThemeService, private store: Store<AppState>, private toastrService: NbToastrService, private dialogService: NbDialogService, private apiService: ScheduleService, @Inject(LOCALE_ID) locale: string, private dateAdapter: DateAdapter, private elem: ElementRef) {
    super()
    this.locale = locale;
    this.nbThemeService.getJsTheme().subscribe((data) => {
      this.primaryColor = data.variables.primaryColor
    });
  }

  ngOnInit() {
    this.getBlockedRecords()
    this.loadAppointments();
  }
  refreshEvents() {
    let elements = this.elem.nativeElement.querySelectorAll('div.cal-scheduler-event-container');
    elements.forEach(ele => {
      this.actionViewChanged = true;
      ele.setAttribute('style', 'top:' + ele.style.top.split('px')[0] * 2.465 + 'px;width:-webkit-fill-available');
    });
    let elements1 = this.elem.nativeElement.querySelectorAll('div.cal-scheduler-event.cal-disabled');
    elements1.forEach(ele => {
      ele.setAttribute('style', 'filter:none;background-color:lightgrey !important');
    });
  }
  ngAfterViewInit() {
    this.timer = setInterval(
      () => {
        if (!this.actionViewChanged) {
          this.refreshEvents();
        } else {
          clearInterval(this.timer);
        }
      }
    );
    // you'll get your through 'elements' below code

  }
  getBlockedRecords() {
    this.dayModifier = ((day: SchedulerViewDay): void => {
      if (!this.isDateValid(day.date, null)) {
        day.cssClass = 'cal-disabled';
      }
    }).bind(this);
    var a = 0;
    this.hourModifier = ((hour: SchedulerViewHour): void => {
      // hour.events=hour.events.map((hr)=>{
      //   hr.top=hr.top*4;
      //   return hr
      // }
      // );
      if (!this.isDateValid(hour.date, null)) {
        hour.cssClass = 'cal-disabled1';
        // hour.events=this.events.map((event)=>{
        //   return {
        //     event:event,top:2000,width:50,left:23
        //   }
        // });
      }
    }).bind(this);
    this.segmentModifier = ((segment: SchedulerViewHourSegment): void => {
      if (!this.isDateValid(segment.date, null)) {
        segment.isDisabled = true;
      }
    }).bind(this);
    this.eventModifier = ((event: CalendarSchedulerEvent): void => {
      event.isDisabled = !this.isDateValid(event.start, this.rows);
    }).bind(this);
    this.dateOrViewChanged();
    this.refresh.next();
  }

  loadAppointments() {
    this.subs.sink = this.apiService.getDoctorAppointments(this.username).subscribe(res => {
      this.events = []
      res.forEach(apnmt => {
        if (apnmt.cancelledBy != "Doctor" && this.text!="fromAdmin" ||this.text=="fromAdmin") {
          let start = new Date(new Date(apnmt.startTime));
          let end = new Date(new Date(apnmt.startTime).setTime(new Date(apnmt.startTime).getTime() + apnmt.duration * 60 * 1000));
          let isDisabled = moment(start).isBefore(new Date());
          this.events.push(<CalendarSchedulerEvent>{
            id: apnmt.id,
            start: start,
            end: end,
            title: apnmt.customerName,
            content: apnmt.topic,
            cssClass: 'test',
            color: !isDisabled ? {
              primary: this.primaryColor,
              secondary: '#D1E8FF',
            } : {
              primary: 'white',
              secondary: this.primaryColor + ' !important',

            },
            actions: !isDisabled ? this.actions : [],
            isClickable: true,
            draggable: false,
            resizable: {
              beforeStart: false,
              afterEnd: false
            }
          })
        }
      });
      this.refresh.next();
    }, error => {

    });
  }

  viewDaysOptionChanged(viewDays: number): void {
    this.calendarScheduler.setViewDays(viewDays);
  }

  changeDate(date: Date): void {
    this.viewDate = date;
    this.dateOrViewChanged();
  }

  changeView(view: CalendarView): void {
    this.view = view;
    this.dateOrViewChanged();
  }

  dateOrViewChanged(): void {
    if (this.startsWithToday) {
      this.prevBtnDisabled = !this.isDateValid(subPeriod(this.dateAdapter, CalendarView.Day/*this.view*/, this.viewDate, 1), null);
      this.nextBtnDisabled = !this.isDateValid(addPeriod(this.dateAdapter, CalendarView.Day/*this.view*/, this.viewDate, 1), null);
    } else {
      this.prevBtnDisabled = !this.isDateValid(endOfPeriod(this.dateAdapter, CalendarView.Day/*this.view*/, subPeriod(this.dateAdapter, CalendarView.Day/*this.view*/, this.viewDate, 1)), null);
      this.nextBtnDisabled = !this.isDateValid(startOfPeriod(this.dateAdapter, CalendarView.Day/*this.view*/, addPeriod(this.dateAdapter, CalendarView.Day/*this.view*/, this.viewDate, 1)), null);
    }

    if (this.viewDate < this.minDate) {
      this.changeDate(this.minDate);
    } else if (this.viewDate > this.maxDate) {
      this.changeDate(this.maxDate);
    }
  }

  isDateValid(date: Date, rows: any): boolean {
    if (rows == null) {
      return /*isToday(date) ||*/ date >= this.minDate && date <= this.maxDate;
    } else {
      var flag = date >= this.minDate && date <= this.maxDate
      this.rows.forEach(element => {
        var from = new Date(element.startTime);
        var to = new Date(element.endTime);
        if ((date < to && date >= from)) {
          flag = false;
        }
      });
      return flag;
    }
  }

  viewDaysChanged(viewDays: number): void {
    this.viewDays = viewDays;
  }

  dayHeaderClicked(day: SchedulerViewDay): void {
    this.loadDoctorSlotDetails(day);
  }

  hourClicked(hour: SchedulerViewHour): void {
  }

  segmentClicked(action: string, segment: SchedulerViewHourSegment): void {
  }

  eventClicked(action: string, event: CalendarSchedulerEvent): void {

  }

  eventTimesChanged({ event, newStart, newEnd }: SchedulerEventTimesChangedEvent): void {
    let ev = this.events.find(e => e.id === event.id);
    ev.start = newStart;
    ev.end = newEnd;
    this.refresh.next();
  }

  openOtherDialog() {

    const message = `Are you sure, you want to delete?`;
    this.dialogService.open(ConfirmDialogComponent, {
      context: {
        title: message,
        from: 'schedule',
        data: this.eventId
      }, dialogClass: 'model-full',
      autoFocus: false
    });
  }

  deleteEvent(eventId) {
    this.eventId = eventId;
    this.openOtherDialog();
  }

  AvailBlockPopup(row: any) {
    this.dialogService.open(AvailableBlockStatusComponent, {
      context: {
        date: row,
        timeList: this.timeList,
        doctorId: this.doctorId,
        doctorname: this.username
      }, dialogClass: 'model-full',
      autoFocus: false
    })
      .onClose.subscribe({
        next: (res) => {
          if (res == 'cancel') { }
          if (res == 'success') {
            let duration = 2000;
            this.toastrService.show('', 'Slots has been updated successfully.', { duration: APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON });
          }
          else { }
        }

      });
  }

  loadDoctorSlotDetails(day: any) {
    const datepipe1: DatePipe = new DatePipe('en-US');
    var date = datepipe1.transform(day.date, 'yyyy-MM-dd');
    this.timeList = [];
    this.subs.sink = this.apiService.getDoctorAvailableSlotDetails(this.doctorId, date).subscribe((resData: any) => {
      this.timeList = resData;
      this.AvailBlockPopup(date);
    }, error => {
      var data = error;
    });
  }
}