import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { PatientService } from '../../services/patient.service';
import { NbDateService, NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { ConfirmDialogComponent } from '../confirmation-dialog/confirm-dialog.component';
import { UnsubscribeOnDestroyAdapter } from '../../services/UnsubscribeOnDestroyadapter';
import { APP_CONSTANTS } from '../../constants';

@Component({
  selector: 'reportsupload-dialog',
  templateUrl: './reportsupload.component.html',
  styleUrls: ['./reportsupload.component.scss'],
  providers: [PatientService,
    DatePipe]
})

export class ReportsUploadDialogComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  personId: string;
  reportsForm: FormGroup;
  @Input() max: any;
  @Input() data: any;
  @Input() from: any;
  tomorrow = new Date();
  fileToUpload: File = null;
  report: string = "";
  mobileno: string;
  submitted: boolean = false;
  skip: boolean;
  datepipe: DatePipe = new DatePipe('en-US');
  loading: boolean = false;
  constructor(protected dateService: NbDateService<Date>, private dialogService: NbDialogService, private toastrService: NbToastrService, private dialogRef: NbDialogRef<ReportsUploadDialogComponent>, private formBuilder: FormBuilder,
    private apiService: PatientService
  ) {
    super()


  }

  ngOnInit() {
    this.personId = this.data.memberDTO.personId;
    this.mobileno = this.data.memberDTO.Mobile;
    if (this.data.skip)
      this.skip = true;
    this.tomorrow = this.dateService.addDay(this.dateService.today(), 0);
    // this.tomorrow.setDate(this.tomorrow.getDate() + 0);
    this.reportsForm = this.formBuilder.group({
      reportName: ['', Validators.required],
      measureTime: ['', Validators.required],
      reportCategory:['', Validators.required]
    });
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    if (this.fileToUpload != null) {
      const reader = new FileReader();
      reader.readAsDataURL(this.fileToUpload);
      reader.onload = () => {

        this.report = reader.result.toString();
      };
    }
    else
      this.report = "";
  }

  get pdf() {
    return this.reportsForm.controls;
  }


  onSave() {
    this.submitted = true;
    if (this.reportsForm.invalid) {
      return;
    }

    if (this.report == "") {
      this.toastrService.show('', 'Please upload the report..', {duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
      return;
    }

    this.reportsForm.value.measureTime = this.datepipe.transform(this.reportsForm.value.measureTime, "yyyy-MM-dd HH:mm:ss");
    this.reportsForm.value.mobileNumber = this.mobileno;
    this.reportsForm.value.reportAttachment = this.report;
    this.loading = true;
    this.subs.sink = this.apiService.uploadReports(this.reportsForm.value).subscribe((resData: any) => {

      if (resData['data']['uploadStatus']) {
        // this.dialogRef.close(true);
        this.toastrService.show('', 'Report has been uploaded successfully.', {duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
      }
      else {
        // this.dialogRef.close(true);
        this.toastrService.show('', 'Unable to upload the Report.', {duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
      }
      this.loading = false;
      this.dialogRef.close({ event: 'refresh' });

    }, error => {
      this.loading = false;
      var data = error;
    });
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  onCancelClick(data: any) {

  }

  onSkip() {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      context: {
        title: 'Are you sure, you want to skip?',
        from: 'reports'
      },
      autoFocus: false
    })
    dialogRef.onClose.subscribe(dialogResult => {
      if (dialogResult) {
        this.dialogRef.close({ event: 'refresh' });
      }
    });
  }
  onSubmit() {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      context: {
        title: 'Are you sure, you want to go to countinue?',
        from: 'reports'
      },
      autoFocus: false
    })
    dialogRef.onClose.subscribe(dialogResult => {
      console.log(dialogResult);
      if (dialogResult) {
        this.subs.sink = this.apiService.submitEHR(this.personId).subscribe((resData: any) => {
        this.toastrService.show('', 'EHR submitted successfully.', {duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
        }, error => {
          this.loading = false;
          var data = error;
        });
        this.dialogRef.close({ event: 'refresh' });
      }
    });
  }
}
