import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, retry, tap, timestamp } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../store/root.reducer';
import { environment } from 'src/environments/environment';
import { getToken } from '../store/selectors/token.selectors';
import { APP_CONSTANTS } from './constants';
import { tokenUpdate } from '../store/actions/token.actions';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private store: Store<AppState>) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // req = req.clone({
    //       url: `${environment.API_REST_URL + req.url}`,
    //     });
    // if (req.url == APP_CONSTANTS.REST_API_URL.FETCH_USER_WITH_REFID || req.url == APP_CONSTANTS.REST_API_URL.USER_REGIS_QUESTIONS || req.url == APP_CONSTANTS.REST_API_URL.USER_REGIS_ANSWERS) {
    //   req = req.clone({
    //     url: `${environment.REST_API_URL + req.url}`,
    //   });
    // } else {
      const match1 = APP_CONSTANTS.OUTSIDE_URlS.find(element => {
        if (req.url.includes(element)) {
          return true;
        }
      });
      // if(APP_CONSTANTS.OUTSIDE_URlS.includes(req.url)){

      if(!match1){
        const match = APP_CONSTANTS.SESSION_IGNORE.find(element => {
          if (req.url.includes(element)) {
            return true;
          }
        });
        if(!match){
          this.store.select(getToken).subscribe(data => { 
            if (data != null && data.token) {
              req = req.clone({
                setHeaders: {
                  Authorization: data.token,
                },
                url: `${environment.API_REST_URL + req.url}`,
              });
            }
          });
        } else{
          req = req.clone({
            // setHeaders: {
            //   Authorization: '',
            // },
            url: `${environment.API_REST_URL + req.url}`,
          });
        } 
      }
      req.clone({

      })
    
    // }

    return next.handle(req).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse) {
          if ((req.url == environment.API_REST_URL + APP_CONSTANTS.REST_API_URL.DOCTOR_LOGIN) || (req.url == environment.API_REST_URL + APP_CONSTANTS.REST_API_URL.ADMIN_LOGIN)) {
            if (evt.headers.get('Authorization')) {
              this.store.dispatch(tokenUpdate({ token: evt.headers.get('Authorization') }));
            }
          }
          // if (evt.body.status == 'FAILURE' || evt.body.statusCode == 'JWT_TOKEN_ERR002' || evt.body.statusCode == 'RT002' || evt.body.statusCode == 'ALDR403') {
          //   this.errorDialogService.openDialog(evt.body.message ?? JSON.stringify(evt.body.description), evt.body.status);
          // }
        }
      }),
      // catchError((error: HttpErrorResponse) => {
      //   // if(error.status==0){
      //   //   this.errorDialogService.openDialog(error.message);
      //   // }else{
      //   //   this.errorDialogService.openDialog(error.error.message ?? JSON.stringify(error.error), error.status);
      //   // }
      //   // console.error("Error from error interceptor", error);
      //   return throwError(error);
      // }),
      finalize(() => {
      })
    ) as Observable<HttpEvent<any>>;
  }
}
export const useEncoding = true;