import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from "@ngrx/store";
import { concatMap, map} from "rxjs/operators";
import { AdminPrescreeningService } from "src/app/adminportal/service/pre-screening.service";
import { adminPrescreeningListLoading, loadAdminPreScreening,updateAdminPreScreening } from "../actions/admin-prescreening.action";
import { AppState } from "../root.reducer";

@Injectable()
export class adminPreScreeningEffects {
    constructor(private actions$: Actions,private apiService:AdminPrescreeningService, private store :Store<AppState>) {

    }
    loadAdminPreScreening$ = createEffect(
        () => this.actions$
            .pipe(
                ofType(loadAdminPreScreening),
                concatMap(action => {
                    this.store.dispatch(adminPrescreeningListLoading());
                    return this.apiService.getAllQuestions();
                }),
                map(data => {
                    if(data)
                        return updateAdminPreScreening({ adminPrescreeningList: data })
                })
            )
    );
}