import { Component, Inject, Input, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/root.reducer';
import { getDoctor } from 'src/app/store/selectors/doctor-user.selectors';
import { APP_CONSTANTS } from '../../constants';
import { MeetingService } from '../../services/meeting.service';
import { UnsubscribeOnDestroyAdapter } from '../../services/UnsubscribeOnDestroyadapter';
import { ConfirmDialogComponent } from '../confirmation-dialog/confirm-dialog.component';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent extends UnsubscribeOnDestroyAdapter implements OnInit  {
  @Input () data:any;
   user:any;s
  payload:any;
  loading: boolean = false;
  constructor(private store:Store<AppState>,private toastrService: NbToastrService,private dialogRef:NbDialogRef<ConfirmDialogComponent>,private apiService:MeetingService) {
    super() 
    this.store.select(getDoctor).subscribe(data => {
      this.user = data?.username;
    });
  }
  ngOnInit() {
    
  }
  onNoClick(): void {
    this.loading = true;
    console.log(this.user);
    this.subs.sink=this.apiService.getDoctorStatus(this.user).subscribe(res => {
      this.loading=false;
      this.payload=res;

      if(this.payload.checkIn)
      {
       this.dialogRef.close(false);
      }
      else{
        this.toastrService.show('','Action already done from other device.',{duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
        this.dialogRef.close(false);

      }
  })
   
  }
  onYesClick(): void {
    this.loading = true;
    this.subs.sink=this.apiService.getDoctorStatus(this.user).subscribe(res => {
      this.loading=false;
      this.payload=res;
      if(this.payload.checkIn)
      {
       
        this.dialogRef.close(true);
      }
      else
      {
        this.toastrService.show('','Action already done from other device.',{duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
        this.dialogRef.close(false);
      }
  })
   
  }
}


