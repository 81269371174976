import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/root.reducer';
import { getPatientList } from 'src/app/store/selectors/home.selectors';

@Component({
  selector: 'app-patient-sdk-view',
  templateUrl: './patientvideosdk.component.html',
  styleUrls: ['./patientvideosdk.component.scss']
})
export class PatientSdkComponent implements OnInit {

  meetingnumber:string;
  doctorDetails:any;
  custromerData: any;
  tempmobileno:string;
  username:string;
  from:string="Patient"
  mobileno:string;
  row: any;
  constructor(private router: Router,private store:Store<AppState>) {
    let data = this.router.getCurrentNavigation() ? this.router.getCurrentNavigation()["extras"]["state"]: null;

    if(data.id)
    {
      this.meetingnumber=data.id;
      this.username=data.username;
    }
    if(!this.meetingnumber)
    {
      this.router.navigate(['/login']);
    }


}

  ngOnInit() {
  }


}
