import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { APP_CONSTANTS } from 'src/app/shared/constants';
@Injectable({
  providedIn: 'root'
})
export class AdminPrescreeningService{

  constructor(private http: HttpClient) { }

  getAllQuestions(){
    return this.http.get(APP_CONSTANTS.REST_API_URL.GET_ADMIN_PRESCREENING_QUESTIONS)
  }

  getAllfilteredComplanits(term:String)
  {
    if (term === '') {
      return of([]);
  }
    return this.http.get(APP_CONSTANTS.REST_API_URL.GET_ADMIN_PRESCREENING_COMPLAINTS ).pipe(
      map((response: any) => {
          let list = [];
          for (var ent of response) {
            if(ent.toLowerCase().includes(term.toLowerCase()))
            {
              list.push(ent)
            }
          }
          return list;
      }));
  }
  getQuestionsDetailsById(id){
    return this.http.get(APP_CONSTANTS.REST_API_URL.GET_ADMIN_PRESCREENING_DETAILS_BY_ID + id)  
  }
  savePrescreening(body){
    return this.http.post(APP_CONSTANTS.REST_API_URL.SAVE_ADMIN_PRESCREENING,body)
  }
  getChildQuestions(cheifComplaint){
    return this.http.get(APP_CONSTANTS.REST_API_URL.GET_CHILD_QUESTIONS_BY_CHEIFCOMPLAINT + cheifComplaint)  
  }
  getAllCheifCompliants(){
    return this.http.get(APP_CONSTANTS.REST_API_URL.GET_ALL_CHIEF_COMPLIANTS)  ;
  }

}