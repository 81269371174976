import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from "@ngrx/store";
import { forkJoin } from "rxjs";
import { concatMap, map, mergeMap, switchMap } from "rxjs/operators";
import { PatientService } from "src/app/shared/services/patient.service";
import {loadMedicationsList, loadPatientAppointmentList, LoadPrescriptionsList,LoadVitals,PatientAppointmentListLoading,PrescriptionsListLoading,UpdateMedicationsList,UpdatePatientAppointmentList,UpdatePrescriptionsList, UpdateVitals, VitalsLoading } from "../actions/cust-profile.actions"
import { AppState } from "../root.reducer";

@Injectable()
export class CustProfileEffects {
    constructor(private actions$: Actions,private patientService:PatientService, private store :Store<AppState>) {

    }
    LoadPrescriptionsList$ = createEffect(
        () => this.actions$
            .pipe(
                ofType(LoadPrescriptionsList),
                concatMap(action => {
                    this.store.dispatch(PrescriptionsListLoading());
                    return this.patientService.getPatientPrescriptions(action.mobileno);
                }),
                map(data => {
                    if(data)
                        return UpdatePrescriptionsList({ prescriptionsList: data })
                })
            )
    );
    LoadPatientAppointmentList$ = createEffect(
        () => this.actions$
            .pipe(
                ofType(loadPatientAppointmentList),
                concatMap(action => {
                    this.store.dispatch(PatientAppointmentListLoading());
                    return this.patientService.getAppointments(action.mobileno);
                }),
                map(data => {
                    return UpdatePatientAppointmentList({patientAppointmentList: data})
                })
            )
    );
    LoadVitals$ = createEffect(
        () => this.actions$
            .pipe(
                ofType(LoadVitals),
                concatMap(action => {
                    this.store.dispatch(VitalsLoading());
                    return this.patientService.getPatientVitals(action.mobileno);
                }),
                map(data => {
                    return UpdateVitals({ vitals: data})
                })
            )
    );
    LoadMedication$ = createEffect(
        () => this.actions$
          .pipe(
            ofType(loadMedicationsList),
            mergeMap(action => {
              var array = [];
                array.push(this.patientService.getActiveMedications(action.personId));
                array.push(this.patientService.getUserMedications(action.personId));
              return forkJoin(array);
            }),
            map(config => {
                var medList = [];
          var medicationList1:any = config[1];
          var medList = [];
          for (var i = 0; i < medicationList1.length; i++) {
            var obj = JSON.parse(JSON.stringify(medicationList1[i]));
            var isRepeat = false;
            for (var j = 0; j < medList.length; j++) {
              var obj1 = medList[j];
              if (obj1.prescriptionId == obj.prescriptionId && obj1.drugName == obj.drugName) {
                obj.time = obj1.time + "," + obj.time;
                obj.daysRemaining = obj.daysRemaining > obj1.daysRemaining ? obj.daysRemaining : obj1.daysRemaining;
                medList[j] = obj;
                isRepeat = true;
              }
            }
            if (!isRepeat) {
              medList.push(obj);
            }
          }
              return UpdateMedicationsList({
                activeMedicationList:config[1],
                userMedicationList: config[0]
              })
            })
          )
      );
}