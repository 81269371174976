import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/store/root.reducer';
import { getUserRoles } from 'src/app/store/selectors/app-config.selectors';
import { APP_CONSTANTS } from '../constants';
import { ChangePasswordService } from '../services/changepassword.service';
import { UnsubscribeOnDestroyAdapter } from '../services/UnsubscribeOnDestroyadapter';

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  passwordForm: FormGroup;
  form2: FormGroup;
  loading = false;
  submitted = false;
  submittedForm1 = false;
  returnUrl: string;
  isPageLoaded = false;
  screen1: boolean = true;
  ButtonText: string;
  edit: boolean = false;
  type: any;
  passmsg: string;
  PasswordText: string = "Forgot Password";
  loadingForm1: boolean = false;
  username: any;
  portaltype: any;
  resetBy: any;
  oldpass: any;
  role: any;
  rolesList: any = [];
  doctor: string = 'doctor';
  constructor(private store: Store<AppState>,private toastrService: NbToastrService,private apiService: ChangePasswordService, private router: Router, private formBuilder: FormBuilder) {
    super()
  }
  ngOnInit(): void {
    this.store.pipe(select(getUserRoles)).subscribe(userRoles => {
      this.rolesList = userRoles;
    })
    
    this.passwordForm = this.formBuilder.group({
      username: ['', Validators.required],
      role: ['', Validators.required]
    });
    this.form2 = this.formBuilder.group({
      otp: ['', [Validators.required, Validators.pattern(APP_CONSTANTS.REGEX.NUMBERS_ONLY)]],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    },
    {
      validator: MustMatch('password', 'confirmPassword')
    });
  }
  get f() {
    return this.passwordForm.controls;
  }
  get pdf() {
    return this.form2.controls;
  }
  back() {
    this.router.navigate([APP_CONSTANTS.APP_ROUTE.AUTH + "/" + 'login']);
  }
  goBack() {
    this.screen1 = true;
    this.router.navigate([APP_CONSTANTS.APP_ROUTE.AUTH + "/" + APP_CONSTANTS.LOGIN_ROUTE.FORGOT_PASSWORD]);
  }
  getValidate() {
    this.submittedForm1 = true;
    this.loadingForm1 = true;
    if (this.passwordForm.invalid) {
      return;
    }
    this.loading = true;
    this.username=this.passwordForm.value.username;
    this.role=this.passwordForm.value.role;
    if(this.role!='doctor')
      this.role='admin'
    else 
      this.role='doctor'
    this.subs.sink = this.apiService.getOtp(this.username,this.role).subscribe((resData: any) => {
      this.loadingForm1 = false;

    }, error => {
      this.loading = false;
      console.log
      if(error.error.text=="Sent Otp Successfylly"){
        this.toastrService.show('',error.error.text, {duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
        this.screen1 = false;
      }
      else{
        this.toastrService.show('', error.error, {duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
      }
    });
  }
  newPassword() {
    this.submitted = true;
    if (this.form2.invalid) {
      return;
    }
    this.loading = true;
    if(this.passwordForm.value.role=='doctor'){
      this.portaltype='doctor';
    }
    else
      this.portaltype='admin';
    var userDTO = {
      "username": this.passwordForm.value.username,
      "portaltype": this.portaltype,
      "resetBy": "otp",
      "otp": this.form2.value.otp,
      "oldPassword":"",
      "newPassword": this.form2.value.password
    };
    this.subs.sink = this.apiService.createNewPassword(userDTO).subscribe((resData: any) => {
      this.loading = false;
    }, error => {
      if(error.status==406){
        this.toastrService.show('',"Entered Otp is NOT valid. Please Retry!", {duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
      }
      if(error.status==200){
        this.toastrService.show('',"Password Updated Successfully", {duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
        this.router.navigate([APP_CONSTANTS.APP_ROUTE.AUTH + "/" + APP_CONSTANTS.LOGIN_ROUTE.LOGIN]);
      }
      this.loading = false;
    });
  }

}
