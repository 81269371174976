import { createReducer, on } from "@ngrx/store"
import { UpdateUserRoles } from "../actions/app-config.actions"
import { UpdateDashbaord ,AdminDashboardLoading,AdminUpcomingConsultationListLoading,UpdateUpcomingConsultationsList, UpdateDoctorsList, UpdatePatientList, UpdatePaymentsList, UpdateAppointmentsCount} from "../actions/home.actions"
import { DashboardInitialState } from "../state/home.state"

const _HomeReducer = createReducer(
    DashboardInitialState,
    on(UpdateDashbaord, (state, action) => {
        return {
            ...state,
            patientList: action.patientList,
            upcomingConsultationsList:action.upcomingConsultationsList,
            doctorsList:action.doctorsList,
            paymentsList:action.paymentsList,
            paymentsFollowupList:action.paymentsFollowupList,
            refundsFollowupList:action.refundsFollowupList,
            appointmentsFollowupList:action.appointmentsFollowupList,
            getAppointmentsCount:action.getAppointmentsCount,
            getDaywiseAppointmentsCount:action.getDaywiseAppointmentsCount,
            getConsultedPatientCountByDepartment:action.getConsultedPatientCountByDepartment,
            getRecentlyConsultedPatients:action.getRecentlyConsultedPatients,
            getRecentAvailableDoctors:action.getRecentAvailableDoctors,
            AdminDashboardLoading:false,
            AdminUpcomingConsultationListLoading:false
        }
    }),
    on(AdminDashboardLoading, (state, action) => {
        return {
            ...state,
            AdminDashboardLoading:true,
            AdminUpcomingConsultationListLoading:true,
        }
    }),
    on(UpdateUpcomingConsultationsList, (state, action) => {
        console.log(action.upcomingConsultationsList)
        return {
            ...state,
            upcomingConsultationsList:action.upcomingConsultationsList,
            AdminUpcomingConsultationListLoading:false
        }
    }),
    on(AdminUpcomingConsultationListLoading, (state, action) => {
        return {
            ...state,
            AdminUpcomingConsultationListLoading:true,
        }
    }),
    on(UpdateDoctorsList, (state, action) => {
        return {
            ...state,
            doctorsList:action.doctorsList,
        }
    }),
    on(UpdatePatientList, (state, action) => {
        return {
            ...state,
            patientList:action.patientList,
        }
    }),
    on(UpdatePaymentsList, (state, action) => {
        return {
            ...state,
            paymentsList:action.paymentsList,
        }
    }),
    on(UpdateAppointmentsCount, (state, action) => {
        return {
            ...state,
            getAppointmentsCount:action.getAppointmentsCount,
            getDaywiseAppointmentsCount:action.getDaywiseAppointmentsCount,
            getConsultedPatientCountByDepartment:action.getConsultedPatientCountByDepartment,
        }
    }),
)

export function HomeReducer(state, action) {
    return _HomeReducer(state, action)
}