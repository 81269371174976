import { MeetingService } from './../services/meeting.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbMenuService, NbSidebarService, NbThemeService, NbToastrService } from '@nebular/theme';
import { filter, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState, logout } from 'src/app/store/root.reducer';
import { getUser } from 'src/app/store/selectors/admin-user.selectors';
import { getDoctor, getDoctorStatus } from 'src/app/store/selectors/doctor-user.selectors';
import { Router } from '@angular/router';
import { APP_CONSTANTS } from '../constants';
import { updateDoctorStatus } from 'src/app/store/actions/doctor-user.actions';
import { ThrowStmt } from '@angular/compiler';
import { UnsubscribeOnDestroyAdapter } from '../services/UnsubscribeOnDestroyadapter';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent extends UnsubscribeOnDestroyAdapter implements OnInit,OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = true;
  showMenuBar: boolean;
  username: string;
  checkinoroutStatus:boolean;
  user: any;
  button: string="Check In";
  @Input() title: string;
  isDoctor: boolean;
  currentTheme = 'default';
  data = { "type": "edit" };
  userMenu = [];
  status: string;
  checkin: boolean=false;

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private toastrService: NbToastrService,
    private store: Store<AppState>,
    private router: Router,
    private apiService: MeetingService
  ) {
    super()
    if (window.innerWidth >= 768) {
      this.userPictureOnly = true;
      this.showMenuBar = true;
    } else if (window.innerWidth < 768) {
      this.userPictureOnly = true;
      this.showMenuBar = false;
    }
    if (this.checkin)
      this.button = "Check Out";
    else
      this.button = "Check In";
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    if (this.title == 'Admin Portal') {
      this.store.select(getUser).subscribe(data => {
        if (data != null) {
          this.userMenu=[];
          var menu = {
            title: data?.firstname,
            group: true
          };
          var menu1 = {
            title: 'Change Password'
          }
          this.isDoctor = false;
          this.userMenu.unshift(menu1);
          this.userMenu.unshift(menu);
          this.userMenu.push({ title: 'Log out' });
          this.user = { "name": data?.firstname }
        } else {
          this.logout();
        }
      })
      this.menuService.onItemClick()
        .pipe(
          filter(({ tag }) => tag === 'my-context-menu'),
          map(({ item: { title } }) => title),
        )
        .subscribe(title => {
          if (title == 'Change Password')
            this.adminChangePassword();
          else if (title == 'Log out') {
            this.logout();
          } else {
          }
        });
    } else {
      this.store.select(getDoctor).subscribe(data => {
        if (data != null) {
          this.userMenu=[];
          this.username = data?.username;
          var menu = {
            title: "Dr. " + data?.given,
            group: true
          };
          var menu1 = {
            title: 'Edit Profile',
          }
          var menu2 = {
            title: 'Change Password'
          }
          this.isDoctor=true;
          this.userMenu.unshift(menu2);
          this.userMenu.unshift(menu1);
          this.userMenu.unshift(menu);
          this.userMenu.push({ title: 'Log out' });
          this.user = { "name": data?.given }
        } else {
          this.logout();
        }

      })
      this.store.select(getDoctorStatus).subscribe(data => {
        this.status=data.status;
        this.checkin=data.checkin;
        this.checkinoroutStatus=data.checkin;
        if(data.checkin)
        {
      
          this.button="Check Out"
        }
        else{

          this.button="Check In"
        }
        
      })
      this.menuService.onItemClick()
        .pipe(
          filter(({ tag }) => tag === 'my-context-menu'),
          map(({ item: { title } }) => title),
        )
        .subscribe(title => {
          if (title == 'Edit Profile') {
            this.editProfile();
          }
          else if (title == 'Change Password')
            this.doctorChangePassword();
          else if (title == 'Log out') {
            // this.checkInOutApi(false);
            this.logout();

          } else {
          }
        });
    }
  }

  ngOnDestroy() {
  }

  changeTheme(themeName: string) {
  }


  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
  logout() {
    // if(this.isDoctor)
    // {
    //   this.subs.sink=this.apiService.updateStatus(this.username, "unavailable").subscribe((resData: any) => {

    //   }, error => {
    //     var data = error;
    //   });
    // }
    this.store.dispatch(logout());
    this.router.navigate([APP_CONSTANTS.APP_ROUTE.AUTH]);
  }

  editProfile() {
    var data = { "type": "edit" }
    this.router.navigate([APP_CONSTANTS.APP_ROUTE.DOCTOR + "/" + APP_CONSTANTS.DOCTOR_ROUTE.REGISTER], { state: data });
  }
  doctorChangePassword() {
    this.router.navigate([APP_CONSTANTS.APP_ROUTE.DOCTOR + "/" + APP_CONSTANTS.DOCTOR_ROUTE.CHANGE_PASSWORD], { state: { text: 'fromDoctor' } });
  }
  adminChangePassword() {
    this.router.navigate([APP_CONSTANTS.APP_ROUTE.ADMIN + "/" + APP_CONSTANTS.ADMIN_ROUTE.CHANGE_PASSWORD], { state: { text: 'fromAdmin' } });
  }



  checkInorOut() {
    if (!this.checkinoroutStatus)
      this.checkInOutApi(true);
    else
      this.checkInOutApi(false);
  }

  checkInOutApi(checkin: boolean) {
    this.subs.sink=this.apiService.updateCheckInCheckOut(checkin, this.username).subscribe((resData: any) => {
      if (checkin) {
        localStorage.setItem("checkin", "true")
        this.store.dispatch(updateDoctorStatus({ checkin: true, status: "available" }));
        this.button = "Check Out";
        this.toastrService.show('', 'Check In successful.', { duration: APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON });
      }
      else {
        localStorage.setItem("checkin", "false")
        this.button = "Check In";
        this.store.dispatch(updateDoctorStatus({ checkin: false, status: "available" }));
        this.toastrService.show('', 'Check Out successful.', { duration: APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON });

      }
    }, error => {
      var data = error;
    });
  }


}
