import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NbRouteTabsetComponent, NbTabsetComponent, NbToastrService } from '@nebular/theme';
import { APP_CONSTANTS } from 'src/app/shared/constants';
import { PatientService } from '../services/patient.service';

@Component({
  selector: 'app-bps-assesment',
  templateUrl: './bps-assesment.component.html',
  styleUrls: ['./bps-assesment.component.scss']
})
export class BpsAssesmentComponent implements OnInit {
  questions: any = [];
  answerDTOList: any = [];
  leisuretimeTable: any = [];
  televisionTable: any = [];
  medicationTable: any = [];
  personId: string;
  memberDto: any;
  customerData: any;
  instructions: string[] = ['Before Food', 'After Food'];
  frequencies: string[] = ["Morning", "Afternoon", "Night", "SOS", "Others"];
  loading: boolean;
  @ViewChild("tabset") NbTabsetComponent:NbTabsetComponent;
  active=0;
  constructor(private apiService: PatientService, private router: Router,private toastrService:NbToastrService) {
    let data = this.router.getCurrentNavigation() ? this.router.getCurrentNavigation()["extras"]["state"] : null;
    if (data != null) {
      console.log("data--", data);
      this.customerData = JSON.parse(JSON.stringify(data));
    }
  }

  ngOnInit(): void {
    this.personId = this.customerData.memberDTO.personId;
    this.memberDto = this.customerData.memberDTO;
    // this.spinner.show();
    this.apiService.getAllQuestions(this.personId).subscribe((data: any) => {
      // this.spinner.hide();
      if (data != null) {
        console.log(data)
        this.questions = data;

        this.questions.forEach(element => {
          element.socialEngagementQuestionsDTOList.forEach(element => {
            if (element.optionType == "text" || element.optionType == "radio" || element.optionType == "radio_text") {
              let answerDTO = {
                personId: this.personId,
                // id: element.userAnswerDTO != null ? element.userAnswerDTO.id : null,
                questionId: element.id,
                subQuestionId: null,
                // historyId:element.historyId,
                userAnswer: element.userAnswerDTO != null ? element.userAnswerDTO.userAnswer : "",
              };
              if (element.id == 1)
                answerDTO.userAnswer = this.memberDto.Name;
              if (element.id == 2)
                answerDTO.userAnswer = this.memberDto.Mobile;
              if (element.id == 3)
                answerDTO.userAnswer = this.memberDto.Age;
              if (element.id == 4)
                answerDTO.userAnswer = this.memberDto.Sex;
              element.userAnswerDTO = answerDTO;
            }
            if (element.optionType == "checkbox_text") {
              let answerDTO = {
                personId: this.personId,
                // id: element.userAnswerDTO != null ? element.userAnswerDTO.id : null,
                questionId: element.id,
                subQuestionId: null,
                // historyId:element.historyId,
                userAnswer: element.userAnswerDTO != null ? element.userAnswerDTO.userAnswer : "",
                userAnswerList: element.userAnswerDTO != null ? element.userAnswerDTO.userAnswer.split(",") : [],
              };
              element.userAnswerDTO = answerDTO;
            }
            if (element.optionType == "table_schedule_leisure" || element.optionType == "table_schedule_television") {
              let answerDTO = {
                personId: this.personId,
                // id: element.userAnswerDTO != null ? element.userAnswerDTO.id : null,
                questionId: element.id,
                subQuestionId: null,
                // historyId:element.historyId,
                userAnswer: element.userAnswerDTO != null ? element.userAnswerDTO.userAnswer : "",
                userActivityDTOList: [],
              };
              if (element.userAnswerDTO != null && element.userAnswerDTO.userActivityDTOList != null) {
                element.userAnswerDTO.userActivityDTOList.forEach(element => {
                  let data = {
                    id: element.id,
                    activity: element.activityName,
                    morningfrom: element.morning != null ? element.morning.split("-")[0] : "",
                    morningto: element.morning != null ? element.morning.split("-")[1] : "",
                    afternoonfrom: element.afternoon != null ? element.afternoon.split("-")[0] : "",
                    afternoonto: element.afternoon != null ? element.afternoon.split("-")[1] : "",
                    eveningfrom: element.evening != null ? element.evening.split("-")[0] : "",
                    eveningto: element.evening != null ? element.evening.split("-")[1] : "",
                    deleted: element.deleted
                  }
                  element.optionType == 'table_schedule_television' ? this.televisionTable.push(data) : this.leisuretimeTable.push(data);
                });
              }
              element.userAnswerDTO = answerDTO;
            }
            if (element.optionType == "table") {
              let answerDTO = {
                personId: this.personId,
                // id: element.userAnswerDTO != null ? element.userAnswerDTO.id : null,
                questionId: element.id,
                // historyId:element.historyId,
                subQuestionId: null,
                medicationInfoDTOList: [],
              };
              if (element.userAnswerDTO != null && element.userAnswerDTO.medicationInfoDTOList != null) {
                element.userAnswerDTO.medicationInfoDTOList.forEach(element => {
                  let data = {
                    id: element.id,
                    medicinename: element.medicineName,
                    dose: element.dose,
                    frequency: element.frequency != null ? element.frequency.split(",") : [],
                    instructions: element.instructions,
                    deleted: element.deleted
                  }
                  this.medicationTable.push(data);
                });
              }
              element.userAnswerDTO = answerDTO;
            }
            // if (element.socialEngagementSubQuestionsDTOList.length > 0) {
            //   element.socialEngagementSubQuestionsDTOList.forEach(subelement => {
            //     if (subelement.subOptionType == "text" || subelement.subOptionType == "radio" || subelement.subOptionType == "radio_text") {
            //       let answerDTO = {
            //         personId: this.personId,
            //         id: subelement.userAnswerDTO != null ? subelement.userAnswerDTO.id : null,
            //         questionId: element.id,
            //         subQuestionId: subelement.id,
            //         userAnswer: subelement.userAnswerDTO != null ? subelement.userAnswerDTO.userAnswer : "",
            //       };
            //       subelement.userAnswerDTO = answerDTO;
            //     }
            //     if (subelement.subOptionType == "checkbox_text") {
            //       let answerDTO = {
            //         personId: this.personId,
            //         id: subelement.userAnswerDTO != null ? subelement.userAnswerDTO.id : null,
            //         questionId: element.id,
            //         subQuestionId: subelement.id,
            //         userAnswer: subelement.userAnswerDTO != null ? subelement.userAnswerDTO.userAnswer : "",
            //         userAnswerList: subelement.userAnswerDTO != null ? subelement.userAnswerDTO.userAnswer.split(",") : [],
            //       };
            //       subelement.userAnswerDTO = answerDTO;
            //     }

            //     // if(subelement.subOptionType == "table_schedule"){
            //     //     let answerDTO = {
            //     //       personId : this.personId,
            //     //       id: subelement.userAnswerDTO != null ? subelement.userAnswerDTO.id : null,
            //     //       questionId : element.id,
            //     //       subQuestionId : subelement.id,
            //     //       userAnswer: subelement.userAnswerDTO != null ? subelement.userAnswerDTO.userAnswer : "",
            //     //       userActivityDTOList: [],
            //     //     };
            //     //     if(subelement.userAnswerDTO != null && subelement.userAnswerDTO.userActivityDTOList != null){
            //     //       subelement.userAnswerDTO.userActivityDTOList.forEach(element => {
            //     //             let data = {
            //     //               id:element.id,
            //     //               activity: element.activityName,
            //     //               morningfrom : element.morning != null ? element.morning.split("-")[0] : "",
            //     //               morningto : element.morning != null ? element.morning.split("-")[1] : "",
            //     //               afternoonfrom : element.afternoon != null ? element.afternoon.split("-")[0] : "",
            //     //               afternoonto : element.afternoon != null ? element.afternoon.split("-")[1] : "",
            //     //               eveningfrom : element.evening != null ? element.evening.split("-")[0] : "",
            //     //               eveningto : element.evening != null ? element.evening.split("-")[1] : "",
            //     //               deleted: element.deleted
            //     //           }
            //     //           subelement.id == 21 ? this.televisionTable.push(data) : this.leisuretimeTable.push(data);
            //     //         });
            //     //     }
            //     //     subelement.userAnswerDTO = answerDTO;
            //     // }
            //   });
            // }
            if (element.freetextQuestion != null) {
              element.others = "";
              if (element.userAnswerDTO != null && element.userAnswerDTO.userAnswer.includes("Others-")) {
                element.others = element.userAnswerDTO.userAnswer.split("Others-")[1];
                if (element.optionType == "checkbox_text") {
                  element.userAnswerDTO.userAnswerList.pop();
                }
              }
            }
          });
        });

      }
    }, error => {
      console.log(error);
      // this.spinner.hide();
    });
  }
  addTelevisiontable() {
    let data = {
      id: null,
      activity: "",
      morningfrom: "",
      morningto: "",
      afternoonfrom: "",
      afternoonto: "",
      eveningfrom: "",
      eveningto: "",
    }
    this.televisionTable.push(data);
  }

  addLeisuretimetable() {
    let data = {
      id: null,
      activity: "",
      morningfrom: "",
      morningto: "",
      afternoonfrom: "",
      afternoonto: "",
      eveningfrom: "",
      eveningto: "",
    }
    this.leisuretimeTable.push(data);
  }

  addMedicationtable() {
    let data = {
      id: null,
      medicinename: "",
      dose: "",
      frequency: "",
      instructions: [],
    }
    this.medicationTable.push(data);
  }

  removeMedication(i) {
    if (this.medicationTable[i].hasOwnProperty("deleted"))
      this.medicationTable[i].deleted = true;
    else
      this.medicationTable.splice(i, 1);

  }

  removeTelevision(i) {
    if (this.televisionTable[i].hasOwnProperty("deleted"))
      this.televisionTable[i].deleted = true;
    else
      this.televisionTable.splice(i, 1);

  }

  removeLeisure(i) {
    if (this.leisuretimeTable[i].hasOwnProperty("deleted"))
      this.leisuretimeTable[i].deleted = true;
    else
      this.leisuretimeTable.splice(i, 1);

  }

  goBack() {
    this.router.navigate([APP_CONSTANTS.APP_ROUTE.ADMIN+"/"+APP_CONSTANTS.ADMIN_ROUTE.PATIENTS]);
    // this.location.back();
  }

  Submit(categoryId) {
    this.answerDTOList = [];
    this.questions.forEach(element => {
      console.log(element);
      if(element.categoryId==categoryId){
      element.socialEngagementQuestionsDTOList.forEach(element => {
        if (element.optionType == "text" || element.optionType == "radio" || element.optionType == "radio_text") {
          if (element.freetextQuestion != null) {
            if (element.others != "")
              element.userAnswerDTO.userAnswer = "Others-" + element.others;
          }
          if (element.userAnswerDTO.userAnswer != "")
            this.answerDTOList.push(element.userAnswerDTO);
        }
        if (element.optionType == "checkbox_text") {
          if (element.freetextQuestion != null) {
            if (element.others != "")
              element.userAnswerDTO.userAnswerList.push("Others-" + element.others);
          }
          if (element.userAnswerDTO.userAnswerList.length > 0) {
            element.userAnswerDTO.userAnswer = element.userAnswerDTO.userAnswerList.toString();
            console.log("anslist--" + JSON.stringify(element.userAnswerDTO.userAnswerList) + element.userAnswerDTO.userAnswerList.toString());
            delete element.userAnswerDTO.userAnswerList;
            this.answerDTOList.push(element.userAnswerDTO);
          }
        }
        if (element.optionType == "table_schedule_television" || element.optionType == "table_schedule_leisure") {

          if (element.optionType == "table_schedule_television") {
            if (element.userAnswerDTO.userAnswer != "") {
              if (this.televisionTable.length > 0) {
                let userActivityDTOList = [];
                this.televisionTable.forEach(activityelement => {
                  let data = {
                    // id: activityelement.id,
                    answerId: element.userAnswerDTO.id,
                    activityName: activityelement.activity,
                    morning: activityelement.morningfrom + "-" + activityelement.morningto,
                    afternoon: activityelement.afternoonfrom + "-" + activityelement.afternoonto,
                    evening: activityelement.eveningfrom + "-" + activityelement.eveningto,
                    deleted: activityelement.deleted
                  }
                  userActivityDTOList.push(data);
                });
                element.userAnswerDTO.userActivityDTOList = userActivityDTOList;
              }
              this.answerDTOList.push(element.userAnswerDTO);
            }
          }
          else {
            if (this.leisuretimeTable.length > 0) {
              let userActivityDTOList = [];
              this.leisuretimeTable.forEach(activityelement => {
                let data = {
                  // id: activityelement.id,
                  answerId: element.userAnswerDTO.id,
                  activityName: activityelement.activity,
                  morning: activityelement.morningfrom + "-" + activityelement.morningto,
                  afternoon: activityelement.afternoonfrom + "-" + activityelement.afternoonto,
                  evening: activityelement.eveningfrom + "-" + activityelement.eveningto,
                  deleted: activityelement.deleted
                }
                userActivityDTOList.push(data);
              });
              element.userAnswerDTO.userActivityDTOList = userActivityDTOList;
              this.answerDTOList.push(element.userAnswerDTO);
            }
          }
        }
        if (element.optionType == "table") {
          if (this.medicationTable.length > 0) {
            let medicationInfoDTOList = [];
            this.medicationTable.forEach(medicationelement => {
              let data = {
                // id: medicationelement.id,
                answerId: element.userAnswerDTO.id,
                medicineName: medicationelement.medicinename,
                dose: medicationelement.dose,
                frequency: medicationelement.frequency.length > 0 ? medicationelement.frequency.toString() : "",
                instructions: medicationelement.instructions,
                deleted: medicationelement.deleted
              }
              medicationInfoDTOList.push(data);
            });
            console.log("med", element.userAnswerDTO);
            element.userAnswerDTO.medicationInfoDTOList = medicationInfoDTOList;
            this.answerDTOList.push(element.userAnswerDTO);
          }

        }
        if (element.socialEngagementSubQuestionsDTOList.length > 0) {
          element.socialEngagementSubQuestionsDTOList.forEach(subelement => {
            if (subelement.subOptionType == "text" || subelement.subOptionType == "radio" || subelement.subOptionType == "radio_text") {
              if (subelement.userAnswerDTO.userAnswer != "")
                this.answerDTOList.push(subelement.userAnswerDTO);
            }
            if (subelement.subOptionType == "checkbox_text") {
              if (subelement.userAnswerDTO.userAnswer.length > 0) {
                subelement.userAnswerDTO.userAnswer = subelement.userAnswerDTO.userAnswerList.toString();
                delete subelement.userAnswerDTO.userAnswerList;
                this.answerDTOList.push(subelement.userAnswerDTO);
              }
            }
            // if (subelement.subOptionType == "table_schedule") {

            //   if (subelement.id == 21) {
            //     if (subelement.userAnswerDTO.userAnswer != "") {
            //       if (this.televisionTable.length > 0) {
            //         let userActivityDTOList = [];
            //         this.televisionTable.forEach(activityelement => {
            //           let data = {
            //             id: activityelement.id,
            //             answerId: subelement.userAnswerDTO.id,
            //             activityName: activityelement.activity,
            //             morning: activityelement.morningfrom + "-" + activityelement.morningto,
            //             afternoon: activityelement.afternoonfrom + "-" + activityelement.afternoonto,
            //             evening: activityelement.eveningfrom + "-" + activityelement.eveningto,
            //             deleted: activityelement.deleted
            //           }
            //           userActivityDTOList.push(data);
            //         });
            //         subelement.userAnswerDTO.userActivityDTOList = userActivityDTOList;
            //       }
            //       this.answerDTOList.push(subelement.userAnswerDTO);
            //     }
            //   }
            //   else {
            //     if (this.leisuretimeTable.length > 0) {
            //       let userActivityDTOList = [];
            //       this.leisuretimeTable.forEach(activityelement => {
            //         let data = {
            //           id: activityelement.id,
            //           answerId: subelement.userAnswerDTO.id,
            //           activityName: activityelement.activity,
            //           morning: activityelement.morningfrom + "-" + activityelement.morningto,
            //           afternoon: activityelement.afternoonfrom + "-" + activityelement.afternoonto,
            //           evening: activityelement.eveningfrom + "-" + activityelement.eveningto,
            //           deleted: activityelement.deleted
            //         }
            //         userActivityDTOList.push(data);
            //       });
            //       subelement.userAnswerDTO.userActivityDTOList = userActivityDTOList;
            //       this.answerDTOList.push(subelement.userAnswerDTO);
            //     }
            //   }

            // }
          });
        }
      });
    }
    });
    console.log("answerdto--", JSON.stringify(this.answerDTOList));
    if (this.answerDTOList.length == 0) {
      this.toastrService.show('', 'Please Select/Fill atleast one question.',{duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
      // this.snackbar.open("Please Select/Fill atleast one question.","",{duration:2000});
      return;
    }
    this.loading=true;
    // this.spinner.show();
    this.apiService.saveUserAnswers(this.answerDTOList).subscribe((resData: any) => {
      console.log("res--" + JSON.stringify(resData));
      this.loading=false;
      if(this.questions.length>this.active+1){
        this.active=this.active+1;
      }
      this.toastrService.show('', 'Assesment has been Saved successfully.',{duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
      // this.spinner.hide();
      // let snackBarRef = this.snackbar.open('',"",{duration:2000});
      // this.location.back();
    }, error => {
      this.toastrService.show('', 'Error Occured.',{duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
      // this.spinner.hide();
      var data = error;
    });
  }
  changeEvent($event){
    this.active=parseFloat($event.tabId);
  }
  SubmitAll(){
    this.apiService.submitUserAnswers(this.personId).subscribe((resData: any) => {
      this.loading=false;
      this.toastrService.show('', 'Assesment has been submitted successfully.',{duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
      this.router.navigate([APP_CONSTANTS.APP_ROUTE.ADMIN+"/"+APP_CONSTANTS.ADMIN_ROUTE.PATIENTS]);
    }, error => {
      this.toastrService.show('', 'Error Occured.',{duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
      var data = error;
    });
  }
}
