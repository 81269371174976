import { Component, Input, OnInit } from '@angular/core';
import { PatientService } from '../services/patient.service';
import { NbDialogService } from '@nebular/theme';
import { ConfirmDialogComponent } from '../shared-dialogue-components/confirmation-dialog/confirm-dialog.component';
import { UnsubscribeOnDestroyAdapter } from '../services/UnsubscribeOnDestroyadapter';
import { AddprescriptionComponent } from '../shared-dialogue-components/addprescription/addprescription.component'
import { Store } from '@ngrx/store';
import { ViewCell } from 'ng2-smart-table';
import { loadMedicationsList, LoadPrescriptionsList } from 'src/app/store/actions/cust-profile.actions';
import { AppState } from 'src/app/store/root.reducer';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
    template: `
      <div >
      <span (click)="open(content,1)"><i class="fa fa-eye"  title="View"></i></span> &nbsp;
      <span *ngIf="edit && prescriptionType!='manual'" (click)="editPrescriptionalt(1)"  ><i class="fa fa-edit"  title="Edit"></i></span> &nbsp;
      <span *ngIf="activated && prescriptionType!='manual'"
        (click)="activateorDeactivatePresccription(1)"><i class="fa fa-toggle-on" title="Deactivate"></i>
      </span>
      <span *ngIf="!activated && prescriptionType!='manual'"
        (click)="activateorDeactivatePresccription(1)"><i class="fa fa-toggle-off" title="Activate"  ></i>
      </span>
      </div>
      <div>
        <ng-template #content let-modal>
        <div class="modal-header">
        <h6 class="modal-title" id="modal-basic-title">Prescription View</h6>
      </div>
      <div class="modal-body">
      <div style="text-align:center;" *ngIf="showText"><i>No Data Found</i></div>
      <img class="image" *ngIf="!showText && con2" src="{{viewUrl}}" />
      <ngx-extended-pdf-viewer [showPrintButton]="false" [showBookmarkButton]="false" [showOpenFileButton]="false" [zoom]="'page-width'" *ngIf="!showText && !con2" [textLayer]="true" [src]="viewUrl"></ngx-extended-pdf-viewer>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">close</button>
      </div>
        </ng-template>
    </div>
    `,
})
export class PrescriptionRenderComponent extends UnsubscribeOnDestroyAdapter implements ViewCell, OnInit {

    renderValue: string;
    @Input() value: string | number;
    @Input() rowData: any;
    custromerData: any;
    mobileno: any;
    activated: boolean;
    id: any;
    checked: boolean = false;
    edit: boolean = false;
    prescriptionId: number;
    username: any;
    prescriptionType: any;
    viewUrl: string;
    showText:boolean=false;
    con2: boolean;
    constructor(private modalService: NgbModal,private apiService: PatientService, private dialogService: NbDialogService, private store: Store<AppState>) {
        super()
    }
    ngOnInit() {
        this.renderValue = '';
        this.custromerData = this.rowData;
        this.activated = this.custromerData.activated;
        this.prescriptionType = this.custromerData.prescriptionType;
        this.mobileno = this.value;
        this.id = this.custromerData.prescriptionId;
        this.username = this.custromerData.doctorName;
        var data1 = JSON.parse(JSON.stringify(this.rowData));
        this.edit = new Date(data1.publishTime).getTime() + 86400000 > new Date().getTime() ? true : false;
    }
    open(content,from) {
        var data1 = JSON.parse(JSON.stringify(this.rowData));
        this.viewUrl=data1.filePath;
        
        if(this.viewUrl==null)
            this.showText=true;
        else {
            this.showText=false;
            if (this.viewUrl.includes('pdf')) {
                this.con2 = false;
              }else{
                this.con2 = true;
              }
        }
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    }
    editPrescriptionalt(from) {
        var data1 = JSON.parse(JSON.stringify(this.rowData));
        this.prescriptionId = data1.prescriptionId;
        this.subs.sink=this.apiService.loadPrescriptionDetails(this.prescriptionId).subscribe(res => {
            this.openEditPrescription1(res[0])
        })

    }
    openEditPrescription1(data) {
        const dialogRef = this.dialogService.open(AddprescriptionComponent, {
            context: {
                data: data,
            },
            autoFocus: false
        })
        dialogRef.onClose.subscribe({
            next: (res) => {
                if (res) {
                    this.loadPrescriptionhistory();
                }
            }
        });
    }
    activateorDeactivatePresccription(from) {
        var data1 = JSON.parse(JSON.stringify(this.rowData));
        const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
            context: {
                title: `Are you sure, you want to ` + (data1.activated ? "deactivate" : "activate") + " this prescription?"
            },
            autoFocus: false
        })
        dialogRef.onClose.subscribe({
            next: (res) => {
                if (res) {
                    this.subs.sink = this.apiService.updatePrescriptionStatus(this.id, !this.activated).subscribe((resData: any) => {
                        this.store.dispatch(loadMedicationsList({"personId":this.custromerData?.personId}));
                        this.checked = !this.checked;
                        this.loadPrescriptionhistory();
                    }, error => {
                        var data = error;
                    });
                }
            }
        });
    }
    consultationList: any = [];
    loadPrescriptionhistory() {
        this.store.dispatch(LoadPrescriptionsList({ "mobileno": this.mobileno }));
    }
}