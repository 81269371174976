import { UpAppointmentsRenderComponent } from './../../adminportal/admin-render-componets/admin-upcomingAppointment-render.component';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { PatientService } from '../services/patient.service';
import { VitalsDataentryComponent } from '../shared-dialogue-components/vitalsdataentry/vitalsdataentry.component';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { MedicalDetailsDataentryComponent } from '../shared-dialogue-components/medicaldetailsdataentry/medicaldetailsdataentry.component';
import { LocalDataSource} from 'ng2-smart-table';
import { BookAppointmentComponent } from '../shared-dialogue-components/bookappointment/bookappointment.component';
import { ReportsUploadDialogComponent } from '../shared-dialogue-components/reportsupload/reportsupload.component';
import { UnsubscribeOnDestroyAdapter } from '../services/UnsubscribeOnDestroyadapter';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/root.reducer';
import {loadMedicationsList, loadPatientAppointmentList, LoadPrescriptionsList, LoadVitals} from 'src/app/store/actions/cust-profile.actions';
import {getMedicationList, getPatientAppointmentList, getPrescriptionsList, getVitals } from 'src/app/store/selectors/cust-profile.selectors';
import { DatePipe } from '@angular/common';
import {PrescriptionRenderComponent} from '../shared-render-components/patient-prescription-render-component';
import { PatientUpcomoingAppointmentRenderComponent } from '../shared-render-components/patient-upcmng-render-component';
import { ReportRenderComponent } from '../shared-render-components/patient-reportview-render-component';
import { ReportsRenderComponent } from '../shared-render-components/cm-reportview-render-component';
import { CustomRenderComponent } from '../shared-render-components/cm-prescription-render-component';
import { APP_CONSTANTS } from '../constants';
import * as cloneDeep from 'lodash/cloneDeep';
import { timer } from 'rxjs';
import { getDoctor } from 'src/app/store/selectors/doctor-user.selectors';
import { PastMedicationComponent } from '../shared-dialogue-components/pastMedications/pastMedication.component';
import { getDate } from 'date-fns';
import { medicineViewComponent } from '../shared-dialogue-components/medicineView-dialog/medicineView.component';

@Component({
  selector: 'app-customer-profile',
  templateUrl: './customerprofile.component.html',
  styleUrls: ['./customerprofile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [PatientService]
})

export class CustomerProfileComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  @Input() data: any;
  memberdata: any;
  @Input() from: any;
  custromerData: any;
  bloodPressure: string = "--";
  temperature: string = "--";
  pulse: string = "--";
  bloodGlucose: string = "--";
  weight: string = "--";
  pulseCapturedTime: string;
  height: string = '--';
  hwCapturedTime: string;
  bpCapturedTime: string;
  glucoCapturedTime: string;
  tempCapturedTime: string;
  spo2CapturedTime: string;
  spO2: string = "--";
  bloodGroup: string;
  diabetic: any;
  bp: any;
  thyroid: any;
  diabeticsince: string;
  thyroidsince: string;
  bpsince: string;
  diseases: string;
  allergies: string;
  mobileno: string = "";
  personId: string = "";
  latestVitals: any;
  patientData: any;
  questions: any = [];
  show: boolean = true;
  memberDTO: any = [];
  cancel = "Cancel";
  view = "View";
  toggleOn = "ToggleOn";
  toggleOff = "ToggleOff";
  settings = {
    hideSubHeader: true,
    defaultStyle: false,
    attr: {
      class: 'responsible' // this is custom table scss or css class for table
    },
    actions: {
      edit: false,
      delete: false,
      add: false,

    },
    columns: {
      index: {
        title: 'C No',
        type: 'text',
        valuePrepareFunction: (value, row, cell) => {
          return cell.row.index + 1;
        },
      },
      topic: {
        title: 'Topic',
        type: 'string',
        valuePrepareFunction: (cell, row) => {
          return row.topic
        },
      },
      doctor:
      {
        title: 'Doctor',
        type: 'string',
        valuePrepareFunction: (cell, row) => {
          return row.doctorName
        },
      },
      date:
      {
        title: 'Date',
        type: 'string',
        valuePrepareFunction: (cell, row) => {
          return row.startTime
        },
      },
      actions: {
        title: 'Actions',
        type: 'custom',
        filter:false,
        renderComponent: UpAppointmentsRenderComponent

      }
    },
  }
  source: LocalDataSource = new LocalDataSource();
  settings1 = {
    hideSubHeader: true,
    defaultStyle: false,
    attr: {
      class: 'responsible' // this is custom table scss or css class for table
    },
    actions: {
      edit: false,
      delete: false,
      add: false,

    },
    columns: {
      index: {
        title: 'S No',
        type: 'text',
        valuePrepareFunction: (value, row, cell) => {
          return cell.row.index + 1;
        },
      },
      chiefComplaint: {
        title: 'Chief Complaint',
        type: 'string',
        valuePrepareFunction: (cell, row1) => {
          return row1.complaints
        },
      },
      date:
      {
        title: 'Consultation Date',
        type: 'string',
        valuePrepareFunction: (cell, row1) => {
          if(row1.prescriptionType==='generated'){
            return row1.publishTime
          }else{
            var formatted =this.datePipe.transform(row1.publishTime, 'dd-MM-yyyy');
            return formatted
          }
        },
      },
      actions: {
        title: 'Actions',
        type: 'custom',
        valuePrepareFunction: (cell, row1) => {
          return this.mobileno
        },
        filter:false,
        renderComponent: CustomRenderComponent,

      }
    },
  }
  source1: LocalDataSource = new LocalDataSource();
  settings2 = {
    hideSubHeader: true,
    defaultStyle: false,
    attr: {
      class: 'responsible' // this is custom table scss or css class for table
    },
    actions: {
      edit: false,
      delete: false,
      add: false,

    },
    columns: {
      rno: {
        title: 'Records No',
        type: 'string', filter: false,
        valuePrepareFunction: (cell, row2) => {
          return row2.recordNo
        },
      },
      rName: {
        title: 'Report Name',
        type: 'string',
        valuePrepareFunction: (cell, row2) => {
          return row2.reportName
        },
      },
      date:
      {
        title: 'Report Date',
        type: 'string',
        valuePrepareFunction: (cell, row2) => {
          var formatted =this.datePipe.transform(row2.measureTime, 'dd-MM-yyyy');
          return formatted
        },
      },
      actions: {
        title: 'Actions',
        type: 'custom',
        filter:false,
        renderComponent: ReportsRenderComponent,
      }
    },
  }
  source2: LocalDataSource = new LocalDataSource();
  settings3 = {
    hideSubHeader: true,
    defaultStyle: false,
    attr: {
      class: 'responsible' // this is custom table scss or css class for table
    },
    actions: {
      edit: false,
      delete: false,
      add: false,

    },
    columns: {
      index: {
        title: 'S No',
        type: 'text',
        valuePrepareFunction: (value, row, cell) => {
          return cell.row.index + 1;
        },
      },
      rName: {
        title: 'Appointment No',
        type: 'string',
        valuePrepareFunction: (cell, row3) => {
          return row3.appointmentId
        },
      },
      chief:
      {
        title: 'Chief Complaint',
        type: 'string',
        valuePrepareFunction: (cell, row3) => {
          return row3.chiefComplaint
        },
      },
      dname: {
        title: 'Doctor Name',
        type: 'string',
        valuePrepareFunction: (cell, row3) => {
          return row3.doctorName
        },
      },
      date:
      {
        title: 'Date',
        type: 'string',
        valuePrepareFunction: (cell, row3) => {
          return row3.startTime
        },
      },
      status:
      {
        title: 'Status',
        type: 'string',
        valuePrepareFunction: (cell, row3) => {
          return row3.status
        },
      },
    },
  }

  source3: LocalDataSource = new LocalDataSource();
  settings4 = {
    hideSubHeader: true,
    defaultStyle: false,
    attr: {
      class: 'responsible' // this is custom table scss or css class for table
    },
    actions: {
      edit: false,
      delete: false,
      add: false,

    },
    columns: {
      index: {
        title: 'S No',
        type: 'text',
        valuePrepareFunction: (value, row, cell) => {
          return cell.row.index + 1;
        },
      },
      topic: {
        title: 'Health Problem',
        type: 'string',
        valuePrepareFunction: (cell, row) => {
          return row.topic
        },
      },
      doctor:
      {
        title: 'Doctor',
        type: 'string',
        valuePrepareFunction: (cell, row) => {
          return row.doctorName
        },
      },
      date:
      {
        title: 'Appointment Date',
        type: 'string',
        valuePrepareFunction: (cell, row) => {
          return row.startTime
        },
      },
      actions: {
        title: 'Actions',
        type: 'custom',
        filter:false,
        renderComponent:  PatientUpcomoingAppointmentRenderComponent,
      }
     
    },
  }
  source4: LocalDataSource = new LocalDataSource();
  settings5 = {
    hideSubHeader: true,
    defaultStyle: false,
    attr: {
      class: 'responsible' // this is custom table scss or css class for table
    },
    actions: {
      edit: false,
      delete: false,
      add: false,

    },
    columns: {
      index: {
        title: 'S No',
        type: 'string',
        valuePrepareFunction: (value, row, cell) => {
          return cell.row.index + 1;
        },
      },
      topic: {
        title: 'Chief Complaint',
        type: 'string',
        valuePrepareFunction: (cell, row4) => {
          return row4.complaints
        },
      },
      date:
      {
        title: 'Prescription Date',
        type: 'string',
        valuePrepareFunction: (cell, row4) => {
          if(row4.prescriptionType==='generated'){
            return row4.publishTime
          }else{
            var formatted =this.datePipe.transform(row4.publishTime, 'dd-MM-yyyy');
            return formatted
          }
        },
      },
      actions: {
        title: 'Actions',
        type: 'custom',
        filter:false,
        valuePrepareFunction: (cell, row4) => {
          return this.mobileno;
        },
        renderComponent: PrescriptionRenderComponent

      }
    },
  }
  source5: LocalDataSource = new LocalDataSource();
  settings6 = {
    hideSubHeader: true,
    defaultStyle: false,
    attr: {
      class: 'responsible' // this is custom table scss or css class for table
    },
    actions: {
      edit: false,
      delete: false,
      add: false,

    },
    columns: {
      rno: {
        title: 'Records No',
        type: 'string', filter: false,
        valuePrepareFunction: (cell, row5) => {
          return row5.recordNo
        },
      },
      rName: {
        title: 'Report Name',
        type: 'string',
        valuePrepareFunction: (cell, row5) => {
          return row5.reportName
        },
      },
      date:
      {
        title: 'Report Date',
        type: 'string',
        valuePrepareFunction: (cell, row5) => {
          var formatted =this.datePipe.transform(row5.measureTime, 'dd-MM-yyyy');
          return formatted
        },
      },
      actions: {
        title: 'Actions',
        type: 'custom',
        filter:false,
        renderComponent: ReportRenderComponent,
      }
    },
  }
  source6: LocalDataSource = new LocalDataSource();
  index: number = 0;
  Age: any;
  CareGiverName: any;
  row1: any;
  row2: any;
  row3: any;
  row4: any;
  headers: { field: string; value: any; }[];
  ecgList: any;
  loading: boolean = false;
  prescriptionsListLoading: boolean;
  
  VitalsLoading: boolean;
  events = [];
  patientAppointmentsLoading: boolean;
  constructor(
    private datePipe: DatePipe,private store: Store<AppState>, private toastrService: NbToastrService, private dialogService: NbDialogService, private router: Router,
    private apiService: PatientService, private modalService: NgbModal,
  ) {
    super()
  }
  elements: any[] = [];
  myDate = new Date();
  dateToday:any;
  storage: Storage = sessionStorage;
  ngOnInit(): void {

    
    this.dateToday= this.datePipe.transform(this.myDate, 'yyyy-MM-dd HH:mm:ss');
    console.log('data is'+this.dateToday);
    if (this.from == 'doctorPortal') {
      this.show = false
    }
    this.custromerData = this.data;
    this.mobileno = this.custromerData?.memberDTO?.Mobile;
    this.personId = this.custromerData?.memberDTO?.personId;
    this.memberDTO = this.custromerData?.memberDTO;
    this.Age = this.custromerData != null ? this.custromerData?.memberDTO?.Age : ""
    this.CareGiverName = this.custromerData?.userSocialengagementDTO != null ? this.custromerData?.userSocialengagementDTO?.careGiverContact?.name : "";
    this.headers = [
      {
        "field": "Care Giver Name",
        "value": JSON.parse(this.custromerData?.userSocialengagementDTO?.careGiverContact)?.name
      },
      {
        "field": "Mobile",
        "value": this.custromerData?.memberDTO?.Mobile
      },
    ]
    this.elements = [{
      "field": "Basic Info",
      "child": [{
        "field": "Gender",
        "value": this.custromerData?.memberDTO.Sex
      },
      {
        "field": "Age",
        "value": this.custromerData?.memberDTO.Age
      },
      {
        "field": "Height",
        "value": ""
      },
      {
        "field": "Weight",
        "value": ""
      },
      {
        "field": "Blood Pressure",
        "value": ""
      },

      {
        "field": "Diabetic",
        "value": ""
      },
      {
        "field": "Thyroid",
        "value": ""
      },
      {
        "field": "Blood Group",
        "value": ""
      },
      ]
    },
    {
      "field": "Allergies",
      "value": ""
    },
    {
      "field": "Medical history",
      "value": ""
    },
    ];
    this.store.select(getPrescriptionsList).subscribe((data) => {
      this.prescriptionsListLoading = data?.prescriptionsListLoading;
      if (data?.prescriptionsList) {
        this.source1.load(data.prescriptionsList);
        this.source5.load(data.prescriptionsList);
      }
    })
    this.store.select(getVitals).subscribe((data) => {
      this.VitalsLoading = data?.VitalsLoading;
      if (data.vitals) {
        this.VitalsLoading = data.VitalsLoading;
        this.loading=this.VitalsLoading;
        this.loadReports(data.vitals)
      }
    })
    this.store.select(getPatientAppointmentList).subscribe((data) => {
      if (data.patientAppointmentList){
        this.row = data.patientAppointmentList.filter((e)=>{
          return (this.from != 'doctorPortal' || (this.from == 'doctorPortal' && e.cancelledBy!='Doctor'))
        });
        this.source.load(this.row)
        if(this.from == 'doctorPortal'){
          this.store.select(getDoctor).subscribe(data => {
            this.row=this.row.filter((e)=>{
              return e.doctorName==data.username
            })
            this.source4.load(this.row)
          })
        }
        this.patientAppointmentsLoading = data.PatientAppointmentListLoading;
      }
      
    })
    this.store.select(getMedicationList).subscribe((data) => {
      // if (data.userMedicationList){
        this.medicationList=data.userMedicationList.map((e)=>{
          return cloneDeep(e);
        });


        this.pastmedicationList=data.userMedicationList.filter( (e)=>
        {
            return e.endTime!=null && new Date(e.endTime) < new Date(this.dateToday)
        });


        this.activemedicationList=data.activeMedicationList.map((e)=>{
          return cloneDeep(e);
        })

        this.activemedicationList=this.activemedicationList.filter( (e)=>
        {
            return e.endTime==null || new Date(e.endTime) >= new Date(this.dateToday)
        });
 
    })
    this.loadPatientVitals();
    this.loadPatientDetails(true);
    this.loadPrescriptionhistory();
    this.loadMedicalhistory();
    this.loadRoutine();
    this.loadAppointmnetsHistory();
    this.loadPatientAppointmentHistory();
    // this.loadAppointmnets();
    

  }

  loadRoutine() {

    this.subs.sink = this.apiService.getRoutine(this.personId, moment(new Date()).format('YYYY-MM-DD')).subscribe((data: any) => {

      this.events = [];
      if (data && data.length > 0) {
        this.events = data;
      }
    });
  }

  gotoBPSAssesment() {
    this.router.navigate(['/bpsassesment'], { state: this.custromerData });
  }

  loadPatientVitals() {
    this.subs.sink = this.apiService.getPatientVitals(this.mobileno).subscribe(
      response => {
        this.patientVitals = response;
      }, error => {
        var data = error;
      });
  }

  reportimage: any;
  reportname: any;
  reportsModel(ReportsModelContent, recordno, reportname) {
    this.reportname = reportname;
    this.subs.sink = this.apiService.getReportAttachment(recordno).subscribe(res => {

      this.reportimage = res;
    });
    this.modalService.open(ReportsModelContent, { windowClass: 'animated fadeInDown', size: 'lg' });
  }

  navigate() {
    let tab: any = "patients";
    this.router.navigate(['/home'], { state: tab });
  }

  consultationList: any = [];
  loadPrescriptionhistory() {
    this.store.dispatch(LoadPrescriptionsList({ "mobileno": this.mobileno }));
  }
  loadPatientAppointmentHistory(){
    this.subs.sink=timer(0, APP_CONSTANTS.CONFIG_DETAILS.AUTO_REFRESH_COMMON).subscribe(()=>{
      this.store.dispatch(loadPatientAppointmentList({ "mobileno": this.mobileno }));
    })
  }
  loadMedicalhistory(){
    this.store.dispatch(LoadVitals({ "mobileno": this.mobileno }));
  }

  medicationList: any = [];
  activemedicationList: any = [];
  pastmedicationList: any=[];

  loadActiveMedications() {
    this.store.dispatch(loadMedicationsList({personId:this.personId}));
    // this.subs.sink = this.apiService.getActiveMedications(this.personId).subscribe((resData: any) => {
    //   var medList = [];
    //   console.log("med--",resData)
    //   if (resData != null) {
        
    //     var medicationList1 = resData;
    //     var medList = [];
    //     for (var i = 0; i < medicationList1.length; i++) {
    //       var obj = medicationList1[i];
    //       var isRepeat = false;
    //       for (var j = 0; j < medList.length; j++) {
    //         var obj1 = medList[j];
    //         if (obj1.prescriptionId == obj.prescriptionId && obj1.drugName == obj.drugName) {
    //           obj.time = obj1.time + "," + obj.time;
    //           obj.daysRemaining = obj.daysRemaining > obj1.daysRemaining ? obj.daysRemaining : obj1.daysRemaining;
    //           medList[j] = obj;
    //           isRepeat = true;
    //         }
    //       }
    //       if (!isRepeat) {
    //         medList.push(obj);
    //       }
    //     }
    //     this.medicationList=medList;
    //     console.log("Active medication :"+JSON.stringify(this.medicationList));
     
    //   }
    // }, error => {
    //   var data = error;
    // });
    // this.subs.sink = this.apiService.getUserMedications(this.personId).subscribe((resData: any) => {
    //   if (resData != null) {
    //     this.activemedicationList = resData;
    //     console.log("user medication :"+JSON.stringify(this.activemedicationList));
    //   }
    // }, error => {
    //   var data = error;
    // });
  }

  appointmentsList: any = [];

  appointmentsHistoryList: any = [];
  reportrows: any = [];
  lipidsList: any = [];
  hbList: any = [];
  uaList: any = [];
  patientReportUploadDTOList: any = [];
  loadReports(response:any) {
        this.reportrows = [];
        this.patientVitals = response;
        this.ecgList = response['PEEcgDetails'] != null ? response['PEEcgDetails'] : [];
        this.lipidsList = response['BloodFatDetails'] != null ? response['BloodFatDetails'] : [];
        this.hbList = response['HbDetails'] != null ? response['HbDetails'] : [];
        this.uaList = response['UrinalysisDetails'] != null ? response['UrinalysisDetails'] : [];
        let uricacidList = response['UaDetails'] != null ? response['UaDetails'] : [];
        let cholList = response['CholDetails'] != null ? response['CholDetails'] : [];
        this.patientReportUploadDTOList = response['patientReportUploadDTOList'] != null ? response['patientReportUploadDTOList'] : [];
        if (this.ecgList && this.ecgList.length > 0) {
          this.ecgList.forEach(element => {
            this.reportrows.push(new Report(element.RecordNo, 'ECG', element.measureTime, element, 'ecg'));

          });
        }
        if (this.lipidsList && this.lipidsList.length > 0) {
          this.lipidsList.forEach(element => {
            this.reportrows.push(new Report(element.RecordNo, 'Lipids Profile', element.measureTime, element, ''));
          });

        }
        if (this.hbList && this.hbList.length > 0) {
          this.hbList.forEach(element => {
            this.reportrows.push(new Report(element.RecordNo, 'Himoglobin', element.measureTime, element, ''));
          });

        }
        if (uricacidList && uricacidList.length > 0) {
          uricacidList.forEach(element => {
            this.reportrows.push(new Report(element.RecordNo, 'Uricacid', element.measureTime, element, ''));
          });

        }
        if (cholList && cholList.length > 0) {
          cholList.forEach(element => {
            this.reportrows.push(new Report(element.RecordNo, 'Cholestrol', element.measureTime, element, ''));
          });

        }
        if (this.uaList && this.uaList.length > 0) {
          this.uaList.forEach(element => {
            this.reportrows.push(new Report(element.RecordNo, 'Urin Analysis', element.measureTime, element, ''));
          });

        }
        if (this.patientReportUploadDTOList && this.patientReportUploadDTOList.length > 0) {
          this.patientReportUploadDTOList.forEach(element => {
            this.reportrows.push(new Report(element.recordNo, element.reportName, element.measureTime, element, 'report'));
          });

        }
        this.source2.load(this.reportrows)
        this.source6.load(this.reportrows);

      }
  loadAppointmnetsHistory() {
    this.subs.sink = this.apiService.getPatientAppointmentsHistory(this.mobileno).subscribe((resData: any) => {
      if (resData != null) {
        this.appointmentsHistoryList = resData;
        for (var i = 0; i < this.appointmentsHistoryList.length; i++) {
          if (this.appointmentsHistoryList[i].status == "SCHEDULED") {
            this.appointmentsHistoryList[i].status = "COMPLETED"
          }
        }
        this.row3 = this.appointmentsHistoryList;
        this.source3.load(this.row3)
      }

    }, error => {
      var data = error;
    });

  }

  uploadReports() {
    this.dialogService.open(ReportsUploadDialogComponent, {
      context: {
        data: this.custromerData,
      }, dialogClass: 'model-full',
      autoFocus: false
    }).onClose.subscribe({
      next: (res) => {
        if (res) {
          this.store.dispatch(LoadVitals({ "mobileno": this.mobileno }));
          this.store.dispatch(LoadPrescriptionsList({ "mobileno": this.mobileno }));
        }
      }
    }

    )
  }
  Convert24hrto12hrs(date)
  {
    if(date.includes("Morning") || date.includes("Afternoon") || date.includes("Night") || date.includes("SOS") || date.includes("Other"))
    {
      return date;
    }
    else
    {
     var s=date.split(",");
     
     if(s.length==1)
     {
       s[0]=new Date("1-1-2022 "+s[0]);
       s[0]=this.datePipe.transform(s[0], 'h:mm a');
       return s[0];
     }
     else
     {
       var l='';
       for(var i=0;i<s.length;i++)
       {
        s[i]=new Date("1-1-2022 "+s[i]);
        s[i]=this.datePipe.transform(s[i], 'h:mm a');
        l=l+s[i];
        if(i!=s.length-1)
        {
          l=l+',';
        }
       }
       return s;
     }
    }
  }

  getDateEnd(date)
  {
    var data=''
    switch(date)
    {
      case 1:
      case 21:
      case 31:     
        data= "st ";
        break;
      case 2:
      case 22:
        data="nd ";
        break;
      case 3:
      case 23:
        data= "rd ";
        break;
      default:
        data="th ";
        break;
    }
    return data;

  }

  

  bookAppointment() {
    this.dialogService.open(BookAppointmentComponent, {
      context: {
        text: 'fromAdmin',
        data: this.custromerData,
      }, dialogClass: 'model-full',
      autoFocus: false
    })
      .onClose.subscribe({
        next: (res) => {
          if (res.event == "refresh") {
          this.store.dispatch(loadPatientAppointmentList({'mobileno':this.mobileno}))
          }
        }

      });
  }

  sendSms() {
    this.loading = true;
    var smsParametersDTO = {
      "message": this.custromerData.memberDTO.Name + ", Please upload your medical documents in the following link, ",
      "url": environment.SMS_URL + "recordsupload/" + this.mobileno,
      "mobileNumber": this.mobileno,
      "template": "Medical Records"
    };
    this.subs.sink = this.apiService.sendSms(smsParametersDTO).subscribe((resData: any) => {
      this.loading = false;
      let duration = 2000;
      this.toastrService.show('', 'Message Sent Successfully',{duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});

    }, error => {
      var data = error;
      let duration = 2000;
      this.toastrService.show('', 'Failed to send Sms',{duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});

      this.loading = false;
    });
  }




  openVitalsPopup() {
    this.dialogService.open(VitalsDataentryComponent, {
      context: {
        data: this.latestVitals,
        show:this.show
      }, dialogClass: 'model-full',
      autoFocus: false
    })
      .onClose.subscribe({
        next: (res) => {
          if (res.data != null) {
            this.loadPatientDetails(false);
            this.loadPatientVitals();
          }
        }

      });


  }

  openPastMedicationPopup() {
    this.dialogService.open(PastMedicationComponent, {
      context: {
        data: this.pastmedicationList,
      }, dialogClass: 'model-full',
      autoFocus: false
    })
      .onClose.subscribe({
        next: (res) => {
        
        }

      });


  }

  openMedicalPopup() {
    this.dialogService.open(MedicalDetailsDataentryComponent, {
      context: {
        data: this.latestVitals,
        memberdata: this.memberDTO,
        show:this.show,
      }, dialogClass: 'model-full',
      autoFocus: false
    })
      .onClose.subscribe({
        next: (res) => {
          if (res.data != null) {
            this.loadPatientDetails(false);
            this.loadPatientVitals();
          }
        }

      });
  }


  openMedicineViewPopup(medicine) {
    this.dialogService.open(medicineViewComponent, {
      context: {
        data: medicine,
      }, dialogClass: 'model-full',
      autoFocus: false
    })
      .onClose.subscribe({
        next: (res) => {
        
        }

      });
  }

  loadPatientDetails(loadMedications) {
    this.subs.sink = this.apiService.getReportdetails(this.mobileno).subscribe((resData: any) => {
      var data = resData;
      if (resData != null) {
        this.personId = resData.personId;
        this.latestVitals = resData;
        if (loadMedications) {
          this.loadActiveMedications();
        }
        this.patientData = resData;
        this.weight = resData.weight != null ? resData.weight + " kg" : "";
        this.height = resData.height != null ? resData.height + " cm" : "";
        this.elements[0].child[2].value = this.height;
        this.elements[0].child[3].value = this.weight;
        this.tempCapturedTime = resData.tempMeasureTime != null ? this.getFancyTime(resData.tempMeasureTime.replace("T", " ")) + " ago" : "";
        this.pulseCapturedTime = resData.boMeasureTime != null ? this.getFancyTime(resData.boMeasureTime.replace("T", " ")) + " ago" : "";
        this.glucoCapturedTime = resData.bsMeasureTime != null ? this.getFancyTime(resData.bsMeasureTime.replace("T", " ")) + " ago" : "";
        this.bpCapturedTime = resData.bpMeasureTime != null ? this.getFancyTime(resData.bpMeasureTime.replace("T", " ")) + " ago" : "";
        this.hwCapturedTime = resData.uhMeasureTime != null ? this.getFancyTime(resData.uhMeasureTime.replace("T", " ")) + " ago" : "";
        this.bloodPressure = resData.highPressure != null &&  resData.lowPressure != null ? resData.highPressure + "/" + resData.lowPressure + " mmHg" : "--";
        this.spo2CapturedTime = resData.boOxygenMeasureTime != null ? this.getFancyTime(resData.boOxygenMeasureTime.replace("T", " ")) + " ago" : "";


        this.temperature = resData.temperature != null && resData.temperature != "" ? resData.temperature + " \xB0F" : "--";
        this.pulse = resData.bpm != null && resData.bpm != "" ? resData.bpm + " bpm" : "--";

        this.spO2 = resData.oxygen && resData.oxygen != "" != null ? resData.oxygen + " %" : "--";
        this.bloodGlucose = resData.bloodSugar != null && resData.bloodSugar != "" ? resData.bloodSugar + " mg/dL" : "--";
        // if (this.pulse == "--")
        //   this.pulseCapturedTime = ""
        // if (this.height == "--")
        //   this.hwCapturedTime = ""
        // if (this.bloodGlucose == "--")
        //   this.glucoCapturedTime = ""
        // if (this.temperature == "--")
        //   this.tempCapturedTime = ""
        // if (this.bloodPressure == "--")
        //   this.bpCapturedTime = ""

        if (resData['userHealthDetailsDTO'] != null) {
          this.bloodGroup = resData['userHealthDetailsDTO']['bloodGroup'] ? resData['userHealthDetailsDTO']['bloodGroup'] + " ve" : "";
          this.diabeticsince = resData['userHealthDetailsDTO']['diabeticSince'] != null && resData['userHealthDetailsDTO']['diabeticSince'] != "" ? " - " + resData['userHealthDetailsDTO']['diabeticSince'] : "";
          this.thyroidsince = resData['userHealthDetailsDTO']['thyroidSince'] != null && resData['userHealthDetailsDTO']['thyroidSince'] != "" ? " - " + resData['userHealthDetailsDTO']['thyroidSince'] : "";
          this.bpsince = resData['userHealthDetailsDTO']['bloodPressureSince'] != null && resData['userHealthDetailsDTO']['bloodPressureSince'] != "" ? " - " + resData['userHealthDetailsDTO']['bloodPressureSince'] : "";
          this.allergies = resData['userHealthDetailsDTO']['allergies'] != null ? resData['userHealthDetailsDTO']['allergies'] : "-";
          if (resData['userHealthDetailsDTO']['diabetic'] != null) {
            this.diabetic = this.stringToBoolean(resData['userHealthDetailsDTO']['diabetic']) ? "Yes" : "No";
          }
          if (resData['userHealthDetailsDTO']['bloodPressure'] != null) {
            this.bp = this.stringToBoolean(resData['userHealthDetailsDTO']['bloodPressure']) ? "Yes" : "No";
          }
          else {
            this.bp = "";
          }
          if (resData['userHealthDetailsDTO']['thyroid'] != null) {
            this.thyroid = this.stringToBoolean(resData['userHealthDetailsDTO']['thyroid']) ? "Yes" : "No";
          }
          this.diseases = resData['userHealthDetailsDTO']['medicalHistory'] != null ? resData['userHealthDetailsDTO']['medicalHistory'] : "-";
          this.elements[1].value = this.allergies;
          this.elements[2].value = this.diseases;
          //  this.headers[2].value=this.bloodGroup;
        }
        this.elements[0].child[4].value = this.bp;
        this.elements[0].child[5].value = this.diabetic;
        this.elements[0].child[6].value = this.thyroid;
        this.elements[0].child[7].value = this.bloodGroup;
      }

    }, error => {
      var data = error;
    });

  }

  getFancyTime(date): string {
    var date1 = new Date(date).getTime();
    var date2 = new Date().getTime();
    var msec = date2 - date1;
    var mins = Math.floor(msec / 60000);
    var hrs = Math.floor(mins / 60);
    var days = Math.floor(hrs / 24);
    mins = mins % 60;
    hrs = hrs % 24;
    days = days % 365;
    if (days >= 1)
      return days == 1 ? days.toString() + " day" : days.toString() + " days";
    else if (days < 1 && hrs >= 1)
      return hrs == 1 ? hrs.toString() + " hour" : hrs.toString() + " hours";
    else
      return mins == 1 ? mins.toString() + " min" : mins.toString() + " mins";
  }

  private stringToBoolean(stringValue: string): boolean | undefined {
    try {
      return JSON.parse(stringValue);
    }
    catch (e) {
      return undefined;
    }
  }

  patientVitals: any;
  rows: any = [];
  vitalType: string;
  row: any = [];
  LargeModel(LargeModelContent, type: string) {
    this.vitalType = type;
    let data: any;
    let mothData: any;
    let yearData: any;

    if (this.patientVitals != null) {
      this.rows = [];
      if (type == 'BMI' && this.patientVitals.HeightDetails != null && this.patientVitals.HeightDetails.length > 0) {
        let dates: any[] = [];
        let BMI: any = [];
        let monthdates: any[] = [];
        let monthBMI: any = [];
        let yeardates: any[] = [];
        let yearBMI: any = [];

        data = {
          "labels": dates,
          "series": BMI
        }
        mothData = {
          "labels": monthdates,
          "series": monthBMI,
        }
        yearData = {
          "labels": yeardates,
          "series": yearBMI,
        }
      }
      else if (type == "Body temperature") {
        let dates: any[] = [];
        let temp: any = [];
        let monthdates: any[] = [];
        let monthtemp: any = [];
        let yeardates: any[] = [];
        let yeartemp: any = [];
        if (this.patientVitals.TemperatureDetails != null) {
          data = {
            "labels": dates,
            "series": temp
          }
          mothData = {
            "labels": monthdates,
            "series": monthtemp,
          }
          yearData = {
            "labels": yeardates,
            "series": yeartemp,
          }
        }
      }
      else if (type == "Blood pressure") {
        let dates: any[] = [];
        let BPhigh: any = [];
        let BpLow: any = [];
        let monthdates: any[] = [];
        let monthBPhigh: any = [];
        let monthBpLow: any = [];
        let yeardates: any[] = [];
        let yearBPhigh: any = [];
        let yearBpLow: any = [];
        if (this.patientVitals.BloodPressureDetails != null) {
          data = {
            "labels": dates,
            "series": [BPhigh, BpLow],
          }
          mothData = {
            "labels": monthdates,
            "series": [monthBPhigh, monthBpLow],
          }
          yearData = {
            "labels": yeardates,
            "series": [yearBPhigh, yearBpLow],
          }
        }
      }
      else if (type == "Pulse") {
        let dates: any[] = [];
        let pulse: any = [];
        let monthdates: any[] = [];
        let monthpulse: any = [];
        let yeardates: any[] = [];
        let yearpulse: any = [];
        data = {
          "labels": dates,
          "series": pulse
        }
        mothData = {
          "labels": monthdates,
          "series": monthpulse,
        }
        yearData = {
          "labels": yeardates,
          "series": yearpulse,
        }
      }
    }
    else if (type == "Blood glucose") {
      let dates: any[] = [];
      let BG: any = [];
      let monthdates: any[] = [];
      let monthBG: any = [];
      let yeardates: any[] = [];
      let yearBG: any = [];
      data = {
        "labels": dates,
        "series": BG
      }
      mothData = {
        "labels": monthdates,
        "series": monthBG,
      }
      yearData = {
        "labels": yeardates,
        "series": yearBG,
      }
    }
  }
}


class Report {
  constructor(
    public recordNo: number,
    public reportName: string,
    public measureTime: string,
    public vital: any,
    public name: any,
  ) { }
}



