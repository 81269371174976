import { Component,Input, OnInit} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {  NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { ApiDrug } from 'src/app/shared/_models/apidrug';
import { lifeStyleModficationsList } from 'src/app/shared/_models/lifeStyleModificationsList';
import { Prescription } from 'src/app/shared/_models/prescription';
import { PrescriptionEditService } from '../../services/Prescription.services';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { lifeStyleModfications } from 'src/app/shared/_models/lifeStyleModificationsDetails';
import { AppState } from 'src/app/store/root.reducer';
import { Store } from '@ngrx/store';
import { getDoctor } from 'src/app/store/selectors/doctor-user.selectors';
import { APP_CONSTANTS } from '../../constants';
import { ACPrescriptionNoteDialog } from 'src/app/doctorportal/doctor-dialogue-components/prescription-note/prescriptionnote.component';
import { DrugsInteractionDialog } from 'src/app/doctorportal/doctor-dialogue-components/drugsinteraction/drugsinteraction.component';
import { UnsubscribeOnDestroyAdapter } from '../../services/UnsubscribeOnDestroyadapter';

@Component({
  selector: 'app-addprescription',
  templateUrl: './addprescription.component.html',
  styleUrls: ['./addprescription.component.scss']
})
export class AddprescriptionComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  appointmentDTO: any;
  Aadhar: string = "";
  patientName: string;
  from: string = "Doctor";
  user: string = "";
  patientNumber: string;
  patientMail: string;
  bloodGroup: string;
  bpCapturedTime: string;
  glucoCapturedTime: string;
  prescription = new Prescription();
  weightCapturedTime: string;
  tempCapturedTime: string;
  diseases: string;
  allergies: string;
  chiefComplaint: string;
  callTransferReason: string;
  bloodPressure: string = "--";
  temperature: string = "--";
  pulse: string = "--";
  bloodGlucose: string = "--";
  weight: string = "--";
  pulseCapturedTime: string;
  height: string = '--';
  disease: string;
  age: string;
  lmp: string;
  bmi: any = "--"
  hdlp: any;
  ldlp: any;
  gender: string;
  diabetic: any;
  thyroid: any;
  opened: boolean;
  isb2b: boolean;
  refresh: string = "no"
  conditions: any = [];
  ecgResult: string = "-";
  cholestrol: string = "--";
  uricacid: string = "--";
  totalcholestrol: string = "--";
  triglyceride: string = "--";
  hdlipoprotein: string = "--";
  ldlipoprotein: string = "--";
  urinanalysis: string = "--";
  himoglobin: string = "--";
  cholestolCapturedTime: string;
  uricacidCapturedTime: string;
  totalcholestrolCapturedTime: string;
  urinanalysisCapturedTime: string;
  himoglobinCapturedTime: string;
  ecgCapturedTime: string;
  hwCapturedTime: string;
  searching = false;
  searchFailed = false;
  spO2: string = "--";
  isDisabled: boolean = true;
  submitted = false;
  drugslist: any = [];
  instructions: string[] = ['Before Food', 'After Food'];
  durations: string[] = [ 'One Day', 'Two Days', 'Three Days', 'Four Days', 'Five Days', 'Six Days', 'Seven Days', 'Eight Days', 'Nine Days',
    'Ten Days', 'Two Weeks', 'Three Weeks', 'Four Weeks', "One Month", "Two Months", "Three Months", "Four Months", "Five Months", "Six Months"];
  frequencies: string[] = ["Morning", "Afternoon", "Night", "SOS", "Others"];
  dosagelist: any = [];
  rows: any = [];
  reportrows: any = [];

  parentChannel: string;
  consultationList: any = [];
  allVitalsDTO: any;
  previewPrescription: any;
  published: boolean = false;
  drugs: Observable<ApiDrug[]>[] = [];
  patientVitals: any;
  ecgList: any = [];

  doctorId: any;
  modificationSuggestions: lifeStyleModficationsList[];
  suggestionselected: number = 0;
  suggestionsIdList: number[] = [0];
  applyStyles: boolean = false;
  public configScroll: PerfectScrollbarConfigInterface = { suppressScrollY: false, wheelPropagation: false };
  public config: PerfectScrollbarConfigInterface = { suppressScrollY: true };
  consultRows: any = [];
  consultTemp = this.consultRows;
  connecting: boolean = true;
  showCancel: boolean = true;
  @Input() data: any;

  public lifeStyleModfication: lifeStyleModfications[] = [];
  username: string;
  currentUser: any;

  consultationForm: FormGroup;
  public prescriptionList: FormArray;
  public lifestylemodificationList: FormArray;
  personId: any;
  loading: boolean = false;
  id:any;

  complaints: any;
  observation: any;
  examinations: any;
  relevantPoints: any;
  diagnosis: any;
  investigation: any;
  drugName: any;
  dosage: any;
  frequency: any;
  duration: any;
  instruction: any;
  notes: any;
  viewType: number;

  constructor(private toastrService: NbToastrService, private store: Store<AppState>, private formBuilder: FormBuilder, private dialogService: NbDialogService,private apiService: PrescriptionEditService, private dialogRef: NbDialogRef<AddprescriptionComponent>) 
  {
    super()
    this.store.select(getDoctor).subscribe(data => {
      this.currentUser = data.username;
    })
  }


  get consultationFormGroup() {
    return this.consultationForm.get('prescriptionList') as FormArray;

  }
  getValidityDrug(i) {
    return (<FormArray>this.consultationForm.get('prescriptionList')).controls[i].get('drugName').errors
    && (<FormArray>this.consultationForm.get('prescriptionList')).controls[i].get('drugName').invalid
    && (<FormArray>this.consultationForm.get('prescriptionList')).controls[i].get('drugName').touched;
  }
  getValidityIns(i) {
    return (<FormArray>this.consultationForm.get('prescriptionList')).controls[i].get('instructions').errors
    && (<FormArray>this.consultationForm.get('prescriptionList')).controls[i].get('instructions').invalid
    && (<FormArray>this.consultationForm.get('prescriptionList')).controls[i].get('instructions').touched;
  }
  getValidityFreq(i) {
    return (<FormArray>this.consultationForm.get('prescriptionList')).controls[i].get('frequency').errors
    && (<FormArray>this.consultationForm.get('prescriptionList')).controls[i].get('frequency').invalid
    && (<FormArray>this.consultationForm.get('prescriptionList')).controls[i].get('frequency').touched;
  }
  getValidityDue(i) {
    return (<FormArray>this.consultationForm.get('prescriptionList')).controls[i].get('duration').errors
    && (<FormArray>this.consultationForm.get('prescriptionList')).controls[i].get('duration').invalid
    && (<FormArray>this.consultationForm.get('prescriptionList')).controls[i].get('duration').touched;
  }

  get lifeStyleModficationFormGroup() {
    return this.consultationForm.get('lifestylemodficationList') as FormArray;
  }

  drugAlts: Observable<any[]>[] = [];
  prescSuggestions: any = [];
  prescSuggestionsFlag: boolean = false;
  showAltFlag: Array<boolean> = [];
  prescriptionNotes: any;

  displayFn(drug: any): string {
    return drug && drug.name ? drug.name : drug;
  }

  suggestPrescription(e: NgbTypeaheadSelectItemEvent) {
    this.loading=true;
    this.subs.sink=this.apiService.suggestPrescription(e.item.prescriptionIds.slice(-3)).subscribe((res: any) => {
          this.loading=false;
      if (res && res.length > 0) {
        this.showPrescriptionNoteDialog(res);
      }
    });
  }

  loadPrescritption(presc: any) {
    // this.prescSuggestionsFlag = presc && presc.prescriptionDetails && presc.prescriptionDetails.length > 0;
    let prescSuggestions = [];
    for (var i = 0; i < this.prescriptionList.length; i++) {
      this.removePrescritption(i);
    }
    for (var i = 0; i < presc.prescriptionDetails.length; i++) {
      this.addPrescritption();
      let p: any = presc.prescriptionDetails[i];
      p.drugName = (p.drug && p.drug.name) ? p.drug.name : p.drugName;
      // p.drugName=JSON.parse(JSON.stringify(p.drug));
      p.rxcui=p.drug?.rxcui;
      delete p.drug;
      if (p.drugAlts && p.drugAlts.length > 0) {
        let ad = [];
        if (p.altDrugs) {
          p.altDrugs.split(", ").forEach((d) => { const dAlt = p.drugAlts.find((alt) => alt.name == d); dAlt ? ad.unshift(dAlt) : ad.unshift({ "name": d }) });
        }
        p.altDrugs = ad;
        this.drugAlts[i] = of(p.drugAlts);
        this.showAltFlag[i] = true;
        
      }
      if (p.frequency) {
        let fq = [];
        p.frequency.split(", ").forEach((f) => fq.push(f));
        p.frequency = fq;
      }
      prescSuggestions.push(p);
    }
    const clinicalNotes = presc.clinicalNotes;
    const lifeStyleModifications = presc.lifeStyleModifications;
    const controls1 = <FormArray>this.consultationForm.controls['lifestylemodficationList'];
    controls1.clear();
    for (var i = 0; i < lifeStyleModifications.length; i++) {
      this.addLifeStyleModfication();
    }
    clinicalNotes['lifestylemodficationList'] = lifeStyleModifications.map(lsc => { return { 'changes': lsc.lifeStyleChanges } });
    clinicalNotes['prescriptionList'] = prescSuggestions;
    clinicalNotes.complaints = { "complaints": clinicalNotes.complaints }
    this.consultationForm.patchValue(clinicalNotes);
  }

  loadDrugAlts(index, medicine) {
    this.subs.sink=this.apiService.getDrugsbyName(medicine).subscribe((res: any[]) => {
      if (res.length > 0) {
        this.getDrugAlts(index, res[0]);
      }
    });
  }

  getDrugs(index,med)
  {
  }
  getDrugAlts(index, medicine) {
    if(medicine.name){
      const controls = <FormArray>this.consultationForm.controls['prescriptionList'];
    controls.at(index).get('altDrugs').patchValue([]);
    this.showAltFlag[index] = false;
    this.drugAlts[index] = of([]);
    if (medicine.generic && medicine.generic.trim().length > 0) {
      this.drugAlts[index] = this.apiService.getDrugsbyGenericName(medicine.generic).pipe(
        tap((resdata: any) => {
          this.showAltFlag[index] = resdata && resdata.length > 0;
          let ind = resdata.findIndex(x => x.id === medicine.id);
          resdata.splice(ind, 1);
          return resdata;
        }),
        catchError(() => {
          return of([]);
        }));
    }
  }
  }
  
  addAltDrug(index: number, item: any) {
    var arrayControl = this.consultationForm.get('prescriptionList') as FormArray;
    // const drugSel = arrayControl.at(index).get('drugName').value;
    let altDrugs = arrayControl.at(index).get('altDrugs').value;
    if (!altDrugs.find(x => x.id === item.id || x.name === item.name)) {
      altDrugs.push(item);
      arrayControl.at(index).get('altDrugs').patchValue(altDrugs);
    }
  }

  showPrescriptionNoteDialog(rows) {
    this.dialogService.open(ACPrescriptionNoteDialog, {
      context: {
        data: { pageValue: rows }
      }, dialogClass: 'model-full',
      autoFocus: false
    })
      .onClose.subscribe({
        next: (result) => {
          if (result.event == 'not-select') {
            this.prescriptionNotes = result.data;
          } else {
            if (result.data != null) {
              this.loadPrescritption(result.data);

            }
          }
        }
      });
  }

  suggestComplaints = (text$: Observable<any>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.apiService.suggestComlaints({
          "doctorId": this.currentUser,
          "clinicalNotes": { "complaints": term }
        }).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    )

  formatter = (result: any) => result.complaints;

  get pdf() {
    return this.consultationForm.controls;
  }

  addPrescritption() {
    this.prescriptionList.push(this.createPrescription());
    const controls = <FormArray>this.consultationForm.controls['prescriptionList'];
    this.manageNameControl(controls.length - 1);
  }

  removePrescritption(index) {
    const controls = <FormArray>this.consultationForm.controls['prescriptionList'];
    controls.removeAt(index);
    this.drugs.splice(index, 1);
    this.drugAlts[index] = of([]);
    this.showAltFlag[index] = false;
  }

  addLifeStyleModfication() {
    this.lifestylemodificationList.push(this.createliftstyleModification());
    const controls1 = <FormArray>this.consultationForm.controls['lifestylemodficationList'];

  }

  removeLifeStyleModfication(index) {
    const controls1 = <FormArray>this.consultationForm.controls['lifestylemodficationList'];
    controls1.removeAt(index);
  }

  createPrescription(): FormGroup {

    return this.formBuilder.group({
      drugName: ['', Validators.required],
      rxcui:[''],
      dosage: [''],
      instructions:['', Validators.required],
      frequency: ['', Validators.required],
      duration:['', Validators.required],
      altDrugs: [[]]
    });
  }
  createliftstyleModification(): FormGroup {

    return this.formBuilder.group({
      changes: ['',[Validators.pattern(APP_CONSTANTS.REGEX.ALPHABET_WITH_SPACE)]]
    });
  }

  ngOnInit() {
    if (window.innerWidth >= 1200) {
      this.viewType = 1;
    } else if (window.innerWidth < 1200 && window.innerWidth >= 960) {
      this.viewType = 4;
    } else if (window.innerWidth < 960 && window.innerWidth >= 768) {
      this.viewType = 3;
      this.applyStyles=true;
    } else if (window.innerWidth < 480) {
      this.viewType = 2;
      this.applyStyles=true;
    }
    this.personId = this.data.personId;
    this.id = this.data.id;

 
    this.drugs[0] = this.drugslist;
    this.complaints = this.data.clinicalNotes.complaints;
    this.observation = this.data.clinicalNotes.observation;
    this.relevantPoints = this.data.clinicalNotes.relevantPoints;
    this.investigation = this.data.clinicalNotes.investigation;
    this.notes = this.data.clinicalNotes.notes;
    this.diagnosis = this.data.clinicalNotes.diagnosis;
    this.examinations = this.data.clinicalNotes.examinations;

    this.consultationForm = this.formBuilder.group({
      complaints: ['', Validators.required],
      observation: [''],
      examinations: [''],
      diagnosis: ['', Validators.required],
      relevantPoints: [''],
      investigation: [''],
      notes: [''],
      prescriptionList: this.formBuilder.array([this.createPrescription()],[Validators.required]),
      lifestylemodficationList: this.formBuilder.array([this.createliftstyleModification()])
    });
    this.manageNameControl(0);

    this.prescriptionList = this.consultationForm.get('prescriptionList') as FormArray;
    this.lifestylemodificationList = this.consultationForm.get('lifestylemodficationList') as FormArray;
    
    this.getLifeStyleModifications();

    var array = [];
    var array1 = [];
    var ind = 0;

    this.loadPrescritption(this.data);
    this.consultationForm.patchValue({
      'complaints':{complaints: this.complaints} , 'observation': this.observation, 'examinations': this.examinations,
      'diagnosis': this.diagnosis, 'relevantPoints': this.relevantPoints, 'notes': this.notes, 'investigation': this.investigation
    });
  }

  manageNameControl(index: number) {
    var arrayControl = this.consultationForm.get('prescriptionList') as FormArray;
    this.drugs[index] = arrayControl.at(index).get('drugName').valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        switchMap(name => name?.length > 2 ? this.apiService.getDrugsbyName(name).pipe(
          tap((resdata: any) => {
            return resdata[3];

          }),
          catchError(() => {
            return of([]);
          })) : []
        )
      );
  }

  getLifeStyleModifications() {
    var suggesttions = this.suggestionsIdList.join(',');
    this.doctorId = this.username;
    this.subs.sink=this.apiService.suggestLifeStyleModifications(this.doctorId, suggesttions).subscribe((resData: any) => {
      this.modificationSuggestions = resData;
      this.modificationSuggestions=this.modificationSuggestions.filter((ele)=>ele.lifeStyleChanges!="");
    }, error => {
      var data = error;
    });

  }

  addLifeStyleSuggestion(item) {
    this.suggestionsIdList.push(item.id);
    this.getLifeStyleModifications();
    if (!this.lifestylemodificationList.value.find(x => x.changes === item.lifeStyleChanges)) {
      const controls1 = <FormArray>this.consultationForm.controls['lifestylemodficationList']
      if (controls1.value[0]?.changes == null || controls1.value[0].changes != '') {
        this.addLifeStyleModfication();
      }
      const controls = <FormArray>this.consultationForm.controls['lifestylemodficationList'];
      var n = this.lifestylemodificationList.length - 1;
      for (var i = 0; i < controls.length - 1; i++) {
        if (controls.value[i].changes == '' && controls.length > 1) {
          this.removeLifeStyleModfication(n);
          n = i;
          break;
        }
      }
      this.lifestylemodificationList.at(n).patchValue({ 'changes': item.lifeStyleChanges });
    }
  }

  publishPrescription(): void {
    let rxcuis = [];
    if (this.consultationForm.invalid) {
      this.toastrService.show("",'Please enter all the mandatory fields.', {duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
      return;
    }
    this.prescription.personId = this.personId;
    var username = this.currentUser;
    this.prescription.doctorId = username;

    let diagnosis = this.consultationForm.get('diagnosis').value;
    if (diagnosis != null && typeof diagnosis != 'string')
      this.consultationForm.patchValue({ 'diagnosis': (diagnosis[0]) })

    let clinicalNotes = Object.assign({}, this.consultationForm.value);
    if (clinicalNotes.complaints != null && typeof (clinicalNotes.complaints) != 'string') {
      clinicalNotes.complaints = clinicalNotes.complaints.complaints
    }
    this.prescription.clinicalNotes = clinicalNotes;
    let prescriptionDetails = [];
    clinicalNotes.prescriptionList.slice(0).forEach(function (val) {
      let value = Object.assign({}, val);
        if (value.drugName != null ) {
          if (value.drugName.rxcui || value.rxcui) {
            let valueEle=value.drugName.rxcui?value.drugName.rxcui:value.rxcui;
            if(valueEle && valueEle!=''){
              rxcuis = rxcuis.concat(valueEle.split(', '));
            }
          }
          value.drugName = value.drugName.name?value.drugName.name:value.drugName;
        }
        delete value.rxcui;
        if (value.frequency != null && typeof (value.frequency) != 'string') {
          value.frequency = value.frequency.join(", ");
        }
        if (value.altDrugs != null && typeof (value.altDrugs) != 'string') {
          value.altDrugs = value.altDrugs.map(function (elem) {
            return elem.name;
          }).join(", ");
        }
      
      prescriptionDetails.push(value);
    });
    delete clinicalNotes.prescriptionList;
    delete clinicalNotes.lifestylemodficationList;
    this.prescription.prescriptionDetails = prescriptionDetails;
    this.prescription.editPrescription = true;
    var username = this.currentUser;
    this.prescription.id = this.id;
    this.lifeStyleModfication = [];

    for (var i = 0; i < this.lifestylemodificationList.value.length; i++) {
      this.lifeStyleModfication.push({ "prescriptionId": this.data.prescriptionId, "lifeStyleChanges": this.lifestylemodificationList.value[i].changes });
    }
    this.prescription.lifeStyleModifications = this.lifeStyleModfication;
    if (this.prescriptionList.length > 1 && rxcuis.length > 1) {
      // this.spinner.show();
      this.subs.sink=this.apiService.getDrugsInteractions(rxcuis.join('+')).subscribe((res) => {
        // this.spinner.hide();
        let interactions = [];
        res['fullInteractionTypeGroup']?.forEach((intGrp) => {
          intGrp['fullInteractionType']?.forEach((intType) => {
            intType['interactionPair']?.forEach((intPair) => {
              interactions.push(intPair['description']);
            })
          })
        });
        if (interactions.length > 0) {
          this.showDrugsInteractionDialog(interactions, "publish");
        } else {
          this.publishPrescriptionFinal();
        }
      })
    } else {
      this.publishPrescriptionFinal();
    }
  }

  showDrugsInteractionDialog(rows, source) {
    this.dialogService.open(DrugsInteractionDialog, {
      context: {
        data: { pageValue: rows ,source: source}
      }, dialogClass: 'model-full',
      autoFocus: false
    })
      .onClose.subscribe({
        next: (result) => {
          if (result.event == 'publish') {
            this.publishPrescriptionFinal();
          }
          
        }
      });
  }
  publishPrescriptionFinal() {

    this.loading = true;
    this.subs.sink=this.apiService.savePrescription(this.prescription).subscribe(response => {
      this.loading = false;
      let duration = 2000;
      this.toastrService.show('', 'Prescription is updated successfully.', {duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
      this.dialogRef.close({ event: 'refresh' });
    },
      error => {
        console.log(error);
      });
  }


  onCancel() {
    this.dialogRef.close(false);
  }

  searchDrug = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.filterDrugs(term)
      ),
      tap(() => this.searching = false)
    )

  filterDrugs(value) {

    var filteredDrugs = [];
    this.drugs = [];
    if (value != null && value != "") {
      this.drugslist.forEach(element => {

        if (element.composition.toLowerCase().includes(value.toLowerCase())) {
          filteredDrugs.push(element);
        }
      });
      return filteredDrugs;
    }
  }

  searchFromService = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.apiService.getICD11(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    );


}
