import { Inject, Input, Optional } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validator, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';



@Component({
  selector: 'statuspage',
  templateUrl: './statuspage.component.html',
  styleUrls: ['./statuspage.component.scss'],
  providers: []
})
export class StatusPageComponent implements OnInit {

  
  message:any;
  type:any;

  constructor(private router: Router, private route: ActivatedRoute)
  {

  }

  ngOnInit() {
    this.type = this.route.snapshot.paramMap.get('message');
    if(this.type=="consultation")
    {
      this.message="Your Consultation successfully completed."
    }
    else if(this.type=="reportsupload")
    {
      this.message="Your Report uploaded successfully"
    }
    console.log(this.message);
}


}
