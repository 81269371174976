import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { AppState } from "../root.reducer";
import { select, Store } from "@ngrx/store";
import { catchError, concatMap, map, mergeMap, switchMap, takeUntil } from "rxjs/operators";
import { LoginService } from "src/app/components/login/login.service";
import { AdminDashboardLoading, AdminUpcomingConsultationListLoading, loadAppointmentCount, loadDashboard, loadDoctorsList, loadFollowupList, loadPatientList, loadPaymentsList, loadUpcomingConsultationsList, UpdateAppointmentsCount, UpdateDashbaord, UpdateDoctorsList, UpdateFollowupList, UpdatePatientList, UpdatePaymentsList, UpdateUpcomingConsultationsList } from "../actions/home.actions";
import { forkJoin, of, Subject, timer } from "rxjs";
import { DashboardService } from "src/app/adminportal/service/dashboard.service";

@Injectable()
export class DashboardEffects {
    constructor(private actions$: Actions,private dashboardService:DashboardService,private store:Store<AppState>) {

    }
    destroy= new Subject();
    dashboardTimer$ = createEffect(
      () => this.actions$
          .pipe(
              ofType(loadDashboard),
              switchMap(() => timer(0,5*60*1000).pipe(takeUntil(this.destroy))),
                 mergeMap(action => {
                  var array = [];
                  this.store.dispatch(AdminDashboardLoading());
                  array.push(this.dashboardService.getAllCustomers().pipe(
                      catchError((err) => {
                          return of()
                        // Handle specific error for this call
                      }
                    )));
                  array.push(this.dashboardService.getAllAppointments().pipe(
                      catchError((err) => {
                          return of()
                        // Handle specific error for this call
                      }
                    )));
                  array.push(this.dashboardService.getDoctors().pipe(
                      catchError((err) => {
                          return of()
                        // Handle specific error for this call
                      }
                    )));
                  array.push(this.dashboardService.getAllAppointmentPayments().pipe(
                      catchError((err) => {
                          return of()
                        // Handle specific error for this call
                      }
                    )));                  
                  array.push(this.dashboardService.getAllFollowups("Payments").pipe(
                      catchError((err) => {
                          return of()
                        // Handle specific error for this call
                      }
                    )));
                  array.push(this.dashboardService.getAllFollowups("Refunds").pipe(
                      catchError((err) => {
                          return of()
                        // Handle specific error for this call
                      }
                    )));
                  array.push(this.dashboardService.getAllFollowups("Appointments").pipe(
                      catchError((err) => {
                          return of()
                        // Handle specific error for this call
                      }
                    )));
                    array.push(this.dashboardService.getAppointmentsCount().pipe(
                      catchError((err) => {
                          return of()
                        // Handle specific error for this call
                      }
                    )));
                    array.push(this.dashboardService.getDaywiseAppointmentsCount().pipe(
                      catchError((err) => {
                          return of()
                        // Handle specific error for this call
                      }
                    )));
                    array.push(this.dashboardService.getConsultedPatientCountByDepartment().pipe(
                      catchError((err) => {
                          return of()
                        // Handle specific error for this call
                      }
                    )));
                    array.push(this.dashboardService.getRecentlyConsultedPatients().pipe(
                      catchError((err) => {
                          return of()
                        // Handle specific error for this call
                      }
                    )));
                    array.push(this.dashboardService.getRecentAvailableDoctors().pipe(
                      catchError((err) => {
                          return of()
                        // Handle specific error for this call
                      }
                    )));
                  return forkJoin(array);
                }),
              map(data => {
                console.log(data);
                return UpdateDashbaord({patientList: data[0],
                  upcomingConsultationsList:data[1],
                  doctorsList:data[2],
                  paymentsList:data[3]
                  ,paymentsFollowupList:data[4],
                  refundsFollowupList:data[5],
                  appointmentsFollowupList:data[6],
                  getAppointmentsCount:data[7],
                  getDaywiseAppointmentsCount:data[8],
                  getConsultedPatientCountByDepartment:data[9],
                  getRecentlyConsultedPatients:data[10],
                  getRecentAvailableDoctors:data[11]
              })
              })
          ), { dispatch: true });
          UpdatePatientList$ = createEffect(
            () => this.actions$
              .pipe(
                ofType(loadPatientList),
                mergeMap(action => {
                  var array = [];
                   return this.dashboardService.getAllCustomers()
                }),
                map(config => {
                  return UpdatePatientList({
                    patientList: config
                  })
                })
              )
          );
          UpdateUpcomingConsultationsList$ = createEffect(
            () => this.actions$
              .pipe(
                ofType(loadUpcomingConsultationsList),
                switchMap(() => timer(0,5*60*1000).pipe(takeUntil(this.destroy))),
                mergeMap(action => {
                   this.store.dispatch(AdminUpcomingConsultationListLoading ());
                   return this.dashboardService.getAllAppointments()
                }),
                map(config => {
                  return UpdateUpcomingConsultationsList({
                    upcomingConsultationsList: config
                  })
                })
              )
          );
          UpdateDoctorsList$ = createEffect(
            () => this.actions$
              .pipe(
                ofType(loadDoctorsList),
                mergeMap(action => {
                  var array = [];
                   return this.dashboardService.getDoctors()
                }),
                map(config => {
                  return UpdateDoctorsList({
                    doctorsList: config
                  })
                })
              )
          );
          UpdatePaymentsList$ = createEffect(
            () => this.actions$
              .pipe(
                ofType(loadPaymentsList),
                mergeMap(action => {
                  var array = [];
                   return this.dashboardService.getAllAppointmentPayments()
                }),
                map(config => {
                  return UpdatePaymentsList({
                    paymentsList: config
                  })
                })
              )
          );
          UpdateFollowupList$ = createEffect(
            () => this.actions$
              .pipe(
                ofType(loadFollowupList),
                mergeMap(action => {
                  var array = [];
                    array.push(this.dashboardService.getAllFollowups("Payments"));
                    array.push(this.dashboardService.getAllFollowups("Refunds"));
                    array.push(this.dashboardService.getAllFollowups("Appointments"));
                  return forkJoin(array);
                }),
                map(config => {
                  return UpdateFollowupList({
                    paymentsFollowupList:config[0],
                    refundsFollowupList:config[1],
                    appointmentsFollowupList:config[2],
                  })
                })
              )
          );
          LoadAppointmentsCount$ = createEffect(
            () => this.actions$
                .pipe(
                    ofType(loadAppointmentCount),
                       mergeMap(action => {
                        var array = [];
                        
                          array.push(this.dashboardService.getAppointmentsCount().pipe(
                            catchError((err) => {
                                return of()
                              // Handle specific error for this call
                            }
                          )));
                          array.push(this.dashboardService.getDaywiseAppointmentsCount().pipe(
                            catchError((err) => {
                                return of()
                              // Handle specific error for this call
                            }
                          )));
                          array.push(this.dashboardService.getConsultedPatientCountByDepartment().pipe(
                            catchError((err) => {
                                return of()
                              // Handle specific error for this call
                            }
                          )));
                        return forkJoin(array);
                      }),
                    map(data => {
                      console.log(data);
                      return UpdateAppointmentsCount({
                        getAppointmentsCount:data[0],
                        getDaywiseAppointmentsCount:data[1],
                        getConsultedPatientCountByDepartment:data[2],
                    })
                    })
                ), { dispatch: true });
}