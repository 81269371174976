import { Action, ActionReducer, ActionReducerMap, createAction, INIT, MetaReducer, UPDATE } from "@ngrx/store";
import { AppConfigState } from "./state/app-config.state";
import { AppConfigReducer } from "./reducer/app-config.reducer";
import { UserReducer } from "./reducer/admin-user.reducer";
import { DashboardState } from "./state/home.state";
import { HomeReducer } from "./reducer/home.reducer";
import { DoctorUserReducer} from "./reducer/doctor-user.reducer";
import { UserState } from "./state/admin-user.state";
import { DoctorState} from "./state/doctor-user.state";
import { DoctorDashboardState } from "./state/doctor-dashboard.state";
import { DoctorDashboardReducer } from "./reducer/doctor-dashboard.reducer";
import { TokenState } from "./state/token.state";
import { TokenReducer } from "./reducer/token.reducer";
import { AdminHomeState } from "./state/admin-home.state";
import { AdminHomeReducer } from "./reducer/admin-home.reducer";
import { CustProfileReducer } from "./reducer/cust-profile.reducer";
import { CustProfileState } from "./state/cust-profile.state";
import { PatientResponseState } from "./state/patient-response.state";
import { PatientResponseReducer } from "./reducer/patient-response.reducer";
import { AdminPrescreeningState } from "./state/admin-prescreening.state";
import { AdminPrescreeningReducer } from "./reducer/admin-prescreening.reducer";

export const logout = createAction("LOGOUT");
export interface AppState {
  token: TokenState,
  user: UserState,
  appConfig: AppConfigState,
  dashboard: DashboardState,
  doctor: DoctorState,
  doctorDashboard: DoctorDashboardState,
  adminDashboard:AdminHomeState,
  custprofile:CustProfileState,
  patientresponse:PatientResponseState,
  adminPrescreening:AdminPrescreeningState
};


export const reducers: ActionReducerMap<AppState> = {
  token: TokenReducer,
  user: UserReducer,
  appConfig: AppConfigReducer,
  dashboard: HomeReducer,
  doctor: DoctorUserReducer,
  doctorDashboard: DoctorDashboardReducer,
  adminDashboard:AdminHomeReducer,
  custprofile:CustProfileReducer,
  patientresponse:PatientResponseReducer,
  adminPrescreening:AdminPrescreeningReducer
};


export function clearState(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return function (state: AppState, action: Action): AppState {
    if (action.type === 'LOGOUT') {
      state = undefined;
    }
    if (action.type === INIT || action.type === UPDATE) {
      const storageValue = localStorage.getItem("state");
      if (storageValue) {
        try {
          return JSON.parse(storageValue);
        } catch {
          localStorage.removeItem("state");
        }
      }
    }
    const nextState = reducer(state, action);
    var stateUser:any={};
    stateUser.user=nextState?.user;
    stateUser.token=nextState?.token;
    stateUser.doctor=nextState?.doctor;
    stateUser.appConfig=nextState?.appConfig;
    localStorage.setItem("state", JSON.stringify(stateUser));
    return nextState;
    // return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = [clearState];