<div>
  <div>
    <section>
      <div>

            <nb-card class="smallcard" [nbSpinner]="loading" nbSpinnerStatus="primary" nbSpinnerSize="giant" nbSpinnerMessage="Loading"> 
              <nb-card-header>Medication View</nb-card-header>
              <div class="card-body">
                <div class="card-block" >
                  <div class="divrow d-flex">
                    <div class="col-md-4">
                      <div  class="stats-icon purple mt-2">
                        <i class="fas fa-pills fa-5x mx-2" style="font-size:60px;margin-top: 10px;"></i>
                      </div>
                    </div>
                    <div class="col-md-8">

                      <div style="font-size: medium;font-weight:700;margin-left: 2px;">Pill Name : </div>
                      <div style="font-size: small;font-weight:200;margin-left: 2px; margin-bottom: 10px;">{{medication.drugName | uppercase}}</div>

                      <div style="font-size: medium;font-weight:700;margin-left: 2px;">Pill Dosage : </div>
                      <div style="font-size: small;font-weight:200;margin-left: 2px; margin-bottom: 10px;">--</div>

                      <div style="font-size: medium;font-weight:700;margin-left: 2px;">Instructions : </div>
                      <div style="font-size: small;font-weight:200;margin-left: 2px; margin-bottom: 10px;">{{ medication.instructions==''? "--" : medication.instructions }}</div>

                     <!-- <p style="font-size: small;margin-bottom:1px;">Usage: {{getMedicineTip(medication)}} </p>  
                      
                      <h6 style="font-size: smaller;margin-top:0px;" class="font-weight-light "><span
                          style="font-size: smaller;" class="font-weight-light">
                          <div class="font-weight-light">
                            <p style="font-size: small;margin-bottom:1px;">Frequecy: {{Convert24hrto12hrs(medication.frequency)}} </p>
                            
                          </div>
    
                        </span></h6> -->
                       
                    </div>
                  </div> 
                  <div class="divrow d-flex">
                    <div class="col-md-12">
                      <div style="font-size: medium;font-weight:700;margin-left: 2px;">Dose : </div>
                      <div style="font-size: small;font-weight:200;margin-left: 2px; margin-bottom: 10px;">{{ getDosage(medication)}}</div>
  
                      <div style="font-size: medium;font-weight:700;margin-left: 2px;">Program : </div>
                      <div style="font-size: small;font-weight:200;margin-left: 2px; margin-bottom: 10px;">{{ getProgram(medication)}}</div>
                    </div>  
                  </div>
                    


                  
                  <div class="card-body" style="text-align: center;margin: 2px;">
                    <button
                    nbButton status="primary" (click)="onCancel()">
                      Close
                    </button>

                    

                </div>

                </div>
              </div>
              </nb-card>

        </div>
        </section>
        </div>

 </div>
