import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { VideoSDKMeeting } from '@videosdk.live/rtc-js-prebuilt';
import { AppState } from 'src/app/store/root.reducer';
import { getDoctor } from 'src/app/store/selectors/doctor-user.selectors';
import { environment } from 'src/environments/environment';
import { APP_CONSTANTS } from 'src/app/shared/constants';


@Component({
  selector: 'videosdk',
  templateUrl: './videosdkmeeting.component.html',
  styleUrls: ['./videosdkmeeting.component.css']
})
export class VideoSdkMeetingComponent implements OnInit {
  userName: any;
  @Input() meetingId: string = "";
  @Input() from: string = "";
  @Input() user:string="";
  // @Input() webcamEnabled:boolean=false;
  // @Input() micEnabled:boolean=false;
  constructor( @Inject(DOCUMENT) document,private route: ActivatedRoute,private store:Store<AppState>) {
  }

  async ngOnInit() {
    if(this.from=='Doctor')
   {
     
    this.store.select(getDoctor).subscribe(data => {
        this.userName=data.username;
    })
   }
   else{
     this.userName=this.user;
   }
    const apiKey = environment.VIDEOSDK_API_KEY;
    const appurl = environment.VIDEO_SDK_URL;
    const config = {
      region:"in001",
      name: this.userName,
      meetingId: this.meetingId,
      apiKey: apiKey,

      containerId: "videosdk",
      redirectOnLeave: this.from =='Doctor'? appurl+APP_CONSTANTS.APP_ROUTE.DOCTOR+'/'+APP_CONSTANTS.DOCTOR_ROUTE.REDIRECT : appurl+"statuspage/consultation",

      micEnabled: true,
      webcamEnabled: true,
      participantCanToggleSelfWebcam: true,
      participantCanToggleSelfMic: true,
      askToJoin:false,
      chatEnabled: false,
      screenShareEnabled: false,
      pollEnabled: false,
      whiteboardEnabled: false,
      raiseHandEnabled: false,

      recordingEnabled: false,
      recordingEnabledByDefault: false,
      // recordingWebhookUrl: 'https://www.videosdk.live/callback',
      // recordingAWSDirPath: `/meeting-recordings/${this.meetingId}/`, // automatically save recording in this s3 path

      brandingEnabled: false,
      brandLogoURL: 'https://picsum.photos/200',
      brandName: 'ELRO',

      participantCanLeave: true, // if false, leave button won't be visible

      livestream: {
        autoStart: true,
        outputs: [
          // {
          //   url: "rtmp://x.rtmp.youtube.com/live2",
          //   streamKey: "<STREAM KEY FROM YOUTUBE>",
          // },
        ],
      },

      permissions: {
        pin: true,
        askToJoin: false, // Ask joined participants for entry in meeting
        toggleParticipantMic: true, // Can toggle other participant's mic
        toggleParticipantWebcam: true, // Can toggle other participant's webcam
        removeParticipant: true, // Remove other participant from meeting
        endMeeting: true, // End meeting for all participant
        drawOnWhiteboard: true, // Can Draw on whiteboard
        toggleWhiteboard: true, // Can toggle whiteboard
        toggleRecording: true, // Can toggle recording
        changeLayout:true
      },

      pin: {
        allowed: true, // participant can pin any participant in meeting
        layout: 'SIDEBAR', // meeting layout - GRID | SPOTLIGHT | SIDEBAR
      },
      joinScreen: {
        visible: false, // Show the join screen ?
        title: "Daily scrum", // Meeting title
        meetingUrl: window.location.href, // Meeting joining url
      },
      layout: {
        type: "SIDEBAR", // "SPOTLIGHT" | "SIDEBAR" | "GRID"
        priority: "PIN", // "SPEAKER" | "PIN",
        // gridSize: 1,
      },
      leftScreen: {
        // visible when redirect on leave not provieded
        actionButton: {
          // optional action button
          label: 'Video SDK Live', // action button label
        },
      },
    };
    
    const meeting = new VideoSDKMeeting();
    meeting.init(config);
    
    // setInterval(function () {
    //   meeting?.join()  
    // }, 1000);

    // console.log(document.getElementById("videosdk"))
  }
}
