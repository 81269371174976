<div class="row">
  <div class="col-xl-7 pad-r-h">
    <div class="row">
      <div class="col-md-6 pad-r-h">
        <nb-card [size]="'small'">
          <nb-card-header>
            Vitals
            <div class="add" (click)="openVitalsPopup()" data-toggle="tooltip" title="Edit Vitals" style="float: right;"><i
              class="fas fa-edit fa-1x mx-2"></i></div>
          </nb-card-header>
          <nb-card-body>
            <div class="row">
              <div class="col-6  pad-r-h">
                <nb-card class="whiteback"  [size]="'tiny'">
                  <nb-card-body class="showgreen">
                    <i class="fas fa-heart-pulse fa-2x mx-2"></i><br>
                    <span class="fn-12">Pulse<br></span>
                    <strong>{{pulse}}</strong><br>
                    <span
                      class="bottom-right-italic">{{pulseCapturedTime}}</span>
                  </nb-card-body>
                </nb-card>
              </div>


              <div class="col-6 pad-l-h">
                <nb-card class="whiteback"  [size]="'tiny'">
                  <nb-card-body class="showgreen">
                    <i class="fas fa-droplet fa-2x mx-2"></i><br>
                    <span class="fn-12">Blood saturation<br></span>
                    <strong>{{spO2}}</strong><br>
                    <span
                    class="bottom-right-italic">{{spo2CapturedTime}}</span>
                  </nb-card-body>
                </nb-card>
              </div>


              <div class="col-6   pad-r-h">
                <nb-card class="whiteback"  [size]="'tiny'">
                  <nb-card-body class="showgreen">
                    <i class="fas fa-droplet fa-2x mx-2"></i><br>
                    <span class="fn-12">Blood pressure<br></span>
                    <strong>{{bloodPressure}}</strong><br>
                    <span
                    class="bottom-right-italic">{{bpCapturedTime}}</span>
                  </nb-card-body>
                </nb-card>

              </div>
              <div class="col-6   pad-l-h">
                <nb-card class="whiteback"  [size]="'tiny'">
                  <nb-card-body class="showgreen">
                    <i class="fas fa-droplet fa-2x o mx-2"></i><br>
                    <span class="fn-12">Blood glucose<br></span>
                    <strong>{{bloodGlucose}}</strong><br>
                    <span
                    class="bottom-right-italic">{{glucoCapturedTime}}</span>
                  </nb-card-body>
                </nb-card>
              </div>
            </div>
          </nb-card-body>
        </nb-card>
      </div>
      <div class="col-md-6 pad-l-h">
        <nb-card [size]="'small'" class="scroll">
          <nb-card-header>Active Medications
            <div class="add" (click)="openPastMedicationPopup()" data-toggle="tooltip" title="Past Medications" style="float: right;"><i
              class="fa fa-history fa-1x mx-2"></i></div>
          </nb-card-header>
          <div class="cardheight">
            <nb-card-body>
              <div *ngIf="activemedicationList.length == 0">
                <div class="col-12">
                  No active medications found.
                </div>
              </div>
              <div class="divrow d-flex" *ngFor="let medication of activemedicationList" (click)="openMedicineViewPopup(medication)">
                <div class="col-md-3">
                  <div *ngIf="medication.uploadType=='AUTO'" class="stats-icon purple mt-2">
                    <i class="fa-solid fa-prescription fa-2x mx-2" style="font-size:24px"></i>
                  </div>
                  <div *ngIf="medication.uploadType=='MANUAL'" class="stats-icon purple mt-2">
                    <i class="fas fa-user-plus fa-2x mx-2" style="font-size:24px"></i>
                  </div>
                </div>
                  <div class="col-md-9">
                    <div style="font-size: small;font-weight:700">{{medication.drugName | uppercase}}</div>
                    <!-- <p style="font-size: small;margin-bottom:1px;">Dosage: <span style="font-size: smaller;"
                        class="font-weight-light ">--</span> </p> -->
                        <!-- <p style="font-size: small;margin-bottom:1px;">Usage: <span style="font-size: smaller;"
                          class="font-weight-light ">{{getMedicineTip(medication)}}</span> </p>  -->
                    
                        <p style="font-size: small;margin-bottom:1px;" *ngIf="medication.uploadType=='AUTO'">Days Remaining: <span style="font-size: smaller;"
                          class="font-weight-light ">{{medication.daysRemaining}} {{medication.daysRemaining == "1" ? "day"
                          : "days"}}</span> </p>   
                    
                    
                    
                    <h6 style="font-size: smaller;margin-top:0px;" class="font-weight-light "><span
                        style="font-size: smaller;" class="font-weight-light">
                        <div class="font-weight-light">
                          <p style="font-size: small;margin-bottom:1px;">Frequency: <span style="font-size: smaller;"
                            class="font-weight-light ">{{ Convert24hrto12hrs(medication.frequency)}}</span> </p>
                          
                        </div>
  
                      </span></h6>
                  </div>
              </div>

              <!-- <div class="divrow d-flex" *ngFor="let medication of activemedicationList">
                <div class="col-md-3">
                  <div class="stats-icon purple mt-2">
                    <i class="fas fa-user-plus fa-2x mx-2" style="font-size:24px"></i>
                  </div>
                </div>
                <div class="col-md-9">
                  <div style="font-size: small;font-weight:700">{{medication.drugName | uppercase}}</div>
                  <p style="font-size: small;margin-bottom:1px;">Dosage: <span style="font-size: smaller;"
                      class="font-weight-light ">{{medication.dose}}</span> </p>
                  <p style="font-size: small;margin-top:0px;margin-bottom:1px;">Frequecy: <span
                    style="font-size: smaller;" class="font-weight-light">{{medication.frequency}}</span></p>
                    <br>
                </div>


              </div> -->

            </nb-card-body>
          </div>
        </nb-card>
      </div>
      <div class="col-md-12">
        <nb-card [size]="'small'" [nbSpinner]="patientAppointmentsLoading" nbSpinnerStatus="primary"
          nbSpinnerSize="giant" nbSpinnerMessage="Loading">
          <nb-card-header>
            Appointments
            <div class="add" *ngIf="show" style="float: right;" (click)="bookAppointment()" data-toggle="tooltip"
              title="Add Appointments"><i class="fas fa-plus fa-1x mx-2"></i></div>

          </nb-card-header>

          <nb-card-body>
            <ng2-smart-table *ngIf="show" [settings]="settings" [source]="source">
            </ng2-smart-table>
            <ng2-smart-table *ngIf="!show" [settings]="settings4" [source]="source4">
            </ng2-smart-table>
          </nb-card-body>
        </nb-card>
      </div>
    </div>
  </div>
  <div class="col-xl-5 pad-l-h" >
    <ngx-user-basic-details (openPopup)="openMedicalPopup()" [username]='custromerData != null ? custromerData.memberDTO.Name : ""' [type]="'info'"
      [showName]="'true'" [size]="'medium'" [elements]="elements" [headers]="headers" [isPatient]="true">
      <nb-user [size]="'giant'" [name]='custromerData != null ? custromerData.memberDTO.Name : ""' [onlyPicture]="true">
      </nb-user>
    </ngx-user-basic-details>
  </div>
</div>


<div class="row " *ngIf="!show">
  <div class="col-xl-12">
    <nb-card [nbSpinner]="prescriptionsListLoading" nbSpinnerStatus="primary" nbSpinnerSize="giant"
      nbSpinnerMessage="Loading">
      <nb-card-header class="zpC_head">Prescriptions
      </nb-card-header>
      <nb-card-body>
        <ng2-smart-table [settings]="settings5" [source]="source5">
        </ng2-smart-table>
      </nb-card-body>
    </nb-card>
  </div>
</div>
<div class="row " style="margin-bottom:10px" *ngIf="show">
  <div class="col-xl-12 col-lg-12" style="margin-left: 0px">
    <nb-card [nbSpinner]="loading" nbSpinnerStatus="primary" nbSpinnerSize="giant" nbSpinnerMessage="Loading">
      <nb-card-header class="zpC_head">EHR
        <div class="add" (click)="uploadReports()" data-toggle="tooltip" title="Add EHR" style="float: right;"><i
            class="fas fa-plus fa-1x mx-2"></i></div>
        <div class="add" (click)="sendSms()" data-toggle="tooltip" title="Send Message"
          style="float: right;margin-left: 10px;"><i class="fas fa-paper-plane fa-1x mx-2"></i></div>
      </nb-card-header>
      <nb-card-body>
        <ng2-smart-table [settings]="settings1" [source]="source1" [nbSpinner]="prescriptionsListLoading"
          nbSpinnerStatus="primary" nbSpinnerSize="giant" nbSpinnerMessage="Loading">
        </ng2-smart-table>
        <ng2-smart-table [settings]="settings2" [source]="source2">
        </ng2-smart-table>
      </nb-card-body>
    </nb-card>
  </div>
</div>
<div class="row " style="margin-bottom:10px" *ngIf="!show">
  <div class="col-xl-12 col-lg-12" style="margin-left: 0px">
    <nb-card [nbSpinner]="VitalsLoading" nbSpinnerStatus="primary" nbSpinnerSize="giant" nbSpinnerMessage="Loading">
      <nb-card-header *ngIf="!show" class="zpC_head">Medical Records
        <div class="add" (click)="uploadReports()" data-toggle="tooltip" title="Add EHR" style="float: right;"><i
            class="fas fa-plus fa-1x mx-2"></i></div>
      </nb-card-header>
      <nb-card-body>
        <ng2-smart-table [settings]="settings6" [source]="source6"></ng2-smart-table>
      </nb-card-body>
    </nb-card>
  </div>
</div>
<div class="row " *ngIf="show">
  <div class="col-xl-12">
    <nb-card>
      <nb-card-header class="zpC_head">Appointments History
      </nb-card-header>
      <nb-card-body>
        <ng2-smart-table [settings]="settings3" [source]="source3">
        </ng2-smart-table>
      </nb-card-body>
    </nb-card>
  </div>
</div>