import { Input} from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PatientService } from '../../services/patient.service';
import { NbDialogRef } from '@nebular/theme';
import { UnsubscribeOnDestroyAdapter } from '../../services/UnsubscribeOnDestroyadapter';

@Component({
  selector: 'app-dialog',
  templateUrl: './ecgdialog.component.html',
  styleUrls: ['./ecgdialog.component.scss'],
})

export class EcgDialogComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  @Input() data:any;
  dialogtitle: string = "ECG Report";
  ecgimg;
  patientName : string;
  age : string;
  gender : string;
  ecgResult : string;
  personId : string;
  ecgData: any;
  loading : boolean ;

  constructor(private dialogRef:NbDialogRef<EcgDialogComponent>,private apiService:PatientService,private sanitiser: DomSanitizer
  )

   {
      super()
   }

  ngOnInit() {
    this.ecgData =this.data;
    this.loadECGdetails();
  }

  loadECGdetails(){
    this.subs.sink=this.apiService.getReportDetails(this.ecgData.RecordNo).subscribe((res : any)=>{
      console.log("res--",res);
      this.loading = true;
      this.ecgimg = res.ecgImg != null ? "data:image/png;base64,"+ res.ecgImg  : "";
    },error => {
      this.loading = true;
      });
      //this.ecgimg = this.ecgData.EcgImg != null ? "data:image/png;base64,"+this.ecgData.EcgImg : "";
      this.patientName = this.ecgData.name;
      this.age = this.ecgData.age;
      this.ecgResult = this.ecgData.Result;
      this.gender = this.ecgData.gender!= null? this.ecgData.gender:"";
  }

  onCancel(){
    this.dialogRef.close(false);
  }


  onCancelClick(data:any){
    console.log("cancel"+data);
  }

  sanitize(url:string){
    return this.sanitiser.bypassSecurityTrustUrl(url);
  }
  
}
