import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP_CONSTANTS } from 'src/app/shared/constants';
@Injectable({
  providedIn: 'root'
})
export class ComponentsService {

  constructor(private http: HttpClient) { }


  getAppointmentDetails(appointmentId) {
    let url = APP_CONSTANTS.REST_API_URL.GET_APPOINTMENT_DETAILS + appointmentId;
    return this.http.get(url);
  }

  getPrescriptionPdfdata(meetingId: any, personId: any) {
    return this.http.get(APP_CONSTANTS.REST_API_URL.DOWNLOAD_PDF + meetingId + "/" + personId)
  }

  getPrescreeningQuestions(complaint:any)
  {
    return this.http.get(APP_CONSTANTS.REST_API_URL.GET_QUESTIONS_BY_COMPLAINT+complaint);
  }

  savePrescreeningAnswers(data:any)
  {

    let url=APP_CONSTANTS.REST_API_URL.SAVE_PRESCREENING_ANSWERS;
    return this.http.post(url, data);
  }

  checkPrescreeingstatus(appointmentId)
  {
    return this.http.get(APP_CONSTANTS.REST_API_URL.GET_PRESCREENING_DETAILS_BY_APPOINTMENTID+appointmentId);
  }

  uploadReports(reportsDTO: any) {
    let url=APP_CONSTANTS.REST_API_URL.SAVE_PATIENT_REPORT_UPLOAD;
    return this.http.post(url, reportsDTO);
}
}



