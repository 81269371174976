import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { ComponentsService } from 'src/app/components/services/componets.service';
import { APP_CONSTANTS } from 'src/app/shared/constants';
import { UnsubscribeOnDestroyAdapter } from 'src/app/shared/services/UnsubscribeOnDestroyadapter';
import { updateQuestions } from 'src/app/store/actions/patient-response.actions';
import { AppState } from 'src/app/store/root.reducer';
import { getPatientResponse } from 'src/app/store/selectors/patient-response.selector';
import { DoctorConsultationsService } from '../../service/doctor-consultation.service';

@Component({
  selector: 'app-doctor-questions-popup',
  templateUrl: './doctor-questions-popup.component.html',
  styleUrls: ['./doctor-questions-popup.component.scss']
})
export class DoctorQuestionsPopupComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  @Input() appointmentId: any;
  questions = [];
  dispquestions: any;
  selected: boolean = false;
  disablebutton: boolean = true;
  questionLength: number = 1;
  PreScreeningAnswersDTO: any;
  loading: boolean;
  response: any;
  allQuestionsDone: boolean;
  questionNo: number;
  resData: any;
  sum: number;
  total: number;
  constructor(private apiService1: DoctorConsultationsService, private router: Router, private changeDetection: ChangeDetectorRef, private toastrService: NbToastrService, private dialogRef: NbDialogRef<DoctorQuestionsPopupComponent>, private store: Store<AppState>, private apiService: ComponentsService) {
    super()
  }

  ngOnInit() {
    this.getData();
  }

  //getting questions based on complaint
  getData() {
    this.subs.sink = this.apiService1.getQuestionsDetailsByAppointmentId(this.appointmentId).subscribe((resData: any) => {
      this.resData = resData;
      this.response = JSON.parse(resData.details);
      this.questions = [];
      this.questionNo = 0;
      this.showNextQuestion(this.response,true);
      // this.dispquestions = this.questions.slice(0, this.questionLength);
    });
  }
  // getAllChildQuestions(response,isParent) {
  //   response.forEach(parent => {
  //     if(isParent && this.questionNo==0){
  //       parent.showQuestion = true;
  //     }
  //     this.questionNo++;
  //     this.questions.push(parent);
  //     parent.preScreeningDetailsList.forEach(details => {
  //       if (details.childQuestions.length > 0) {
  //         this.getAllChildQuestions(details.childQuestions,false);
  //       }
  //     });
  //   });
  // }
  showNextQuestion(response, showQuestion) {
    response.forEach(parent => {
      debugger
      if (parent.selectedOption) {
        parent.showQuestion = true;
      }
      console.log(parent);
      this.questions.push(parent);
      if (showQuestion) {
        var parentQuestion = this.questions.filter((e) => {
          if (e.id == parent.id) {
            return true;
          } else {
            return false;
          }
        });
        if (!parent.selectedOption) {
          this.questions = this.questions.map((e) => {
            if (e.id == parent.id && this.questionNo == 0) {
              e.showQuestion = true;
            }
            return e
          });
          this.questionNo++;
          return false;
        }
        parent.preScreeningDetailsList.forEach(details => {
          if (details.options == parentQuestion[0].selectedOption) {
              this.showNextQuestion(details.childQuestions, true);
          } else {
              this.showNextQuestion(details.childQuestions, false);
          }
        });
      }else{
        parent.preScreeningDetailsList.forEach(details => {
           this.showNextQuestion(details.childQuestions, false);
        });
      }
    });
  }
  resetQuestion(response, resetElements) {
    response.forEach(parent => {
      if (resetElements) {
        this.questions = this.questions.map((e) => {
          if (e.id == parent.id) {
            e.selectedOption = null;
            e.seviority = null;
            e.showQuestion = false;
          }
          return e
        });
      }
      if (parent.selectedOption) {
        parent.showQuestion = true;
      }
      var parentQuestion = this.questions.filter((e) => {
        if (e.id == parent.id) {
          return true;
        } else {
          return false;
        }
      });
      if (!parent.selectedOption && this.questionNo == 0 && !resetElements) {
        this.questions = this.questions.map((e) => {
          if (e.id == parent.id) {
            e.showQuestion = true;
          }
          return e
        });
        this.questionNo++;
      }
      parent.preScreeningDetailsList.forEach(details => {
        if (details.options == parentQuestion[0]?.selectedOption) {
          if (details.childQuestions.length > 0) {
            this.resetQuestion(details.childQuestions, false);
          }
        } else {
          this.questions = this.questions.map((e) => {
            if (details.childQuestions.map((e1) => {
              return e1.id;
            }).includes(e.id)) {
              e.showQuestion = false;
            }
            return e
          });
          this.resetQuestion(details.childQuestions, true);
        }
      });
    });
    console.log(this.questions);
  }
  updateData(option, ques) {
    console.log(option, ques);
    var i = 0;
    this.questions = this.questions.map((ele) => {
      if (ele.id == ques.id) {
        ele.selectedOption = option.options;
        ele.seviority = option.seviority;
      }
      return ele;
    })
    this.questionNo = 0;
    this.resetQuestion(this.response, false);
    console.log(this.questionNo);
    // if(this.questionNo==0){
    //   this.submit();
    // }
  }
  submit() {
    this.sum = 0;
    this.total = 0;
    this.loading = true;
    var respon = this.saveResponse(this.response);
    this.resData.seviority = this.total == 0 ? 0 : this.sum / this.total;
    delete this.resData.details;
    this.resData.preScreeningDTOList = respon;
    this.subs.sink = this.apiService.savePrescreeningAnswers(this.resData).subscribe((res: any) => {
      this.loading = false;
      this.toastrService.show('', 'Prescreening completed successfully.', { duration: APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON });
      this.dialogRef.close(false);
    });
  }
  saveResponse(response) {
    return response.map((parent1) => {
      if (parent1.showQuestion) {
        parent1 = this.questions.filter((e) => {
          return parent1.id == e.id;
        })[0];
        this.sum += parent1.seviority;
        this.total++;
      }
      parent1.preScreeningDetailsList.forEach(details => {
        if (details.childQuestions.length > 0) {
          parent1.preScreeningDetailsList.childQuestions = this.saveResponse(details.childQuestions);
        }
      });
      parent1.parent = parent1.isParent;
      delete parent1.isParent;
      delete parent1.createdDate;
      delete parent1.showQuestion;
      delete parent1.lastUpdatedDate;
      return parent1;
    });
  }
  cancel(){
    this.dialogRef.close();
  }
}
