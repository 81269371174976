<!-- <div id="app">
    <nav class="navbar navbar-expand navbar-dark bg-dark" *ngIf="isLoggedIn">
        <a href="#" class="navbar-brand"></a>

        <ul class="navbar-nav mr-auto" routerLinkActive="active">
            <li class="nav-item">
                <a href="/home" class="nav-link" style="color: white;font-size: 22px;" (click)="navigate()"><i style="margin-right: 5px;font-size: 22px;" class="fa fa-home"></i> Home </a>
            </li>
        </ul>

        <ul class="navbar-nav ml-auto" *ngIf="isLoggedIn">
            <li class="nav-item">
                <a class="nav-link" style="color: white;font-size: 22px;" routerLink="profile"><i style="margin-right: 5px;font-size: 22px;" class="fa fa-user-alt"></i> {{username}} </a>
            </li>
            <li class="nav-item">
                <a href class="nav-link" style="color: white;font-size: 22px;" (click)="logout()">Logout <i style="margin-right: 5px;font-size: 22px;" class="fa fa-sign-out-alt"></i></a>
            </li>
        </ul>
    </nav>

    <div class="container-fluid">
        <router-outlet></router-outlet>
    </div>
</div> -->
  <!-- <ngx-one-column-layout> -->
    <!-- <nb-menu [items]=""></nb-menu> -->
    <router-outlet></router-outlet>
<!-- </ngx-one-column-layout> -->
