export interface DoctorDashboardState {
  patientList: any,
  upcomingConsultationsList:any,
  getDoctorAppointmentsCount:any,
  getDoctorDaywiseAppointmentsCount:any,
  getDoctorRecentlyConsultedPatients:any,
  getDoctorPrescriptions:any,
  DoctorDashboardLoading: boolean;
  DoctorUpcomingConsultationsListLoading:boolean


}
export const DoctorDashboardInitialState: DoctorDashboardState = {
  patientList: null,
  upcomingConsultationsList:null,
  getDoctorAppointmentsCount:null,
  getDoctorDaywiseAppointmentsCount:null,
  getDoctorRecentlyConsultedPatients:null,
  getDoctorPrescriptions:null,
  DoctorDashboardLoading: false,
  DoctorUpcomingConsultationsListLoading:false,
};