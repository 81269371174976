<!DOCTYPE html>
<html>

<head>
  <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500&display=swap" rel="stylesheet">
  <style>
    body {
      font-family: 'Quicksand', sans-serif;
    }

    #prescption {
      background: #fff;
      ;
      border: 1px solid #000000;
      text-align: left;
      padding: 8px;
      font-size: 14px;
      font-weight: 400;

    }

    p {
      font-size: 14px;
      font-weight: 400;
      padding: 3px 0;
      margin: 0;
    }

    .no-border tr td,
    .no-border tr th {
      border: none;
    }

    .header {
      border-bottom: solid 2px gray;
      margin: 10px;
    }

    .line {
      border-top: solid 1px gray;
    }

    .padd {
      padding: 5px 80px 5px 80px;
    }

    .padd1 {
      padding: 30px 80px;
    }

    .text-center {
      text-align: center;
    }

    #prescption h4 {
      font-size: 24px;
      padding: 2px 0;
      text-transform: uppercase;
    }

    #prescption h5 {
      font-size: 20px;
      margin: 10px 0;
    }

    #prescption {
      width: 95%;
    }

    .text-right {
      text-align: right;
    }

    .drugs tr td,
    .drugs tr th {
      padding-bottom: 10px;
    }
  </style>
</head>

<body >

  <nb-card class="gaintcard">
    <nb-card-body>
      <div class="content">
        <div class="col-md-12">

          <table id="prescription" style="align-items:center;">
            <tr>
              <th class="padd1">
                <table class="no-border" style="margin: 5px 5px 5px 0px">
                  <tr class="header">
                    <td style="width: 80%;">
                      <h4>Dr. {{doctorname}}{{doctorspecalisation != null ? "," : ""}} {{doctorspecalisation}}</h4>
                      <p style="text-transform: uppercase;">Qualification: {{doctorqualification}}</p>
                      <p style="text-transform: uppercase;">Registration Number: {{doctorregno}}</p>
                      <p style="text-transform: uppercase;">Address: {{doctoraddress}}</p>
                      <p style="text-transform: uppercase;">Email: {{doctoremail}}</p>
                      <p style="text-transform: uppercase;">Phone Number: {{doctormobile}}</p>
                    </td>

                    <td style="width: 20%;vertical-align: top;">
                      <img src="{{imagePath}}" height="100" />
                    </td>

                  </tr>
                </table>
              </th>
            </tr>

            <tr>
              <th class="padd">
                <table class="no-border" width="100%" style="margin:2px;">
                  <tr>
                    <td style="width: 20%;padding: 2px;">Date Of Consultation</td>
                    <td style="width: 40%;padding: 2px;">{{time1 | date: 'dd-MM-yyyy HH:mm:ss'}}</td>
                    <td style="width: 40%;"></td>
                  </tr>
                  <tr>
                    <td style="width: 20%;padding: 2px;">Name Of Patient</td>
                    <td style="width: 50%;padding: 2px;">{{patientName}}, {{age}}, {{gender}}</td>
                  </tr>

                  <br />
                  <!-- <tr>
                     <td rowspan="2" style="width: 20%;padding: 2px;vertical-align: text-top;">Address</td>
                     <td rowspan="2" style="width: 40%;padding: 2px;vertical-align: text-top;">{{address}}</td>
         
                     <td rowspan="2" style="width: 20%;"></td>
                     <td rowspan="2" style="width: 40%;"></td>
                     <td style="width: 40%;">
                       <table width="100%" style="margin-left: 10px;">
                         <tr >
                           <td style="width: 50%;padding: 2px;">Blood Pressure<br/> </td>
                           <td style="width: 50%;padding: 2px;">{{bloodPressure}} mmHg</td>
                         </tr>
                       </table>
                     </td></tr> -->

                  <tr>
                    <td style="width: 20%;padding: 2px;">Height</td>
                    <td style="width: 40%;padding: 2px;">{{height}} cm.</td>

                    <td style="width: 40%;">
                      <table width="100%" style="margin-left: 10px;">
                        <tr>
                          <td style="width: 50%;padding: 2px;">Pulse </td>
                          <td style="width: 50%;padding: 2px;"> {{pulse}} bpm</td>
                        </tr>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td style="width: 20%;padding: 2px;">Weight</td>
                    <td style="width: 40%;padding: 2px;">{{weight}} kg</td>

                    <td style="width: 40%;">
                      <table width="100%" style="margin-left: 10px;">
                        <tr>
                          <td style="width: 50%;padding: 2px;">SpO2 </td>
                          <td style="width: 50%;padding: 2px;"> {{spO2}}%</td>
                        </tr>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td style="width: 20%;padding: 2px;">Blood Glucose</td>
                    <td style="width: 40%;padding: 2px;">{{bloodGlucose}} mg/dL</td>
                    <td style="width: 40%;">
                      <table width="100%" style="margin-left: 10px;">
                        <tr>
                          <td style="width: 50%;padding: 2px;">Blood Pressure<br /> </td>
                          <td style="width: 50%;padding: 2px;">{{bloodPressure}} mmHg</td>
                        </tr>
                      </table>
                    </td>
                  </tr>

                  <tr>
                    <td rowspan="2" style="width: 20%;"></td>
                    <td rowspan="2" style="width: 40%;"></td>
                    <td style="width: 40%;">
                      <table width="100%" style="margin-left: 10px;">
                        <tr>
                          <td style="width: 50%;padding: 2px;">Body Temperature<br /></td>
                          <td style="width: 50%;padding: 2px;">{{temperature}} <span>&#176;</span>F</td>
                        </tr>
                      </table>
                    </td>
                  </tr>

                </table>

              </th>
            </tr>

            <tr>
              <td class="padd">
                <table class="line">
                  <tr style="margin-bottom: 10px;">
                    <td>

                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td class="padd">
                <table class="no-border" width="100%">
                  <tr>
                    <td style="width: 40%;vertical-align: text-top;">
                      <table style="margin-top: 10px;">
                        <tr>
                          <td style="padding-bottom: 10px;">
                            <p> <strong>CHIEF COMPLAINTS </strong></p>
                            {{clinicalnotes != null ? clinicalnotes.complaints: ""}}{{clinicalnotes != null ? " -
                            "+clinicalnotes.observation: ""}}
                          </td>
                        </tr>
                        <tr>
                          <td style="padding-bottom: 10px;">
                            <p> <strong>RELAVENT POINTS FROM HISTORY</strong></p>
                            {{clinicalnotes != null ? clinicalnotes.relevantPoints: ""}}
                          </td>
                        </tr>
                        <tr>
                          <td style="padding-bottom: 10px;">
                            <p> <strong>EXAMINATIONS/LAB FINDINGS </strong></p>
                            {{clinicalnotes != null ? clinicalnotes.examinations: ""}}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p> <strong>SUGGESTED INVESTIGATIONS </strong></p>
                            {{clinicalnotes != null ? clinicalnotes.investigation: ""}}
                          </td>
                        </tr>
                      </table>
                    </td>

                    <td style="width: 60%;vertical-align: text-top;border-left: solid 1px gray;">
                      <table style="margin: 10px 0 10px 0;">
                        <tr>
                          <td style="padding-left: 10px;padding-bottom: 10px;">
                            <p> <strong>DIAGNOSIS/PROVISIONAL DIAGNOSIS </strong></p>
                            {{clinicalnotes != null ? clinicalnotes.diagnosis: ""}}
                          </td>
                        </tr>
                        <tr>
                          <td style="padding: 0 0 10px 10px;"><img width="25px" height="25px"
                              src="../../../assets/images/rx.jpg" /></td>
                        </tr>
                        <tr>
                          <td style="padding-left: 10px;">
                            <table class="drugs" width="100%">

                              <tbody class="sep" *ngFor="let prescription of prescriptionlist;let i = index">
                                <tr>
                                  <td *ngIf="prescription.drugName !=''">{{i+1}}. {{prescription.drugName}},{{prescription.duration}}</td>
                                  <!-- <td>{{medicineFrequency(prescription.frequency)}}</td> -->
                                </tr>
                                <tr *ngIf="prescription.altDrugs != null && prescription.altDrugs !=''">
                                  <td style="padding-left:12px;">Alternative: {{prescription.altDrugs}}</td>
                                </tr>
                                <tr>
                                  <td style="padding-left:12px;">{{prescription.name}}</td>
                                </tr>
                              </tbody>
                            </table>

                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>

                </table>

              </td>
            </tr>

            <tr>
              <td class="padd">
                <table class="line">
                  <tr style="margin-bottom: 10px;">
                    <td>
                      <p> <strong>SPECIAL INSTRUCTIONS </strong></p>
                      {{clinicalnotes != null ? clinicalnotes.notes: ""}}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td class="padd">
                <table class="line">
                  <tr style="margin-bottom: 10px;">
                    <td>
                      <p> <strong>LIFE STYLE MODIFICATIONS </strong></p>

                      <ul class="list">
                        <li *ngFor="let tip of lifeStyleModifications; let i = index">
                          {{tip.lifeStyleChanges}}
                        </li>
                      </ul>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>


            <tr>
              <td class="padd">
                <table align="right" class="no-border">
                  <tr [hidden]="doctorsignature ==''">
                    <td class="pull-right">
                      <img src="{{doctorsignature}}" class="sign" height="50px" />
                    </td>
                  </tr>
                  <tr>
                    <td class="text-right">
                      <strong>Doctor Signature</strong> <br />
                      {{time1 | date: 'dd-MM-yyyy HH:mm:ss'}}<br />
                    </td>
                  </tr>
                </table>

              </td>
            </tr>

            <tr>
              <td class="padd">
                <table class="line">
                  <tr style="margin-bottom: 10px;">
                    <td>
                      <p style="font-size: 12px;">Note: This prescription is generated on a teleconsultation. <span
                          style="float: right;font-size: 16px;font-weight: bold;">DEMO</span></p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>

          </table>

          
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div style="text-align: center">
        <button nbButton status="primary" (click)="downloadAsPDF()">
          Download As PDF
        </button>
      </div>
    </nb-card-footer>
  </nb-card>

</body>

</html>