<nb-card [nbSpinner]="loading" nbSpinnerStatus="primary" nbSpinnerSize="giant" nbSpinnerMessage="Loading">
  <nb-card-header>
    {{data}}
  </nb-card-header>
  <nb-card-body>
    <div class="col-md-12 text-center" style="margin-top:10px;">
      <button  nbButton status="primary" (click)="onNoClick()">Reject</button>
      <button  nbButton status="primary" (click)="onYesClick()" style="margin-left: 20px;">Accept</button>
    </div>
  </nb-card-body>
    
</nb-card>
<div >
   
   