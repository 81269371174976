import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { tap, filter, first, finalize } from 'rxjs/operators';
import { AppState } from 'src/app/store/root.reducer';
import { areUserRolesLoaded } from 'src/app/store/selectors/app-config.selectors';
import { loadUserRoles } from 'src/app/store/actions/app-config.actions';

@Injectable({
    providedIn: 'root'
})
export class LoginResolver implements Resolve<any> {
    constructor(private store: Store<AppState>) {

    }
    loading = false;
    resolve(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<any> {
            return this.store
            .pipe(
               select(areUserRolesLoaded),
                tap(userRolesLoaded => {
                    // if (!this.loading && !userRolesLoaded) {
                    if (!this.loading) {
                        this.loading = true;
                        this.store.dispatch(loadUserRoles());
                    }
                }),
                filter(userRolesLoaded => userRolesLoaded),
                first(),
                finalize(() => this.loading = false)
            );
    }
}