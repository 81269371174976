import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngx-no-header-layout',
  styleUrls: ['./no-header.layout.scss'],
  templateUrl:'./no-header.layout.html',
})
export class NoHeaderLayoutComponent { 
  @Input() title:string;
}
