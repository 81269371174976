<nb-card>
    <nb-card-header>Schedule</nb-card-header>
<div class=" row ">
    <div class="col-12 col-md-3 col-sm-3  col-xl-2 valign-center text-right " style="display:none">
        <select class="form-control m-t-b" #viewDayOptionSelect [ngModel]="viewDays " (change)="viewDaysOptionChanged(viewDayOptionSelect.value) ">
            <option [value]="1">One day</option>
            <option [value]="3 ">Three days</option>
            <option [value]="7 ">One week</option>
        </select>
    </div>
    <div class="col-12" style="text-align-last: center;">
        <div class="btn-group m-t-b " role="group ">
            <button type="button " nbButton status="primary"  mwlCalendarPreviousView [view]="view " [(viewDate)]="viewDate " [daysInWeek]="viewDays " [excludeDays]="excludeDays " [disabled]="prevBtnDisabled
        " (viewDateChange)="dateOrViewChanged() ">
                <span class="valign-center ">
                    <i title="Precedente" class="fa fa-arrow-circle-left"></i>
                </span>
            </button>
            <button type="button " class="btn btn-light " mwlCalendarToday [(viewDate)]="viewDate " (viewDateChange)="dateOrViewChanged() ">
                <span class="valign-center ">
                        <i title="Successivo" class="fa fa-calendar-week"></i>
                </span>
            </button>
            <button type="button " nbButton status="primary"  mwlCalendarNextView [view]="view " [(viewDate)]="viewDate " [daysInWeek]="viewDays " [excludeDays]="excludeDays " [disabled]="nextBtnDisabled
        " (viewDateChange)="dateOrViewChanged() ">
                <span class="valign-center ">
                        <i title="Successivo" class="fa fa-arrow-circle-right"></i>
                </span>
            </button>
        </div>
    </div>
</div>
<ng-container [ngSwitch]="view ">
    <calendar-scheduler-view *ngSwitchCase="CalendarView.Week " [viewDays]="viewDays " [viewDate]="viewDate " [events]="events " [locale]="locale " [responsive]="true " [weekStartsOn]="weekStartsOn
        " [excludeDays]="excludeDays " [startsWithToday]="startsWithToday " [hourSegments]="hourSegments " [dayStartHour]="dayStartHour " [dayEndHour]="dayEndHour " [dayModifier]="dayModifier " [hourModifier]="hourModifier "
        [segmentModifier]="segmentModifier
        " [eventModifier]="eventModifier" [showEventActions]="true" [showSegmentHour]="true" [zoomEventOnHover]="true " (viewDaysChanged)="viewDaysChanged($event) " (dayHeaderClicked)="dayHeaderClicked($event.day) "
        (hourClicked)="hourClicked($event.hour) " (segmentClicked)="segmentClicked( 'Clicked', $event.segment) " (eventClicked)="eventClicked( 'Clicked', $event.event) " (eventTimesChanged)="eventTimesChanged($event) "
        [refresh]="refresh ">
    </calendar-scheduler-view>

</ng-container>
</nb-card>

