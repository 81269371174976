export interface CustProfileState {
  prescriptionsList: any,
  prescriptionsListLoading: boolean;
  vitals:any,
  VitalsLoading: boolean;
  PatientAppointmentListLoading:boolean
  patientAppointmentList:any,
  activeMedicationList:any,
  userMedicationList:any
}
export const CustProfileInitialState: CustProfileState = {
  prescriptionsList: null,
  prescriptionsListLoading: false,
  vitals:null,
  VitalsLoading:false,
  PatientAppointmentListLoading:false,
  patientAppointmentList:null,
  activeMedicationList:[],
  userMedicationList:[]
  // upcomingConsultationsList:null,
  // doctorsList:null,
  // paymentsList:null
  // ,paymentsFollowupList:null,
  // refundsFollowupList:null,
  // appointmentsFollowupList:null,
  // getAppointmentsCount:null,
  // getDaywiseAppointmentsCount:null,
  // getConsultedPatientCountByDepartment:null,
  // getRecentlyConsultedPatients:null,
  // getRecentAvailableDoctors:null
};