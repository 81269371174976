import { Directive, ElementRef, HostListener, Inject, Input, Optional } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validator, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import {Location} from "@angular/common";
import { PatientService } from '../../services/patient.service';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { APP_CONSTANTS } from '../../constants';
import { UnsubscribeOnDestroyAdapter } from '../../services/UnsubscribeOnDestroyadapter';

@Component({
  selector: 'vitalsdataentry',
  templateUrl: './vitalsdataentry.component.html',
  styleUrls: ['./vitalsdataentry.component.scss'],
  providers: [PatientService]
})

export class VitalsDataentryComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  personId : string;
  loading:boolean=false;
  vitalsForm: FormGroup;
  @Input() max: any;
  @Input() show:any;
  tomorrow = new Date();
  fileToUpload: File = null;
  report: string = "";
  submitted: boolean = false;
  memberData: any;
  @Input () data:any;
  constructor(private toastrService: NbToastrService,private dialogRef:NbDialogRef<VitalsDataentryComponent>,private formBuilder: FormBuilder,
    private apiService:PatientService,private location : Location)
   {
     super()
   }

  ngOnInit() {
    console.log("show ->",this.show);
    this.memberData = this.data;
    this.personId = this.memberData.personId;
    this.vitalsForm = this.formBuilder.group({
      systolic: [this.memberData.highPressure,[Validators.max(300), Validators.min(0),Validators.pattern(APP_CONSTANTS.REGEX.FLOAT_NUM_UPTO_FIVE_DIGITS)]],
      daistolic: [this.memberData.lowPressure,[Validators.max(300), Validators.min(0),Validators.pattern(APP_CONSTANTS.REGEX.FLOAT_NUM_UPTO_FIVE_DIGITS)]],
      pulse: [this.memberData.bpm,[Validators.max(300), Validators.min(0),Validators.pattern(APP_CONSTANTS.REGEX.FLOAT_NUM_UPTO_FIVE_DIGITS)]],
      spo2: [this.memberData.oxygen,[Validators.max(100), Validators.min(0),Validators.pattern(APP_CONSTANTS.REGEX.FLOAT_NUM_UPTO_FIVE_DIGITS)]],
      temp: [this.memberData.temperature,[Validators.max(125), Validators.min(85),Validators.pattern(APP_CONSTANTS.REGEX.FLOAT_NUM_UPTO_FIVE_DIGITS)]],
      height: [this.memberData.height,[Validators.max(300), Validators.min(50),Validators.pattern(APP_CONSTANTS.REGEX.FLOAT_NUM_UPTO_FIVE_DIGITS)]],
      weight: [this.memberData.weight,[Validators.max(300), Validators.min(0),Validators.pattern(APP_CONSTANTS.REGEX.FLOAT_NUM_UPTO_FIVE_DIGITS)]],
      glucose: [this.memberData.bloodSugar,[Validators.max(999), Validators.min(0),Validators.pattern(APP_CONSTANTS.REGEX.FLOAT_NUM_UPTO_FIVE_DIGITS)]],
    });
  }

  get pdf() {
    return this.vitalsForm.controls;
  }

  onCancel(){
    this.dialogRef.close(false);
  }

  checkValid() {
    if(this.vitalsForm.get('systolic').value!= null || this.vitalsForm.get('daistolic').value!= null || this.vitalsForm.get('pulse').value!= null ||
    this.vitalsForm.get('spo2').value!= null || this.vitalsForm.get('temp').value!= null ||this.vitalsForm.get('height').value!= null ||
    this.vitalsForm.get('weight').value!= null ||this.vitalsForm.get('glucose').value!= null) {
      return false;
    } else {
      return true;
    }
}

  onSubmit(){
    this.loading=true;
    this.submitted = true;
    console.log(this.vitalsForm);
    if (this.vitalsForm.invalid) {
      this.loading=false;
      return;
    }
    const datepipe: DatePipe = new DatePipe('en-US');

    var vitalsDTO = {"RecordNo": datepipe.transform(this.tomorrow,"yyyyMMddHHmmsssss"), "MeasureTime": datepipe.transform(this.tomorrow,"yyyy-MM-dd HH:mm:ss"), "DeviceType": "Manual",
    "personId":this.personId};
    if((this.vitalsForm.value.systolic!= this.memberData.highPressure) || (this.vitalsForm.value.daistolic!= this.memberData.lowPressure)){
      if((this.vitalsForm.value.systolic==null && this.vitalsForm.value.daistolic!=null) || (this.vitalsForm.value.systolic!=null && this.vitalsForm.value.daistolic==null)){
        this.toastrService.show('','Provide both systolic and daistolic values',{duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
        this.loading=false;
        return
      }
      vitalsDTO['BloodPressure'] = { "HighPressure":this.vitalsForm.value.systolic , "LowPressure": this.vitalsForm.value.daistolic, "Pulse": this.vitalsForm.value.pulse, "Result": "" };
     }
    if((this.vitalsForm.value.height!= this.memberData.height) || (this.vitalsForm.value.weight!= this.memberData.weight)){
      let bmi = 0;
    if(this.vitalsForm.value.height!= this.memberData.height || this.vitalsForm.value.weight!= this.memberData.weight)
      bmi = (this.vitalsForm.value.weight * 10000)/ (this.vitalsForm.value.height * this.vitalsForm.value.height);
      vitalsDTO['Height'] = { "Height": this.vitalsForm.value.height,"Weight": this.vitalsForm.value.weight, "BMI": Math.round(bmi * 10)/10, "IdealWeight": "74", "Result": "" };
    }
    // if((this.vitalsForm.value.spo2!= this.vitalsForm.value.pulse) ){
    //   vitalsDTO['Bo'] = {
    //     "Oxygen": this.vitalsForm.value.spo2,
    //     "OxygenList": "",
    //      "Bpm": this.vitalsForm.value.pulse,
    //     "BpmList": "", "Result": "", "StartTime": "", "EndTime": "", "SecondCount": "" ,
    //     "oxygenMeasureTime":this.vitalsForm.value.spo2==this.memberData.oxygen ? this.memberData?.boOxygenMeasureTime: datepipe.transform(this.tomorrow,"yyyy-MM-dd HH:mm:ss"),
    //     "measureTime": this.vitalsForm.value.pulse==this.memberData.bpm ? this.memberData?.boMeasureTime: datepipe.transform(this.tomorrow,"yyyy-MM-dd HH:mm:ss")
    //   };
    // }
    vitalsDTO['updatedBy']=this.show?"Care Manager":'Doctor'; 
    if(this.vitalsForm.value.spo2!= this.memberData.oxygen ||this.vitalsForm.value.pulse != this.memberData.bpm){
      vitalsDTO['Bo'] = {
        "Oxygen": this.vitalsForm.value.spo2,
        "OxygenList": "",
        "Bpm": this.vitalsForm.value.pulse,
        "BpmList": "", "Result": "", "StartTime": "", "EndTime": "", "SecondCount": "" ,
        "oxygenMeasureTime":this.vitalsForm.value.spo2==this.memberData.oxygen ? this.memberData?.boOxygenMeasureTime: datepipe.transform(this.tomorrow,"yyyy-MM-dd HH:mm:ss"),
        "measureTime": this.vitalsForm.value.pulse==this.memberData.bpm ? this.memberData?.boMeasureTime: datepipe.transform(this.tomorrow,"yyyy-MM-dd HH:mm:ss")
      };
    }
    if(this.vitalsForm.value.temp!= this.memberData.temperature)
      vitalsDTO['Temperature']  = { "Temperature": this.vitalsForm.value.temp, "Result": "" };
    if(this.vitalsForm.value.glucose!= this.memberData.bloodSugar)
      vitalsDTO['BloodSugar']  = { "BloodSugar": this.vitalsForm.value.glucose, "BloodsugarType": "", "Result": "" };
    
    console.log("vitalsdto--",JSON.stringify(vitalsDTO));
    this.subs.sink=this.apiService.uploadVitals(vitalsDTO).subscribe((resData: any) => {
    console.log("res--",resData);
    this.dialogRef.close({ event: 'close',"data":"reload"});
    this.toastrService.show('','Vitals has been uploaded successfully..',{duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
    } , error => {
     this.toastrService.show('','Vitals has been uploaded successfully..',{duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
     });
  }

}

@Directive({
  selector: '[numbersOnlywithDecimal]'
})
export class NumbersOnlywithDecimalsDirective {

  private regex: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab'];

  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}