<nb-card [ngClass]="{'gaintcard':viewType==1,'smallcard':viewType==2,'mediumcard':viewType==3,'mediumLarge':viewType==4}" [nbSpinner]="loading" nbSpinnerStatus="primary" nbSpinnerSize="giant"
    nbSpinnerMessage="Loading">
    <nb-card-header>
        Edit Prescription
    </nb-card-header>
    <nb-card-body [ngClass]="{'setMargin':applyStyles}">
        <form [formGroup]="consultationForm" autocomplete="off">
            <div class="form-body">
                <div class="row">
                    <div class="form-group position-relative has-icon-left col-md-6 col-xl-4">
                        <label for="chiefComplaint">Chief complaint <span aria-hidden="true"
                                style="color: red;">*</span></label>
                        <input type="text" id="chiefComplaint" class="form-control" placeholder="Chief complaint"
                            [disabled]="published" (selectItem)="suggestPrescription($event)"
                            [ngbTypeahead]="suggestComplaints" [resultFormatter]="formatter"
                            [inputFormatter]="formatter" ngbTooltip="Chief complaint" placement="top"
                            formControlName="complaints">
                        <div *ngIf="submitted && pdf.complaints.errors" style="color:#DC3545; font-size: small;">
                            <div *ngIf="pdf.complaints.errors.required">Chief complaint is required</div>
                        </div>

                    </div>
                    <div class="form-group position-relative has-icon-left col-md-6 col-xl-4">
                        <label for="symptoms">Symptoms</label>
                        <input type="text" id="symptoms" class="form-control" placeholder="Symptoms"
                            [disabled]="published" ngbTooltip="Symptoms" placement="top" formControlName="observation">
                    </div>
                    <div class="form-group position-relative has-icon-left col-md-6 col-xl-4">
                        <label for="examinations">Examinations/Lab Findings</label>
                        <input type="text" id="examinations" class="form-control"
                            placeholder="Examinations/Lab Findings" [disabled]="published"
                            ngbTooltip="Examinations/Lab Findings" placement="top" formControlName="examinations">
                    </div>
                    <div class="form-group position-relative has-icon-left col-md-6 col-xl-4">
                        <label for="relevantPoints">Relevant points From History</label>
                        <input type="text" id="relevantPoints" class="form-control" placeholder="History"
                            [disabled]="published" ngbTooltip="Relevant points from history" placement="top"
                            formControlName="relevantPoints">
                    </div>
                    <div class="form-group position-relative has-icon-left col-md-6 col-xl-4">
                        <label for="diagnosys">Diagnosis/ Provisional Diagnosis <span aria-hidden="true"
                                style="color: red;">*</span></label>
                        <input id="typeahead-http" type="text" class="form-control" placeholder="Diagnosis"
                            [disabled]="published" [ngClass]="{ 'is-invalid': submitted && pdf.diagnosis.errors }"
                            [ngbTypeahead]="searchFromService" ngbTooltip="Diagnosis/ Provisional Diagnosis"
                            placement="top" formControlName="diagnosis" />
                        <div *ngIf="submitted && pdf.diagnosis.errors" style="color:#DC3545; font-size: small;">
                            <div *ngIf="pdf.diagnosis.errors.required">Diagnosis/ Provisional diagnosis is
                                required</div>
                        </div>
                    </div>
                    <div class="form-group position-relative has-icon-left col-md-6 col-xl-4">
                        <label for="investigation">Suggested Investigations</label>
                        <input type="text" id="investigation" class="form-control"
                            placeholder="Suggested Investigations" [disabled]="published"
                            ngbTooltip="Suggested Investigations" placement="top" formControlName="investigation">
                    </div>
                </div>
                <div class="form-group">
                    <label for="clinicalNotes">Clinical Notes / Special Instruction</label>
                    <input type="text" id="clinicalNotes" class="form-control" placeholder="Clinical Notes"
                        [disabled]="published" ngbTooltip="Clinical Notes / Special Instruction" placement="top"
                        formControlName="notes">
                </div>
                <div class="" formArrayName="prescriptionList">
                    <label for="">Medication<span aria-hidden="true" style="color: red;">*</span></label>
                    <div class="form-group ">
                        <div class="col-12 pl-0" *ngIf="!published">
                            <button data-repeater-create nbButton status="primary" (click)="addPrescritption()">
                                <i class="fa fa-plus"></i> Add
                            </button>
                        </div>
                    </div>
                    <div *ngFor="let prescriptionForm of prescriptionList.controls; let i = index;" [formGroupName]="i">
                        <div class="row pr-0">
                            <div class="form-group col-md-8 col-xl-4">
                                <label for="drug">Drug</label>
                                <input type="text" class="form-control" placeholder="Drug" formControlName="drugName"
                                    [disabled]="published" [nbAutocomplete]="auto">
                                <!-- [matAutocomplete]="auto" -->
                                <!-- <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                        (optionSelected)='getDrugAlts(i,$event.option.value)'>
                        <mat-option *ngFor="let drug of drugs[i] | async" [value]="drug">
                          {{drug.name}}
                        </mat-option>
                      </mat-autocomplete> -->

                                <nb-autocomplete #auto [handleDisplayFn]="displayFn" [focusInputOnValueChange]="true"
                                    (selectedChange)="getDrugAlts(i,$event)">
                                    <nb-option *ngFor="let drug of drugs[i] | async;" [value]="drug">
                                        {{ drug.name }}
                                    </nb-option>

                                </nb-autocomplete>
                                <div style="color:#DC3545; font-size: small;" *ngIf="getValidityDrug(i)">
                                    Drug is required
                                </div>
                                <input type="text" style="display: none;" formControlName="rxcui">
                            </div>

                            <div class="form-group  col-md-2 " style="padding-left:20px;display:none;">
                                <label for="strength">Strength</label>
                                <input type="text" id="strength" nbInput fullWidth class="form-control"
                                    placeholder="Strength" [disabled]="published" ngbTooltip="Strength" placement="top"
                                    formControlName="dosage">
                            </div>

                            <div class="form-group col-md-4 col-xl-2">
                                <label for="instruction">Instructions</label>
                                <nb-select formControlName="instructions" fullWidth [disabled]="published">
                                    <nb-option *ngFor="let instruction of instructions" [value]="instruction">
                                        {{instruction}}
                                    </nb-option>
                                </nb-select>
                                <div style="color:#DC3545; font-size: small;" *ngIf="getValidityIns(i)">
                                    Instructions is required
                                </div>
                            </div>
                            <div class="form-group  col-md-5 col-xl-3">
                                <label for="frequency">Frequency</label>
                                <nb-select multiple formControlName="frequency" fullWidth [disabled]="published">
                                    <nb-option *ngFor="let frequency of frequencies" [value]="frequency">
                                        {{frequency}}</nb-option>
                                </nb-select>
                                <div style="color:#DC3545; font-size: small;" *ngIf="getValidityFreq(i)">
                                    Frequency is required
                                </div>
                            </div>
                            <div class="form-group  col-md-5  col-xl-2">
                                <label for="duration">Duration</label>
                                <nb-select formControlName="duration" fullWidth [disabled]="published">
                                    <nb-option *ngFor="let duration of durations" [value]="duration">
                                        {{duration}}</nb-option>
                                </nb-select>
                                <div style="color:#DC3545; font-size: small;" *ngIf="getValidityDue(i)">
                                    Duration is required
                                </div>
                            </div>

                            <div class="form-group col-md-1 text-center " *ngIf="!published">
                                <button class=" mt-2" nbButton status="primary" type="button"
                                    (click)="removePrescritption(i)"><i class="fa fa-times"></i></button>
                            </div>
                        </div>
                        <div class="row" [hidden]="!showAltFlag[i]">
                            <div class="col-md-3">
                                <label for="altDrugs">&nbsp;Alternative&nbsp;Drugs:</label>
                            </div>
                            <div class="form-group col-md-3">
                                <ng-select [items]="drugAlts[i] | async" [multiple]="true" 
                                    formControlName="altDrugs" bindLabel="name" placeholder="Alternative Drugs">
                                </ng-select>
                            </div>
                            <div class="form-group col-md-6" role="group" aria-label="Alternative Drugs">
                                <button type="button" style="min-height: 36px;margin-bottom: 5px;margin-right: 5px;"
                                    class="js-programmatic-multi-set-val btn btn-sm " nbButton status="primary"
                                    *ngFor="let item of drugAlts[i] | async | slice:0:4; let in=index"
                                    (click)="addAltDrug(i,item)">
                                    <i class="feather ft-plus"></i> {{item.name}}
                                </button>
                            </div>
                        </div>
                        <hr class="line-2">
                    </div>

                </div>
                <div class="" formArrayName="lifestylemodficationList">
                    <label for="">Life Style Modifications</label>
                    <div class="form-group ">
                        <div class="col-12 pl-0" *ngIf="!published">
                            <button data-repeater-create nbButton status="primary" (click)="addLifeStyleModfication()">
                                <i class="feather ft-plus"></i> Add

                            </button>

                            <button type="button"
                                style="min-height: 36px;margin-bottom: 5px;margin-left:1px;margin-right: 5px;"
                                class="js-programmatic-multi-set-val btn btn-sm " nbButton status="primary"
                                *ngFor="let item of modificationSuggestions | slice:0:3; let in=index"
                                (click)="addLifeStyleSuggestion(item)">
                                <i class="fa fa-plus"></i> {{item.lifeStyleChanges}}
                            </button>
                        </div>
                    </div>
                    <div class=""
                        *ngFor="let prescriptionForm of lifeStyleModficationFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="row">
                            <div class="form-group  col-md-10 prl-1" style="padding-left:20px;">
                                <label for="Changes">Tips</label>
                                <input type="text" id="changes" class="form-control"
                                    placeholder="Life Style Modifications" [disabled]="published"
                                    ngbTooltip="life style changes" placement="top" formControlName="changes">

                                <div style="color:#DC3545; font-size: small;"
                                    *ngIf="lifeStyleModficationFormGroup.controls[i].get('changes').errors?.pattern">
                                    only Alphabet are allowed
                                </div>
                            </div>
                            <div class="form-group col-md-2 text-center " *ngIf="!published">
                                <button class="mt-2" nbButton status="primary" type="button"
                                    (click)="removeLifeStyleModfication(i)"><i class="fa fa-times"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center">
                <button type="button" class=" mr-1" nbButton status="primary" (click)="onCancel()"> cancel </button>
                <button type="submit" (click)="publishPrescription()" class=" mr-1" nbButton status="primary"
                    [disabled]="consultationForm.invalid">
                    Update</button>
            </div>
        </form>
    </nb-card-body>
</nb-card>