<nb-card [size]="size">
  <nb-card-header>
    Profile
    <div class="add" *ngIf="isPatient" (click)="editPopup()" data-toggle="tooltip" title="Edit Basic Info" style="float: right;"><i
      class="fas fa-edit fa-1x mx-2"></i></div>
  </nb-card-header>
  <nb-card-body>
    <div class="row" >
        <div class="col-sm-12 d-flex justify-content-center icon-container pad-t-h">
          <div class="icon status-{{ type }}">
            <ng-content></ng-content>
          </div>
        </div>
    </div>
    <div class="row d-flex justify-content-center h6 text-left fn-b-16">{{username}}
    </div>
    <div class="d-flex fn-12 justify-content-center" *ngFor="let ele of headers;index as i"  [ngClass]="{'pad-r-h':i%2==0,'pad-l-h':i%2!=0}" >
      {{ele.field}}: {{ele.value?ele.value:'NA'}}<br>
    </div>
    <br>
    <div class="row">

          <div class="col-12" *ngFor="let ele of elements;index as j"  >
            <nb-card class="whiteback"   [size]="'tiny'">
              <nb-card-body class="showgreen fn-12">
                <!-- <i class="fas fa-heart-pulse fa-2x mx-2" ></i><br> -->
                <span class="head-row fn-b-16">{{ele.field}}</span>
                <hr class="hr-break">
                <div class="row text-left">
                  <div class="col-12 fn-12">
                    {{ele.value}}
                  </div>
                  <div class='col-md-6' *ngFor="let child of ele?.child;index as i"  [ngClass]="{'pad-r-h':i%2==0,'pad-l-h':i%2!=0}">
                   <div class="row">
                     <div class="col-6 pad-r-h">
                      <strong>{{child.field}}:</strong> 
                     </div>
                     <div class="col-6 pad-l-h">
                      {{child.value}}
                     </div>
                   </div>
                   
                  </div>
                </div>
              </nb-card-body>
            </nb-card>
          </div>
    </div>
  </nb-card-body>
</nb-card>