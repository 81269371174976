import { createAction, props } from "@ngrx/store";

export const UpdatePrescriptionsList = createAction(
    "[UpdatePrescriptionsList cust profile] UpdatePrescriptionsList cust profile",
    props<{ 
        prescriptionsList: any,
    }>()
);
export const UpdateVitals = createAction(
    "[UpdateVitals cust profile] UpdateVitals cust profile",
    props<{ 
        vitals: any,
    }>()
);
export const PrescriptionsListLoading = createAction(
    "[PrescriptionsListLoading cust profile] PrescriptionsListLoading cust profile"
);
export const VitalsLoading = createAction(
    "[VitalsLoading cust profile] VitalsLoading cust profile"
);
export const LoadPrescriptionsList = createAction(
    "[LoadPrescriptionsList cust profile effects] LoadPrescriptionsList cust profile effects",
    props<{ 
        mobileno: any,
    }>()
);
export const LoadVitals = createAction(
    "[LoadVitals cust profile effects] LoadVitals cust profile effects",
    props<{ 
        mobileno: any,
    }>()
);
export const UpdatePatientAppointmentList = createAction(
    "[UpdatePatientAppointmentList cust profile Effect] UpdatePatientAppointmentList cust profile",
    props<{ 
        patientAppointmentList: any,
    }>()
);
export const PatientAppointmentListLoading = createAction(
    "[PatientAppointmentListLoading cust profile] PatientAppointmentListLoading cust profile"
);

export const loadPatientAppointmentList = createAction(
    "[load loadPatientAppointmentList cust profile effect] loadPatientAppointmentList ",
    props<{ 
        mobileno: any,
    }>()
);
export const loadMedicationsList = createAction(
    "[load loadMedicationsList cust profile effect] loadMedicationsList ",
    props<{ 
        personId: any,
    }>()
);
export const UpdateMedicationsList  = createAction(
    "[UpdateMedicationsList cust profile Effect] UpdateMedicationsList cust profile",
    props<{ 
        activeMedicationList:any,
        userMedicationList:any
    }>()
);