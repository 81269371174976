<nb-card [nbSpinner]="loading" nbSpinnerStatus="primary" nbSpinnerSize="giant" nbSpinnerMessage="Loading">
    <nb-card-body>
        <form [formGroup]="form" (ngSubmit)="onSave()">
            <div>
                <div>
                    <label>Cancellation Reason</label>
                    <input nbInput fullWidth type="text" id="allergies" (keypress)="keyPressAlpha($event)" placeholder="Enter Cancellation Reason" [(ngModel)]="reason" placement="top" 
                    style="width: 100%;height: 40px;"  formControlName="cancellationReason">
                    <div class="error" *ngIf="cancellationReason?.errors?.required && (cancellationReason?.dirty || cancellationReason?.touched)">
                        Cancellation reason is required 
                    </div>
                </div>
            </div>
            <div style="text-align: center;margin: 10px;">
                <button type="button" nbButton status="primary" (click)="onCancel()">
                    Cancel
                </button>&nbsp;
                <button type="submit" nbButton status="primary" [disabled]="form.invalid">
                    Save
                </button>
            </div>
        </form>
    </nb-card-body>
</nb-card>