import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';

@Component({
  selector: 'app-vitalsgraphview',
  templateUrl: './vitalsgraphview.component.html',
  styleUrls: ['./vitalsgraphview.component.scss']
})
export class VitalsgraphviewComponent implements OnInit {
  @Input() patientVitals: any;
  @Input() vitalType: any;
  rows: any = [];

  public lineChartLabels = ['2000', '2001', '2002', '2003', '2004', '2005', '2006'];
  public lineChartType = 'line';
  public lineChartLegend = true;
  public lineChartData = [
    {data: [75, 49, 89, 31, 86, 35, 50], label: 'Series A'},
    {data: [48, 38, 65, 39, 66, 17, 80], label: 'Series B'}
  ];
  getlineArea(data, label, type) {
    if (label == "Blood pressure") {
      if (type == "week") {
        this.chartsData = [
          { data: data.series[0], label: 'Systolic' },
          { data: data.series[1], label: 'Diastolic' },
        ];
        this.chartsLabels = data.labels;
      }
      if (type == "month") {
        this.monthChartsData = [
          { data: data.series[0], label: 'Systolic' },
          { data: data.series[1], label: 'Diastolic' },
        ];
        this.monthChartsLabels = data.labels;
      }
      if (type == "year") {
        this.yearChartsData = [
          { data: data.series[0], label: 'Systolic' },
          { data: data.series[1], label: 'Diastolic' },
        ];
        this.yearChartsLabels = data.labels;
      }
      // this.chartsOptions = this.lineChartsOptions;
    }
    else {
      if (type == "week") {
        this.chartsData = [
          { data: data.series, label: label },
        ];
        this.chartsLabels = data.labels;
      }
      if (type == "month") {
        this.monthChartsData = [
          { data: data.series, label: label },
        ];
        this.monthChartsLabels = data.labels;
      }
      if (type == "year") {
        this.yearChartsData = [
          { data: data.series, label: label },
        ];
        this.yearChartsLabels = data.labels;
      }
      // this.chartsOptions = this.lineChartsOptions;
    }
  }
  //public lineChartstype = "line";
  //public lineChartLegend = true;

  dataLoaded: boolean = false;
  public chartsData: Array<any> = [];
  public monthChartsData: Array<any> = [];
  public yearChartsData: Array<any> = [];
  public chartsLabels;
  public monthChartsLabels;
  public yearChartsLabels;
  public chartsOptions;

  settings = {
    actions: {
      add: false,
      edit: false,
      delete: false,
    },
    pager:
    {
      perPage:8,
    },
    hideSubHeader:true,
    columns: {
      vital: {
        title: 'Value',
        type: 'string',

      },
      time: {
        title: 'Time',
        type: 'string',

      },
   
      },
  };
  source: LocalDataSource = new LocalDataSource();

  // lineChartsOptions: any = {
  //   animation: {
  //     duration: 1000, // general animation time
  //     easing: 'easeOutBack'
  //   },
  //   hover: {
  //     animationDuration: 1000, // duration of animations when hovering an item
  //     mode: 'label'
  //   },
  //   responsiveAnimationDuration: 1000, // animation duration after a resize
  //   responsive: true,
  //   maintainAspectRatio: false,
  //   legend: {
  //     position: 'bottom',
  //   },
  //   scales: {
  //     xAxes: [{

  //       ticks: {
  //         padding: 4
  //       },
  //       scaleLabel: {
  //         display: true,
  //         labelString: 'Date'
  //       }
  //     }],
  //     yAxes: [{
  //       ticks: {
  //         padding: 4
  //       },
  //       scaleLabel: {
  //         display: true,
  //         labelString: 'Value'
  //       }
  //     }]
  //   },
  // };

  constructor(private datepipe: DatePipe) {

  }

  ngOnInit() {
    let data: any;
    let mothData: any;
    let yearData: any;
    let currentdate = new Date();
    let weekdate = new Date(currentdate.getFullYear(), currentdate.getMonth(), currentdate.getDate() - 7);
    let monthdate = new Date(currentdate.getFullYear(), currentdate.getMonth() - 1, currentdate.getDate());
    let yeardate = new Date(currentdate.getFullYear() - 1, currentdate.getMonth(), currentdate.getDate() - 7);

    if (this.patientVitals != null) {
      this.rows = [];
      if (this.vitalType == 'BMI' && this.patientVitals.HeightDetails != null && this.patientVitals.HeightDetails.length > 0) {
        let dates: any[] = [];
        let BMI: any = [];
        let monthdates: any[] = [];
        let monthBMI: any = [];
        let yeardates: any[] = [];
        let yearBMI: any = [];
        this.patientVitals.HeightDetails.forEach(element => {
          if (element.BMI != null)
            this.rows.push(new Vital(this.patientVitals.Member.Name, element.BMI, this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss")));
          if (element.BMI) {
            let vitaldate = new Date(element.measureTime);
            if (weekdate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
              dates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
              BMI.push(+element.BMI);
            }
            if (monthdate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
              monthdates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
              monthBMI.push(+element.BMI);
            }
            if (yeardate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
              yeardates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
              yearBMI.push(+element.BMI);
            }
          }
        });

        data = {
          "labels": dates,
          "series": BMI
        }
        mothData = {
          "labels": monthdates,
          "series": monthBMI,
        }
        yearData = {
          "labels": yeardates,
          "series": yearBMI,
        }
      }
      else if (this.vitalType == "Body temperature") {
        let dates: any[] = [];
        let temp: any = [];
        let monthdates: any[] = [];
        let monthtemp: any = [];
        let yeardates: any[] = [];
        let yeartemp: any = [];
        if (this.patientVitals.TemperatureDetails != null) {
          this.patientVitals.TemperatureDetails.forEach(element => {
            if (element.Temperature != null)
              this.rows.push(new Vital(this.patientVitals.Member.Name, element.Temperature + " \xB0F", this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss")));
            if (element.Temperature) {
              let vitaldate = new Date(element.measureTime);
              if (weekdate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                dates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                temp.push(+element.Temperature);
              }
              if (monthdate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                monthdates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                monthtemp.push(+element.Temperature);
              }
              if (yeardate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                yeardates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                yeartemp.push(+element.Temperature);
              }
            }
          });
          data = {
            "labels": dates,
            "series": temp
          }
          mothData = {
            "labels": monthdates,
            "series": monthtemp,
          }
          yearData = {
            "labels": yeardates,
            "series": yeartemp,
          }
        }

      }
      else if (this.vitalType == "Blood pressure") {
        let dates: any[] = [];
        let BPhigh: any = [];
        let BpLow: any = [];
        let monthdates: any[] = [];
        let monthBPhigh: any = [];
        let monthBpLow: any = [];
        let yeardates: any[] = [];
        let yearBPhigh: any = [];
        let yearBpLow: any = [];
        if (this.patientVitals.BloodPressureDetails != null) {
          this.patientVitals.BloodPressureDetails.forEach(element => {
            if (element.HighPressure != null) {
              var bp = element.HighPressure + "/" + element.LowPressure + " mmHg";
              this.rows.push(new Vital(this.patientVitals.Member.Name, bp, this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss")));
            }
            if (element.HighPressure) {
              let vitaldate = new Date(element.measureTime);
              if (weekdate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                dates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                BPhigh.push(+element.HighPressure);
                BpLow.push(+element.LowPressure)
              }
              if (monthdate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                monthdates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                monthBPhigh.push(+element.HighPressure);
                monthBpLow.push(+element.LowPressure)
              }
              if (yeardate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                yeardates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                yearBPhigh.push(+element.HighPressure);
                yearBpLow.push(+element.LowPressure)
              }
            }
          });
          data = {
            "labels": dates,
            "series": [BPhigh, BpLow],
          }
          mothData = {
            "labels": monthdates,
            "series": [monthBPhigh, monthBpLow],
          }
          yearData = {
            "labels": yeardates,
            "series": [yearBPhigh, yearBpLow],
          }
        }
      }
      else if (this.vitalType == "Pulse") {
        let dates: any[] = [];
        let pulse: any = [];
        let monthdates: any[] = [];
        let monthpulse: any = [];
        let yeardates: any[] = [];
        let yearpulse: any = [];
        if (this.patientVitals.BoDetails != null) {
          this.patientVitals.BoDetails.forEach(element => {
            if (element.Bpm != null)
              this.rows.push(new Vital(this.patientVitals.Member.Name, element.Bpm + " bpm", this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss")));
            if (element.Bpm) {
              let vitaldate = new Date(element.measureTime);
              if (weekdate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                dates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                pulse.push(+element.Bpm);
              }
              if (monthdate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                monthdates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                monthpulse.push(+element.Bpm);
              }
              if (yeardate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                yeardates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                yearpulse.push(+element.Bpm);
              }
            }
          });
          data = {
            "labels": dates,
            "series": pulse
          }
          mothData = {
            "labels": monthdates,
            "series": monthpulse,
          }
          yearData = {
            "labels": yeardates,
            "series": yearpulse,
          }
        }
      }
      else if (this.vitalType == "Blood glucose") {
        let dates: any[] = [];
        let BG: any = [];
        let monthdates: any[] = [];
        let monthBG: any = [];
        let yeardates: any[] = [];
        let yearBG: any = [];
        if (this.patientVitals.BloodSugarDetails != null && this.patientVitals.BloodSugarDetails.length > 0) {
          this.patientVitals.BloodSugarDetails.forEach(element => {
            if (element.BloodSugar != null)
              this.rows.push(new Vital(this.patientVitals.Member.Name, element.BloodSugar + " mg/dL", this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss")));
            if (element.BloodSugar) {
              let vitaldate = new Date(element.measureTime);
              if (weekdate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                dates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                BG.push(+element.BloodSugar);
              }
              if (monthdate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                monthdates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                monthBG.push(+element.BloodSugar);
              }
              if (yeardate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                yeardates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                yearBG.push(+element.BloodSugar);
              }
            }
          });
          data = {
            "labels": dates,
            "series": BG
          }
          mothData = {
            "labels": monthdates,
            "series": monthBG,
          }
          yearData = {
            "labels": yeardates,
            "series": yearBG,
          }
        }
      }
      else if (this.vitalType == "Blood saturation") {
        let dates: any[] = [];
        let BS: any = [];
        let monthdates: any[] = [];
        let monthBS: any = [];
        let yeardates: any[] = [];
        let yearBS: any = [];
        if (this.patientVitals.BoDetails != null && this.patientVitals.BoDetails.length > 0) {
          this.patientVitals.BoDetails.forEach(element => {
            if (element.Oxygen != null)
              this.rows.push(new Vital(this.patientVitals.Member.Name, element.Oxygen + " %", this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss")));
            if (element.Oxygen) {
              let vitaldate = new Date(element.measureTime);
              if (weekdate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                dates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                BS.push(+element.Oxygen);
              }
              if (monthdate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                monthdates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                monthBS.push(+element.Oxygen);
              }
              if (yeardate.getTime() < vitaldate.getTime() && vitaldate.getTime() < currentdate.getTime()) {
                yeardates.push(element.measureTime == null ? "" : this.datepipe.transform(element.measureTime, "dd-MM-yyyy HH:mm:ss"));
                yearBS.push(+element.Oxygen);
              }
            }
          });
          data = {
            "labels": dates,
            "series": BS
          }
          mothData = {
            "labels": monthdates,
            "series": monthBS,
          }
          yearData = {
            "labels": yeardates,
            "series": yearBS,
          }
        }
      } else {
        return;
      }
      this.source.load(this.rows);

    }
    if (data != null) {

      this.getlineArea(data, this.vitalType, "week");
    }
    if (mothData != null) {
      this.getlineArea(mothData, this.vitalType, "month");
    }
    if (yearData != null) {

      this.getlineArea(yearData, this.vitalType, "year");
    }
  }

}

class Vital {
  constructor(
    public name: number,
    public vital: string,
    public time: string,
  ) { }
}

