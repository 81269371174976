<div>
  <div>
    <section>
      <div>

        <nb-card class="smallcard" [nbSpinner]="loading" nbSpinnerStatus="primary" nbSpinnerSize="giant"
          nbSpinnerMessage="Loading">
          <nb-card-header>Upload Vitals</nb-card-header>
          <div class="card-body">
            <div class="card-block">

              <form [formGroup]="vitalsForm">

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="form-control-label" for="bloodgroup">BloodGroup</label>
                      <select class="form-control" formControlName="bloodgroup">
                        <option value="A+">A+</option>
                        <option value="B+">B+</option>
                        <option value="O+">O+</option>
                        <option value="AB+">AB+</option>
                        <option value="A-">A-</option>
                        <option value="B-">B-</option>
                        <option value="O-">O-</option>
                        <option value="AB-">AB-</option>
                      </select>

                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-control-label" for="diabetic">Diabetic</label>
                      <select class="form-control" formControlName="diabetic">
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6" *ngIf="vitalsForm.get('diabetic').value=='YES'">
                    <div class="form-group">
                      <label class="form-control-label" for="medicalhistory">Diabetic since</label>
                      <input class="form-control" type="text" (keypress)="omitSpecialChar($event)"
                        formControlName="diabeticsince" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-control-label" for="thyroid">Thyroid</label>
                      <select class="form-control" formControlName="thyroid">
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </select>

                    </div>
                  </div>
                  <div class="col-md-6" *ngIf="vitalsForm.get('thyroid').value=='YES'">
                    <div class="form-group">
                      <label class="form-control-label" for="medicalhistory">Thyroid since</label>
                      <input class="form-control" type="text" (keypress)="omitSpecialChar($event)"
                       formControlName="thyroidsince" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-control-label" for="bloodPressure">Blood pressure</label>
                      <div class="form-group">
                        <select class="form-control" formControlName="bloodpressure">
                          <option value="YES">YES</option>
                          <option value="NO">NO</option>
                        </select>

                      </div>
                    </div>
                  </div>
                  <div class="col-md-6" *ngIf="vitalsForm.get('bloodpressure').value=='YES'">
                    <div class="form-group">
                      <label class="form-control-label" for="medicalhistory">Blood Pressure since</label>
                      <input class="form-control" type="text" (keypress)="omitSpecialChar($event)"
                       formControlName="bloodpressuresince" />
                    </div>
                  </div>

                </div>


                <hr />
                <div class="row">

                  <div class="col-md-6">

                    <div class="form-group">
                      <label class="form-control-label" for="medicalhistory">Medical history</label>
                      <input class="form-control" type="text" maxlength="255" formControlName="medicalhistory" />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-control-label" for="allergies">Allergies</label>
                      <input class="form-control" type="text" maxlength="255" formControlName="allergies" />
                    </div>
                  </div>

                </div>

              </form>

              <div class="card-body" style="text-align: center;margin: 10px;">
                <button nbButton status="primary" (click)="onCancel()">
                  Cancel
                </button>

                <button style="margin-left: 25px;" type="submit" nbButton status="primary" (click)="onSubmit()"
                  [disabled]="!vitalsForm.valid">
                  Submit
                </button>

              </div>

            </div>
          </div>
        </nb-card>
      </div>
    </section>
  </div>

</div>