import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { AppState } from "../root.reducer";
import { select, Store } from "@ngrx/store";
import { catchError, concatMap, map, mergeMap, switchMap, takeUntil, tap, timestamp } from "rxjs/operators";
import { forkJoin, merge, of, Subject, timer } from "rxjs";
import { DoctorDashboardService } from "src/app/doctorportal/service/doctor-dashboard.service";
import { getDoctor } from "../selectors/doctor-user.selectors";
import { loadDoctorPatientList,LoadDoctorUpcomingConsultationsList,loadDoctorDashboard, UpdateDoctorDashbaord, UpdateDoctorPatientList, UpdateDoctorUpcomingConsultationsList, DoctorDashboardLoading, DoctorUpcomingConsultationsListLoading} from "../actions/doctor-dashboard.actions";
import { APP_CONSTANTS } from "src/app/shared/constants";
@Injectable()
export class DoctorDashboardEffects {
  constructor(private actions$: Actions, private dashboardService: DoctorDashboardService, private store: Store<AppState>) {

  }
  currentTimer:any;
  destroy = new Subject();
  doctorDashboardTimer$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(loadDoctorDashboard),
        switchMap(() => timer(0,APP_CONSTANTS.CONFIG_DETAILS.AUTO_REFRESH_COMMON).pipe(tap(currentTimer=>{
          this.currentTimer=currentTimer;
          takeUntil(this.destroy)
        }))),
        mergeMap(action => {
          var array = [];
          if(this.currentTimer==0){
            this.store.dispatch(DoctorDashboardLoading());
          }
          this.store.select(getDoctor).subscribe((data) => {
            array.push(this.dashboardService.getPatientConsultations(data.username)
              .pipe(
                catchError((err) => {
                  return of()
                  // Handle specific error for this call
                }
                )));
            array.push(this.dashboardService.getDoctorAppointments(data.username).pipe(
              catchError((err) => {
                return of()
                // Handle specific error for this call
              }
              )));
            array.push(this.dashboardService.getDoctorAppointmentsCount(data.id).pipe(
              catchError((err) => {
                return of()
                // Handle specific error for this call
              }
              )));
            array.push(this.dashboardService.getDoctorDaywiseAppointmentsCount(data.id).pipe(
              catchError((err) => {
                return of()
                // Handle specific error for this call
              }
              )));
            array.push(this.dashboardService.getDoctorRecentlyConsultedPatients(data.username).pipe(
              catchError((err) => {
                return of()
                // Handle specific error for this call
              }
              )));
            array.push(this.dashboardService.getDoctorPrescriptions(data.username).pipe(
              catchError((err) => {
                return of()
                // Handle specific error for this call
              }
              )));
          });
          return forkJoin(array);
        }),
        map(data => {
          let list:any=data[1]
          return UpdateDoctorDashbaord({patientList: data[0],
              upcomingConsultationsList:list.filter((ele)=> {
                if(ele.cancelledBy!='Doctor')
                {
                  return true;
                }
                else{
                  return false; 
                }
               }),
            getDoctorAppointmentsCount: data[2],
            getDoctorDaywiseAppointmentsCount: data[3],
            getDoctorRecentlyConsultedPatients: data[4],
            getDoctorPrescriptions: data[5],
          })
        })
      ), { dispatch: true });

  updateDoctorPatientList$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(loadDoctorPatientList),
        mergeMap(action => {
          var array = [];
          this.store.select(getDoctor).subscribe((data) => {
            array.push(this.dashboardService.getPatientConsultations(data.username));
          });
          return forkJoin(array);
        }),
        map(config => {
          return UpdateDoctorPatientList({
            patientList: config[0]
          })
        })
      )
  );
  UpdateDoctorUpcomingConsultationsList$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(LoadDoctorUpcomingConsultationsList),
        mergeMap(action => {
          var array = [];
          this.store.dispatch(DoctorUpcomingConsultationsListLoading ());
          this.store.select(getDoctor).subscribe((data) => {
            array.push(this.dashboardService.getDoctorAppointments(data.username));
          });
          return forkJoin(array);
        }),
        map(config => {
          let list:any=config[0];
          return UpdateDoctorUpcomingConsultationsList({
            upcomingConsultationsList: list.filter((ele)=> {
              if(ele.cancelledBy!='Doctor')
              {
                return true;
              }
              else{
                return false; 
              }
             }),
          })
        })
      )
  );
}