import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UnsubscribeOnDestroyAdapter } from 'src/app/shared/services/UnsubscribeOnDestroyadapter';
import { ComponentsService } from '../services/componets.service';

@Component({
  templateUrl: './prescriptiontest.component.html',
  styleUrls: ['./prescriptiontest.component.scss'],
  providers: []
})
export class PrescriptiontestComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  meetingid: string;
  personid: string;
  viewUrl: any;
  showText: boolean = true
  loading: boolean = false
  constructor(private route: ActivatedRoute, private apiService: ComponentsService, private router: Router) {
    super()
    this.meetingid = this.route.snapshot.paramMap.get('meetingid');
    this.personid = this.route.snapshot.paramMap.get('personid');
  }

  ngOnInit() {
    this.loadrescriptionDetails();
  }

  loadrescriptionDetails() {
    this.loading=true;
    this.subs.sink=this.apiService.getPrescriptionPdfdata(this.meetingid, this.personid).subscribe((resData: any) => {
      this.loading=false;
      this.viewUrl = resData.prescriptionFilePath;
      // this.viewUrl = "https://elro-file-vault.s3.amazonaws.com/prescription/PRESCRIPTION_539.pdf"
      if (this.viewUrl == null)
        this.showText = true;
      else
        this.showText = false;
    }, error => {

    });
  }
}









