import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AppState } from 'src/app/store/root.reducer';
import { Store, select } from '@ngrx/store';
import { getUserRoles } from 'src/app/store/selectors/app-config.selectors';
import { LoginService } from './login.service';
import { updateUser } from 'src/app/store/actions/admin-user.actions';
import { APP_CONSTANTS } from 'src/app/shared/constants';
import { updateDoctor, updateDoctorStatus } from 'src/app/store/actions/doctor-user.actions';
import { UnsubscribeOnDestroyAdapter } from 'src/app/shared/services/UnsubscribeOnDestroyadapter';
import localForage from "localforage";

@Component({
    selector: 'app-login',
    styleUrls: ['login.component.scss'],
    templateUrl: 'login.component.html'
})
export class LoginComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    rolesList: any = [];
    doctor: string = 'Doctor';
    viewType: number;
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private store: Store<AppState>,
        private loginService: LoginService
    ) {
        super();
    }

    ngOnInit() {
        if (window.innerWidth >= 1200) {
            this.viewType = 1;
          } else if (window.innerWidth < 1200 && window.innerWidth >= 960) {
            this.viewType = 4;
          } else if (window.innerWidth < 960 && window.innerWidth >= 768) {
            this.viewType = 3;
          } else if (window.innerWidth < 480) {
            this.viewType = 2;
          }
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            role: ['', Validators.required]
        });
        this.store.pipe(select(getUserRoles)).subscribe(userRoles => {
            this.rolesList = userRoles;
        })
    }
    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }
    getRegistration() {
        this.router.navigate([APP_CONSTANTS.APP_ROUTE.AUTH + '/' + APP_CONSTANTS.DOCTOR_ROUTE.REGISTER])
    }
    forgotPassword() {
        this.router.navigate([APP_CONSTANTS.APP_ROUTE.AUTH + "/" + APP_CONSTANTS.LOGIN_ROUTE.FORGOT_PASSWORD]);
    }
    onSubmit() {
        this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }
        this.loading = true;
        if (this.f.role.value == 'Doctor') {
            this.subs.sink = this.loginService.loginDoctor(this.f.username.value, this.f.password.value)
                .pipe(first())
                .subscribe(
                    data => {
                        if (data.status == 200) {
                            var docname=this.f.username.value
                            console.log(docname);
                            localForage.setDriver([
                                localForage.INDEXEDDB,
                                localForage.WEBSQL,
                                localForage.LOCALSTORAGE
                              ]).then(function () {
                                localForage.setItem("doctorname", docname);
                              });
                             
     

                            this.subs.sink = this.loginService.getDoctorDetails(this.f.username.value).subscribe(
                                res => {
                                    this.store.dispatch(updateDoctor({ id: res['id'], given: res['given'], username: res['username'], consultationFee: res['feeAmount'], consultationType: res['doctorType'] }));
                                    this.subs.sink = this.loginService.updateDoctorStatus(this.f.username.value).subscribe(res => {
                                        console.log('data--', res)
                                        this.store.dispatch(updateDoctorStatus({ checkin: res['checkIn'], status: res['status'] }));
                                        this.router.navigate([APP_CONSTANTS.APP_ROUTE.DOCTOR + "/" + APP_CONSTANTS.DOCTOR_ROUTE.DASHBOARD]);
                                    })
                                });
                        } else {
                            this.error = "Username or password or role is incorrect";
                            this.loading = false;
                        }
                    },
                    error => {
                        this.error = "Username or password or role is incorrect";
                        this.loading = false;
                    });
        } else {
            this.subs.sink = this.loginService.login(this.f.username.value, this.f.password.value, this.f.role.value)
                .pipe(first())
                .subscribe(
                    data => {
                        if (data.status == 200) {
                            console.log(data);
                            this.store.dispatch(updateUser(data.body.data));
                            if (data.body.data.roleDTO.role == 'admin') {
                                this.router.navigate([APP_CONSTANTS.APP_ROUTE.ADMIN + "/" + APP_CONSTANTS.ADMIN_ROUTE.ADMIN_HOME])
                            }
                            else {
                                this.router.navigate([APP_CONSTANTS.APP_ROUTE.ADMIN + "/" + APP_CONSTANTS.ADMIN_ROUTE.HOME]);
                            }

                        }
                        else {
                            this.error = "Username or password or role is incorrect";
                            this.loading = false;
                        }
                    },
                    error => {
                        this.error = error.error.message;
                        this.loading = false;
                    });
        }
    }
}
