
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP_CONSTANTS } from '../constants';
@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

  constructor(private http: HttpClient) { }

  getOtp(username: any, role: any) {
    let url = APP_CONSTANTS.REST_API_URL.GET_OTP + role + '/' + username;
    return this.http.get(url)
  }
  createNewPassword(userDTO) {
    let url = APP_CONSTANTS.REST_API_URL.CHANGE_PASSWORD
    return this.http.post(url, userDTO,)
  }
}
