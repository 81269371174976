import { Component, Input, OnInit } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { ViewCell } from 'ng2-smart-table';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/root.reducer';
import { CancelDialogComponent } from 'src/app/shared/shared-dialogue-components/cancel-dialog/cancel-dialog.component';
import { LoadDoctorUpcomingConsultationsList } from 'src/app/store/actions/doctor-dashboard.actions';
import { getDoctor } from 'src/app/store/selectors/doctor-user.selectors';
import { AppointmentConsultationsComponent } from 'src/app/doctorportal/doctor-dialogue-components/appointment-consultations/appointment-consultations.component';
import { APP_CONSTANTS } from '../constants';
import { UnsubscribeOnDestroyAdapter } from '../services/UnsubscribeOnDestroyadapter';
import { DoctorDashboardService } from 'src/app/doctorportal/service/doctor-dashboard.service';
@Component({
  template: `
      <div > <span (click)=" startConsultation()"> <i class="fa fa-video-camera"  title="Meet"></i> </span> &nbsp;
      <span (click)="cancelConsultation(1)"><i class="fa fa-times"  title="cancel"></i></span>
      </div>
    `,
})
export class PatientUpcomoingAppointmentRenderComponent extends UnsubscribeOnDestroyAdapter implements ViewCell, OnInit {

  renderValue: string;
  username: string;
  @Input() value: string | number;
  @Input() rowData: any;
  active: boolean;
  paymentCheck: boolean;
  custromerData: any;
  mobileno: any;
  DoctorUpcomingConsultationsListLoading: any;
  status: any;
  subs: any;
  constructor(private dialogService: NbDialogService, private store: Store<AppState>
    , private toastrService: NbToastrService, private apiservice: DoctorDashboardService) {
    super();
    this.store.select(getDoctor).subscribe(data => {
      this.username = data.username;
    })
  }

  ngOnInit() {
    this.renderValue = '';
  }
  startConsultation() {
    var data1 = JSON.parse(JSON.stringify(this.rowData));
    console.log(data1);
    // if (data1['consultationtype'] == 'Paid') {
      this.subs.sink = this.apiservice.getPaymentStatus(data1['id']).subscribe((resData: any) => {
        if (resData) {
          this.openConsultationPopup();
        }
      }, error => {
        console.log(error);
        this.status = error.status;
        if (this.status == "402") {
          this.toastrService.show('', 'Payment not done.', { duration: APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON });
        }
        else if(this.status=="406")
        {
        this.toastrService.show('','Meeting can be joined 15 minutes prior to the Scheduled time.',{duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
        }else if(this.status=="424")
        {
        this.toastrService.show('','Patient has not yet joined',{duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
        }
      });
    // }
    // else {
    //   this.active = (data1.remainingTime < 900) ? true : false
    //   if (this.active) {
    //     this.openConsultationPopup();
    //   }
    //   else {
    //     this.toastrService.show('', 'Meeting can be joined 15 minutes prior to the Scheduled time.', { duration: APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON });
    //   }
    // }

  }
  openConsultationPopup() {

    var data1 = JSON.parse(JSON.stringify(this.rowData));

    this.dialogService.open(AppointmentConsultationsComponent, {
      context: {
        data: { pageValue: data1 }
      }, dialogClass: 'model-full',
      autoFocus: false,
      closeOnBackdropClick:false,
      hasBackdrop:true
    })
      .onClose.subscribe({
        next: (res) => {
          if (res?.event == 'refresh') {
            // this.getCustomers();
          }
        }
      });
  }

  cancelConsultation(from) {
    var data1 = JSON.parse(JSON.stringify(this.rowData));
    this.dialogService.open(CancelDialogComponent, {
      context: {
        data: data1,
        username: this.username,
        from: 'Doctor'
      }, dialogClass: 'model-full',
      autoFocus: false
    })
      .onClose.subscribe({
        next: (res) => {
          if (res) {
            this.store.dispatch(LoadDoctorUpcomingConsultationsList());
          }

        }
      });
  }


}
