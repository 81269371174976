import { Component, Directive, OnDestroy } from '@angular/core';
import { SubSink } from 'node_modules/subsink/dist/subsink';

/**
* A class that automatically unsubscribes all observables when 
* the object gets destroyed
*/
@Directive()
export class UnsubscribeOnDestroyAdapter implements OnDestroy {

   /**The subscription sink object that stores all subscriptions */
   subs = new SubSink();

   /**
   * The lifecycle hook that unsubscribes all subscriptions 
   * when the component / object gets destroyed
   */
   ngOnDestroy(): void {
      console.log("unsubscribed");
      this.subs.unsubscribe();
   }
}