export interface AppConfigState {
  refreshRate: number,
  userRoles:UserRole[],
  isUserRolesLoaded:boolean
}

export interface UserRole {
  id: number, 
  role: string,
  roledescription: string
}
export const initialState: AppConfigState = {
  refreshRate: 15,
  userRoles:null,
  isUserRolesLoaded:false
};