import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from '../constants';
@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    updateFcmToken(fcmToken:string,userName:string):Observable<any> {
       let url =APP_CONSTANTS.REST_API_URL.UPDATE_FCM_TOKEN + fcmToken + "/" + userName;
        return this.http.get(url);
    }
   
}


