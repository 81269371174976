import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(private http: HttpClient) { }


  getDoctorBlockSlotsPromise(doctorId) {
    let url = APP_CONSTANTS.REST_API_URL.GET_BLOCKED_REQUESTS + doctorId;
    return this.http.get(url).toPromise();
  }

  getDoctorAppointments(doctorId: any): Observable<any> {
    let url = APP_CONSTANTS.REST_API_URL.GET_DOCTOR_APPOINTMENTS + doctorId;
    return this.http.get(url);
  }

  cancelAppointment(appointmentDTO) {
    let url = APP_CONSTANTS.REST_API_URL.CANCEL_APPOINTMENT;
    return this.http.post(url, appointmentDTO)
  }

  getDoctorAvailableSlotDetails(id: any, date: any) {
    let url = APP_CONSTANTS.REST_API_URL.GET_AVAILABLE_TIME_SLOTS + id + "?date=" + date;
    return this.http.get(url)
  }

  getAllDoctors() {
    return this.http.get(APP_CONSTANTS.REST_API_URL.GET_DOCTORS)
  }

  updateUserSlotDetails(dataDTO: any, id: any, date: any) {
    let url = APP_CONSTANTS.REST_API_URL.UPDATE_AVAIALBLE_SLOTS_DAY + id + "?date=" + date
    return this.http.post(url, dataDTO)
  }

  getDoctorDetails(userName) {
    return this.http.get(APP_CONSTANTS.REST_API_URL.GET_REGISTRATION + userName);
  }

  bookDoctorAppointment(AppointmentDTO) {
    let url = APP_CONSTANTS.REST_API_URL.BOOK_APPOINTMENT
    return this.http.post(url, AppointmentDTO)
  }

  editBlockedSlots(AppointmentDTO) {
    let url = APP_CONSTANTS.REST_API_URL.BOOK_APPOINTMENT
    return this.http.post(url, AppointmentDTO)
  }

}