<div class="row">
    <div class="col-12">
        <nb-card>
          <nb-card-header>
              Payments & Refunds
          </nb-card-header>
          <nb-card-body>
                <ng2-smart-table [settings]="settings" [source]="source" >
                </ng2-smart-table>
               
          </nb-card-body>            
        </nb-card>   
    </div>
</div>

