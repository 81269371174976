import { createAction, props } from "@ngrx/store";
import { roleDTO } from "../state/admin-user.state";

export const loadUser = createAction(
    "[load user Details] Load User Details "
); 

export const updateUser = createAction(
    "[save user] Save User Details",
    props<{
        id: number,
        username: string,
        firstname: string,
        lastname: string,
        mobileno: string,
        roleDTO:roleDTO
      }>()
);
