export interface DoctorState {
  id: number,
  given:string,
  username:string,
  consultationFee:string,
  consultationType:string,
  checkin:boolean,
  status:string
}

export const initialDoctorState: DoctorState = null;