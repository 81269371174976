import { createFeatureSelector, createSelector } from "@ngrx/store";
import { DashboardState } from "../state/home.state";

const getDashbaordState = createFeatureSelector<DashboardState>('dashboard')

export const getAppConfig = createSelector(getDashbaordState, (state) => {
    return state;
})

export const getDoctorDashboard = createSelector(
    getDashbaordState,
    state => {
        state.AdminDashboardLoading
        return state;
    }
);

export const getDashboard = createSelector(
    getDashbaordState,
    state => state
);

export const getPatientList = createSelector(
    getDashbaordState,
    state => state.patientList
);
export const getUpcomingConsultationsList = createSelector(
    getDashbaordState,
    state =>{
        state.upcomingConsultationsList,
        state.AdminUpcomingConsultationListLoading
        return state
    }
);
export const getDoctorsList = createSelector(
    getDashbaordState,
    state => state.doctorsList
);
export const getPaymentsList = createSelector(
    getDashbaordState,
    state => state.paymentsList
);
export const getPaymentsFollowupList = createSelector(
    getDashbaordState,
    state => state.paymentsFollowupList
);
export const getRefundsFollowupList = createSelector(
    getDashbaordState,
    state => state.refundsFollowupList
);
export const getAppointmentsCount = createSelector(
    getDashbaordState,
    state => state.getAppointmentsCount
);
export const getDaywiseAppointmentsCount = createSelector(
    getDashbaordState,
    state => state.getDaywiseAppointmentsCount
);
export const getConsultedPatientCountByDepartment = createSelector(
    getDashbaordState,
    state => state.getConsultedPatientCountByDepartment
);
export const getRecentlyConsultedPatients = createSelector(
    getDashbaordState,
    state => state.getRecentlyConsultedPatients
);
export const getRecentAvailableDoctors = createSelector(
    getDashbaordState,
    state => state.getRecentAvailableDoctors
);
export const getAppointmentsFollowupList = createSelector(
    getDashbaordState,
    state => state.appointmentsFollowupList
);
