import { Component, Input, OnInit} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PatientService } from '../services/patient.service';
import { NbDialogService} from '@nebular/theme';
import { ViewCell } from 'ng2-smart-table';
import { UnsubscribeOnDestroyAdapter } from '../services/UnsubscribeOnDestroyadapter';
import { EcgDialogComponent } from '../shared-dialogue-components/ecgdilaog/ecgdialog.component';

@Component({
    templateUrl: '../customerprofile/ReportsView.component.html',
    styleUrls: ['../customerprofile/customerprofile.component.scss'],
  })
  export class ReportRenderComponent extends UnsubscribeOnDestroyAdapter implements ViewCell, OnInit {
  
    renderValue: string;
    reportimage: any;
    reportname: any;
    @Input() value: string | number;
    @Input() rowData: any;
    patientVitals: any;
    data: any;
  con1: boolean;
  con2: boolean;
  con3: boolean;
  showText: boolean = false;
  
    constructor(private modalService: NgbModal, private apiService: PatientService, private dialogService: NbDialogService) {
      super()
  
    }
  
    ngOnInit() {
      this.renderValue = '';
    }
    viewRecord(from, MediumModelContent, HBModelContent, UAModelContent, UricacidModelContent, CholModelContent, ReportsModelContent) {
      var data1 = JSON.parse(JSON.stringify(this.rowData));
  
      var ReportsModelContent = ReportsModelContent;
      var MediumModelContent = MediumModelContent;
      var HBModelContent = HBModelContent;
      var UAModelContent = UAModelContent;
      var UricacidModelContent = UricacidModelContent;
      var CholModelContent = CholModelContent;
      this.openReports(data1, MediumModelContent, HBModelContent, UAModelContent, UricacidModelContent, CholModelContent, ReportsModelContent);
    }
  
    openReports(data1, lipid, hb, ur, uricacid, chol, reports) {
      if (data1.reportName == "ECG" && data1.name == "ecg")
        this.openEcg(data1.vital)
      else if (data1.reportName == 'Lipids Profile')
        this.MediumModel(lipid, data1.vital);
      else if (data1.reportName == 'Himoglobin')
        this.HBModel(hb, data1.vital);
      else if (data1.reportName == 'Urin Analysis')
        this.UAModel(ur, data1.vital)
      else if (data1.reportName == 'Uricacid')
        this.UricacidModel(uricacid, data1.vital)
      else if (data1.reportName == 'Cholestrol')
        this.cholModel(chol, data1.vital)
      else {
        this.reportsModel(reports, data1.vital);
      }
    }
    openEcg(ecg) {
      ecg.name = this.patientVitals.Member.Name;
      ecg.gender = this.patientVitals.Member.Sex;
      ecg.age = this.patientVitals.Member.Age;
  
      const dialogRef = this.dialogService.open(EcgDialogComponent, {
        context: {
          data: ecg
        },
        autoFocus: false
      });
  
      dialogRef.onClose.subscribe(result => {
  
      });
  
    }
    loading: boolean;
    reportDto: any;
    reportsModel(ReportsModelContent, reportDto) {
  
      this.reportDto = reportDto;
      this.subs.sink=this.apiService.getReportAttachment(reportDto.recordNo).subscribe(res => {
        this.loading = true;
      }, error => {
        this.reportDto.reportAttachment = error.error.text;
        this.loading=true;
        if (this.reportDto.reportAttachment == null)
          this.showText = true;
        else {
          if (this.reportDto.reportAttachment.includes('data:image')) {
            this.con1 = true;
          }
          else if (this.reportDto.reportAttachment.includes('https') && (this.reportDto.reportAttachment.includes('jpg') 
          || this.reportDto.reportAttachment.includes('jpeg') || this.reportDto.reportAttachment.includes('png'))) {
            this.con2 = true;
          }
          else{
            this.con3=true;
            this.showText=false;
          }
      }
      });
      
      this.modalService.open(ReportsModelContent, { windowClass: 'animated fadeInDown', size: 'lg' });
    }
    vitalType: string;
    lipids: any;
    MediumModel(MediumModelContent, data) {
      this.lipids = data;
      this.modalService.open(MediumModelContent, { windowClass: 'animated fadeInDown', size: 'md' });
    }
  
    hb: any;
    HBModel(HBModelContent, data) {
      this.hb = data;
      this.modalService.open(HBModelContent, { windowClass: 'animated fadeInDown', size: 'md' });
    }
  
    ur: any;
    UAModel(UAModelContent, data) {
      this.ur = data;
      this.modalService.open(UAModelContent, { windowClass: 'animated fadeInDown', size: 'md' });
    }
  
    urica: any;
    UricacidModel(UricacidModelContent, data) {
      this.urica = data;
      this.modalService.open(UricacidModelContent, { windowClass: 'animated fadeInDown', size: 'md' });
    }
  
    chol: any;
    cholModel(CholModelContent, data) {
      this.chol = data;
      this.modalService.open(CholModelContent, { windowClass: 'animated fadeInDown', size: 'md' });
    }
  
  
  }