import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDateService, NbDialogService, NbToastrService } from '@nebular/theme';
import { APP_CONSTANTS } from 'src/app/shared/constants';
import { UnsubscribeOnDestroyAdapter } from 'src/app/shared/services/UnsubscribeOnDestroyadapter';
import { ComponentsService } from '../services/componets.service';

@Component({
  selector: 'app-reportsupload',
  templateUrl: './reportsupload.component.html',
  styleUrls: ['./reportsupload.component.scss']
})
export class ReportsuploadComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  mobileno: any;
  reportsForm: FormGroup;
  report: string = "";
  tomorrow = new Date();
  fileToUpload: File = null;
  loading: boolean;
  submitted: boolean;
  

  constructor(private router: Router,private datepipe : DatePipe, private apiService: ComponentsService,private formBuilder: FormBuilder,private route: ActivatedRoute,protected dateService: NbDateService<Date>, private dialogService: NbDialogService, private toastrService: NbToastrService) {
    super()
   }

  ngOnInit(){
    this.mobileno = this.route.snapshot.paramMap.get("mobileno");

    this.reportsForm = this.formBuilder.group({
      reportName: ['', Validators.required],
      measureTime: ['', Validators.required],
      reportCategory:['', Validators.required]
    });
  }

  get pdf() {
    return this.reportsForm.controls;
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    if (this.fileToUpload != null) {
      const reader = new FileReader();
      reader.readAsDataURL(this.fileToUpload);
      reader.onload = () => {

        this.report = reader.result.toString();
      };
    }
    else
      this.report = "";
  }

  onSubmit() {
    this.submitted = true;
    if (this.reportsForm.invalid) {
      return;
    }

    if (this.report == "") {
      this.toastrService.show('', 'Please upload the report..', {duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
      return;
    }

    this.reportsForm.value.measureTime = this.datepipe.transform(this.reportsForm.value.measureTime, "yyyy-MM-dd HH:mm:ss");
    this.reportsForm.value.mobileNumber = this.mobileno;
    this.reportsForm.value.reportAttachment = this.report;
    this.loading = true;
    this.subs.sink=this.apiService.uploadReports(this.reportsForm.value).subscribe((resData: any) => {
      var flag=false;
      this.loading = false;
      if (resData) {
        flag=true;
        this.toastrService.show('', 'Report has been uploaded successfully.', {duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
      
      }
      else {
        this.toastrService.show('', 'Unable to upload the Report.', {duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
      }
      if(flag)
      {
        this.router.navigate([APP_CONSTANTS.APP_ROUTE.AUTH + "/"+APP_CONSTANTS.LOGIN_ROUTE.STATUSMESSAGE.slice(0, -9),"reportsupload"])
      }
    }, error => {
      this.loading = false;
      var data = error;
    });
  }

}
