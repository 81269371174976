
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP_CONSTANTS } from '../constants';
@Injectable({
  providedIn: 'root'
})
export class PrescriptionPdfService {

  constructor(private http: HttpClient) { }

  getUserDetails(username: any) {
    let url = APP_CONSTANTS.REST_API_URL.GET_REGISTRATION + username;
    return this.http.get(url)
  }

    

}
