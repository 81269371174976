import { Recurrence } from "./RecurrenceObject"
import { Settings } from "./SettingsObject"

export class RequestMeeting{
        topic: string
        type: number
        start_time: string
        duration: number
        schedule_for: string
        timezone: string
        password: string
        agenda: string
        recurrence: Recurrence
        settings: Settings
}