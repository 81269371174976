import { createReducer, on } from "@ngrx/store"
import { UpdateUserRoles } from "../actions/app-config.actions"
import { initialState } from "../state/app-config.state"

const _AppConfigReducer = createReducer(
    initialState,
    on(UpdateUserRoles,
        (state, { UserRoles }) => ({ ...state, userRoles: UserRoles, isUserRolesLoaded: true })),

)

export function AppConfigReducer(state, action) {
    return _AppConfigReducer(state, action)
}