import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP_CONSTANTS } from 'src/app/shared/constants';
@Injectable({
  providedIn: 'root'
})
export class AdminHomeService {

  constructor(private http: HttpClient) { }

    getAllDoctors() {
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_DOCTORS)
    }
    getAllPatients(){
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_CUSTOMER_DETAILS)
    }
    getAllUsers(){
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_ALL_USERS)
    }
    getAllRoles(){
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_USER_ROLES)
    }
}