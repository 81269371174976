import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/root.reducer';
import { getUser } from 'src/app/store/selectors/admin-user.selectors';
import { getDoctor } from 'src/app/store/selectors/doctor-user.selectors';
import { APP_CONSTANTS } from '../constants';
import { ChangePasswordService } from '../services/changepassword.service';
import { UnsubscribeOnDestroyAdapter } from '../services/UnsubscribeOnDestroyadapter';

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent  extends UnsubscribeOnDestroyAdapter implements OnInit {
  personaldetailsForm: any;
  submitted = false;
  loading:boolean = false;
  passmsg: string;
  username: any;
  portaltype: any;
  resetBy: any;
  otp: any;
  text : any;
  role : any;
  constructor(private store: Store<AppState>,private toastrService: NbToastrService,private apiService:ChangePasswordService,private router: Router,private formBuilder: FormBuilder) {
    super()
    let data = this.router.getCurrentNavigation() ? this.router.getCurrentNavigation()["extras"]["state"]['text'] : null;
    this.text = data;
   }

  ngOnInit(): void {
    if(this.text=='fromDoctor'){
      this.store.select(getDoctor).subscribe(data => {
        this.username=data?.username;
      })
    }
    else{
      this.store.select(getUser).subscribe((data)=>{
        this.username = data?.username
        if(data?.roleDTO?.role=='admin'){
          this.role='admin'
        }else if(data?.roleDTO?.role=='care manager'){
          this.role='careManager'
        }
      })
    }
    this.personaldetailsForm = this.formBuilder.group({
      oldpass: ['', Validators.required],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    },
    {
      validator: MustMatch('password', 'confirmPassword')
    });
  }
  get pdf() {
    return this.personaldetailsForm.controls;
  }
  goBack(){
    if(this.text=='fromDoctor')
      this.router.navigate([APP_CONSTANTS.APP_ROUTE.DOCTOR+"/"+APP_CONSTANTS.DOCTOR_ROUTE.DASHBOARD]);
    else if(this.text=='fromAdmin' && this.role=='admin')
      this.router.navigate(['/'+APP_CONSTANTS.APP_ROUTE.ADMIN+"/"+APP_CONSTANTS.ADMIN_ROUTE.ADMIN_HOME]);
    else if(this.text=='fromAdmin' && this.role=='careManager' )
      this.router.navigate(['/'+APP_CONSTANTS.APP_ROUTE.ADMIN+"/"+APP_CONSTANTS.ADMIN_ROUTE.HOME]);
    else {}
  }
  onSubmit() {
    this.submitted = true;
    if (this.personaldetailsForm.invalid) {
      return;
    }
    this.loading=true;
    if(this.text=='fromDoctor')
      this.portaltype='doctor';
    else 
      this.portaltype='admin';
    var userDTO = {
      "username":this.username,
      "portaltype":this.portaltype,
      "resetBy":"oldPassword",
      "otp":"",
      "oldPassword":this.personaldetailsForm.value.oldpass, 
      "newPassword":this.personaldetailsForm.value.password
    };
    this.subs.sink = this.apiService.createNewPassword(userDTO).subscribe((resData: any) => {
      this.loading = false;
    }, error => {
      if(error.status==424){
        this.toastrService.show('',"Incorrect Old Password", {duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
      }
      if(error.status==200){
        this.toastrService.show('',"Password Updated Successfully", {duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
        
        if(this.text=='fromDoctor')  
        {
          this.router.navigate(['/'+APP_CONSTANTS.APP_ROUTE.DOCTOR+"/"+APP_CONSTANTS.DOCTOR_ROUTE.DASHBOARD]);
        }
         
        else if(this.text=='fromAdmin' && this.role=='admin')
          {
            this.router.navigate(['/'+APP_CONSTANTS.APP_ROUTE.ADMIN+"/"+APP_CONSTANTS.ADMIN_ROUTE.ADMIN_HOME]);
          }
        else if(this.text=='fromAdmin' && this.role=='careManager' )
        {
          this.router.navigate(['/'+APP_CONSTANTS.APP_ROUTE.ADMIN+"/"+APP_CONSTANTS.ADMIN_ROUTE.HOME]);
        }
           
        else {}
      }
      this.loading = false;
    });
  }
}