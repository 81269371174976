import { ClinicalNotes } from './clinicalnotes';
import { lifeStyleModfications } from "./lifeStyleModificationsDetails";
import { PrescriptionDetails } from './prescriptionDetails';
export class Prescription {
	id:Number;
  	deviceId:Number;
	doctorId:string;
	meetingId:Number;
	personId:any;
	time:Date;
	isPublished:boolean;
	editPrescription:boolean;
	publishTime:Date;
	prescriptionDetails: PrescriptionDetails[];
	lifeStyleModifications:lifeStyleModfications[];
	clinicalNotes:ClinicalNotes;
	prescriptionNotes:string;
	  drugName:string;
	  //summary:any=false;
	  doctorName:string;
  
}