
import { Component,Input, OnInit} from '@angular/core';
import { NbDialogRef} from '@nebular/theme';

@Component({
    templateUrl: 'durgsinteraction.html',
    styleUrls: ['../appointment-consultations/appointment-consultations.component.scss']
  })
  export class DrugsInteractionDialog implements OnInit{
    resData: any;
    interactions: string[] = [];
    source: string;
    @Input() data:any;
    constructor(private dialogRef:NbDialogRef<DrugsInteractionDialog>) {
      
    }
    ngOnInit() {
      this.resData = this.data.pageValue;
      this.source = this.data.source;
      this.interactions = this.data.pageValue;
    }
    onCancel(): void {
      this.dialogRef.close({ event: 'cancel', data: false });
    }
  
    onSave() {
      this.dialogRef.close({ event: this.source, data: true });
    }
  
  }