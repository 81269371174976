
import { Component,Input, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef} from '@nebular/theme';
import { distanceInWordsToNow } from 'date-fns';
@Component({
    templateUrl: 'prescriptionnote.html',
    styleUrls: ['../appointment-consultations/appointment-consultations.component.scss']
  })
  export class ACPrescriptionNoteDialog  implements OnInit{
    reason: string;
    prescriptions: [];
    @Input() data:any;
    showNoteInput: boolean = false;
    constructor(private formBuilder: FormBuilder,
       private dialogRef:NbDialogRef<ACPrescriptionNoteDialog>
      ) {
      
    }
    ngOnInit() {
      this.prescriptions = this.data.pageValue;
      this.showNoteInput = false;
    }
    form: FormGroup = this.formBuilder.group({
      prescriptionNote: ['', [Validators.required]],
    });
    get prescriptionNote() {
      return this.form.get('prescriptionNote');
    }
    keyPressAlpha(event) {
      var inp = String.fromCharCode(event.keyCode);
      if (/[a-zA-Z ]/.test(inp)) {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
    }
    onCancel(): void {
      this.showNoteInput = true;
    }
    onBack(): void {
      this.showNoteInput = false;
    }
    onSelect(prescription: any): void {
      this.dialogRef.close({ event: "select", data: prescription });
    }
    onPrescriptionNoteSave(): void {
      this.dialogRef.close({ event: "not-select", data: this.reason });
    }
    getDaysAgoText(prescription: any) {
      return distanceInWordsToNow(new Date(prescription.time), { addSuffix: true })
    }
  }