import { createReducer, on } from "@ngrx/store"
import { updateAdminPreScreening } from "../actions/admin-prescreening.action"
import { AdminPrescreeningInitialState } from "../state/admin-prescreening.state"

const _AdminPrescreeningReducer = createReducer(
    AdminPrescreeningInitialState,
    on(updateAdminPreScreening, (state, action) => {
        return {
            ...state,
            adminPrescreeningList: action.adminPrescreeningList,
            adminPrescreeningLoading:false
        }
    })
)

export function AdminPrescreeningReducer(state, action) {
    return _AdminPrescreeningReducer(state, action)
}