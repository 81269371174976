import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { RequestMeeting } from '../models/RequestMeetingObject';
import { APP_CONSTANTS } from '../constants';
@Injectable({
    providedIn: 'root'
})
export class PatientService {
    constructor(private http: HttpClient) { }
    loadPrescriptionDetails(prescriptionId){
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_PRESCRIPTION_DETAILS + prescriptionId);
    }
    getRoutine(custId, date) {
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_ROUTINE + date + "&custmoerId=" + custId);
    }
    getPatientVitals(mobile) {
        let url = APP_CONSTANTS.REST_API_URL.GET_ALL_VITALS_REPORT + mobile;
        return this.http.get(url);
    }
    getReportAttachment(recordno: any) {
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_ATTACHMENT_BY_REPORT_NO
            + recordno);
    }
    getPatientPrescriptions(mobile) {
        let url = APP_CONSTANTS.REST_API_URL.GET_USER_PRESCRIPTION_DETAILS + mobile;
        return this.http.get(url);
    }
    getActiveMedications(personId) {
        let url = APP_CONSTANTS.REST_API_URL.GET_ACTIVE_MEDICATION + personId;
        return this.http.get(url);
    }
    getUserMedications(mobile: any) {
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_USER_MEDICATION + mobile)
    }
    getAppointments(mobile) {
        let url = APP_CONSTANTS.REST_API_URL.GET_CUSTOMER_APPOINTMENTS + mobile;
        return this.http.get(url);
    }

    getPatientAppointmentsHistory(mobile) {
        let url = APP_CONSTANTS.REST_API_URL.GET_PATIENTS_CONSULTATION_HISTORY + mobile;
        return this.http.get(url);
    }
    sendSms(smsParametersDTO: any) {
        let url = APP_CONSTANTS.REST_API_URL.SEND_OBJ_SMS
        return this.http.post(url, smsParametersDTO)
    }
    cancelAppointment(appointmentDTO) {
        let url = APP_CONSTANTS.REST_API_URL.CANCEL_APPOINTMENT
        return this.http.post(url, appointmentDTO)
    }
    updatePrescriptionStatus(prescriptionId: string, status: boolean) {
        return this.http.get(APP_CONSTANTS.REST_API_URL.UPDATE_PRESCRIPTION_STATUS + prescriptionId + "/" + status)
    }
    getReportdetails(mobileno: any) {
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_ALL_VITALS_DETAILS + mobileno)
    }
    uploadVitals(vitalsDTO: any) {
        return this.http.post(APP_CONSTANTS.REST_API_URL.UPLOAD_VITALS, vitalsDTO)
    }
    saveCustomer(userDTO) {
        let url = APP_CONSTANTS.REST_API_URL.SAVE_PATIENT_REGISTRATION
        return this.http.post(url, userDTO)
    }
    getAllDoctors() {
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_DOCTORS)
    }
    uploadReports(reportsDTO: any) {
        return this.http.post(APP_CONSTANTS.REST_API_URL.SAVE_PATIENT_REPORT_UPLOAD, reportsDTO)
    }
    getDoctorsFreeSlots(docIds, dateVal): Observable<any> {
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_DOCTOR_FREE_SLOTS + docIds + "?date=" + dateVal)
    }
    rescheduleAppointment(AppointmentDTO) {
        let url = APP_CONSTANTS.REST_API_URL.RESCHEDULE_APPOINTMENT
        return this.http.post(url, AppointmentDTO)
    }
    bookDoctorAppointment(AppointmentDTO) {
        let url = APP_CONSTANTS.REST_API_URL.BOOK_APPOINTMENT
        return this.http.post(url, AppointmentDTO,{responseType: 'json'})
    }
    handleError(error) {
        return throwError(error.message || "Server Error")
    }
    scheduleMeeting(obj: RequestMeeting) {
        return this.http.post(APP_CONSTANTS.REST_API_URL.CREATE, obj,{responseType: 'text',observe: 'response'});
    }
    getReportDetails(reportNo: any) {
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_VITALS_DETAILS_BY_RECORD_NO + reportNo)
    }
    cancelAppointmentRequest(followupdeskDTO) {
        let url = APP_CONSTANTS.REST_API_URL.SAVE_FOLLOWUPS
        return this.http.post(url, followupdeskDTO)
    }
    getAllQuestions(personId: string) {
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_ALL_QUESTIONS + "" + personId)
    }
    saveUserAnswers(UserAnswerDTO) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json;');
        let url = APP_CONSTANTS.REST_API_URL.SAVE_ASSESMENT_USER_ANSWERS;
        return this.http.post(url, UserAnswerDTO, { headers: headers, responseType: 'text', observe: 'response' })
    }
    submitUserAnswers(personId) {
        let url = APP_CONSTANTS.REST_API_URL.SUBMIT_USER_ANSWERS+personId;
        return this.http.post(url,{})
    }
    submitEHR(personId) {
        let url = APP_CONSTANTS.REST_API_URL.SUBMIT_EHR+personId;
        return this.http.post(url,{})
    }
    
}