<nb-card [ngClass]="{'gaintcard':viewType==1,'smallcard':viewType==2,'mediumcard':viewType==3,'mediumLarge':viewType==4}" [nbSpinner]="loading" nbSpinnerStatus="primary" nbSpinnerSize="giant"
    nbSpinnerMessage="Loading">
    <nb-card-header style="text-align: center;">{{title}} Doctor Appointment</nb-card-header>
    <nb-card-body [ngClass]="{'setMargin':applyStyles}">
        <div class="card-block">
            <form [formGroup]="scheduleForm">
                <div class="form-group ">
                    <label class="form-control-label" for="medicalhistory">Chief Complaint</label>
                    <input class="form-control" type="text" [class.is-invalid]="pdf.topic.invalid && pdf.topic.touched"
                    
                        [ngClass]="{ 'is-invalid': submitted && pdf.topic.errors }" formControlName="topic"
                        [readonly]="reschedule">
                    <div *ngIf="pdf.topic.errors && pdf.topic.invalid && pdf.topic.touched">
                        <small class="text-danger" *ngIf="pdf.topic.errors.required">Chief Complaint required</small>
                        <small class="text-danger" *ngIf="pdf.topic.errors.pattern">Alphabets with space is allowed</small>
                    </div>
                    <div class="form-control-position">
                        <i class="feather ft-user"></i>
                    </div>
                </div>

                <div class="form-group" *ngIf="show && appointmentType!=1">
                    <label class="form-control-label" for="bloodgroup">Doctor Type</label>
                    <nb-select fullWidth placeholder="Doctor Type" formControlName="doctortype" [disabled]="reschedule">

                        <nb-option *ngFor="let doctortype of doctortypeList" [value]="doctortype">
                            {{doctortype}}
                        </nb-option>
                    </nb-select>
                </div>
                <div class="form-group" formArrayName="doctorsDTOList" *ngIf="show">
                    <label>Doctor</label>
                    <div class="row" *ngFor="let docForm of doctorDetailsFormGroup.controls; let i = index;">
                        <div [formGroupName]="i" class="col-md-10">
                            <div *ngIf="title=='ReSchedule'">
                                <nb-select fullWidth placeholder="Select Your Doctor" formControlName="doctor"
                                    [ngClass]="{ 'is-invalid': submitted }" [disabled]="reschedule">
                                    <nb-option *ngFor="let doctor of doctorsList" [value]="doctor">
                                        <span>{{doctor.username}}&nbsp;&nbsp;</span> |&nbsp;&nbsp;
                                        <small>Speciality: {{doctor.practicing}} &nbsp;&nbsp;</small>
                                        <small *ngIf="appointmentType!=1" >{{ doctor.doctorType != null ? " | "
                                            +doctor.doctorType+"("+doctor.feeAmount+")" : "" }} </small>
                                    </nb-option>
                                </nb-select>
                            </div>
                            <div *ngIf="title!='ReSchedule'">
                                <nb-select fullWidth placeholder="Select Your Doctor" formControlName="doctor"
                                    [ngClass]="{ 'is-invalid': submitted }" [disabled]="reschedule">

                                    <nb-option *ngFor="let doctor of doctorsList | sortBy:true:'asc':'username'"
                                        [value]="doctor">
                                        <span>{{doctor.username}}&nbsp;&nbsp;</span> |&nbsp;&nbsp;
                                        <small>Speciality: {{doctor.practicing}} &nbsp;&nbsp;</small>
                                        <small *ngIf="appointmentType!=1" >{{ doctor.doctorType != null ? " | "
                                            +doctor.doctorType+"("+doctor.feeAmount+")" : "" }} </small>
                                    </nb-option>
                                </nb-select>
                            </div>
                        </div>
                        <div class="col-md-2" *ngIf="i!=0">
                            <button [disabled]="reschedule" nbButton status="primary" type="button"
                                (click)="removeDoctor(i)"><i class="fa fa-close"></i></button>
                        </div>
                        <div class="col-md-2" *ngIf="i==0">
                            <button [disabled]="reschedule" nbButton status="primary" type="button"
                                (click)="addDoctor()"><i class="fa fa-plus"></i></button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-4">
                        <label class="form-control-label" for="date">Date</label>
                        <input readonly fullWidth nbInput formControlName="date" [max]="stopDate"
                            [nbDatepicker]="dateTimePicker"
                            [ngClass]=" { 'is-invalid': submitted && pdf.date.errors } ">
                        <nb-datepicker #dateTimePicker [min]="minDate"></nb-datepicker>
                        <div *ngIf="pdf.date.errors && pdf.date.invalid && pdf.date.touched">
                            <small class="text-danger" *ngIf="pdf.date.errors.required">Date is required,</small><small
                                class="text-danger" *ngIf="pdf.date.errors.incorrect">Date is invalid (range:
                                {{currentDate| date:'yyyy-MM-dd'}} - {{stopDate| date:'yyyy-MM-dd'}})</small>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="form-group ">
                            <label>Time </label><br>
                            <div class="row">
                                <div class="col-12" *ngIf="(doctorsSlots | async)?.length==0">
                                    No Slots Available
                                </div>
                                <nb-radio-group formControlName="from_time" style="display:contents" *ngIf="(doctorsSlots | async)?.length!=0">
                                    <nb-radio *ngFor="let option of doctorsSlots| async" [value]="option">
                                        {{option.startTime | date:'hh:mm a'}}
                                    </nb-radio>
                                </nb-radio-group>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="totalFee>0">
                    <div class="col-12" *ngIf="!showError">
                        Consultaion Fee: {{totalFee}}
                    </div>
                    <div class="col-12 text-danger" *ngIf="showError">
                        Please remove the duplicate doctor and proceed
                    </div>
                </div>
                <div style="text-align: center;margin-top: 10px; ">
                    <button type="button " nbButton status="primary" (click)="goBack() "
                        style="margin-right: 20px;margin-top:10px;">Cancel</button>
                    <button type="button " nbButton status="primary" style="margin-top:10px;" [disabled]="!scheduleForm.valid || showError || loading"
                        (click)="onSubmit($event) ">{{title}} Appointment</button>
                </div>

            </form>
        </div>
    </nb-card-body>
</nb-card>