<nb-card class="gaintcard" [nbSpinner]="loading" nbSpinnerStatus="primary" nbSpinnerSize="giant" nbSpinnerMessage="Loading" style="margin-left: auto; margin-right: auto; ">
    <nb-card-header>
        Upload Reports
      </nb-card-header>
    <nb-card-body >
        <form [formGroup]="reportsForm">

            <div class="form-group">
              <label class="form-control-label"
              for="reportName">Report Name:</label>
              <input class="form-control" type="text" maxlength="255"
                  [class.is-invalid]="pdf.reportName.invalid && pdf.reportName.touched" required
                  formControlName="reportName" />
              <div *ngIf="pdf.reportName.errors && pdf.reportName.invalid && pdf.reportName.touched">
                  <small class="text-danger" *ngIf="pdf.reportName.errors.required">Report Name is required</small>
              </div>
          </div>
          <div class="form-group">
            <label class="form-control-label" for="reportCategory">Report Category</label>
            <nb-select fullWidth formControlName="reportCategory" [ngClass]="{ 'is-invalid': submitted && pdf.reportCategory.errors }"
              id="reportCategory" required>
              <nb-option [value]="">Select</nb-option>
              <nb-option [value]="'prescription'">Prescription</nb-option>
              <nb-option [value]="'labReports'">Lab Reports</nb-option>
              <nb-option [value]="'other'">Others</nb-option>
            </nb-select>
            <div *ngIf="pdf.reportCategory.errors && pdf.reportCategory.invalid && pdf.reportCategory.touched" class="invalid-feedback">
              <div *ngIf="pdf.reportCategory.errors.required">category is required</div>
            </div>
          </div>
          <div class="form-group">
              <label class="form-control-label" for="measureTime">Report Date:</label>
              <div class="input-group">

                <input  fullWidth nbInput readonly [class.is-invalid]="pdf.measureTime.errors && pdf.measureTime.invalid && pdf.measureTime.touched" formControlName="measureTime" [nbDatepicker]="dateTimePicker"  
                 ngbTooltip="Report Date" placeholder="DD/MM/YYYY" required [nbDatepicker]="dateTimePicker">
              <nb-datepicker #dateTimePicker  [max]="tomorrow"></nb-datepicker>  
              </div>
            <div *ngIf="pdf.measureTime.errors && pdf.measureTime.invalid && pdf.measureTime.touched">
                <small class="text-danger" *ngIf="pdf.measureTime.errors.required">Report Date is required</small>
            </div>
          </div>

            <div style="margin: 5px;">
              <label class="label-control" style="margin-right:20px;" for="file">Upload Report</label>
              <input type="file" id="file" accept="image/jpg, image/jpeg, image/png,application/pdf" (change)="handleFileInput($event.target.files)">
              <img src="{{report}}" width="100px" height="100px" *ngIf="report != '' && !report.includes('data:application/pdf')" />    
          </div>

          </form>

          <div class="card-body" style="text-align: center;margin: 10px;">
            <button style="margin-left: 0px;"
              nbButton status="primary" (click)="onSubmit()" [disabled]="reportsForm.invalid">
              Submit
            </button>

        </div>

    </nb-card-body>
    <nb-card-footer>

        

    </nb-card-footer>

</nb-card>