import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component,Input, OnInit,ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { APP_CONSTANTS } from '../../constants';
import { ScheduleService } from '../../services/schedule.service';
import { UnsubscribeOnDestroyAdapter } from '../../services/UnsubscribeOnDestroyadapter';

export class CodeableConcept {
  coding: string;
  text: string;
}

export class Period {
  start: string;
  end: string;
}

export class Identifier {
  use: string; // usual | official | temp | secondary | old (If known)
  type: CodeableConcept // Description of identifier
  system: string; // The namespace for the identifier value
  value: string; // The value that is unique
  period: Period; // Time period when id is/was valid for use
  assigner: string; // Organization that issued id (may be just text)
}

export class Qualification {
  identifier: Identifier;
  code: CodeableConcept
  period: Period;
  issuer: string;
}

export class HumanName {
  use: string;
  text: string
  family: string;
  given: string;
  prefix: string;
  suffix: string;
  period: Period
}

export class ContactPoint {
  use: string;
  rank: Number
  system: string;
  value: string;
  period: Period;
}

export class Attachment {
  contentType: string; // Mime type of the content, with charset etc.
  language: string; // Human language of the content (BCP-47)
  data: string; // Data inline, base64ed
  url: string; // Uri where the data can be found
  size: Number // Number of bytes of content (if url provided)
  hash: string; // Hash of the data (sha-1, base64ed)
  title: string; // Label to display in place of the data
  creation: string; // Date attachment was first created
}

export class Address {
  use: string; // home | work | temp | old | billing - purpose of this address
  type: string;  // postal | physical | both
  text: string;  // Text representation of the address
  line: string;  // Street name, number, direction & P.O. Box etc.
  city: string;  // Name of city, town etc.
  district: string;  // District name (aka county)
  state: string;  // Sub-unit of country (abbreviations ok)
  postalCode: string;  // Postal code for area
  country: string;  // Country (e.g. can be ISO 3166 2 or 3 letter code)
  period: Period// Time period when address was/is in use

}

export class timevalues {
  startTime:string;
  endTime:string;
}

export class UpdateSlot
{
  startTime:string;
  endTime:string;
  doctorId:number;
  doctorName:string;
  duration:string;
  availableDate:string;
}



declare var require: any;
const dayjs = require("dayjs");
@Component({
  templateUrl: './availableblockstatus.component.html',
  styleUrls: ['./availableblockstatus.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvailableBlockStatusComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  public updatedtimelist:Array<UpdateSlot>=[];
  @Input() doctorname: any;
  @Input() doctorId: any;
  @Input() timeList:Array<timevalues>=[];
  @Input() date:string;
  startDate:Date=new Date();
  Day:string;
  loading:boolean=false;
  personaldetailsForm: FormGroup;
  blockdetailsForm: FormGroup;
  employmentdetailsForm: FormGroup;
  returnUrl: string;
  error = '';
  availableDate:string;
 
  showdata:boolean=true;

  @Input() max: any;
  tomorrow = new Date();
  duration=[{key:"15",name:"15 Min"},
  {key:"30",name:"30 Min"},
  {key:"45",name:"45 Min"},
  {key:"60",name:"60 Min"}]
  public timeSlotList: FormArray;
  public timeSlotList1: FormArray;
  viewType: number;
  constructor(private toastrService: NbToastrService,private dialogRef:NbDialogRef<AvailableBlockStatusComponent>,private formBuilder: FormBuilder,
    private apiService: ScheduleService) {
      super();
    const datepipe1: DatePipe = new DatePipe('en-US');
  }


  get pdf() {
    return this.personaldetailsForm.controls;
  }

  get pdf1() {
    return this.blockdetailsForm.controls;
  }

  get getTimeSlotFormrGoup() {
    return this.personaldetailsForm.get('timeSlotDTOList') as FormArray;
  }

  get getTimeSlotFormrGoup1() {
    return this.blockdetailsForm.get('timeSlotDTOList1') as FormArray;
  }

  getValidity(i) {
    return (<FormArray>this.personaldetailsForm.get('timeSlotDTOList')).controls[i].invalid;
  }

  getValidity1(i) {
    return (<FormArray>this.blockdetailsForm.get('timeSlotDTOList1')).controls[i].invalid;
  }

  addTimeSlots() {
    if(this.showdata==false)
    {
      this.showdata=true;
    }
    else
    {
      this.timeSlotList.push(this.createTimeSlots());
    }
  }

  addTimeSlots1() {
    this.timeSlotList1.push(this.createTimeSlots1());
  }

  removeTimeSlots(index) {
    this.timeSlotList.removeAt(index);
  }

  removeTimeSlots1(index) {
    this.timeSlotList1.removeAt(index);
  }

  createEducation(): FormGroup {
    return this.formBuilder.group({
      id: [0],
      courseName: ['', Validators.required],
      university: ['', Validators.required],
      completionyear: ['', Validators.required],
    });
  }
  createAppointment(): FormGroup {
    return this.formBuilder.group({
      id: [0],
      availabilitySlotDuration: ['', Validators.required],
      availabilitySlotDays: [[], Validators.required],
      timeSlotDTOList: this.formBuilder.array([this.createTimeSlots()]),
    });
  }
  createTimeSlots(): FormGroup {
    return this.formBuilder.group({
      id: [0],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
    });
  }
  createTimeSlots1(): FormGroup {
    return this.formBuilder.group({
      id: [0],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
    });
  }
  namePressAlpha(event) {
    var inp = String.fromCharCode(event.nameCode);
    if (/[a-zA-Z]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  multipleSelectArray = [
    {
      "item_id": 1, "item_text": "Monday"
    },
    {
      "item_id": 2, "item_text": "Tuesday"
    },
    {
      "item_id": 3, "item_text": "Wednesday"
    },
    {
      "item_id": 4, "item_text": "Thursday"
    },
    {
      "item_id": 5, "item_text": "Friday"
    },
    {
      "item_id": 6, "item_text": "Saturday"
    },
    {
      "item_id": 0, "item_text": "Sunday"
    }
  ];
  multipleSelectArray1 = [
   "MONDAY","TUESDAY","WEDNESDAY","THURSDAY","FRIDAY","SATURDAY","SUNDAY"
  ];
  timeSlots=APP_CONSTANTS.TIME_SLOTS;
  multipleMultiSelect = []


  ngOnInit() {
    if (window.innerWidth >= 1200) {
      this.viewType = 1;
    } else if (window.innerWidth < 1200 && window.innerWidth >= 960) {
      this.viewType = 4;
    } else if (window.innerWidth < 960 && window.innerWidth >= 768) {
      this.viewType = 3;
    } else if (window.innerWidth < 480) {
      this.viewType = 2;
    }
    this.personaldetailsForm = this.formBuilder.group({
      timeSlotDTOList: this.formBuilder.array([this.createTimeSlots()]),
    });
    // this.blockdetailsForm=this.formBuilder.group({
    //   timeSlotDTOList1: this.formBuilder.array([this.createTimeSlots1()]),
    // });
    this.timeSlotList = this.personaldetailsForm.get('timeSlotDTOList') as FormArray;
    //this.timeSlotList1 = this.blockdetailsForm.get('timeSlotDTOList1') as FormArray;
    //this.loadUserDetails();
    if(this.timeList.length==0)
    {
      this.showdata=false;
    }
    else
    {
      this.showdata=true;
    }
    for(var i=0;i<this.timeList.length-1;i++)
    {
        this.addTimeSlots();
    }
    this.personaldetailsForm.patchValue({
      timeSlotDTOList:this.timeList
    });
  }
  checkRange(timeSlots){
    var flag=true;
      if(timeSlots.length>0){
          for(var i=0;i<timeSlots.length;i++){
            for(var j=0;j<=i;j++){
              if(i!=j){
                var startTimeArr1=timeSlots[i].startTime.split(":");
                var startTimeArr2=timeSlots[j].startTime.split(":");
                var endTimeArr1=timeSlots[i].endTime.split(":");
                var endTimeArr2=timeSlots[j].endTime.split(":");
                var date=new Date();
                var StartDate1=date.setHours(startTimeArr1[0],startTimeArr1[1],startTimeArr1[2]);
                var StartDate2=date.setHours(startTimeArr2[0],startTimeArr2[1],startTimeArr2[2]);
                var EndDate1=date.setHours(endTimeArr1[0],endTimeArr1[1],endTimeArr1[2]);
                var EndDate2=date.setHours(endTimeArr2[0],endTimeArr2[1],endTimeArr2[2]);
                if((StartDate1 < EndDate2) && (StartDate2 < EndDate1)) {
                  //overlapping dates
                  this.toastrService.show('', "Time Slots Overlapping. Please Check", { duration: 2000 });
                  this.loading = false;
                  flag=false;
                }
                             
              console.log(i +":"+j);
              }
            }
          }
      }
      return flag;
  }
  onSubmit() {
    const datepipe1: DatePipe = new DatePipe('en-US');
    this.loading=true;
    var flag=true;
    var temp=this.personaldetailsForm.get('timeSlotDTOList').value;
    for(var i=0;i<temp.length;i++)
    {
      this.availableDate=datepipe1.transform(this.date,'yyyy-MM-dd');
      var start=new Date(datepipe1.transform(this.availableDate+" "+temp[i].startTime,'yyyy-MM-dd hh:mm:ss a'));
      var end=new Date(datepipe1.transform(this.availableDate+" "+temp[i].endTime,'yyyy-MM-dd hh:mm:ss a'));
      if(start>=end)
      {
        this.loading = false;
        this.toastrService.show('','Please enter Valid Time slots.',{duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
        return;
      }
    }
    if(!this.checkRange(temp)){
      flag=false;
    }
    if(!flag){
      return;
    }
    var data=this.personaldetailsForm.get('timeSlotDTOList').value;
    
    this.availableDate=datepipe1.transform(this.date,'yyyy-MM-dd 00:00:00');
    for(var i=0;i<data.length;i++)
    {
      this.updatedtimelist.push({startTime:data[i].startTime,endTime:data[i].endTime,doctorId:this.doctorId,doctorName:this.doctorname,duration:"15",availableDate:this.availableDate});
    }
    this.subs.sink=this.apiService.updateUserSlotDetails(this.updatedtimelist,this.doctorId,this.date).subscribe((resData: any) => {
      this.loading = false;
      this.dialogRef.close('success')
    }, error => {
      var data = error;
      this.loading = false;
    });
  }




  getTimeSlots(i){
    var slot=(<FormArray>this.personaldetailsForm.get('appointmentsDTOList')).controls[i].get('availabilitySlotDuration');
    // var time=new Date(8);
    // if(slot!=''){

    // }
    var arr = [], i, j;
    // for(i=8; i<23; i++) {
    //   for(j=0; j<2; j++) {
    //     var format;
    //     if(i<12){
    //       format=" AM"
    //     }else{
    //       format=" PM"
    //     }
    //     var hr;
    //     if(i<=12){
    //       hr=i;
    //     }else{
    //       hr=i-12;
    //     }
    //     arr.push({name:i + ":" + (j===0 ? "00" : 30*j) +":00",name:hr + ":" + (j===0 ? "00" : 30*j)+format});
    //   }
    // }
    // arr.push({name:"abc",name:"test"})
    return arr;

  }

  goBack() {
    this.dialogRef.close('cancel');
  }

  loadUserDetails() {


    this.subs.sink=this.apiService.getDoctorDetails(this.doctorname).subscribe((resData: any) => {
      let userData = resData;


    }, error => {
      var data = error;
    });
  }
}
