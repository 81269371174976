import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers, metaReducers } from './store/root.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AppConfigEffects } from './store/effects/app-config.effects';
import { ComponentsModule } from './components/components.module';
import { CommonModuleModule } from './common-module/common-module.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { DashboardEffects } from './store/effects/home.effects';
import { DoctorDashboardEffects } from './store/effects/doctor-dashboard.effects';
import { PaymentsRefundsComponent } from './adminportal/payments-refunds/payments-refunds.component';
import { CommonModule } from '@angular/common';
import { AdminDashboardEffects } from './store/effects/admin-home.effects';
import { CustProfileEffects } from './store/effects/cust-profile.effects';
import { adminPreScreeningEffects } from './store/effects/admin-prescreening.effect';


@NgModule({
  declarations: [
    AppComponent,
    PaymentsRefundsComponent
    ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([AppConfigEffects,DashboardEffects,DoctorDashboardEffects,AdminDashboardEffects,CustProfileEffects,adminPreScreeningEffects
    ]),
    CommonModuleModule.forRoot(),
    SharedModule.forRoot(),
    ComponentsModule.forRoot(),
    

  ],
  providers: [
  
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
