import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP_CONSTANTS } from 'src/app/shared/constants';
@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private http: HttpClient) { }


  getAllAreaOfPractice(): any[] {
    return ["Accupunture"
      , "Addiction Medicine"
      , "ADHD & Autism"
      , "Adolescent Medicine"
      , "Adult Cardiac Electrophysiology"
      , "Adult Echocardiography"
      , "Aerospace Medicine"
      , "Aesthetic Medicine"
      , "Allergology"
      , "Allergy and Immunology"
      , "Alternative Medicine"
      , "Anatomical Pathology"
      , "Anatomy"
      , "Andrology"
      , "Anesthesiology"
      , "Anesthesiology - Critical Care Medicine"
      , "Anesthesiology - Hospice and Palliative Medicine"
      , "Anesthesiology - Pain Medicine"
      , "Anesthesiology - Pediatric Anesthesiology"
      , "Anesthesiology - Sleep Medicine"
      , "Asthma Expert"
      , "Audiology"
      , "Audiovestibular Medicine"
      , "Aviation Medicine"
      , "Ayurvedic Medicine"
      , "Bariatrics"
      , "Bio-Chemistry"
      , "Bio-Physics"
      , "Breast Surgery"
      , "Cardiology"
      , "Cardiology - Cardiac Electrophysiology"
      , "Cardiovascular And Pulmonary Physiotherapy"
      , "Cardiovascular medicine"
      , "Child and Adolescent Psychiatry and Psychotherapy"
      , "Clinical Biochemical Genetics"
      , "Clinical Cytogenetics"
      , "Clinical Genetics"
      , "Clinical Haematology"
      , "Clinical Informatics"
      , "Clinical Molecular Genetics"
      , "Clinical Neurophysiology"
      , "Clinical Pharmacology"
      , "Clinical pharmacology and Therapeutics"
      , "Clinical Psychology"
      , "Clinician Educator"
      , "Clinician Investigator Program"
      , "Colon and Rectal Surgery"
      , "Community Child Health"
      , "Community Medicine"
      , "Cosmetology"
      , "Critical Care"
      , "Cytopathology"
      , "Dentistry"
      , "Dentistry - Community Dentistry"
      , "Dentistry - Conservative Dentistry"
      , "Dentistry - Cosmetic"
      , "Dentistry - Endodontics"
      , "Dentistry - Oral Medicine"
      , "Dentistry - Oral Pathology"
      , "Dentistry - Oral Surgery"
      , "Dentistry - Orthodontics"
      , "Dentistry - Pediatric"
      , "Dentistry - Pedodontics"
      , "Dentistry - Periodontics"
      , "Dentistry - Prosthodontics"
      , "Dentofacial Orthopedic"
      , "Dermatology"
      , "Dermatology & Venerology"
      , "Dermatology - Dermatopathology"
      , "Dermatology - Pediatric Dermatology"
      , "Dermatology, Venerology & Leprosy"
      , "Dermatopathology"
      , "Dermatosurgeon"
      , "Developmental Pediatrics"
      , "Diabetology"
      , "Diagnostic Radiology"
      , "Diet & Nutrition"
      , "Embroyology"
      , "Emergency Medicine"
      , "Emergency Medicine - Emergency Medical Services2"
      , "Emergency Medicine - Hospice and Palliative Medicine"
      , "Emergency Medicine - Internal Medicine-Critical Care Medicine"
      , "Emergency Medicine - Medical Toxicology"
      , "Emergency Medicine - Pediatrics"
      , "Emergency Medicine - Sports Medicine"
      , "Emergency Medicine - Undersea and Hyperbaric Medicine"
      , "Endocrinology"
      , "Endocrinology and Diabetes Mellitus"
      , "Endocrinology and Metabolism"
      , "ENT"
      , "ENT - Head & Neck Surgery"
      , "ENT - Head & Neck Surgery - Pediatric"
      , "Environmental Health"
      , "Family Medicine"
      , "Family Medicine - Adolescent Medicine"
      , "Family Medicine - Geriatric Medicine"
      , "Family Medicine - Hospice and Palliative Medicine"
      , "Family Medicine - Sleep Medicine"
      , "Family Medicine - Sports Medicine"
      , "Fertility Medicine"
      , "Forensic Medicine"
      , "Gastroenterology"
      , "General Physician"
      , "General Practice"
      , "Genito-urinary Medicine"
      , "Geriatrics"
      , "GI Surgery"
      , "Gynecologic Reproductive Endocrinology and Infertility"
      , "Gynecology"
      , "Gynecology - Oncology"
      , "Hair Restoration"
      , "Head Pain"
      , "Health Administration"
      , "Hearing & Speech"
      , "Heart Failure/Transplant"
      , "HIV Medicine"
      , "Holistic Medicine"
      , "HomeoPathy"
      , "Hospital Administration"
      , "Hypnotherapy"
      , "Implantology"
      , "Infertility"
      , "Intensive Care Medicine"
      , "Internal Medicine"
      , "Internal Medicine - Adolescent Medicine"
      , "Internal Medicine - Adult Congenital Heart Disease3"
      , "Internal Medicine - Advanced Heart Failure and Transplant"
      , "Internal Medicine - Allergy"
      , "Internal Medicine - Allergy & Immunology"
      , "Internal Medicine - Angiology"
      , "Internal Medicine - Cardiology"
      , "Internal Medicine - Cardiovascular Disease"
      , "Internal Medicine - Clinical Cardiac Electrophysiology"
      , "Internal Medicine - Critical Care Medicine"
      , "Internal Medicine - Diabetology"
      , "Internal Medicine - Endocrinology, Diabetes and Metabolism"
      , "Internal Medicine - Gastroenterology"
      , "Internal Medicine - Geriatric Medicine"
      , "Internal Medicine - Hematology"
      , "Internal Medicine - Hematology & Oncology"
      , "Internal Medicine - Hepatology"
      , "Internal Medicine - Hospice and Palliative Medicine"
      , "Internal Medicine - Immunology"
      , "Internal Medicine - Infectious Disease"
      , "Internal Medicine - Interventional Cardiology"
      , "Internal Medicine - Medical Oncology"
      , "Internal Medicine - Nephrology"
      , "Internal Medicine - Nephrology & Dialysis"
      , "Internal Medicine - Obstretic Medicine"
      , "Internal Medicine - Pulmonary Disease"
      , "Internal Medicine - Pulmonology"
      , "Internal Medicine - Rheumatology"
      , "Internal Medicine - Sleep Medicine"
      , "Internal Medicine - Sports Medicine"
      , "Internal Medicine - Transplant Hepatology"
      , "Interventional Cardiology"
      , "Interventional Radiology and Diagnostic Radiology"
      , "IVF Specialist"
      , "Lab Medicine"
      , "Laparoscopy"
      , "Laproscopic Surgery"
      , "Laryngology"
      , "Laser & Skin"
      , "Marital Therapy"
      , "Maternal-Fetal Medicine"
      , "Medical Administration"
      , "Medical Biochemical Genetics"
      , "Medical Biochemistry"
      , "Medical Gastroenterology"
      , "Medical Genetics"
      , "Medical Microbiology"
      , "Medical Oncology"
      , "Medical Physics"
      , "Medical Toxicology"
      , "Microbiology"
      , "Molecular Genetic Pathology"
      , "Natural Cure"
      , "Naturopathy"
      , "Neonatal-Perinatal Medicine"
      , "Neonatology"
      , "Nephrology"
      , "Neuro Rehablitation"
      , "Neuro-Psychiatry"
      , "Neuro-Psychology"
      , "Neurological Surgery"
      , "Neurology"
      , "Neurology - Brain Injury Medicine"
      , "Neurology - Child Neurology"
      , "Neurology - Clinical Neurophysiology"
      , "Neurology - Epilepsy"
      , "Neurology - Hospice and Palliative Medicine"
      , "Neurology - Neurodevelopmental Disabilities"
      , "Neurology - Neuromuscular Medicine"
      , "Neurology - Pain Medicine"
      , "Neurology - Radiology"
      , "Neurology - Sleep Medicine"
      , "Neurology - Vascular Neurology"
      , "Neuropathology"
      , "Nuclear Medicine"
      , "Nuclear Radiology"
      , "Nutrition"
      , "Nutritionist - Sports"
      , "Nutritionist - Sports Medicine"
      , "Obesity & Weight Management"
      , "Obstetrics and Gynecology"
      , "Obstetrics and Gynecology - Critical Care Medicine"
      , "Obstetrics and Gynecology - Female Pelvic Medicine and"
      , "Obstetrics and Gynecology - Gynecologic Oncology"
      , "Obstetrics and Gynecology - Hospice and Palliative Medicine"
      , "Obstetrics and Gynecology - Maternal and Fetal Medicine"
      , "Obstetrics and Gynecology - Obstetrics and Gynaecological Ultrasound"
      , "Obstetrics and Gynecology - Reconstructive Surgery"
      , "Obstetrics and Gynecology - Reproductive Endocrinology/Infertility"
      , "Obstetrics and Gynecology - Urogynecology"
      , "Occupational and Environmental Medicine"
      , "Occupational Medicine"
      , "Occupational Therapist"
      , "Oncology"
      , "Ophthalmology"
      , "Ophthalmology - LASIK Surgery"
      , "Ophthalmology - Pediatric"
      , "Ophthalmology - Retinal Surgery"
      , "Opticians"
      , "Optometry"
      , "Oral and Maxillofacial Surgery"
      , "Oral Medicine and Radiology1"
      , "Orthopaedic"
      , "Orthopaedic Surgery"
      , "Orthopaedic Surgery - Foot & Ankle"
      , "Orthopaedic Surgery - Orthopaedic Sports Medicine"
      , "Orthopaedic Surgery - Pediatric"
      , "Orthopaedic Surgery - Reconstruction"
      , "Orthopaedic Surgery - Spine"
      , "Orthopaedic Surgery - Surgery of the Hand"
      , "Otolaryngology"
      , "Otolaryngology - Neurotology"
      , "Otolaryngology - Pediatric Otolaryngology"
      , "Otolaryngology - Plastic Surgery Within the Head and Neck"
      , "Otolaryngology - Sleep Medicine"
      , "Otorhinolaryngology"
      , "Pain Management"
      , "Pain Medicine"
      , "Palliative Care"
      , "PathoClinical Informatics"
      , "Pathology"
      , "Pathology - Anatomic"
      , "Pathology - Anatomic/Pathology-Clinical"
      , "Pathology - Blood Banking/Transfusion Medicine"
      , "Pathology - Chemical"
      , "Pathology - Clinical Cytogenetics & Molecular Genetics"
      , "Pathology - Clinical Informatics"
      , "Pathology - Forensic"
      , "Pathology - Hematology"
      , "Pathology - Histopathology"
      , "Pathology - Immunology"
      , "Pathology - Medical Microbiology"
      , "Pathology - Medical Microbiology and Virology"
      , "Pathology - Microbiology"
      , "Pathology - Molecular Genetic"
      , "Pathology - Pediatric"
      , "Pediatric Radiology"
      , "Pediatric Urology"
      , "Pediatrics"
      , "Pediatrics - Adolescent Medicine"
      , "Pediatrics - Allergy"
      , "Pediatrics - Allergy & Asthma"
      , "Pediatrics - Cardiology"
      , "Pediatrics - Child Abuse Pediatrics"
      , "Pediatrics - Clinical Genetics"
      , "Pediatrics - Clinical Pharmacology"
      , "Pediatrics - Community Child Health"
      , "Pediatrics - Critical Care Medicine"
      , "Pediatrics - Developmental-Behavioral Pediatrics"
      , "Pediatrics - Emergency Medicine"
      , "Pediatrics - Endocrinology"
      , "Pediatrics - Gastroenterology"
      , "Pediatrics - Gastroenterology and Hepatology"
      , "Pediatrics - General Medicine"
      , "Pediatrics - General Paediatrics"
      , "Pediatrics - Geriatric Medicine"
      , "Pediatrics - Haematology"
      , "Pediatrics - Hematology-Oncology"
      , "Pediatrics - Hospice and Palliative Medicine"
      , "Pediatrics - Immunology and Allergy"
      , "Pediatrics - Infectious Diseases"
      , "Pediatrics - Intensive Care Medicine"
      , "Pediatrics - Medical Oncology"
      , "Pediatrics - Medical Toxicology"
      , "Pediatrics - Neonatal-Perinatal Medicine"
      , "Pediatrics - Nephrology"
      , "Pediatrics - Nephrology & Dialysis"
      , "Pediatrics - Neurodevelopmental Disabilities"
      , "Pediatrics - Neurology"
      , "Pediatrics - Nuclear Medicine"
      , "Pediatrics - Palliative Medicine"
      , "Pediatrics - Pulmonology"
      , "Pediatrics - Respiratory and Sleep Medicine"
      , "Pediatrics - Rheumatology"
      , "Pediatrics - Sleep Medicine"
      , "Pediatrics - Sports Medicine"
      , "Pediatrics - Transplant Hepatology"
      , "Pharmaceutical Medicine"
      , "Pharmacology"
      , "Phlebology"
      , "Physical Medicine and Rehabilitation"
      , "Physical Medicine and Rehabilitation - Brain Injury Medicine"
      , "Physical Medicine and Rehabilitation - Hospice and Palliative Medicine"
      , "Physical Medicine and Rehabilitation - Neuromuscular Medicine"
      , "Physical Medicine and Rehabilitation - Pain Medicine"
      , "Physical Medicine and Rehabilitation - Pediatric Rehabilitation Medicine"
      , "Physical Medicine and Rehabilitation - Spinal Cord Injury Medicine"
      , "Physical Medicine and Rehabilitation - Sports Medicine"
      , "Physician Assistant"
      , "Physiology"
      , "Physiotherapy"
      , "Physiotherapy - Geriatric"
      , "Physiotherapy - Pediatric"
      , "Physiotherapy - Sports and Musculoskeletal"
      , "Plastic Reconstructive and Aesthetic Surgery"
      , "Plastic Surgery"
      , "Plastic Surgery - Hand"
      , "Plastic Surgery - Head and Neck"
      , "Podiatry"
      , "Preventive medicine"
      , "Proctology"
      , "Psychiatry"
      , "Psychiatry - Addiction"
      , "Psychiatry - Brain Injury Medicine"
      , "Psychiatry - Child and Adolescent"
      , "Psychiatry - Clinical Neurophysiology"
      , "Psychiatry - Forensic"
      , "Psychiatry - General"
      , "Psychiatry - Geriatric"
      , "Psychiatry - Hospice and Palliative Medicine"
      , "Psychiatry - Learning disability"
      , "Psychiatry - Old age psychiatry"
      , "Psychiatry - Pain Medicine"
      , "Psychiatry - Psychosomatic Medicine"
      , "Psychiatry - Psychotherapy"
      , "Psychiatry - Sleep Medicine"
      , "Psychology"
      , "Public Health and General Preventive Medicine"
      , "Public Health Medicine"
      , "Pulmonary Medicine"
      , "Pulmonology"
      , "Radio Diagnosis"
      , "Radiology"
      , "Radiology - Diagnostic Radiology"
      , "Radiology - Diagnostic Ultrasound"
      , "Radiology - Medical Ultrasonography"
      , "Radiology - Neuroradiology"
      , "Radiology - Nuclear Medicine"
      , "Radiology - Radiation Oncology"
      , "Radiotherapy"
      , "Rehabilitation Medicine"
      , "Renal Medicine"
      , "Respiratory medicine"
      , "Respirology"
      , "Rheumatology"
      , "Rhinology"
      , "Sexology"
      , "Sexual Health Medicine"
      , "Siddha"
      , "Social & Preventive Medicine"
      , "Spa Therapy"
      , "Spine Pain Management"
      , "Sport & Exercise Medicine"
      , "Sugery - Botox"
      , "Surgery"
      , "Surgery - Cardiac"
      , "Surgery - Colon & Rectal"
      , "Surgery - Coloproctology"
      , "Surgery - Congenital Cardiac"
      , "Surgery - Coronary"
      , "Surgery - Critical Care"
      , "Surgery - Ear"
      , "Surgery - Endocrine"
      , "Surgery - ENT"
      , "Surgery - Gastro-Intestinal"
      , "Surgery - GastroIntestinal"
      , "Surgery - Hand"
      , "Surgery - Head & Neck"
      , "Surgery - Hepato-Pancreatico-Biliary"
      , "Surgery - Hospice and Palliative Medicine"
      , "Surgery - Neonatal"
      , "Surgery - Nose"
      , "Surgery - Oncology"
      , "Surgery - Oral & Maxillofacial"
      , "Surgery - Pediatric"
      , "Surgery - Plastics"
      , "Surgery - Thoracic"
      , "Surgery - Thoracic and Cardiac"
      , "Surgery - Transplant"
      , "Surgery - Trauma"
      , "Surgery - Urology"
      , "Surgery - Vascular"
      , "Surgical Foundations"
      , "Surgical Gasteroenterology"
      , "Toxicology"
      , "Transfusion Medicine"
      , "Travel Medicine"
      , "Trichology"
      , "Tuberclosis & Respiratory Diseases"
      , "Unani"
      , "Undersea and Hyperbaric Medicine"
      , "Urgent Care"
      , "Urology"
      , "Urology - Female Pelvic Medicine and"
      , "Urology - Oncology"
      , "Urology - Pediatric Urology"
      , "Urology - Reconstructive Surgery1"
      , "Vascular and Interventional Radiology"
      , "Venerology"
      , "Veterinary"
      , "Wellness"
      , "Wilderness Medicine"
      , "Yoga and Naturopathy"]
  }

  getCountries(): string[] {
    return ["Afghanistan"
      , "Albania"
      , "Algeria"
      , "American Samoa"
      , "Andorra"
      , "Angola"
      , "Anguilla"
      , "Antarctica"
      , "Antigua and Barbuda"
      , "Argentina"
      , "Armenia"
      , "Aruba"
      , "Australia"
      , "Austria"
      , "Azerbaijan"
      , "Bahamas"
      , "Bahrain"
      , "Bangladesh"
      , "Barbados"
      , "Belgium"
      , "Belize"
      , "Benin"
      , "Bermuda"
      , "Bhutan"
      , "Bolivia"
      , "Bosnia and Herzegovina"
      , "Botswana"
      , "Brazil"
      , "British Indian Ocean Territory"
      , "British Virgin Islands"
      , "Brunei"
      , "Bulgaria"
      , "Burkina Faso"
      , "Burundi"
      , "Cambodia"
      , "Cameroon"
      , "Canada"
      , "Cape Verde"
      , "Cayman Islands"
      , "Central African Republic"
      , "Chad"
      , "Chile"
      , "China"
      , "Christmas Island"
      , "Cocos (Keeling) Islands"
      , "Colombia"
      , "Comoros"
      , "Cook Islands"
      , "Costa Rica"
      , "Croatia"
      , "Cuba"
      , "Cyprus"
      , "Czech Republic"
      , "Denmark"
      , "Djibouti"
      , "Dominica"
      , "Dominican Republic"
      , "Ecuador"
      , "Egypt"
      , "El Salvador"
      , "Equatorial Guinea"
      , "Eritrea"
      , "Estonia"
      , "Ethiopia"
      , "Falkland Islands"
      , "Faroe Islands"
      , "Fiji"
      , "Finland"
      , "France"
      , "French Polynesia"
      , "Gabon"
      , "Gambia"
      , "Gaza Strip"
      , "Georgia"
      , "Germany"
      , "Ghana"
      , "Gibraltar"
      , "Greece"
      , "Greenland"
      , "Grenada"
      , "Guam"
      , "Guatemala"
      , "Guinea"
      , "Guinea-Bissau"
      , "Guyana"
      , "Haiti"
      , "Holy See (Vatican City)"
      , "Honduras"
      , "Hong Kong"
      , "Hungary"
      , "Iceland"
      , "India"
      , "Indonesia"
      , "Ireland"
      , "Isle of Man"
      , "Israel"
      , "Italy"
      , "Ivory Coast"
      , "Jamaica"
      , "Japan"
      , "Jersey"
      , "Jordan"
      , "Kazakhstan"
      , "Kenya"
      , "Kiribati"
      , "Kosovo"
      , "Kuwait"
      , "Kyrgyzstan"
      , "Laos"
      , "Latvia"
      , "Lebanon"
      , "Lesotho"
      , "Libya"
      , "Liechtenstein"
      , "Lithuania"
      , "Luxembourg"
      , "Macau"
      , "Macedonia"
      , "Madagascar"
      , "Malawi"
      , "Malaysia"
      , "Maldives"
      , "Mali"
      , "Malta"
      , "Marshall Islands"
      , "Mauritania"
      , "Mauritius"
      , "Mayotte"
      , "Mexico"
      , "Micronesia"
      , "Moldova"
      , "Monaco"
      , "Mongolia"
      , "Montenegro"
      , "Montserrat"
      , "Morocco"
      , "Mozambique"
      , "Namibia"
      , "Nauru"
      , "Nepal"
      , "Netherlands"
      , "Netherlands Antilles"
      , "New Caledonia"
      , "New Zealand"
      , "Nicaragua"
      , "Niger"
      , "Nigeria"
      , "Niue"
      , "Norfolk Island"
      , "Northern Mariana Islands"
      , "Norway"
      , "Oman"
      , "Pakistan"
      , "Palau"
      , "Panama"
      , "Papua New Guinea"
      , "Paraguay"
      , "Peru"
      , "Philippines"
      , "Pitcairn Islands"
      , "Poland"
      , "Portugal"
      , "Puerto Rico"
      , "Qatar"
      , "Romania"
      , "Russia"
      , "Rwanda"
      , "Saint Barthelemy"
      , "Saint Helena"
      , "Saint Kitts and Nevis"
      , "Saint Lucia"
      , "Saint Martin"
      , "Saint Pierre and Miquelon"
      , "Saint Vincent and the Grenadines"
      , "Samoa"
      , "San Marino"
      , "Sao Tome and Principe"
      , "Saudi Arabia"
      , "Senegal"
      , "Serbia"
      , "Seychelles"
      , "Singapore"
      , "Slovakia"
      , "Slovenia"
      , "Solomon Islands"
      , "Somalia"
      , "South Africa"
      , "South Korea"
      , "Spain"
      , "Sri Lanka"
      , "Suriname"
      , "Svalbard"
      , "Swaziland"
      , "Sweden"
      , "Switzerland"
      , "Taiwan"
      , "Tajikistan"
      , "Tanzania"
      , "Thailand"
      , "Timor-Leste"
      , "Togo"
      , "Tokelau"
      , "Tonga"
      , "Trinidad and Tobago"
      , "Tunisia"
      , "Turkey"
      , "Turkmenistan"
      , "Turks and Caicos Islands"
      , "Tuvalu"
      , "Uganda"
      , "Ukraine"
      , "United Arab Emirates"
      , "United Kingdom"
      , "United States"
      , "Uruguay"
      , "Uzbekistan"
      , "Virgin Islands"
      , "Vanuatu"
      , "Venezuela"
      , "Vietnam"
      , "Wallis and Futuna"
      , "West Bank"
      , "Western Sahara"
      , "Yemen"
      , "Zambia"];
  }
  getUserDetails(username: any) {
    let url = APP_CONSTANTS.REST_API_URL.GET_REGISTRATION + username;
    return this.http.get(url)
  }
  updateUserDetails(regDTO: any) {
    let url = APP_CONSTANTS.REST_API_URL.UPDATE_REGISTRATION;
    return this.http.post(url, regDTO ,{ observe: 'response' })
  }
  registration(regDTO: any) {
    let url = APP_CONSTANTS.REST_API_URL.SAVE_REGISTRATION;
    return this.http.post(url, regDTO,{ observe: 'response' })
  }
}
