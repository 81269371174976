import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RegisterService } from '../../doctorportal/service/register.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { NbDateService, NbToastrService } from '@nebular/theme';
import { APP_CONSTANTS } from 'src/app/shared/constants';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/root.reducer';
import { getDoctor } from 'src/app/store/selectors/doctor-user.selectors';
import { updateDoctor } from 'src/app/store/actions/doctor-user.actions';

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
          // return if another validator has already found an error on the matchingControl
          return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

export class Registration {
  id: Number;
  family: string;
  given: string;
  username: string;
  mobile: string;
  email: string;
  password: string;
  confirmPassword: string;
  gender: string;
  line: string;
  systemOfMedicine: string;
  practicing: string;
  visibletoPublic: string;
  registeredState: string;
  registeredCountry: string;
  registrationNo: string;
  registrationDate: string;
  validTill: string;
  courseName: string;
  university: string;
  college: string;
  completionYear: string;
  areaOfPractice: string;
  practicingSince: string;
  city: string;
  district: string;
  state: string;
  postalCode: string;
  show:true;
}
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DatePipe
  ]
})
export class RegisterComponent implements OnInit {
  passmsg;any;
  personaldetailsForm: FormGroup;
  registrationdetailsForm: FormGroup;
  employmentdetailsForm: FormGroup;
  returnUrl: string;
  error = '';
  today = new Date();
  registration = new Registration();
  type: any;
  edit: boolean = false;
  register: string = "Register";
  show:boolean=false;
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  errorMessage = '';
  successMessage = '';
  user = {};
  users = [];
  years: number[] = [];
  areaOfPractice: any[];
  countires: string[];
  invalidPassword: boolean = false;
  public educationList: FormArray;
  public appointmentList: FormArray;
  defaultImage = '../assets/images/portrait/small/default.png';
  fileToUpload: File = null;
  signature: string = "";
  @Input() max: any;
  tomorrow = new Date(); 
  duration=[{key:"15",name:"15 Min"},
  {key:"30",name:"30 Min"},
  ]
  newFileData: string | ArrayBuffer;
  minDate: Date;
  maxDate:Date;
  showError: boolean;
  multipleSelectArray = [
    {
      "item_id": 1, "item_text": "Monday"
    },
    {
      "item_id": 2, "item_text": "Tuesday"
    },
    {
      "item_id": 3, "item_text": "Wednesday"
    },
    {
      "item_id": 4, "item_text": "Thursday"
    },
    {
      "item_id": 5, "item_text": "Friday"
    },
    {
      "item_id": 6, "item_text": "Saturday"
    },
    {
      "item_id": 0, "item_text": "Sunday"
    }
  ];
  multipleSelectArray1 = [
   "MONDAY","TUESDAY","WEDNESDAY","THURSDAY","FRIDAY","SATURDAY","SUNDAY"
  ];
  timeSlots=APP_CONSTANTS.TIME_SLOTS;
  multipleMultiSelect = []

  slotAvailability = {
    night: { start: '', end: '' },
    evening: { start: '', end: '' },
    noon: { start: '', end: '' },
    morning: { start: '', end: '' }
  }
  username: string;
  formatted: string;

  constructor( protected dateService: NbDateService<Date>, private formBuilder: FormBuilder,
    private router: Router,
    private registerService:RegisterService,
    private toastrService: NbToastrService,
    private datepipe: DatePipe,
    private store:Store<AppState>
    ) { 
      console.log(this.router.getCurrentNavigation());
    this.type = this.router.getCurrentNavigation() ? this.router.getCurrentNavigation()["extras"]["state"] : null;
    if (this.type != null) {
      console.log("type--", this.type['type']);
      if (this.type['type'] == "edit") {
        console.log(this.type);
        this.edit = true;
        this.register = "Update"
      }
    }
    this.store.select(getDoctor).subscribe(data => {
      this.username=data.username;
    })
  }

  ngOnInit(): void {
    this.areaOfPractice = this.registerService.getAllAreaOfPractice();
    this.countires = this.registerService.getCountries();
    this.personaldetailsForm = this.formBuilder.group({
      id: [''],
      given: ['', [Validators.required,Validators.minLength(2),Validators.maxLength(20),Validators.pattern(APP_CONSTANTS.REGEX.ALPHABET_WITH_SPACE)]],
      family: ['',[Validators.required,Validators.minLength(2),Validators.maxLength(20),Validators.pattern(APP_CONSTANTS.REGEX.ALPHABET_WITH_SPACE)]],
      username: ['', [Validators.required,Validators.minLength(2),Validators.maxLength(20),Validators.pattern(APP_CONSTANTS.REGEX.ALPHANUMERIC)]],
      mobile: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
      birthDate: ['', Validators.required],
      gender: ['', Validators.required],
      email: ['',[Validators.pattern(APP_CONSTANTS.REGEX.EMAIL)]],
      city: ['', [Validators.required,Validators.minLength(2),Validators.maxLength(20),Validators.pattern(APP_CONSTANTS.REGEX.ADDRESS)]],
      country: ['India', Validators.required],
      doctorType:['',Validators.required],
      feeAmount:['',[Validators.pattern(APP_CONSTANTS.REGEX.FLOAT_NUM_UPTO_FIVE_DIGITS),Validators.min(0)],],
      postalcode:[''],
      registeredCountry: [{ value: 'India', disabled: true }, Validators.required],
      registeredAt: ['',[Validators.required,Validators.minLength(2),Validators.maxLength(20)]],
      registrationNo: ['', [Validators.required]],
      educationDTOList: this.formBuilder.array([this.createEducation()]),
      practicing: ['', Validators.required],
      practicingSince:'',
      agreeTerms:false,
      appointmentsDTOList: this.formBuilder.array([this.createAppointment()]),

    },
    {
      validator: MustMatch('password', 'confirmPassword')
    });
    if(!this.edit){
      this.personaldetailsForm.get('agreeTerms').setValidators(Validators.requiredTrue);
    }
    this.educationList = this.personaldetailsForm.get('educationDTOList') as FormArray;
    this.appointmentList = this.personaldetailsForm.get('appointmentsDTOList') as FormArray;
    this.registrationdetailsForm = this.formBuilder.group({

    });
    this.employmentdetailsForm = this.formBuilder.group({
      speciality: ['', Validators.required],
      subspeciality: ['', Validators.required],
      state_h: ['', Validators.required],
      district_h: ['', Validators.required],
      hositalname: ['', Validators.required],
      designation: ['', Validators.required],

    });
    this.minDate=this.dateService.addYear(this.dateService.today(),-100)
    this.maxDate=this.dateService.addYear(this.dateService.today(),-17)
    if (this.edit)
      this.loadUserDetails();
  }
  loadUserDetails() {
    this.loading = true;
    this.registerService.getUserDetails(this.username).subscribe((resData: any) => {
      this.loading = false;
      let userData = resData;
      userData.birthDate = userData.birthDate?.split(" ")[0];
      userData.password = "Achala@123";
      userData.confirmPassword = "Achala@123";
      this.signature = resData.signature != null ? resData.signature : "";
      if(this.signature!=''){
        this.show=true;
      }
      this.years = [];
      let dobyear = parseInt(userData.birthDate?.split("-")[0]);
      for (let i = dobyear + 18; i <= this.tomorrow.getFullYear(); i++) {
        this.years.push(i);
      }
      for (var i = 0; i < userData.educationDTOList.length - 1; i++) {
        this.addEducation();
      }
      for (var i = 0; i < userData.appointmentsDTOList.length - 1; i++) {
        this.addAppointment();
      }
      for (var i = 0; i < userData.appointmentsDTOList.length; i++) {
      var appointmentData=userData.appointmentsDTOList[i];
        for(var j = 0; j < appointmentData.timeSlotDTOList.length-1; j++){
          this.addAppointmentTimeSlots(i);
        }
      }
      var array=[];
      // userData.appointmentsDTOList=userData.appointmentsDTOList.map(element => {
      //     return {...element,availabilitySlotDuration:15}
      // });
      userData.birthDate = new Date( userData.birthDate);
      this.personaldetailsForm.patchValue(userData);
      this.loading = false
    }, error => {
      var data = error;
      this.loading = false;
    });
  }
  newFile(){
    this.show=false;
  }

  dobChange() {
    let date = new Date(this.personaldetailsForm.value.birthDate);
    console.log("dobyesr--", date)
    console.log("date--",this.personaldetailsForm.value.birthDate)
    if (!isNaN(date.getFullYear())) {
      this.years = [];
      for (let i = date.getFullYear() + 18; i <= this.tomorrow.getFullYear(); i++) {
        this.years.push(i);
      }
    }
  }
  checkRange(timeSlots){
    var flag=true;
      if(timeSlots.length>0){
          for(var i=0;i<timeSlots.length;i++){
            for(var j=0;j<=i;j++){
              if(i!=j){
                var startTimeArr1=timeSlots[i].startTime.split(":");
                var startTimeArr2=timeSlots[j].startTime.split(":");
                var endTimeArr1=timeSlots[i].endTime.split(":");
                var endTimeArr2=timeSlots[j].endTime.split(":");
                var date=new Date();
                var StartDate1=date.setHours(startTimeArr1[0],startTimeArr1[1],startTimeArr1[2]);
                var StartDate2=date.setHours(startTimeArr2[0],startTimeArr2[1],startTimeArr2[2]);
                var EndDate1=date.setHours(endTimeArr1[0],endTimeArr1[1],endTimeArr1[2]);
                var EndDate2=date.setHours(endTimeArr2[0],endTimeArr2[1],endTimeArr2[2]);
                if((StartDate1 < EndDate2) && (StartDate2 < EndDate1)) {
                  //overlapping dates
                  this.toastrService.show('', "Time Slots Overlapping. Please Check", { duration: 2000 });
                  this.loading = false;
                  flag=false;
                }
                             
              console.log(i +":"+j);
              }
            }
          }
      }
      return flag;
  }
  onSubmit() {
    this.submitted = true;
    if (this.personaldetailsForm.invalid) {
      return;
    }
    if (this.personaldetailsForm.value.password != this.personaldetailsForm.value.confirmPassword) {
      this.toastrService.show('', "Please enter confirm password same as password.", { duration: 2000 });
      this.loading = false;
      return;
    }
    if(this.signature==null|| this.signature==''){
      if(this.personaldetailsForm.value.file==null){
        this.toastrService.show('', "Please upload signature", { duration: 2000 });
        this.loading = false;
        return;
      }
    }
    var appList=this.personaldetailsForm.value.appointmentsDTOList;
    var flag=true;
    this.multipleSelectArray1.forEach(element => {
      var timeSlots=[];
      if(flag){
      appList.forEach(appArray => {
        if(appArray.availabilitySlotDays.includes(element)){
          appArray.timeSlotDTOList.forEach(timeSlot => {
            timeSlots.push(timeSlot);
            let date=new Date();
            var startTimeArr1=timeSlot.startTime.split(":");
            var StartDate1=date.setHours(startTimeArr1[0],startTimeArr1[1],startTimeArr1[2]);
            var endTimeArr1=timeSlot.endTime.split(":");
            var EndDate1=date.setHours(endTimeArr1[0],endTimeArr1[1],endTimeArr1[2]);
            if((StartDate1 >= EndDate1 )) {
              this.toastrService.show('', "Invalid time slots. Please Check", {duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
              this.loading = false;
              flag=false;
            }
          });
        }
      });
      if(!this.checkRange(timeSlots)){
        flag=false;
      }
    }
      console.log(flag);
    });
    if(!flag){
      return;
    }
    this.personaldetailsForm.value.educationDTOList = this.uniqueData(this.personaldetailsForm.value.educationDTOList, "courseName");
    this.personaldetailsForm.value.birthDate = this.datepipe.transform(this.personaldetailsForm.value.birthDate, "yyyy-MM-dd") + " 00:00:00";
    this.personaldetailsForm.value.signature = this.signature;

    console.log("res--", JSON.stringify(this.personaldetailsForm.value));
    if (this.edit) {
      this.personaldetailsForm.value.password = null;
      this.personaldetailsForm.value.confirmPassword = null;

      if(this.personaldetailsForm.value.doctorType=='Free')
      {
        this.personaldetailsForm.value.feeAmount=0;
      }
      this.loading = true;
      this.registerService.updateUserDetails(this.personaldetailsForm.value).subscribe((resData: any) => {
        this.loading = false;
        var data = resData['body'];
        if (!data.emailExist && !data.mobileExist && !data.registrationNoExist) {
          this.store.dispatch(updateDoctor({ id: this.personaldetailsForm.value.id, given: this.personaldetailsForm.value.given, username: this.personaldetailsForm.value.username, consultationFee: this.personaldetailsForm.value.feeAmount, consultationType:this.personaldetailsForm.value.doctorType }));
          this.toastrService.show('', "Profile details has been updated successfully.", {duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
            this.router.navigate(['/'+APP_CONSTANTS.APP_ROUTE.DOCTOR+"/"+APP_CONSTANTS.DOCTOR_ROUTE.DASHBOARD]);
        }
        else {
          let msg = "";
          if (data.emailExist)
            msg = "This email"
          if (data.mobileExist)
            msg = "This mobile number"
          if (data.registrationNoExist)
            msg = "This licence number"
          if (data.emailExist && data.mobileExist)
            msg = "This email and mobile number"
          if (data.emailExist && data.registrationNoExist)
            msg = "This email and licence number"
          if (data.mobileExist && data.registrationNoExist)
            msg = "This mobile number and licence number"
          if (data.emailExist && data.mobileExist && data.registrationNoExist)
            msg = "This email, mobile number and licence number"
          this.toastrService.show(msg + "", "already exists, please use other values.",{duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
          this.loading = false;

        }
      }, error => {
        var data = error;
        this.loading = false;
      });
    }
    else {
      this.loading = true;
      this.registerService.getUserDetails(this.personaldetailsForm.value.username).subscribe((resData: any) => {
        this.loading = false;
        console.log("res--" + JSON.stringify(resData));
        this.loading = true;
        if (resData == null) {
          let reqBody = this.personaldetailsForm.value;
          this.registerService.registration(reqBody).subscribe((resData: any) => {
            this.loading = false;
            var data = resData['body'];
            if (!data.emailExist && !data.mobileExist && !data.registrationNoExist) {
              this.toastrService.show('', "This doctor has been registered successfully with us, Please proceed to login.", {duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
              this.router.navigate([APP_CONSTANTS.APP_ROUTE.AUTH+'/'+APP_CONSTANTS.REST_API_URL.DOCTOR_LOGIN])
            }
            else {
              let msg = "";
              if (data.emailExist)
                msg = "This email"
              if (data.mobileExist)
                msg = "This mobile number"
              if (data.registrationNoExist)
                msg = "This licence number"
              if (data.emailExist && data.mobileExist)
                msg = "This email and mobile number"
              if (data.emailExist && data.registrationNoExist)
                msg = "This email and licence number"
              if (data.mobileExist && data.registrationNoExist)
                msg = "This mobile number and licence number"
              if (data.emailExist && data.mobileExist && data.registrationNoExist)
                msg = "This email, mobile number and licence number"
              this.toastrService.show(msg + "", " already exists, please use other values.", {duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
              this.loading = false;

            }
          }, error => {
            var data = error;
            this.loading = false;
          });
        }
        else {
          this.toastrService.show('', "This username has been already taken,please try with other username.", {duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
          this.loading = false;
        }
      }, error => {
        var data = error;
        this.loading = false;
      });
    }

  }
  goBack(){
    this.router.navigate([APP_CONSTANTS.APP_ROUTE.DOCTOR+"/"+APP_CONSTANTS.DOCTOR_ROUTE.DASHBOARD]);
  }
  back(){
    this.router.navigate([APP_CONSTANTS.APP_ROUTE.AUTH+"/"+'login']);
  }
  handleFileInput(files: FileList) {
    this.newFile();
    this.fileToUpload = files.item(0);
    console.log("filedata=",this.fileToUpload)
    console.log(files.item(0).name)
    const reader = new FileReader();
    reader.readAsDataURL(this.fileToUpload);
    reader.onload = () => {
      this.signature = reader.result.toString();
    };
    
  }
  uniqueData(array, name) {
    // create new objects for use
    var uniqueArray = [];
    var map = new Map();

    // loop throught array
    array.forEach((user, index) => {
      // first item is always unique add to unique whithout check
      if (index == 0) {
        // using map set first item in map name and value is dynamic which we can set
        map.set(array[index].courseName, array[index].courseName);
        uniqueArray.push(array[index]);
      }

      //check if the name already exists if exists do not push else push
      if (!map.get(user[name])) {
        map.set(user[name], user[name]);
        uniqueArray.push(user);
      }
    });
    return uniqueArray;
  }

  get pdf() {
    return this.personaldetailsForm.controls;
  }
  get personaldetailsFormGroup() {
    return this.personaldetailsForm.get('educationDTOList') as FormArray;
  }
  get getAppointmentsFormrGoup() {
    return this.personaldetailsForm.get('appointmentsDTOList') as FormArray;
  }
  getAppointmentsFormTimeSlotsGroup(i) {
    return (<FormArray>this.personaldetailsForm.get('appointmentsDTOList')).controls[i].get('timeSlotDTOList') as FormArray;
  }
  
  getValidity(i) {
    return (<FormArray>this.personaldetailsForm.get('educationDTOList')).controls[i].invalid;
  }
  getValidityUniv(i) {
    return (<FormArray>this.personaldetailsForm.get('educationDTOList')).controls[i].get('university').errors
    && (<FormArray>this.personaldetailsForm.get('educationDTOList')).controls[i].get('university').invalid
    && (<FormArray>this.personaldetailsForm.get('educationDTOList')).controls[i].get('university').touched;
  }
  getValidityCourse(i) {
    return (<FormArray>this.personaldetailsForm.get('educationDTOList')).controls[i].get('courseName').errors
    && (<FormArray>this.personaldetailsForm.get('educationDTOList')).controls[i].get('courseName').invalid
    && (<FormArray>this.personaldetailsForm.get('educationDTOList')).controls[i].get('courseName').touched;
  }
  getValidityAvai(i) {
    console.log(<FormArray>this.personaldetailsForm.get('appointmentsDTOList'));
    return (<FormArray>this.personaldetailsForm.get('appointmentsDTOList')).controls[i].invalid;
  }
  getValidityDays(i){
    return (<FormArray>this.personaldetailsForm.get('appointmentsDTOList')).controls[i].get('availabilitySlotDays').errors
    && (<FormArray>this.personaldetailsForm.get('appointmentsDTOList')).controls[i].get('availabilitySlotDays').invalid
    && (<FormArray>this.personaldetailsForm.get('appointmentsDTOList')).controls[i].get('availabilitySlotDays').touched;
  }
  getValidityTimeSlot(i,j) {
    return (<FormArray>(<FormArray>this.personaldetailsForm.get('appointmentsDTOList')).controls[i].get('timeSlotDTOList')).controls[j].invalid;
  }
  addEducation() {
    this.educationList.push(this.createEducation());
  }
  addAppointment() {
    this.appointmentList.push(this.createAppointment());
  }
  addAppointmentTimeSlots(i) {
    (<FormArray>(<FormArray>this.personaldetailsForm.get('appointmentsDTOList')).controls[i].get('timeSlotDTOList')).push(this.createTimeSlots())
    // this.appointmentList[i]["timeSlotDTOList"].push(this.createTimeSlots());
  }
  removeEducation(index) {
    this.educationList.removeAt(index);
  }
  removeAppointment(index) {
    this.appointmentList.removeAt(index);
  }
  removeTimeSlot(i,index) {
    (<FormArray>(<FormArray>this.personaldetailsForm.get('appointmentsDTOList')).controls[i].get('timeSlotDTOList')).removeAt(index)
    // this.appointmentList[i]["timeSlotDTOList"].removeAt(index);
  }
  createEducation(): FormGroup {
    return this.formBuilder.group({
      id: [0],
      courseName: ['', [Validators.required,Validators.pattern(APP_CONSTANTS.REGEX.ALPHABET_WITH_DOT)]],
      university: ['', [Validators.required,Validators.pattern(APP_CONSTANTS.REGEX.ALPHABET_WITH_DOT)]],
      completionyear: ['', Validators.required],
    });
  }
  createAppointment(): FormGroup {
    return this.formBuilder.group({
      id: [0],
      availabilitySlotDuration: ['', Validators.required],
      availabilitySlotDays: ['', Validators.required],
      timeSlotDTOList: this.formBuilder.array([this.createTimeSlots()]),
    });
  }
  createTimeSlots(): FormGroup {
    return this.formBuilder.group({
      id: [0],
      startTime: ['', Validators.required],
      endTime: ['', Validators.required],
    });
  }

  MovetoLogin(){
    this.router.navigate([APP_CONSTANTS.APP_ROUTE.AUTH + '/' +APP_CONSTANTS.REST_API_URL.DOCTOR_LOGIN]);
  }
  MoveToTerms()
  {
    this.router.navigate([APP_CONSTANTS.APP_ROUTE.AUTH+ '/' +APP_CONSTANTS.LOGIN_ROUTE.TERMS_CONDITIONS]);
  }
}