import { MessagingService } from './../../shared/services/messaging.service';
import { Component , OnInit} from '@angular/core';


@Component({
  selector: 'app-instant1-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit{
  title = 'push-notification';
  message;
  constructor(private messagingService: MessagingService) { }
  ngOnInit() {
    console.log("NotificationsComponent ==>> ");
    this.messagingService.requestPermission()
    this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage
  }

}
