<div>
  <span
    (click)="viewRecord(1,MediumModelContent,HBModelContent,UAModelContent,UricacidModelContent,CholModelContent,ReportsModelContent)"><i
      class="fa fa-eye" title="View"></i></span>
</div>
<ng-template class="modal text-left" #MediumModelContent let-c="close" let-d="dismiss">
  <div class="modal-md">

    <div class="modal-body">
      <section>

        <div class="row">
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Lipids Profile Report</h4>
              </div>
              <div class="card-body">
                <div class="card-block">

                  <div class="table-responsive">
                    <table class="table table-bordered mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Parameter</th>
                          <th scope="col">Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Total cholestrol</td>
                          <td>{{lipids.TChol}} mg/dL</td>
                        </tr>
                        <tr>
                          <td>High density lipoprotein</td>
                          <td>{{lipids.HdlChol}} mg/dL</td>
                        </tr>
                        <tr>
                          <td>Low density lipoprotein</td>
                          <td>{{lipids.CalcLdl}} mg/dL</td>
                        </tr>
                        <tr>
                          <td>Tryglyceride</td>
                          <td>{{lipids.Trig}} mg/dL</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn grey btn-outline-secondary" (click)="d('Close modal')">Close</button>
    </div>

  </div>
</ng-template>

<!-- Hb Modal -->
<ng-template class="modal text-left" #HBModelContent let-c="close" let-d="dismiss">
  <div class="modal-md">

    <div class="modal-body">
      <section>

        <div class="row">
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Hemoglobin Report</h4>
              </div>
              <div class="card-body">
                <div class="card-block">

                  <div class="table-responsive">
                    <table class="table table-bordered mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Parameter</th>
                          <th scope="col">Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Hemoglobin</td>
                          <td>{{hb.Hb}} g/dL</td>
                        </tr>
                        <tr>
                          <td>Hematocrit</td>
                          <td>{{hb.Hct}} %</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn grey btn-outline-secondary" (click)="d('Close modal')">Close</button>
    </div>

  </div>
</ng-template>

<!-- Chol Modal -->
<ng-template class="modal text-left" #CholModelContent let-c="close" let-d="dismiss">
  <div class="modal-md">

    <div class="modal-body">
      <section>

        <div class="row">
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Cholestrol Report</h4>
              </div>
              <div class="card-body">
                <div class="card-block">

                  <div class="table-responsive">
                    <table class="table table-bordered mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Parameter</th>
                          <th scope="col">Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Cholestrol</td>
                          <td>{{chol.Chol}} mg/dL</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn grey btn-outline-secondary" (click)="d('Close modal')">Close</button>
    </div>

  </div>
</ng-template>

<!-- Uric acid Modal -->
<ng-template class="modal text-left" #UricacidModelContent let-c="close" let-d="dismiss">
  <div class="modal-md">

    <div class="modal-body">
      <section>

        <div class="row">
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Uric Acid Report</h4>
              </div>
              <div class="card-body">
                <div class="card-block">

                  <div class="table-responsive">
                    <table class="table table-bordered mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Parameter</th>
                          <th scope="col">Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Uric Acid</td>
                          <td>{{urica.Ua}} mg/dL</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn grey btn-outline-secondary" (click)="d('Close modal')">Close</button>
    </div>

  </div>
</ng-template>

<!-- UA Modal -->
<ng-template class="modal text-left" #UAModelContent let-c="close" let-d="dismiss">
  <div class="modal-md">

    <div class="modal-body">
      <section>

        <div class="row">
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Urine Analysis Report</h4>
              </div>
              <div class="card-body">
                <div class="card-block">

                  <div class="table-responsive">
                    <table class="table table-bordered mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Parameter</th>
                          <th scope="col">Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>LEU</td>
                          <td>{{ur.LEU}}</td>
                        </tr>

                        <tr>
                          <td>URO</td>
                          <td>{{ur.URO}}</td>
                        </tr>

                        <tr>
                          <td>BIL</td>
                          <td>{{ur.BIL}}</td>
                        </tr>

                        <tr>
                          <td>KET</td>
                          <td>{{ur.KET}}</td>
                        </tr>

                        <tr>
                          <td>GLU</td>
                          <td>{{ur.GLU}}</td>
                        </tr>

                        <tr>
                          <td>PRO</td>
                          <td>{{ur.PRO}}</td>
                        </tr>

                        <tr>
                          <td>BLD</td>
                          <td>{{ur.BLD}}</td>
                        </tr>

                        <tr>
                          <td>NIT</td>
                          <td>{{ur.NIT}}</td>
                        </tr>

                        <tr>
                          <td>pH</td>
                          <td>{{ur.PH}}</td>
                        </tr>

                        <tr>
                          <td>SG</td>
                          <td>{{ur.SG}}</td>
                        </tr>

                        <tr>
                          <td>VC</td>
                          <td>{{ur.VC}}</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn grey btn-outline-secondary" (click)="d('Close modal')">Close</button>
    </div>

  </div>
</ng-template>

<!-- reports Modal -->
<ng-template class="modal text-left" #ReportsModelContent let-c="close" let-d="dismiss">
  <div class="modal-lg">

    <div class="modal-body">
      <section>

        <div [hidden]="loading" class="text-center">
          <div class="loader-wrapper">
            <div class="loader-container">
              <div class="fading-circle loader-blue-grey">
                <div class="circle1 circle"></div>
                <div class="circle2 circle"></div>
                <div class="circle3 circle"></div>
                <div class="circle4 circle"></div>
                <div class="circle5 circle"></div>
                <div class="circle6 circle"></div>
                <div class="circle7 circle"></div>
                <div class="circle8 circle"></div>
                <div class="circle9 circle"></div>
                <div class="circle10 circle"></div>
                <div class="circle11 circle"></div>
                <div class="circle12 circle"></div>
              </div>
            </div>
          </div>
        </div>

        <div [hidden]="!loading" class="row">
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">{{reportDto.reportName}} Report</h4>
              </div>
              <div class="">
                <div class="" style="width: 100%;overflow: auto;">

                  <img class="image" *ngIf="con1 || con2" src="{{reportDto.reportAttachment}}" />
                  <div style="text-align:center;" *ngIf="showText"><i>No Data Found</i></div>
                  <div>
                    <ngx-extended-pdf-viewer [showPrintButton]="false" [showBookmarkButton]="false" [showOpenFileButton]="false" [zoom]="'page-width'" *ngIf="con3 && !showText" [textLayer]="true" [src]="reportDto.reportAttachment"></ngx-extended-pdf-viewer>

                    <!-- <iframe type="application/pdf" *ngIf="con3 && !showText" [src]="reportDto.reportAttachment | urlsafe" width="100%"
                      height="450" frameborder="2"></iframe> -->
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn grey btn-outline-secondary" (click)="d('Close modal')">Close</button>
    </div>

  </div>
</ng-template>