import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { AppState } from "../root.reducer";
import { select, Store } from "@ngrx/store";
import { catchError, concatMap, map, mergeMap, switchMap, takeUntil } from "rxjs/operators";
import { loadAdminDashboard, loadDoctorsList, loadPatientList, UpdateAdminDashbaord,UpdateDoctorsList,UpdatePatientList,UpdateUsersList,UpdateRolesList,loadUserList, loadRolesList, AdminHomeDashboardLoading, AdminDoctorsListLoading, AdminPatientListLoading, AdminRolesListLoading, AdminUserListLoading} from "../actions/admin-home.actions";
import { forkJoin, of, Subject, timer } from "rxjs";
import { AdminHomeService } from "src/app/adminportal/service/admin-home.service";

@Injectable()
export class AdminDashboardEffects {
    constructor(private actions$: Actions,private dashboardService:AdminHomeService,private store:Store<AppState>) {

    }
    destroy= new Subject();
    admindashboardTimer$ = createEffect(
      () => this.actions$
          .pipe(
              ofType(loadAdminDashboard),
              switchMap(() => timer(0,5*60*1000).pipe(takeUntil(this.destroy))),
                 mergeMap(action => {
                  var array = [];
                  this.store.dispatch(AdminHomeDashboardLoading());
                  array.push(this.dashboardService.getAllDoctors().pipe(
                      catchError((err) => {
                          return of()
                        // Handle specific error for this call
                      }
                    )));
                  array.push(this.dashboardService.getAllPatients().pipe(
                      catchError((err) => {
                          return of()
                        // Handle specific error for this call
                      }
                    )));
                  array.push(this.dashboardService.getAllUsers().pipe(
                      catchError((err) => {
                          return of()
                        // Handle specific error for this call
                      }
                    )));
                  array.push(this.dashboardService.getAllRoles().pipe(
                      catchError((err) => {
                          return of()
                        // Handle specific error for this call
                      }
                    )));
                  return forkJoin(array);
                }),
              map(data => {
                return UpdateAdminDashbaord({
                    doctorsList: data[0],
                    patientList: data[1],
                    usersList: data[2],
                    rolesList: data[3],
              })
              })
          ), { dispatch: true });
          UpdateDoctorsList$ = createEffect(
            () => this.actions$
              .pipe(
                ofType(loadDoctorsList),
                mergeMap(action => {
                  var array = [];
                   this.store.dispatch(AdminDoctorsListLoading());
                   return this.dashboardService.getAllDoctors()
                }),
                map(config => {
                  return UpdateDoctorsList({
                    doctorsList: config
                  })
                })
              )
          );
          UpdatePatientList$ = createEffect(
            () => this.actions$
              .pipe(
                ofType(loadPatientList),
                mergeMap(action => {
                  var array = []; 
                  this.store.dispatch(AdminPatientListLoading());

                   return this.dashboardService.getAllPatients()
                }),
                map(config => {
                  return UpdatePatientList({
                    patientList: config
                  })
                })
              )
          );
          UpdateUsersList$ = createEffect(
            () => this.actions$
              .pipe(
                ofType(loadUserList),
                mergeMap(action => {
                   this.store.dispatch(AdminUserListLoading());
                   return this.dashboardService.getAllUsers()
                }),
                map(config => {
                  return UpdateUsersList({
                    usersList: config
                  })
                })
              )
          );
          UpdateRolesList$ = createEffect(
            () => this.actions$
              .pipe(
                ofType(loadRolesList),
                mergeMap(action => {
                  var array = [];
                  this.store.dispatch(AdminRolesListLoading());
                   return this.dashboardService.getAllRoles()
                }),
                map(config => {
                  return UpdateRolesList({
                    rolesList: config
                  })
                })
              )
          );
}