import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AppConfigState } from "../state/app-config.state";

const getAppConfigState = createFeatureSelector<AppConfigState>('appConfig')

export const getAppConfig = createSelector(getAppConfigState, (state) => {
    return state;
})

export const areUserRolesLoaded = createSelector(
    getAppConfigState,
    state => state.isUserRolesLoaded
);

export const getUserRoles = createSelector(
    getAppConfigState,
    state => state.userRoles
);