import { createReducer, on } from "@ngrx/store"
import { updateUser } from "../actions/admin-user.actions"
import { initialUserState } from "../state/admin-user.state"

const _UserReducer = createReducer(
    initialUserState,
    on(updateUser, (state, action) => {
        return {
            ...state,
            id: action.id,
            username: action.username,
            firstname: action.firstname,
            lastname: action.lastname,
            mobileno: action.mobileno,
            roleDTO:action.roleDTO,
        }
    }),

)

export function UserReducer(state, action) {
    return _UserReducer(state, action)
}