import { Directive, ElementRef, HostListener, Inject, Input, Optional } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validator, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import {Location} from "@angular/common";
import { PatientService } from '../../services/patient.service';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { APP_CONSTANTS } from '../../constants';
import { UnsubscribeOnDestroyAdapter } from '../../services/UnsubscribeOnDestroyadapter';

@Component({
  selector: 'medicineView',
  templateUrl: './medicineView.component.html',
  styleUrls: ['./medicineView.component.scss'],
  providers: [PatientService]
})

export class medicineViewComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  personId : string;
  loading:boolean=false;
  vitalsForm: FormGroup;

  tomorrow = new Date();
  medication:any;
  fileToUpload: File = null;
  report: string = "";
  submitted: boolean = false;
  memberData: any;
  @Input () data:any;
  constructor(private toastrService: NbToastrService,private dialogRef:NbDialogRef<medicineViewComponent>,private formBuilder: FormBuilder,
    private apiService:PatientService,private location : Location,
    private datePipe:DatePipe)
   {
     super()
   }

  ngOnInit() {

    this.memberData = this.data;
    this.medication=this.data;
    console.log("Active medication");
    console.log(this.medication);

  }



  getDateEnd(date)
  {
    var data=''
    switch(date)
    {
      case 1:
      case 21:
      case 31:     
        data= date+"st ";
        break;
      case 2:
      case 22:
        data=date+"nd ";
        break;
      case 3:
      case 23:
        data= date+"rd ";
        break;
      default:
        data=date+"th ";
        break;
    }
    return data;

  }

  getDosage(data:any)
  {
    var s='';
    if(data.uploadType=='AUTO')
    {
      s='DAILY' + ' | ' + data.frequency;
    }
    else
    {
      if(data.customFrequency)
      {
        if(data.customFrequency['isRepeat']==0)
        {
          s="DAILY"+' | '+ this.Convert24hrto12hrs(data.frequency);
        }
        else
        { if(data.customFrequency['selectMode']=='DAILY')
          {
            s=data.customFrequency['selectMode']+' | '+this.Convert24hrto12hrs(data.frequency);
          }
          else if(data.customFrequency['selectMode']=='WEEKLY')
          {
            s=data.customFrequency['selectMode']+' | '+ data.customFrequency['weeklyWeekdays']+' | '+this.Convert24hrto12hrs(data.frequency);
          }
          else if(data.customFrequency['selectMode']=='MONTHLY')
          {
            if(data.customFrequency['monthlyPattern']=='DAY')
            {
              s=data.customFrequency['selectMode']+' | '+this.getDateEnd(data.customFrequency['monthlyDate'])+" of month" +' | '+this.Convert24hrto12hrs(data.frequency);
            }
            else if(data.customFrequency['monthlyPattern']=='CUSTOM')
            {
              s=data.customFrequency['selectMode']+' | '+ data.customFrequency['monthlyCustomTime'] +' '+ data.customFrequency['monthlyCustomDays'] +' | '+this.Convert24hrto12hrs(data.frequency);
            }
          }
          else if(data.customFrequency['selectMode']=='YEARLY')
          {
            if(data.customFrequency['yearlyPattern']=='MONTH')
            {
              s=data.customFrequency['selectMode']+' | '+this.getDateEnd(data.customFrequency['yearlyDate'])+" of "+data.customFrequency['yearlyMonth'] +' | '+this.Convert24hrto12hrs(data.frequency);
            }
            else
            {
              s=data.customFrequency['selectMode']+' | '+ (data.customFrequency['yearlyCustomTime']+" "+data.customFrequency['yearlyCustomDay']+" of "+data.customFrequency['yearlyCustomMonth'])
              +' | '+this.Convert24hrto12hrs(data.frequency);
            }
          }
          else
          {
            s=data.customFrequency['selectMode']+' | '+this.Convert24hrto12hrs(data.frequency);
          }
          
        }

      }
      else
      {
        s='DAILY'+ ' | ' + data.frequency;
      }
    }
    
    return s;

  }


  getProgram(data:any)
  {
      var str='';
      if(data.uploadType=='AUTO')
      {
        str='Total :' + (data.daysRemaining>1?" "+data.daysRemaining+" days": " "+data.daysRemaining+" day");
      }
      else
      {
        if(data.uploadType=='MANUAL')
        {
            if(data.customFrequency)
            {
                 if(data.customFrequency['isRepeat']==0)
                  {
                       str="Total : 1 day";
                   }
                  else
                  {
                    if(data.customFrequency['selectMode']=='DAILY')
                    {
                       
                        if(data.customFrequency['dailyPattern']=='EVERY')
                        {
                              if(data.customFrequency['endMode']=='NO_END_DATE')
                              {
                                str= "Every "+ (data.customFrequency['noOfDays']>1?" "+data.customFrequency['noOfDays']+" days":"day") 
                              }
                              else if(data.customFrequency['endMode']=='END_AFTER')
                              {
                                str= 'Total :' + (data.customFrequency['noOfDays']*data.customFrequency['endValue']>1?" "+data.customFrequency['noOfDays']*data.customFrequency['endValue']+" days ": " "+
                                data.customFrequency['noOfDays']*data.customFrequency['endValue']+" day ")+  " | " +"Every "+ (data.customFrequency['noOfDays']>1?" "+data.customFrequency['noOfDays']+" days":" day");
                              }
                              else
                              {
                                str= "Every "+ (data.customFrequency['noOfDays']>1?" "+data.customFrequency['noOfDays']+" days":"day")+"| Until "+this.Convert24hrto12hrsDate(data.endTime) ; 
                              }
                              
                        } 
                        else
                        {
                          str="Today";
                        } 
                      }
                    else if(data.customFrequency['selectMode']=='WEEKLY')
                    {
                      if(data.customFrequency['endMode']=='NO_END_DATE')
                      {
                        str= "Every "+ (data.customFrequency['noOfWeeks']>1?" "+data.customFrequency['noOfWeeks']+" weeks":" week");
                      }
                      else if(data.customFrequency['endMode']=='END_AFTER')
                      {
                        str= 'Total :' + (data.customFrequency['noOfWeeks']*data.customFrequency['endValue']>1?" "+data.customFrequency['noOfWeeks']*data.customFrequency['endValue']+" weeks ": " "+
                        data.customFrequency['noOfWeeks']*data.customFrequency['endValue']+" day ")+  " | " +"Every "+ (data.customFrequency['noOfWeeks']>1?" "+data.customFrequency['noOfWeeks']+" weeks":" week");
                      }
                      else
                      {
                        str= "Every "+ (data.customFrequency['noOfWeeks']>1?" "+data.customFrequency['noOfWeeks']+" weeks":" week")+" | Until "+this.Convert24hrto12hrsDate(data.endTime); 
                      }
          
                      }
          
                    else if(data.customFrequency['selectMode']=='MONTHLY')
                    {
          
                      if(data.customFrequency['monthlyPattern']=='DAY')
                      {
                        if(data.customFrequency['endMode']=='NO_END_DATE')
                        {
                          str= "Every "+ (data.customFrequency['noOfMonths']>1?" "+data.customFrequency['noOfMonths']+" months":" month");
                        }
                        else if(data.customFrequency['endMode']=='END_AFTER')
                        {
                          str= 'Total :' + (data.customFrequency['noOfMonths']*data.customFrequency['endValue']>1?" "+data.customFrequency['noOfMonths']*data.customFrequency['endValue']+" months ": " "+
                        data.customFrequency['noOfMonths']*data.customFrequency['endValue']+" month ")+  " | " +"Every "+ (data.customFrequency['noOfMonths']>1?" "+data.customFrequency['noOfMonths']+" months":" month");
                        }
                        else
                        {
                          str= "Every "+ (data.customFrequency['noOfMonths']>1?" "+data.customFrequency['noOfMonths']+" months":" month")+" | Until "+this.Convert24hrto12hrsDate(data.endTime); 
                        }
                      }
          
                      else if(data.customFrequency['monthlyPattern']=='CUSTOM')
                      {
                        if(data.customFrequency['endMode']=='NO_END_DATE')
                        {
                          str= "Every "+ (data.customFrequency['noOfMonths']>1?" "+data.customFrequency['noOfMonths']+" months":" month");
                        }
                        else if(data.customFrequency['endMode']=='END_AFTER')
                        {
                          str= 'Total :' + (data.customFrequency['noOfMonths']*data.customFrequency['endValue']>1?" "+data.customFrequency['noOfMonths']*data.customFrequency['endValue']+" months ": " "+
                        data.customFrequency['noOfMonths']*data.customFrequency['endValue']+" month ")+  " | " +"Every "+ (data.customFrequency['noOfMonths']>1?" "+data.customFrequency['noOfMonths']+" months":" month");
                        }
                        else
                        {
                          str= "Every "+ (data.customFrequency['noOfMonths']>1?" "+data.customFrequency['noOfMonths']+" months":" month")+" | Until "+this.Convert24hrto12hrsDate(data.endTime); 
                        }
                      }
                      
          
                      }
          
                    else if(data.customFrequency['selectMode']=='YEARLY')
                    {
          
                      if(data.customFrequency['yearlyPattern']=='MONTH')
                      {
                        if(data.customFrequency['endMode']=='NO_END_DATE')
                        {
                          str= "Every"+ (data.customFrequency['noOfYears']>1?" "+data.customFrequency['noOfYears']+" years":" year");
                        }
                        else if(data.customFrequency['endMode']=='END_AFTER')
                        {
                          str= 'Total :' + (data.customFrequency['noOfYears']*data.customFrequency['endValue']>1?" "+data.customFrequency['noOfDays']*data.customFrequency['endValue']+" years ": " "+
                                data.customFrequency['noOfYears']*data.customFrequency['endValue']+" year ")+  " | " +"every "+ (data.customFrequency['noOfYears']>1?" "+data.customFrequency['noOfDays']+" years":" year");
                        }
                        else
                        {
                          str= "Every"+ (data.customFrequency['noOfYears']>1?" "+data.customFrequency['noOfYears']+" years ":" year ")
                          +" | Until "+this.Convert24hrto12hrsDate(data.endTime); 
                        }
                      }
                      else if(data.customFrequency['yearlyPattern']=='CUSTOM')
                      {
                        if(data.customFrequency['endMode']=='NO_END_DATE')
                        {
                          str= "Every"+ (data.customFrequency['noOfYears']>1?" "+data.customFrequency['noOfYears']+" years":" year");
                        }
                        else if(data.customFrequency['endMode']=='END_AFTER')
                        {
                          str= 'Total :' + (data.customFrequency['noOfYears']*data.customFrequency['endValue']>1?" "+data.customFrequency['noOfDays']*data.customFrequency['endValue']+" years ": " "+
                                data.customFrequency['noOfYears']*data.customFrequency['endValue']+" year ")+  " | " +"every "+ (data.customFrequency['noOfYears']>1?" "+data.customFrequency['noOfDays']+" years":"year");
                        }
                        else
                        {
                          str= "Every"+ (data.customFrequency['noOfYears']>1?" "+data.customFrequency['noOfYears']+" years ":"year ")
                          +" | Until "+this.Convert24hrto12hrsDate(data.endTime); 
                        }
                      }
                      
                      }
                  
                  }
            }
            else
            {
              str='Total :' + (data.daysRemaining>1?" "+data.daysRemaining+" days": " "+data.daysRemaining+" day");
            }
        }   
      }
      return str;
  }
  


  getMedicineTip(data:any)
  {
    if(data.uploadType=='MANUAL')
    {
      if(data.customFrequency)
      {
        if(data.customFrequency['isRepeat']==0)
        {
          return "Take today at "+ data.customFrequency['startTime'];
        }
        else
        {
          if(data.customFrequency['selectMode']=='DAILY')
          {
             
            if(data.customFrequency['dailyPattern']=='EVERY')
            {
                  if(data.customFrequency['endMode']=='NO_END_DATE')
                  {
                    return "Take medication every "+ (data.customFrequency['noOfDays']>1?" "+data.customFrequency['noOfDays']+" days":"day") 
                  }
                  else if(data.customFrequency['endMode']=='END_AFTER')
                  {
                    return "Take medication every "+ (data.customFrequency['noOfDays']>1?" "+data.customFrequency['noOfDays']+" days":"day")+(data.customFrequency['endMode']=='END_AFTER'? "(repeat "+data.customFrequency['endValue']+" times)" :""); 
                  }
                  else
                  {
                    return "Take medication every "+ (data.customFrequency['noOfDays']>1?" "+data.customFrequency['noOfDays']+" days":"day")+(data.customFrequency['endMode']=='END_AFTER'? "(repeat "+data.customFrequency['endValue']+" times)" :"")+" until "+this.Convert24hrto12hrsDate(data.endTime); 
                  }
                  
            }  
          }
          else if(data.customFrequency['selectMode']=='WEEKLY')
          {
            if(data.customFrequency['endMode']=='NO_END_DATE')
            {
              return "Take medication every "+ (data.customFrequency['noOfWeeks']>1?" "+data.customFrequency['noOfWeeks']+" weeks":" week")+(" on "+ data.customFrequency['weeklyWeekdays'].toLowerCase( ));
            }
            else if(data.customFrequency['endMode']=='END_AFTER')
            {
              return "Take medication every "+ (data.customFrequency['noOfWeeks']>1?" "+data.customFrequency['noOfWeeks']+" weeks":" week")+(" on "+ data.customFrequency['weeklyWeekdays'].toLowerCase( ))+(data.customFrequency['endMode']=='END_AFTER'? "(repeat "+data.customFrequency['endValue']+" times)" :""); 
            }
            else
            {
              return "Take medication every "+ (data.customFrequency['noOfWeeks']>1?" "+data.customFrequency['noOfWeeks']+" weeks":" week")+(data.customFrequency['endMode']=='END_AFTER'? "(repeat "+data.customFrequency['endValue']+" times)" :"")+(" on "+ data.customFrequency['weeklyWeekdays'].toLowerCase( ))+" until "+this.Convert24hrto12hrsDate(data.endTime); 
            }

          }

          else if(data.customFrequency['selectMode']=='MONTHLY')
          {

            if(data.customFrequency['monthlyPattern']=='DAY')
            {
              if(data.customFrequency['endMode']=='NO_END_DATE')
              {
                return "Take medication every "+ (data.customFrequency['noOfMonths']>1?" "+data.customFrequency['noOfMonths']+" months":" month")+" on "+ data.customFrequency['monthlyDate']+(this.getDateEnd(data.customFrequency['monthlyDate'])) ;
              }
              else if(data.customFrequency['endMode']=='END_AFTER')
              {
                return "Take medication every "+ (data.customFrequency['noOfMonths']>1?" "+data.customFrequency['noOfMonths']+" months":" month")+" on "+ data.customFrequency['monthlyDate']+(this.getDateEnd(data.customFrequency['monthlyDate']))+(data.customFrequency['endMode']=='END_AFTER'? " (repeat "+data.customFrequency['endValue']+" times)" :""); 
              }
              else
              {
                return "Take medication every "+ (data.customFrequency['noOfMonths']>1?" "+data.customFrequency['noOfMonths']+" months":" month")+ " on "+ data.customFrequency['monthlyDate']+(this.getDateEnd(data.customFrequency['monthlyDate']))+(data.customFrequency['endMode']=='END_AFTER'? "(repeat "+data.customFrequency['endValue']+" times)" :"")+" until "+this.Convert24hrto12hrsDate(data.endTime); 
              }
            }

            else if(data.customFrequency['monthlyPattern']=='CUSTOM')
            {
              if(data.customFrequency['endMode']=='NO_END_DATE')
              {
                return "Take medication every "+ (data.customFrequency['noOfMonths']>1?" "+data.customFrequency['noOfMonths']+" months":" month")+" on "+ data.customFrequency['monthlyCustomTime'].toLowerCase( )+" "+data.customFrequency['monthlyCustomDays'].toLowerCase( );
              }
              else if(data.customFrequency['endMode']=='END_AFTER')
              {
                return "Take medication every "+ (data.customFrequency['noOfMonths']>1?" "+data.customFrequency['noOfMonths']+" months":" month")+" on "+ data.customFrequency['monthlyCustomTime'].toLowerCase( )+" "+data.customFrequency['monthlyCustomDays'].toLowerCase( )+(data.customFrequency['endMode']=='END_AFTER'? " (repeat "+data.customFrequency['endValue']+" times)" :""); 
              }
              else
              {
                return "Take medication every "+ (data.customFrequency['noOfMonths']>1?" "+data.customFrequency['noOfMonths']+" months":" month")+" on "+ data.customFrequency['monthlyCustomTime'].toLowerCase( )+" "+data.customFrequency['monthlyCustomDays'].toLowerCase( )+(data.customFrequency['endMode']=='END_AFTER'? " (repeat "+data.customFrequency['endValue']+" times)" :"")+" until "+this.Convert24hrto12hrsDate(data.endTime); 
              }
            }
            

          }

          else if(data.customFrequency['selectMode']=='YEARLY')
          {

            if(data.customFrequency['yearlyPattern']=='MONTH')
            {
              if(data.customFrequency['endMode']=='NO_END_DATE')
              {
                return "Take medication every "+ (data.customFrequency['noOfYears']>1?" "+data.customFrequency['noOfYears']+" years":" year")+ " on "+
                 data.customFrequency['yearlyDate']
                +this.getDateEnd(data.customFrequency['yearlyDate'])+" "+data.customFrequency['yearlyMonth'].toLowerCase();
              }
              else if(data.customFrequency['endMode']=='END_AFTER')
              {
                return "Take medication every "+ (data.customFrequency['noOfYears']>1?" "+data.customFrequency['noOfYears']+" years":" year")+" on "+ data.customFrequency['yearlyDate']+this.getDateEnd(data.customFrequency['yearlyDate'])
                +" "+data.customFrequency['yearlyMonth'].toLowerCase()+(data.customFrequency['endMode']=='END_AFTER'? "(repeat "+data.customFrequency['endValue']+" times)" :""); 
              }
              else
              {
                return "Take medication every "+ (data.customFrequency['noOfYears']>1?" "+data.customFrequency['noOfYears']+" years":" year")+" on "+ data.customFrequency['yearlyDate']+this.getDateEnd(data.customFrequency['yearlyDate'])
                +" "+data.customFrequency['yearlyMonth'].toLowerCase()+(data.customFrequency['endMode']=='END_AFTER'? "(repeat "+data.customFrequency['endValue']+" times)" :"")+" until "+this.Convert24hrto12hrsDate(data.endTime); 
              }
            }
            else if(data.customFrequency['yearlyPattern']=='CUSTOM')
            {
              if(data.customFrequency['endMode']=='NO_END_DATE')
              {
                return "Take medication every "+ (data.customFrequency['noOfYears']>1?" "+data.customFrequency['noOfYears']+" years":" year")
                +" on "+(data.customFrequency['yearlyCustomTime'].toLowerCase()+" "+data.customFrequency['yearlyCustomDay'].toLowerCase()+" of "+data.customFrequency['yearlyCustomMonth'].toLowerCase());
              }
              else if(data.customFrequency['endMode']=='END_AFTER')
              {
                return "Take medication every "+ (data.customFrequency['noOfYears']>1?" "+data.customFrequency['noOfYears']+" years":" year")
                +" on "+(data.customFrequency['yearlyCustomTime'].toLowerCase()+" "+data.customFrequency['yearlyCustomDay'].toLowerCase()+" of "+data.customFrequency['yearlyCustomMonth'].toLowerCase())+(data.customFrequency['endMode']=='END_AFTER'? "(repeat "+data.customFrequency['endValue']+" times)" :""); 
              }
              else
              {
                return "Take medication every "+ (data.customFrequency['noOfYears']>1?" "+data.customFrequency['noOfYears']+" years":" year")
                +" on "+(data.customFrequency['yearlyCustomTime'].toLowerCase()+" "+data.customFrequency['yearlyCustomDay'].toLowerCase()+" of "+data.customFrequency['yearlyCustomMonth'].toLowerCase())+(data.customFrequency['endMode']=='END_AFTER'? "(repeat "+data.customFrequency['endValue']+" times)" :"")+" until "+this.Convert24hrto12hrsDate(data.endTime); 
              }
            }
            
          }
        }
      }
      else
      {
        return "Take medication daily until "+this.Convert24hrto12hrsDate(data.endTime);
      }
    }
    else if(data.uploadType=='AUTO')
    {
      if(data.endTime)
      {
        return "Take medication everyday "+" until "+ this.Convert24hrto12hrsDate(data.endTime);
      }
      else
      {
        return "Take medication everyday "+data.frequency;
      }
      
    }
  
  }



  onCancel(){
    this.dialogRef.close(false);
  }


  Convert24hrto12hrs(date)
  {
    if(date.includes("Morning") || date.includes("Afternoon") || date.includes("Night") || date.includes("SOS") || date.includes("Other"))
    {
      return date;
    }
    else
    {
     var s=date.split(",");
     
     if(s.length==1)
     {
       s[0]=new Date("1-1-2022 "+s[0]);
       s[0]=this.datePipe.transform(s[0], 'h:mm a');
       return s[0];
     }
     else
     {
       var l='';
       for(var i=0;i<s.length;i++)
       {
        s[i]=new Date("1-1-2022 "+s[i]);
        s[i]=this.datePipe.transform(s[i], 'h:mm a');
        l=l+s[i];
        if(i!=s.length-1)
        {
          l=l+',';
        }
       }
       return l;
     }
    }
  }


  Convert24hrto12hrsDate(date)
  {
    if(date.includes("Morning") || date.includes("Afternoon") || date.includes("Night") || date.includes("SOS") || date.includes("Other"))
    {
      return date;
    }
    else
    {
     var s=date;
     var l='';
     s=new Date(s);
     l=this.datePipe.transform(s, 'MMMM d,y h:mm a');
     return l;
     }
     
  }

  

}
