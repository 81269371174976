import { createAction, props } from "@ngrx/store";

export const loadDashboard = createAction(
    "[load dashboard Resolver] Load Dashboard "
); 

export const UpdateDashbaord = createAction(
    "[update dashboard Effect] Update dashboard",
    props<{ 
        patientList: any,
        upcomingConsultationsList:any,
        doctorsList:any,
        paymentsList:any
        ,paymentsFollowupList:any,
        refundsFollowupList:any,
        appointmentsFollowupList:any,
        getAppointmentsCount:any,
        getDaywiseAppointmentsCount:any,
        getConsultedPatientCountByDepartment:any,
        getRecentlyConsultedPatients:any,
        getRecentAvailableDoctors:any,
    }>()
);
export const AdminDashboardLoading = createAction(
    "[AdminDashboardLoading admin dashboard ] AdminDashboardLoading admin dashboard"
);
export const loadPatientList = createAction(
    "[load PatientList Resolver] loadPatientList "
); 
export const loadUpcomingConsultationsList = createAction(
    "[load UpcomingConsultationsList Resolver] loadUpcomingConsultationsList "
);
export const AdminUpcomingConsultationListLoading = createAction(
    "[AdminUpcomingConsultationListLoading admin upc ] AdminUpcomingConsultationListLoading admin upc"
);
export const loadDoctorsList = createAction(
    "[load DoctorsList Resolver] loadDoctorsList "
); 
export const loadAppointmentCount = createAction(
    "[load dashboard update] loadAppointmentCount "
); 
export const loadPaymentsList = createAction(
    "[loadPaymentsList Resolver] loadPaymentsList "
); 

export const loadFollowupList = createAction(
    "[loadFollowupList Resolver] loadPaymentsFollowupList "
); 
export const UpdatePatientList = createAction(
    "[UpdatePatientList dashboard Effect] UpdatePatientList dashboard",
    props<{ 
        patientList: any,
    }>()
);

export const UpdateUpcomingConsultationsList = createAction(
    "[UpdateUpcomingConsultationsList dashboard Effect] UpdateUpcomingConsultationsList dashboard",
    props<{ 
        upcomingConsultationsList:any,
    }>()
);
export const UpdateDoctorsList = createAction(
    "[UpdateDoctorsList dashboard Effect] UpdateDoctorsList dashboard",
    props<{ 
        doctorsList:any,
    }>()
);
export const UpdatePaymentsList = createAction(
    "[UpdatePaymentsList dashboard Effect] UpdatePaymentsList dashboard",
    props<{ 
        paymentsList:any
    }>()
);
export const UpdateFollowupList = createAction(
    "[UpdateFollowupList dashboard Effect] UpdateFollowupList dashboard",
    props<{ 
        paymentsFollowupList:any,
        refundsFollowupList:any,
        appointmentsFollowupList:any,
    }>()
);
export const UpdateAppointmentsCount = createAction(
    "[Update AppointmentsCount dashboard Effect] Update AppointmentsCount dashboard",
    props<{ 
        getAppointmentsCount:any,
        getDaywiseAppointmentsCount:any,
        getConsultedPatientCountByDepartment:any
    }>()
);
