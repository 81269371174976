<nb-card
  [ngClass]="{'gaintcard':viewType==1,'smallcard':viewType==2,'mediumcard':viewType==3,'mediumLarge':viewType==4}">
  <!-- <nb-card > -->
  <nb-card-header>
    <div style="float: left;" data-toggle="tooltip" data-placement="right" title="back">
      <span (click)="onCancel()" style="font-size: 25px;cursor: pointer;">
        <i class="fa-solid fa-arrow-left"></i>
      </span>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-xl-5">
        <nb-card [size]="'large'">
          <nb-card-header>
            Profile
          </nb-card-header>
          <nb-card-body>
            <div class="row">
              <div class="col-sm-12 d-flex justify-content-center icon-container pad-t-h">
                <div class="icon status-primary">
                  <nb-user [size]="'giant'" [name]='patientName' [onlyPicture]="true"></nb-user>
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-center h6 text-left fn-b-16">{{patientName}}
            </div>
            <div class="d-flex fn-12 justify-content-center">
              Mobile : {{patientNumber}}<br>
            </div>
            <!-- <div class="row"> -->
              <nb-tabset>
                <nb-tab tabTitle="Info">
                  <div class="col-12" *ngFor="let ele of elements;index as j">
                    <nb-card class="whiteback" [size]="ele.type">
                      <nb-card-body class="showgreen fn-12">
                        <span class="head-row fn-b-16">{{ele.field}}</span>
                        <hr class="hr-break">
                        <div class="row text-left">
                          <div class="col-12 fn-12">
                            {{ele.value}}
                          </div>
                          <div *ngFor="let child of ele?.child;index as i"
                            [ngClass]="{'pad-r-h col-md-7':i%2==0,'pad-l-h col-md-5':i%2!=0}">
                            <div class="row">
                              <div class="col-6 pad-r-h">
                                <strong>{{child.field}}:</strong>
                              </div>
                              <div class="col-6 pad-l-h">
                                {{child.value}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </nb-card-body>
                    </nb-card>
                  </div>
                </nb-tab>
                <nb-tab tabTitle="Vitals">
                  <div class="row">
                    <div class="col-6 pad-r-h">
                      <nb-card class="whiteback" [size]="'tiny'">
                        <nb-card-body class="showgreen" (click)="LargeModel(LargeModelContent,'Pulse')">
                          <i class="fas fa-heart-pulse fa-2x mx-2"></i><br>
                          <span class="fn-12">Pulse<br></span>
                          <strong>{{pulse}}</strong><br>
                          <span class="bottom-right-italic">{{pulseCapturedTime}}</span>
                        </nb-card-body>
                      </nb-card>
                    </div>
                    <div class="col-6 pad-l-h">
                      <nb-card class="whiteback" [size]="'tiny'">
                        <nb-card-body class="showgreen" (click)="LargeModel(LargeModelContent,'Blood saturation')">
                          <i class="fas fa-droplet fa-2x mx-2"></i><br>
                          <span class="fn-12">Blood saturation<br></span>
                          <strong>{{spO2}}</strong><br>
                          <span class="bottom-right-italic">{{spo2CapturedTime}}</span>
                        </nb-card-body>
                      </nb-card>
                    </div>
                  </div>
                  <div class="row">

                  
                    <div class="col-6 pad-r-h">
                      <nb-card class="whiteback" [size]="'tiny'">
                        <nb-card-body class="showgreen" (click)="LargeModel(LargeModelContent,'Blood pressure')">
                          <i class="fas fa-droplet fa-2x mx-2"></i><br>
                          <span class="fn-12">Blood pressure<br></span>
                          <strong>{{bloodPressure}}</strong><br>
                          <span class="bottom-right-italic">{{bpCapturedTime}}</span>
                        </nb-card-body>
                      </nb-card>

                    </div>
                    <div class="col-6   pad-l-h">
                      <nb-card class="whiteback" [size]="'tiny'">
                        <nb-card-body class="showgreen" (click)="LargeModel(LargeModelContent,'Blood glucose')">
                          <i class="fas fa-droplet fa-2x o mx-2"></i><br>
                          <span class="fn-12">Blood glucose<br></span>
                          <strong>{{bloodGlucose}}</strong><br>
                          <span class="bottom-right-italic">{{glucoCapturedTime}}</span>
                        </nb-card-body>
                      </nb-card>
                  </div>
                </div>
                </nb-tab>
                <nb-tab tabTitle="Reports">
                  <ng2-smart-table [settings]="settings1" [source]="source1">
                  </ng2-smart-table>
                </nb-tab>
                <nb-tab tabTitle="Prescription">
                  <ng2-smart-table [settings]="settings5" [source]="source5">
                  </ng2-smart-table>
                </nb-tab>
                <nb-tab tabTitle="Severity">
                  <nb-card [size]="'small'">
                    <nb-card-header>
                      <h6>{{severityText}} Severity
                        <div class="add" data-toggle="tooltip" *ngIf="showEdit" title="Edit Severity"
                          (click)="editPreScreening()"><i class="fas fa-edit fa-1x mx-2"></i></div>
                      </h6>
                    </nb-card-header>
                    <nb-card-body>
                      <div *ngIf="severityText==''">
                        No data available
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <nb-card *ngFor="let item of questions">
                            <nb-card-body>
                              <div class="row">
                                <div class="col-xs-12 col-md-12"><strong>{{item.question}}</strong></div>
                              </div>
                              <div class="row">
                                <div class="col-xs-12 col-md-10"><i>{{item.selectedOption}}</i></div>
                                <div class="col-xs-6 col-md-2">{{item.seviority}}</div>
                              </div>
                            </nb-card-body>
                          </nb-card>
                        </div>
                      </div>
                    </nb-card-body>
                  </nb-card>
                </nb-tab>
              </nb-tabset>
            <!-- </div> -->
          </nb-card-body>
        </nb-card>
      </div>
      <div [ngClass]="{ 'col-xl-7': !isFullScreen,'full-screen':isFullScreen }">
        <nb-card [size]="'large'">
          <nb-card-header id="source">
            Source
            <a (click)="toogleFullScreen()" class="float-r" [title]="isFullScreen?'Minimize':'Maximize'">
              <span *ngIf="isFullScreen">
                <nb-icon icon="compress" pack="fas"> </nb-icon>
              </span>
              <span *ngIf="!isFullScreen">
                <nb-icon icon="expand" pack="fas"> </nb-icon>
              </span>
            </a>

          </nb-card-header>
          <nb-card-body>
            <videosdk [meetingId]="meetingNumber" [from]="from" [user]="username"></videosdk>
          </nb-card-body>
        </nb-card>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <nb-card [nbSpinner]="loading" nbSpinnerStatus="primary" nbSpinnerSize="giant" nbSpinnerMessage="Loading">
          <nb-card-header>
            Prescription
            <p>Patient Notes: {{chiefComplaint}}</p>
          </nb-card-header>
          <nb-card-body [ngClass]="{'setMargin':applyStyles}">
            <form [formGroup]="consultationForm" autocomplete="off">
              <div class="form-body">
                <div class="row">
                  <div class="col-md-6 col-xl-4 form-group">
                    <label for="chiefComplaint"> Chief complaint <span aria-hidden="true"
                        style="color: red;">*</span></label>
                    <input type="text" id="chiefComplaint" class="form-control" placeholder="Chief complaint"
                      [disabled]="published" (selectItem)="suggestPrescription($event)"
                      [ngbTypeahead]="suggestComplaints" [resultFormatter]="formatter" [inputFormatter]="formatter"
                      ngbTooltip="Chief complaint" placement="top" formControlName="complaints">
                    <div *ngIf="submitted && pdf.complaints.errors" style="color:#DC3545; font-size: small;">
                      <div *ngIf="pdf.complaints.errors.required">Chief complaint is required</div>
                    </div>

                  </div>
                  <div class="col-md-6 col-xl-4 form-group">
                    <label for="symptoms">Symptoms</label>
                    <input type="text" id="symptoms" class="form-control" placeholder="Symptoms" [disabled]="published"
                      ngbTooltip="Symptoms" placement="top" formControlName="observation">
                  </div>
                  <div class="col-md-6 col-xl-4 form-group">
                    <label for="examinations">Examinations/Lab Findings</label>
                    <input type="text" id="examinations" class="form-control" placeholder="Examinations/Lab Findings"
                      [disabled]="published" ngbTooltip="Examinations/Lab Findings" placement="top"
                      formControlName="examinations">
                  </div>
                  <div class="col-md-6 col-xl-4 form-group">
                    <label for="relevantPoints">Relevant points From History</label>
                    <input type="text" id="relevantPoints" class="form-control" placeholder="History"
                      [disabled]="published" ngbTooltip="Relevant points from history" placement="top"
                      formControlName="relevantPoints">
                  </div>
                  <div class="col-md-6 col-xl-4 form-group">
                    <label for="diagnosys"> Diagnosis/ Provisional Diagnosis <span aria-hidden="true"
                        style="color: red;">*</span></label>
                    <input id="typeahead-http" type="text" class="form-control" placeholder="Diagnosis"
                      [disabled]="published" [ngClass]="{ 'is-invalid': submitted && pdf.diagnosis.errors }"
                      [ngbTypeahead]="searchFromService" ngbTooltip="Diagnosis/ Provisional Diagnosis" placement="top"
                      formControlName="diagnosis" />
                    <div *ngIf="submitted && pdf.diagnosis.errors" style="color:#DC3545; font-size: small;">
                      <div *ngIf="pdf.diagnosis.errors.required">Diagnosis/ Provisional diagnosis is required</div>
                    </div>
                  </div>
                  <div class="col-md-6 col-xl-4 form-group">
                    <label for="investigation">Suggested Investigations</label>
                    <input type="text" id="investigation" class="form-control" placeholder="Suggested Investigations"
                      [disabled]="published" ngbTooltip="Suggested Investigations" placement="top"
                      formControlName="investigation">
                  </div>
                </div>
                <div class="form-group">
                  <label for="clinicalNotes">Clinical Notes / Special Instruction</label>
                  <input type="text" id="clinicalNotes" class="form-control" placeholder="Clinical Notes"
                    [disabled]="published" ngbTooltip="Clinical Notes / Special Instruction" placement="top"
                    formControlName="notes">
                </div>
                <div class="" formArrayName="prescriptionList">
                  <label for="">Medication<span aria-hidden="true" style="color: red;">*</span></label>
                  <div class="form-group ">
                    <div class="col-12 pl-0" *ngIf="!published">
                      <button data-repeater-create nbButton status="primary" (click)="addPrescritption()">
                        <i class="fa fa-plus"></i> Add
                      </button>
                    </div>
                  </div>
                  <div *ngFor="let prescriptionForm of prescriptionList.controls; let i = index;" [formGroupName]="i">
                    <div class="row">
                      <div class="col-md-8 col-xl-4">
                        <label for="drug">Drug<span aria-hidden="true" style="color: red;">*</span></label>
                        <input [focusInputOnValueChange]="false" type="text" class="form-control" placeholder="Drug"
                          formControlName="drugName" [disabled]="published" [nbAutocomplete]="auto">
                        <nb-autocomplete #auto [handleDisplayFn]="displayFn" (selectedChange)="getDrugAlts(i,$event)">
                          <nb-option *ngFor="let drug of drugs[i] | async;" [value]="drug">
                            {{ drug.name }}
                          </nb-option>
                        </nb-autocomplete>
                        <input type="text" style="display: none;" formControlName="rxcui">
                      </div>

                      <div style="display:none;">
                        <label for="strength">Strength</label>
                        <input type="text" id="strength" nbInput fullWidth class="form-control" placeholder="Strength"
                          [disabled]="published" ngbTooltip="Strength" placement="top" formControlName="dosage">
                      </div>

                      <div class="col-md-4 col-xl-2">
                        <label for="instruction">Instructions</label>
                        <nb-select formControlName="instructions" fullWidth [disabled]="published">
                          <nb-option *ngFor="let instruction of instructions" [value]="instruction">{{instruction}}
                          </nb-option>
                        </nb-select>
                        <div style="color:#DC3545; font-size: small;" *ngIf="getValidityIns(i)">
                          Instructions is required
                        </div>
                      </div>
                      <div class="col-md-5 col-xl-3">
                        <label for="frequency">Frequency</label>
                        <nb-select multiple formControlName="frequency" fullWidth [disabled]="published">
                          <nb-option *ngFor="let frequency of frequencies" [value]="frequency">{{frequency}}</nb-option>
                        </nb-select>
                        <div style="color:#DC3545; font-size: small;" *ngIf="getValidityFreq(i)">
                          Frequency is required
                        </div>
                      </div>
                      <div class="col-md-5 col-xl-2">
                        <label for="duration">Duration</label>
                        <nb-select formControlName="duration" fullWidth [disabled]="published">
                          <nb-option *ngFor="let duration of durations" [value]="duration">{{duration}}</nb-option>
                        </nb-select>
                        <div style="color:#DC3545; font-size: small;" *ngIf="getValidityDue(i)">
                          Duration is required
                        </div>
                      </div>

                      <div class="col-md-2 col-xl-1 text-center " *ngIf="!published">
                        <button class="mt-2" nbButton status="primary" type="button" (click)="removePrescritption(i)"><i
                            class="fa fa-times"></i></button>
                      </div>
                      <div class="col-md-12 col-xl-3 mt-2" [hidden]="!showAltFlag[i]">
                        <label for="altDrugs">&nbsp;Alternative&nbsp;Drugs:</label>
                      </div>
                      <div class="col-md-12 col-xl-4 mt-2" [hidden]="!showAltFlag[i]">
                        <ng-select [items]="drugAlts[i] | async" [multiple]="true"
                          formControlName="altDrugs" bindLabel="name" placeholder="Alternative Drugs">
                        </ng-select>
                      </div>
                      <div class="col-md-12 col-xl-5 mt-2" role="group" aria-label="Alternative Drugs" [hidden]="!showAltFlag[i]">
                        <button type="button" style="min-height: 36px;margin-bottom: 5px;margin-right: 5px;"
                          class="js-programmatic-multi-set-val btn btn-sm " nbButton status="primary"
                          *ngFor="let item of drugAlts[i] | async | slice:0:4; let in=index"
                          (click)="addAltDrug(i,item)">
                          <i class="feather ft-plus"></i> {{item.name}}
                        </button>
                      </div>
                    </div>
                    <hr class="line-2">
                  </div>

                </div>
                <div class="" formArrayName="lifestylemodficationList">
                  <label for="">Life Style Modifications</label>
                  <div class="form-group ">
                    <div class="col-12 pl-0" *ngIf="!published">
                      <button data-repeater-create nbButton status="primary" (click)="addLifeStyleModfication()">
                        <i class="feather ft-plus"></i> Add

                      </button>
                      <div [ngClass]="{'setPadding':applyStyles,'display':!applyStyles}">
                        <button type="button"
                          style="min-height: 36px;margin-bottom: 5px;margin-left:2px;margin-right: 5px;"
                          class="js-programmatic-multi-set-val btn btn-sm " nbButton status="primary"
                          *ngFor="let item of modificationSuggestions | slice:0:3; let in=index"
                          (click)="addLifeStyleSuggestion(item)">
                          <i class="fa fa-plus"></i> {{item.lifeStyleChanges}}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="" *ngFor="let prescriptionForm of lifeStyleModficationFormGroup.controls; let i = index;">
                    <div [formGroupName]="i" class="row">
                      <div class="form-group  col-md-10 prl-1" style="padding-left:20px;">
                        <label for="Changes">Tips</label>
                        <input type="text" (keypress)="keyPressAlphanumeric($event)" id="changes" class="form-control"
                          placeholder="Life Style Modifications" [disabled]="published" ngbTooltip="life style changes"
                          placement="top" formControlName="changes">
                      </div>


                      <div class="form-group col-md-2 text-center " *ngIf="!published">
                        <button class="mt-2" nbButton status="primary" type="button"
                          (click)="removeLifeStyleModfication(i)"><i class="fa fa-times"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="isDisabled">

                <div class="form-actions" *ngIf="!published" style="text-align: center;">
                  <button type="submit" (click)="savePrescription()" style="margin-top:10px;" class="mr-1" nbButton
                    status="primary" [disabled]="consultationForm.invalid">
                    Save
                  </button>
                  <button type="submit" nbButton status="primary" (click)="review()"
                    style="margin-left:7px;margin-top:10px;">
                    Review & Publish
                  </button>
                  <button type="button" class="mr-1" nbButton status="primary" (click)="reset()"
                    style="margin-top:10px;margin-left:20px;">
                    Reset
                  </button>

                </div>
              </div>
            </form>
          </nb-card-body>
        </nb-card>
      </div>
    </div>

    <ng-template class="modal text-left" #LargeModelContent let-c="close" let-d="dismiss">
      <div>

        <div class="modal-body">
          <app-vitalsgraphview [vitalType]="vitalType" [patientVitals]="patientVitals"></app-vitalsgraphview>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn grey btn-outline-secondary" (click)="d('Close modal')">Close</button>
        </div>

      </div>
    </ng-template>