import { PaymentsRenderComponent } from './../admin-render-componets/admin-payment-render.component';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { LocalDataSource, ViewCell } from 'ng2-smart-table';
import { ConfirmDialogComponent } from 'src/app/shared/shared-dialogue-components/confirmation-dialog/confirm-dialog.component';
import { AppState } from 'src/app/store/root.reducer';
import { getPaymentsList } from 'src/app/store/selectors/home.selectors';
import { AdminService } from '../service/admin.service';

@Component({
  selector: 'app-payments-refunds',
  templateUrl: './payments-refunds.component.html',
  styleUrls: ['./payments-refunds.component.scss']
})
export class PaymentsRefundsComponent implements OnInit {
  appointmentPaymentsList: any = [];
  schedule="reschedule";
  cancle="cancle";
  message="message"
  settings = {
    actions: {
      edit:false,
      delete:false,
      add:false
  },
  pager:
    {
      perPage:10,
    },
    columns: {
      id:{
        title:"Appointment Id",
        type:'string',
      },
      customerName: {
        title: 'Patient Name',
        type: 'string',
      },
      
      customerMobile: {
        title: 'Patient Mobile',
        type: 'string',
      },
      startTime: {
        title: 'Date',
        type: 'string',
      },
      consultationfee:{
        title:'Amount',
        type:'string',
      },
      paymentStatus:{
        title: 'Payment Status',
        type: 'string',
      },
      refundStatus:{
        title: 'Refund Status',
        type:'string',
      },
      paymentId:{
        title:'Payment Id',
        type:'string'
      },
      status:{
        title:"Appointment Status",
        type:'string'
      },
      actions:{
        title: 'Actions',
        type: 'custom',
        filter:false,
        renderComponent: PaymentsRenderComponent
      }
      
    },
  };

  source: LocalDataSource = new LocalDataSource();

  constructor(private store:Store<AppState>) { }

  ngOnInit() {
    this.store.select(getPaymentsList).subscribe(data => {
      if(data!=null)
      {
        this.appointmentPaymentsList=data;
        this.appointmentPaymentsList=this.appointmentPaymentsList.filter((ele)=>
        {
          if(ele.createdDate)
          {
            return true;
          }
        })
        var data1=this.appointmentPaymentsList.map((ele)=>{
          if (ele.createdDate) {
            let appointmentCreatedTime = new Date(ele.createdDate);
            let appointmentTime=new Date(ele.startTime);
            appointmentCreatedTime.setHours(appointmentCreatedTime.getHours() + 1);
            if (new Date().getTime() > appointmentCreatedTime.getTime() && new Date().getTime() <  appointmentTime.getTime())
            {
              return {
                ...ele,
                regenerate:true,
                resend:false,
              }
             }
            else
            {   return {
              ...ele,
              regenerate:false,
              resend:new Date().getTime() <  appointmentTime.getTime()?true:false,
              }
            }  
            
          }
        });
        this.source.load(data1);
      }
    });
  }
}


