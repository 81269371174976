import { Component, OnInit, Inject, Input } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { AdminService } from 'src/app/adminportal/service/admin.service';
import { APP_CONSTANTS } from '../../constants';
import { UnsubscribeOnDestroyAdapter } from '../../services/UnsubscribeOnDestroyadapter';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  @Input() title: string;
  @Input() from: string;
  @Input() data: any;
  message: string;
  loading: boolean = false;
  constructor(private toastrService: NbToastrService, private apiService: AdminService, private dialogRef: NbDialogRef<ConfirmDialogComponent>) {
    super()
  }

  ngOnInit() {
    this.title = this.title;
  }

  onConfirm(): void {
    this.loading = true;
    if (this.from == 'customer')
      this.customerDelete(this.data);
    else if (this.from == 'doctorManagement')
      this.dmDelete(this.data)
    else if (this.from == 'refund')
      this.refundInit(this.data);
    else if (this.from == 'role')
      this.roleDelete(this.data);
    else if (this.from == 'users')
      this.userDelete(this.data);
    else if (this.from == 'schedule')
      this.scheduleDelete(this.data);
    else if(this.from=='preScreening')
      this.prescreeningDelete(this.data);
    else if (this.from == 'reports')
      this.skip();
    else {
      this.skip();
    }
  }
  prescreeningDelete(data:any){
    this.subs.sink=this.apiService.deletePrescreening(data.id).subscribe((data: any) => {
      this.loading = false;
      this.dialogRef.close(true);
      this.toastrService.show('', 'Question deleted successfully.', { duration: APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON });

    }, error => {
      console.log(error);
    });
  }
  scheduleDelete(data: any) {
    this.subs.sink=this.apiService.deleteAppointment(data).subscribe((data: any) => {
      this.loading = false;
      this.dialogRef.close(true);
      this.toastrService.show('', 'Appointment deleted successfully.', { duration: APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON });
    })
  }
  userDelete(data1: any) {
    this.subs.sink=this.apiService.deleteUser(data1).subscribe((data: any) => {
      this.loading = false;
      this.dialogRef.close(true);
      this.toastrService.show('', 'User deleted successfully.', { duration: APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON });

    }, error => {
      console.log(error);
    });
  }
  roleDelete(data1: any) {
    this.subs.sink=this.apiService.deleteUserRole(data1.id).subscribe((data: any) => {
    }, error => {
      console.log(error);
      this.loading = false;
      this.toastrService.show('', error.error.text, { duration: APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON });
      if (error.error.text == 'role deleted successfully')
        this.dialogRef.close(true);
    });
  }
  refundInit(data1: any) {
    this.subs.sink=this.apiService.intiateRefund(data1).subscribe((resData: any) => {
      this.loading = false;
      this.dialogRef.close(true);
      this.toastrService.show('', 'Refund request has been intiated successfully.', { duration: APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON });
    }, error => {
      var data = error;
    });
  }
  dmDelete(data1: any) {
    this.subs.sink=this.apiService.deleteDoctor(data1.username).subscribe((data: any) => {
      this.loading = false;
      this.dialogRef.close(true);
      this.toastrService.show('', 'Doctor deleted successfully.', { duration: APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON });
    }, error => {
      console.log(error);
    });
  }
  customerDelete(data1: any) {
    this.subs.sink=this.apiService.deleteCustomer(data1.memberDTO.personId).subscribe((data: any) => {
      this.loading = false;
      this.dialogRef.close(true);
      this.toastrService.show('', 'Customer deleted successfully.', { duration: APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON });
    }, error => {
      console.log(error);
      this.loading = false;
    });
  }
  skip() {
    this.loading = false;
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}
