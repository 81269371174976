<nb-card [ngClass]="{'gaintcard':viewType==1,'smallcard':viewType==2,'mediumcard':viewType==3,'mediumLarge':viewType==4}"  [nbSpinner]="loading" nbSpinnerStatus="primary" nbSpinnerSize="giant" nbSpinnerMessage="Loading">

    <nb-card-header>Slot Details</nb-card-header>

    <nb-card-body>
        <form [formGroup]="personaldetailsForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-md-10">
                    Doctor Available Slots
                 </div>
                 <div class="col-md-1">
                    <button nbButton status="primary" type="button" title="add slots" (click)="addTimeSlots()"><i
                        class="fa fa-plus"></i> </button>
                </div>
             </div>
            <div class="row" *ngIf="!showdata">
                <div class="col-12">
                    No available slots
                </div>
            </div>
            <div class="" formArrayName="timeSlotDTOList" *ngIf="showdata">
                <div class="" *ngFor="let appointmentTimeSlotsForm of getTimeSlotFormrGoup.controls; let j = index;">
                    <div [formGroupName]="j" class="row">
                        <fieldset class="form-group position-relative col-md-2 ">Time Slots</fieldset>
                        <fieldset class="form-group position-relative col-md-4">
                            <select class="form-control" formControlName="startTime"
                                [ngClass]="{ 'is-invalid': submitted }">
                                <option value="">From</option>
                                <option *ngFor="let obj of timeSlots" [value]="obj.key">{{obj.name}}</option>
                            </select>
                        </fieldset>
                        <fieldset class="form-group position-relative col-md-4">
                            <select class="form-control" formControlName="endTime"
                                [ngClass]="{ 'is-invalid': submitted  }">
                                <option value="">To</option>
                                <option *ngFor="let obj of timeSlots" [value]="obj.key">{{obj.name}}</option>
                            </select>
                        </fieldset>

                        <fieldset class="col-md-1" *ngIf="j==0">
                            <button nbButton status="primary" type="button" (click)="removeTimeSlots(j)"><i
                                    class="fa fa-times"></i></button>
                        </fieldset>
                        <fieldset class="col-md-1" *ngIf="j!=0">
                            <button nbButton status="primary" type="button" (click)="removeTimeSlots(j)"><i
                                    class="fa fa-times"></i></button>
                        </fieldset>
                    </div>
                </div>
            </div>

        </form>
    </nb-card-body>
    <nb-card-footer>
        <div class="row">
            <div class="col-md-6">
                <button [disabled]="personaldetailsForm.invalid" type="submit" (click)="onSubmit()" nbButton fullWidth status="primary">
                    Update
                </button>
            </div>
            <div class="col-md-6">
                <button nbButton fullWidth status="primary" (click)="goBack()">
                    Cancel
                </button>
            </div>
        </div>
    </nb-card-footer>
</nb-card>