import { environment } from "src/environments/environment";
export const RXNAV_URL = environment.RXNAV_URL;
export const GETCD10_URL = "https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=name&df=name&terms=";
export const APP_CONSTANTS = {
  get APP_ROUTE() {
    return {
      ADMIN: 'admin',
      DOCTOR: 'doctor',
      AUTH: 'auth',
    };
  },
  get REGEX() {
    return {
      NUMBERS_ONLY: "^[0-9]*$",
      NUMBERS_ONLY_START_WITH_ONE:"^[1-9]{1}[0-9]{0,1}$",
      ALPHABET_ONLY:"[a-zA-Z]+[a-zA-Z\s]",
      ALPHABET_WITH_SPACE:"^[A-Za-z]+[A-Za-z ]*$",
      ALPHABET_WITH_DOT:"^[A-Za-z.]+[A-Za-z. ]*$",
      MOBILE_NUM:"^[6-9](?!0+$)\d{9}$",
      PASSWORD:"^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$",
      ALPHA_NUMERIC_WITH_QUESTIONMARK:"^[a-zA-Z0-9.? ]*$",
      FLOATING_NUMBERS:"^(?!0\d)\d*(\.\d+)?$",
      EMAIL:"[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}",
      EMAIL2:"^[a-zA-Z0-9][a-zA-Z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$",
      ADDRESS:"^[a-zA-Z]+(\s[a-zA-Z]+)?$",
      POSTAL_CODE:"^(?!0+$)\d{6}$",
      FLOAT_NUM_UPTO_FIVE_DIGITS:"^-?[0-9]\\d{0,4}(\\.\\d{1,5})?$",
      ALPHANUMERIC_WITH_SPACE:"^[a-zA-Z0-9. ]*$",
      ALPHANUMERIC:"^[a-zA-Z0-9.]*$",
    }
  },
  get LOGIN_ROUTE() {
    return {
      LOGIN: 'login',
      LOGOUT: 'logout',
      FORGOT_PASSWORD: 'forgot-password',
      PATIENT: 'patientconsultation/:appointmentid',
      PATIENTMEETVIEW: 'patients-sdk-view',
      PRESCRIPTIONPDF: 'prescriptionpdf/:meetingid/:personid',
      PRIVACY_POLICIES: 'privacy-policy',
      STATUSMESSAGE: 'statuspage/:message',
      TERMS_CONDITIONS: 'terms-conditions',
      UPLOAD_REPORTS:'recordsupload/:mobileno',
      PATIENT_AUTO: 'patientvideoconsultation/:appointmentid',

    };
  },
  get DOCTOR_ROUTE() {
    return {
      DASHBOARD: 'dashboard',
      SCHEDULE: 'Schedule',
      PATIENTS: 'patients',
      APPOINTMENTS: 'appointments',
      REGISTER: 'register',
      DOCTOR_PATIENT_PROFILE: 'doctor-patients-view',
      CHANGE_PASSWORD: 'change-password',
      REDIRECT: 'redirect-dashboard',

    };
  },
  get ADMIN_ROUTE() {
    return {
      HOME: 'home',
      ADMIN_HOME: 'admin-home',
      DOCTORS: 'doctors',
      DOCTOR: 'doctors',
      PATIENTS: 'patients',
      DOCTOR_PROFILE: 'doctor-profile',
      ADMIN_PATIENT_PROFILE: 'admin-patients-view',
      PATIENT_PROFILE: 'customer-profile',
      UPCOMING_CONSULTATIONS: 'upcoming-consultations',
      PAYMENTS: 'payments',
      FOLLOW_UP_DESK: 'follow-up-desk',
      FOLLOW_UP_DESK_VIEW: 'follow-up-desk-view',
      USER_MANAGEMENT: 'user-management',
      CUSTOMER_MANAGEMENT: 'customer-management',
      USER_ROLES: 'user-roles',
      PRE_SCREENING: 'pre-screening',
      DOCTOR_MANAGEMENT: 'doctor-management',
      ADMIN_DOCTORS_VIEW: 'admin-doctors',
      CAREMANAGER_DOCTORS: 'caremanager-doctors',
      CHANGE_PASSWORD: 'change-password',
      BPS_ASSESMENT:'bps-assesment',
      ROUTINE:'routine'
    };
  },
  get REST_API_URL() {
    return {
      GET_USER_ROLES: 'api/v1/getRoles',
      ADMIN_LOGIN: 'api/v1/adminLogin',
      DOCTOR_LOGIN: 'login',
      LOGIN: 'auth/logout',
      GET_CUSTOMER_DETAILS: 'api/v1/getCustomerDetails',
      GET_ALL_CUSTOMER_APPTS: 'api/v1/getAllCustomerAppointments',
      GET_DOCTORS: 'api/v1/getDoctors',
      GET_ALL_APPONT_PAYMENTS: 'api/v1/getAllAppointmentPayments',
      GET_ALL_PAYMENTS_FOLLOWUPS: 'api/v1/getAllPaymentsFollowups',
      GET_ALL_REFUNDS_FOLLOWUPS: 'api/v1/getAllRefundsFollowups',
      GET_ALL_APPOINTMENT_FOLLOWUPS: 'api/v1/getAllAppointmentFollowups',
      GET_REGISTRATION: "api/v1/getRegistration/",
      GET_DOCTOR_APPOINTMENTS: 'api/v1/getDoctorAppointments/',
      GET_CONSULTED_PATIENTS: 'api/v1/getConsultedPatients/',
      UPDATE_REGISTRATION: 'api/v1/updateUser/',
      SAVE_REGISTRATION: 'api/v1/saveRegistration/',
      GET_APP_COUNT: "api/v1/getAppointmentsCount",
      GET_DAYWISE_APPS_COUNT: "api/v1/getDaywiseAppointmentsCount",
      GET_APPS_BY_DEPT: "api/v1/getConsultedPatientCountByDepartment",
      GET_REC_CON_PAT: "api/v1/getRecentlyConsultedPatients",
      GET_REC_AVA_DOC: "api/v1/getRecentAvailableDoctors",
      GET_DOC_APP_COUNT: 'api/v1/getDoctorAppointmentsCount/',
      GET_DOC_DAYWISE_APPS_COUNT: "api/v1/getDoctorDaywiseAppointmentsCount/",
      GET_DOC_REC_CON_PAT: "api/v1/getDoctorRecentlyConsultedPatients/",
      GET_DOC_PRESCRIPTION: 'api/v1/getDoctorPrescriptions/',
      EDIT_PRESCRIPTION: 'robotapi/editPrescription',
      B2B_EDIT_PRESCRIPTION: 'api/v1/b2bEditPrescription',
      B2B_PUBLISH_PRESCRIPTION: 'api/v1/b2bPublishPrescription',
      PUBLISH_PRESCRIPTION: 'robotapi/publishPrescription',
      GET_IMAGE_BASE64: "api/v1/getImageBase64String",
      GET_USER_PRESCRIPTIONS: "robotapi/getUserPrescriptions/",
      B2B_GET_USER_PRESCRIPTIONS: "api/v1/b2bGetUserPrescriptions/",
      SUGGEST_COMPLIANTS: "api/v1/suggestComlaints",
      GET_MEDICINE: "api/v1/getMedicine/",
      GET_MEDICINE_BY_GENERIC: "api/v1/getMedicinesByGeneric/",
      SUGGEST_PRESCRIPTIONS: "api/v1/suggestPrescription",
      GET_MEDICINE_LIST: "api/v1/getMedicineList",
      GET_ALL_USERS: "api/v1/getUserRoles/",
      SAVE_ROLES: "api/v1/saveRoles/",
      SAVE_ADMIN_USER: "api/v1/saveAdminUser/",
      SAVE_PATIENT_REGISTRATION: "api/v1/savePatientRegistration/",
      GET_MEMBERID_BY_PHONE: "api/v1/getMemberIdByPhone/mobile?mobile=",
      REGISTER_MOBILE: "api/v1/registerMobile/",
      IMPORT_PATIENT_DATA: 'api/v1/importPatientData',
      UPDATE_APPROVED_REFUNDS: 'api/v1/updateApprovedRefunds',
      SAVE_REG_FROM_EXCEL: "api/v1/saveRegistrationFromExcel",
      DELETE_USER_ROLES: "api/v1/deleteUserRoles/",
      DELETE_APPOINTMENT: "api/v1/deleteAppointment/",
      DELETE_PRESCREENING:'api/v1/deletePreScreeningQuestionsById/',
      DELETE_ROLE: "api/v1/deleteRole/",
      CANCEL_APPOINTMENT: "api/v1/cancelAppointment/",
      GET_ALL_CUSTOMER_APPOINTMENTS: "api/v1/getAllCustomerAppointments",
      SEND_OBJ_SMS: "api/v1/sendObjSMS/",
      REGENERATE_PAYMENT_LINK: "api/v1/regeneratePaymentLink/",
      RESEND_PAYMENT_LINK: "api/v1/resendPaymentLink/",
      REFUND_REQUEST: "api/v1/refundRequest/",
      DELETE_PROFILE: "api/v1/deleteProfile/",
      DELETE_DOCTOR: "api/v1/deleteDoctor/",
      GET_BLOCKED_REQUESTS: "api/v1/getMyBlockedRequests/",
      GET_AVAILABLE_TIME_SLOTS: "api/v1/getAvailableDocTimeSlots/",
      GET_APPOINTMENT_DETAILS: "api/v1/getAppointmentDetails/",
      DOWNLOAD_PDF: "api/v1/downloadPdf/",
      GET_PATIENT_DETAILS_BY_ID: "api/v1/getPatientDetailsById/",
      GET_ALL_VITALS_DETAILS: "api/v1/getAllVitalsDetails/",
      SAVE_MEETING_ID: "api/v1/saveMeetingId/",
      GET_ALL_VITALS_REPORT: "api/v1/getAllVitalsReport/",
      GET_PROFILE_AND_PRESCRIPTIONS: "api/v1/getUserProfileAndPrescriptions/",
      GET_USER_PRESCRIPTION_DETAILS: 'api/v1/getUserPrescriptionsDetails/',
      GET_LIFESTYLE_MODIFICATIONS: "api/v1/getLifeStyleModificationSuggestions/",
      SEARCH_ICD: "api/v1/searchICD/",
      EDIT_PATIENT_PRESCRIPTION: 'api/v1/editPrescription',
      PUBLISH_PATIENT_PRESCRIPTION: 'api/v1/publishPrescription',
      GET_CUSTOMER_DETAILS_BY_MOBILE: 'api/v1/getCustomerDetailsByMobile/',
      SAVE_FOLLOWUPS: "api/v1/saveFollowups/",
      RESCHEDULE_APPOINTMENT: "api/v1/rescheduleAppointment/",
      GET_CONSULTED_PATIENTS_DETAILS: "api/v1/getConsultedPatientDetails/",
      GET_DOCTOR_APPOINTMENT_HISTORY: "api/v1/getDoctorAppointmentsHistory/",
      GET_PRESCRIPTION_VITALS_DETAILS: "api/v1/getPrescriptionVitalsDetails/",
      GET_MEETING_DETAILS: "api/v1/getmeetingdetails/",
      UPDATE_CHECKIN_CHECKOUT: "api/v1/updateCheckInCheckOut/",
      SAVE_MEETING_DETAILS: "api/v1/saveMeetingDetails/",
      GET_OTP: "forgotPasswordSendOTP/",
      CHANGE_PASSWORD: "changePassword",
      GET_ROUTINE: "api/v1/getRoutineEventInstances?date=",
      GET_PRESCRIPTION_DETAILS: "api/v1/getPrescription/",
      GET_ALL_QUESTIONS: "api/v1/getQuestions/",
      GET_ATTACHMENT_BY_REPORT_NO: "api/v1/getReportAttachmentByRecordNO/",
      GET_ACTIVE_MEDICATION: "api/v1/getAllMedications/",
      GET_USER_MEDICATION: "api/v1/getAllMedications/",
      GET_CUSTOMER_APPOINTMENTS: "api/v1/getCustomerAppointments/",
      GET_PATIENTS_CONSULTATION_HISTORY: "api/v1/getPatientConsultationHistory/",
      UPDATE_PRESCRIPTION_STATUS: "api/v1/updatePrescriptionStatus/",
      UPDATE_USER_STATUS:"api/v1/updateUserStatus/",
      UPLOAD_VITALS: "api/v1/saveVitalsData/",
      SAVE_PATIENT_REPORT_UPLOAD: "api/v1/savePatientReportUpload/",
      GET_DOCTOR_FREE_SLOTS: "api/v1/getDoctorsFreeSlots/",
      BOOK_APPOINTMENT: "api/v1/bookAppointment/",
      CREATE: "api/v1/create",
      GET_VITALS_DETAILS_BY_RECORD_NO: "api/v1/getAllVitalsDetailsByRecordNo/",
      UPDATE_AVAIALBLE_SLOTS_DAY: "api/v1/updateAvailableSlotsDay/",
      UPDATE_FCM_TOKEN: "api/v1/updateFcmToken/",
      UPDATE_STATUS: "api/v1/updateStatus/",
      UPDATE_DOCTOR_STATUS:"api/v1/getDoctorDetailsbyName/",
      GET_QUESTIONS_BY_COMPLAINT:"api/v1/getPreScreeningQuestionsByCheifCompliant/",
      GET_ADMIN_PRESCREENING_QUESTIONS:'api/v1/getAllPreScreeningQuestions',
      GET_ADMIN_PRESCREENING_COMPLAINTS:'api/v1/getAllCheifCompliants',
      GET_ADMIN_PRESCREENING_DETAILS_BY_ID:"api/v1/getPreScreeningDetailsByPreScreeningId/",
      GET_CHILD_QUESTIONS_BY_CHEIFCOMPLAINT:'api/v1/getPreScreeningChildQuestionsByCheifCompliant/',
      GET_PRESCREENING_DETAILS_BY_APPOINTMENTID:'api/v1/getPreScreeningAnswersByAppointmentId/',
      SAVE_ADMIN_PRESCREENING:'api/v1/savePreScreeningQuestions',
      SAVE_PRESCREENING_ANSWERS:"api/v1/savePreScreeningAnswers",
      CHECK_PAYMENT_STATUS:"api/v1/checkAppointmentStatus/",      
      SAVE_ASSESMENT_USER_ANSWERS:"api/v1/saveUserAnswer",
      GET_WAKEUP_SLEEP_TIME:"api/v1/getWakeupandSleeptime/",
      GET_ROUTINE_MASTER_DATA:"api/v1/getRoutineMasterdata",
      DELETE_ROUTINE_EVENT:"api/v1/deleteRoutineEventInstance",
      UPDATE_ROUTINE:"api/v1/updateRoutineEvent",
      SAVE_ROUTINE:"api/v1/saveRoutineEvent",
      GET_EXCERCISES:"api/v1/getExercises",
      SAVE_UPDATE_ROUTINE_EVENT_INSTANCE:"api/v1/saveOrUpdateRoutineEventInstance",
      GET_DEVICE_TOKEN:"api/v1/getDeviceToken/",
      CREATE_ROUTINE_MASTER_DATA:"api/v1/createRoutineMasterdata",
      GET_ALL_CHIEF_COMPLIANTS:"api/v1/getAllCheifCompliants",
      SUBMIT_USER_ANSWERS:"api/v1/submitUserAnswers/",
      SUBMIT_EHR:"api/v1/submitEHR/"
    }
  },
  get SESSION_IGNORE() {
    return [
      APP_CONSTANTS.REST_API_URL.GET_USER_ROLES,
      APP_CONSTANTS.REST_API_URL.ADMIN_LOGIN,
      APP_CONSTANTS.REST_API_URL.DOCTOR_LOGIN,
      APP_CONSTANTS.REST_API_URL.GET_APPOINTMENT_DETAILS,
      APP_CONSTANTS.REST_API_URL.DOWNLOAD_PDF,
      APP_CONSTANTS.REST_API_URL.GET_OTP,
      APP_CONSTANTS.REST_API_URL.CHANGE_PASSWORD,
      APP_CONSTANTS.REST_API_URL.GET_REGISTRATION,
      APP_CONSTANTS.REST_API_URL.SAVE_REGISTRATION,
      APP_CONSTANTS.REST_API_URL.SAVE_PATIENT_REPORT_UPLOAD,
      APP_CONSTANTS.REST_API_URL.GET_PRESCREENING_DETAILS_BY_APPOINTMENTID,
      APP_CONSTANTS.REST_API_URL.GET_QUESTIONS_BY_COMPLAINT,
      APP_CONSTANTS.REST_API_URL.SAVE_PRESCREENING_ANSWERS,

    ];
  },
  get OUTSIDE_URlS() {
    return [
      environment.RXNAV_URL,
      environment.FCM_URL
    ];
  },
  get CONFIG_DETAILS() {
    return {
      TOAST_BAR_DURATION_COMMON: 2000,
      PRODUCT_LOGO_PATH: "../../../assets/images/logo1.png",
      AUTO_REFRESH_COMMON: 5 * 60 * 1000,
      DOC_STATUS_AUTO_REFRESH: 2 * 60 * 1000,
      BPS_DEFAULT_TIME_MIN: 60
    }
  },
  get TIME_SLOTS() {
    return [
      { key: "00:00:00", name: "12:00 AM" },
      { key: "00:30:00", name: "12:30 AM" },
      { key: "01:00:00", name: "1:00 AM" },
      { key: "01:30:00", name: "1:30 AM" },
      { key: "02:00:00", name: "2:00 AM" }, { key: "02:30:00", name: "2:30 AM" },
      { key: "03:00:00", name: "3:00 AM" }, { key: "03:30:00", name: "3:30 AM" },
      { key: "04:00:00", name: "4:00 AM" }, { key: "04:30:00", name: "4:30 AM" },
      { key: "05:00:00", name: "5:00 AM" }, { key: "05:30:00", name: "5:30 AM" },
      { key: "06:00:00", name: "6:00 AM" }, { key: "06:30:00", name: "6:30 AM" },
      { key: "07:00:00", name: "7:00 AM" }, { key: "07:30:00", name: "7:30 AM" },
      { key: "08:00:00", name: "8:00 AM" },
      { key: "08:30:00", name: "8:30 AM" },
      { key: "09:00:00", name: "9:00 AM" },
      { key: "09:30:00", name: "9:30 AM" },
      { key: "10:00:00", name: "10:00 AM" },
      { key: "10:30:00", name: "10:30 AM" },
      { key: "11:00:00", name: "11:00 AM" },
      { key: "11:30:00", name: "11:30 AM" },
      { key: "12:00:00", name: "12:00 PM" },
      { key: "12:30:00", name: "12:30 PM" },
      { key: "13:00:00", name: "1:00 PM" },
      { key: "13:30:00", name: "1:30 PM" },
      { key: "14:00:00", name: "2:00 PM" },
      { key: "14:30:00", name: "2:30 PM" },
      { key: "15:00:00", name: "3:00 PM" },
      { key: "15:30:00", name: "3:30 PM" },
      { key: "16:00:00", name: "4:00 PM" },
      { key: "16:30:00", name: "4:30 PM" },
      { key: "17:00:00", name: "5:00 PM" },
      { key: "17:30:00", name: "5:30 PM" },
      { key: "18:00:00", name: "6:00 PM" },
      { key: "18:30:00", name: "6:30 PM" },
      { key: "19:00:00", name: "7:00 PM" },
      { key: "19:30:00", name: "7:30 PM" },
      { key: "20:00:00", name: "8:00 PM" },
      { key: "20:30:00", name: "8:30 PM" },
      { key: "21:00:00", name: "9:00 PM" },
      { key: "21:30:00", name: "9:30 PM" },
      { key: "22:00:00", name: "10:00 PM" },
      { key: "22:30:00", name: "10:30 PM" },
      { key: "23:00:00", name: "11:00 PM" },
      { key: "23:30:00", name: "11:30 PM" },
      { key: "23:45:01", name: "12:00 AM" },
    ]
  }
}

