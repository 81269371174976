import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { AppState } from 'src/app/store/root.reducer';
import { Store } from '@ngrx/store';
import { ConfirmDialogComponent } from 'src/app/shared/shared-dialogue-components/confirmation-dialog/confirm-dialog.component';
import { AdminService } from '../service/admin.service';
import { APP_CONSTANTS } from 'src/app/shared/constants';
import { UnsubscribeOnDestroyAdapter } from 'src/app/shared/services/UnsubscribeOnDestroyadapter';

@Component({
    template: `
    <span *ngIf = "paymentstatus" data-toggle="tooltip" title="Re Generate Payment link" (click)="regeneratePaymentlink()"><i  class="fa fa-share-from-square"></i></span>
    <span *ngIf = "resendstatus" data-toggle="tooltip" title="Re Send Payment link" (click)="resendPaymentlink()"><i  class="fa fa-paper-plane"></i></span>
    <span *ngIf = "refundsstatus " data-toggle="tooltip" title="Initiate Refund" (click)="intiateRefund()"><i  class="fa fa-credit-card"></i></span>
    <span *ngIf="!paymentstatus && !resendstatus && !refundsstatus" title="No action Needed">--</span>
    `,
  })
  export class PaymentsRenderComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  
    renderValue: string;
    title:string;
    active:boolean=false;
    paymentstatus:boolean=false;
    refundsstatus:boolean=false;
    resendstatus:boolean=false;
  
  
    @Input() value: string | number;
    @Input() rowData: any;
    username:string;
    constructor(private dialogService: NbDialogService,private store:Store<AppState>,private toastrService: NbToastrService,private apiService:AdminService){
      super()
    }
  
    ngOnInit() {
      var data1=JSON.parse(JSON.stringify(this.rowData));
      this.paymentstatus=data1?.status == 'SCHEDULED' && data1?.paymentStatus && data1?.paymentStatus == 'Pending' && data1?.regenerate;
      this.refundsstatus=data1?.paymentStatus && data1?.paymentStatus == 'Done' && data1?.refundStatus == null;
      this.resendstatus=data1?.status == 'SCHEDULED' && data1?.paymentStatus && data1?.paymentStatus == 'Pending' && !data1?.regenerate && data1?.resend;
    }
  
    resendPaymentlink()
    {
        var data1=JSON.parse(JSON.stringify(this.rowData));
        delete data1.regenerate;
        delete data1.resend;
        this.subs.sink=this.apiService.resendPaymentLink(data1.paymentId).subscribe((resData: any) => {
          if(resData.success)
          {
            this.toastrService.show('','Payment link has been sent successfully.',{duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
          }
        }, error => {
          var data = error;
        });
  
    }
    regeneratePaymentlink()
    {
        var data1=JSON.parse(JSON.stringify(this.rowData));
        delete data1.regenerate;
        delete data1.resend;
        this.subs.sink=this.apiService.regeneratePaymentLink(data1).subscribe((resData: any) => {
          this.toastrService.show('','Payment link has been sent successfully.',{duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
        }, error => {
          var data = error;
        });
  
    }
    intiateRefund()
    { 
      var data1=JSON.parse(JSON.stringify(this.rowData));
      // delete data1.regenerate;
      delete data1.regenerate;
      delete data1.resend;
      const message = `Are you sure, you want to intiate refund for this payment?`;
      const dialogRef=this.dialogService.open(ConfirmDialogComponent, {
          context:{
            title:message,
            from:'refund',
            data:data1
          },
          autoFocus: false
        });
      }
  }