<nb-card [nbSpinner]="loading" nbSpinnerStatus="primary" nbSpinnerSize="giant" nbSpinnerMessage="Loading">
  <nb-card-body>
    <div class="col-12">
    <nb-tabset responsive="true" #tabset (changeTab)="changeEvent($event)">
      <nb-tab *ngFor="let category of questions;let i = index" tabTitle="{{category.categoryName}}" [active]="i==active" tabId={{i}}>
        <div *ngFor="let question of category.socialEngagementQuestionsDTOList;let j = index">
          <div class="row" *ngIf="question.optionType=='text'">
            <div class="col-xl-3">
              <label class="form-control-label">{{question.question}}</label>
            </div>
            <div class="col-xl-9">
              <input style="width: 100%;" class="form-control" placeholder={{question.question}}
              [(ngModel)]="question.userAnswerDTO.userAnswer"
              [disabled]="question.id == 1 || question.id == 2 || question.id == 3">
            </div>
          </div>
          <div class="row" *ngIf="question.optionType =='checkbox_text'">
            <div class="col-xl-3">
              <label class="form-control-label">{{question.question}}</label>
            </div>
            <div class="col-xl-9">
              <div class="m-t-b-10">
              <nb-select fullWidth [(ngModel)]="question.userAnswerDTO.userAnswerList" multiple>
                <nb-option *ngFor="let option of question.options" [value]="option">
                  {{option}}
                </nb-option>
              </nb-select>
            </div>
              <div class="m-t-b-10" *ngIf="question.freetextQuestion != null">
                <input style="width: 100%;" class="form-control" placeholder={{question.freetextQuestion}}
                  [(ngModel)]="question.others">
              </div>
            </div>
          </div>
          <div class="row" *ngIf="question.optionType =='radio' || question.optionType =='radio_text'">
            <div class="col-xl-3">
              <label class="form-control-label">{{question.question}}</label><br>
            </div>
            <div class="col-xl-9">
              <nb-radio-group class="form-control" style="display:contents;" [disabled]="question.id == 4"
              [(ngModel)]="question.userAnswerDTO.userAnswer" [name]="question.userAnswerDTO.questionId">
              <nb-radio class="example-radio-button" style="display:inline;" *ngFor="let option of question.options"
                [value]="option">
                {{option}}
              </nb-radio>
            </nb-radio-group>
            <div *ngIf="question.freetextQuestion != null">
              <input style="width: 100%;" class="form-control" placeholder={{question.freetextQuestion}}
                [(ngModel)]="question.others">
            </div>
            </div>
          </div>
          <div class="row"
            *ngIf="question.optionType =='table_schedule_television' || question.optionType =='table_schedule_leisure'">
            <div class="col-xl-12">
              <label class="form-control-label">{{question.question}}</label> 
            </div>
            <div class="col-xl-11">
              <button type="button"
              nbButton status="primary"
              (click)="question.optionType =='table_schedule_television' ? addTelevisiontable(): addLeisuretimetable()"
              style="float: right;" class="btn btn-primary"
              [hidden]="question.optionType =='table_schedule_television' && (question.userAnswerDTO.userAnswer == 'no' || question.userAnswerDTO.userAnswer == '')">Add</button>
            <br>
            <nb-radio-group *ngIf="question.optionType =='table_schedule_television'"
              [(ngModel)]="question.userAnswerDTO.userAnswer" [name]="question.userAnswerDTO.questionId">
              <nb-radio class="example-radio-button" value="yes"> Yes </nb-radio>
              <nb-radio class="example-radio-button" value="no"> No </nb-radio>
            </nb-radio-group>
            <table style="width:100%;margin-top: 15px;"
              [hidden]="question.optionType =='table_schedule_television' && (question.userAnswerDTO.userAnswer == 'no' || question.userAnswerDTO.userAnswer == '')">
              <tr>
                <td rowspan="2">Activity</td>
                <td colspan="2">Morning</td>
                <td colspan="2">Afternoon</td>
                <td colspan="2">Evening</td>
              </tr>
              <tr>
                <td>From</td>
                <td>To</td>
                <td>From</td>
                <td>To</td>
                <td>From</td>
                <td>To</td>
              </tr>
              <tr
                *ngFor="let televiosion of question.optionType =='table_schedule_television' ? televisionTable : leisuretimeTable;let i = index"
                [hidden]="televiosion.hasOwnProperty('deleted') && televiosion.deleted">
                <td><input class="form-control" style="width: 100%;" [(ngModel)]="televiosion.activity"> </td>
                <td><input class="form-control" style="width: 100%;" type="time" [(ngModel)]="televiosion.morningfrom">
                </td>
                <td><input class="form-control" style="width: 100%;" type="time" [(ngModel)]="televiosion.morningto">
                </td>
                <td><input class="form-control" style="width: 100%;" type="time"
                    [(ngModel)]="televiosion.afternoonfrom"></td>
                <td><input class="form-control" style="width: 100%;" type="time" [(ngModel)]="televiosion.afternoonto">
                </td>
                <td><input class="form-control" style="width: 100%;" type="time" [(ngModel)]="televiosion.eveningfrom">
                </td>
                <td><input class="form-control" style="width: 100%;" type="time" [(ngModel)]="televiosion.eveningto">
                </td>
                <button nbButton status="primary" class="btn btn-link" style="margin-right: -35px;"
                  (click)="question.optionType =='table_schedule_television' ? removeTelevision(i) : removeLeisure(i)"><i
                    style="font-size: 20px;" class="fa fa-close"></i></button>
              </tr>
            </table>
            </div>
            
          </div>
          <div class="row" *ngIf="question.optionType =='table'">
            <div class="col-xl-12">
              <label>{{question.question}}</label>
            </div>
            <div class="col-xl-11">

             <button type="button" nbButton status="primary"
              (click)="addMedicationtable()" style="float: right;" class="btn btn-primary">Add</button> <br>
            <table style="width:100%;margin-top: 15px;">
              <tr>
                <td style="width: 40%;">Medicine Name</td>
                <td style="width: 20%;">Dosage</td>
                <td style="width: 20%;">Frequecy</td>
                <td style="width: 20%;">Instructions</td>
              </tr>
              <tr *ngFor="let medication of medicationTable;let i=index"
                [hidden]="medication.hasOwnProperty('deleted') && medication.deleted">
                <td style="width: 40%;"><input class="form-control" style="width: 100%;"
                    [(ngModel)]="medication.medicinename"> </td>
                <td style="width: 20%;"><input class="form-control" style="width: 100%;" [(ngModel)]="medication.dose">
                </td>
                <td style="width: 20%;">
                  <nb-select style="width: 100%;" multiple [(ngModel)]="medication.frequency">
                    <nb-option *ngFor="let frequency of frequencies" [value]="frequency">{{frequency}}</nb-option>
                  </nb-select>
                </td>
                <td style="width: 20%;">
                  <nb-select style="width: 100%;" [(ngModel)]="medication.instructions">
                    <nb-option *ngFor="let instruction of instructions" [value]="instruction">{{instruction}}
                    </nb-option>
                  </nb-select>
                </td>
                <button nbButton status="primary" class="btn btn-link" style="margin-right: -35px;"
                  (click)="removeMedication(i)"><i style="font-size: 20px;" class="fa fa-close"></i></button>
              </tr>
            </table>
          </div>
        </div>
          <hr class="line-2">
        </div>
        <!-- <div *ngIf="question.optionType =='subQuestions'">
        <h6 style="margin-left: 10px;">{{question.id}}) {{question.question}}</h6>
        <div *ngFor="let subquestion of question.socialEngagementSubQuestionsDTOList;let k = index">

          <div class="divrow" *ngIf="subquestion.subOptionType=='text'">
            <label class="form-control-label">{{k+1}}) {{subquestion.subQuestion}}</label>
            <input style="width: 100%;" class="form-control" placeholder={{subquestion.subExample}}
              [(ngModel)]="subquestion.userAnswerDTO.userAnswer">
          </div>

          <div class="divrow" *ngIf="subquestion.subOptionType =='checkbox_text'">
            <h6>{{k+1}}) {{subquestion.subQuestion}} </h6>
              <nb-select [(ngModel)]="subquestion.userAnswerDTO.userAnswerList" multiple>
                <nb-option *ngFor="let option of subquestion.subOptions" [value]="option">
                  {{option}}
                </nb-option>
              </nb-select>
          </div>

          <div class="divrow" *ngIf="subquestion.subOptionType =='radio' || subquestion.subOptionType =='radio_text'">
            <label>{{k+1}}) {{subquestion.subQuestion}}</label><br>
            <nb-radio-group [(ngModel)]="subquestion.userAnswerDTO.userAnswer" [name]="subquestion.userAnswerDTO.subQuestionId">
              <nb-radio class="example-radio-button" *ngFor="let option of subquestion.subOptions"
                [value]="option">
                {{option}}
              </nb-radio>
            </nb-radio-group>
          </div>

          <div class="divrow" *ngIf="subquestion.subOptionType =='table_schedule'">
            <label>{{k+1}}) {{subquestion.subQuestion}}</label> <button type="button" nbButton status="primary"
              (click)="subquestion.id == 21 ? addTelevisiontable(): addLeisuretimetable()" style="float: right;"
              class="btn btn-primary"
              [hidden]="subquestion.id == 21 && (subquestion.userAnswerDTO.userAnswer == 'no' || subquestion.userAnswerDTO.userAnswer == '')">Add</button>
            <br>

            <nb-radio-group *ngIf="subquestion.id == 21" [(ngModel)]="subquestion.userAnswerDTO.userAnswer" [name]="subquestion.userAnswerDTO.subquestion">
              <nb-radio class="example-radio-button" value="yes"> Yes </nb-radio>
              <nb-radio class="example-radio-button" value="no"> No </nb-radio>
            </nb-radio-group>

            <table style="width:100%;margin-top: 15px;"
              [hidden]="subquestion.id == 21 && (subquestion.userAnswerDTO.userAnswer == 'no' || subquestion.userAnswerDTO.userAnswer == '')">
              <tr>
                <td rowspan="2">Activity</td>
                <td colspan="2">Morning</td>
                <td colspan="2">Afternoon</td>
                <td colspan="2">Evening</td>
              </tr>
              <tr>
                <td>From</td>
                <td>To</td>
                <td>From</td>
                <td>To</td>
                <td>From</td>
                <td>To</td>
              </tr>

              <tr *ngFor="let televiosion of subquestion.id == 21 ? televisionTable : leisuretimeTable;let i = index"
                [hidden]="televiosion.hasOwnProperty('deleted') && televiosion.deleted">
                <td><input class="form-control" style="width: 100%;" [(ngModel)]="televiosion.activity"> </td>
                <td><input class="form-control" style="width: 100%;" type="time" [(ngModel)]="televiosion.morningfrom">
                </td>
                <td><input class="form-control" style="width: 100%;" type="time" [(ngModel)]="televiosion.morningto">
                </td>
                <td><input class="form-control" style="width: 100%;" type="time"
                    [(ngModel)]="televiosion.afternoonfrom"></td>
                <td><input class="form-control" style="width: 100%;" type="time" [(ngModel)]="televiosion.afternoonto">
                </td>
                <td><input class="form-control" style="width: 100%;" type="time" [(ngModel)]="televiosion.eveningfrom">
                </td>
                <td><input class="form-control" style="width: 100%;" type="time" [(ngModel)]="televiosion.eveningto">
                </td>
                <button nbButton status="primary" class="btn btn-link" style="margin-right: -35px;"
                  (click)="subquestion.id == 21 ? removeTelevision(i) : removeLeisure(i)"><i style="font-size: 20px;"
                    class="fa fa-close"></i></button>
              </tr>

            </table>

          </div>

        </div>
      </div> -->
        <!-- </nb-accordion-item-body>-->
        <div style="position: fixed;bottom: 0;left: 40%;z-index: 100;display: inline-block;margin-bottom: 5px;">
          <button type="button" (click)="goBack()" style="margin: 10px;" nbButton status="primary">Cancel</button>
          <button type="button" (click)="Submit(category.categoryId)" style="margin: 10px;" nbButton status="primary">Save & Next</button>
          <button type="button" (click)="SubmitAll()" style="margin: 10px;" nbButton status="primary">Submit</button>
        </div>
      </nb-tab>
    </nb-tabset> 
  </div>
  </nb-card-body>
</nb-card>