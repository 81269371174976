import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { APP_CONSTANTS } from './shared/constants';

const routes: Routes = [{
    path: APP_CONSTANTS.APP_ROUTE.ADMIN,
    loadChildren: () => import('./adminportal/adminportal.module')
      .then(m => m.AdminportalModule),
  },
  {
    path: APP_CONSTANTS.APP_ROUTE.DOCTOR,
    loadChildren: () => import('./doctorportal/doctorportal.module')
      .then(m => m.DoctorportalModule),
  },
  {
    path: APP_CONSTANTS.APP_ROUTE.AUTH,
    loadChildren: () => import('./components/components.module')
      .then(m => m.ComponentsModule),
  },
  {
    path: '',
    loadChildren: () => import('./components/components.module')
      .then(m => m.ComponentsModule),
  },
  //  { path: '**', redirectTo: APP_CONSTANTS.APP_ROUTE.AUTH },
  ];
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
