import { createReducer, on } from "@ngrx/store"
import { AdminDoctorsListLoading, AdminHomeDashboardLoading, AdminPatientListLoading, AdminRolesListLoading, AdminUserListLoading, UpdateAdminDashbaord, UpdateDoctorsList, UpdatePatientList, UpdateRolesList, UpdateUsersList } from "../actions/admin-home.actions"
import { AdminDashboardInitialState } from "../state/admin-home.state"

const _AdminHomeReducer = createReducer(
    AdminDashboardInitialState,
    on(UpdateAdminDashbaord, (state, action) => {
        return {
            ...state,
            doctorsList:action.doctorsList,
            patientList: action.patientList,
            usersList:action.usersList,
            rolesList:action.rolesList,
            AdminHomeDashboardLoading:false
        }
    }),
    on(AdminHomeDashboardLoading, (state, action) => {
        return {
            ...state,
            AdminHomeDashboardLoading:true
        }
    }),
    on(UpdateUsersList, (state, action) => {
        return {
            ...state,
            usersList:action.usersList,
            adminUsersListLoading:false
        }
    }),
    on(AdminUserListLoading, (state, action) => {
        return {
            ...state,
            adminUsersListLoading:true
        }
    }),

    on(UpdateDoctorsList, (state, action) => {
        return {
            ...state,
            doctorsList:action.doctorsList,
            adminDoctorsListLoading:false
        }
    }),
    on(AdminDoctorsListLoading, (state, action) => {
        return {
            ...state,
            adminDoctorsListLoading:true
        }
    }),

    on(UpdatePatientList, (state, action) => {
        return {
            ...state,
            patientList:action.patientList,
            adminPatientListLoading:false
        }
    }),
    on(AdminPatientListLoading, (state, action) => {
        return {
            ...state,
            adminPatientListLoading:true
        }
    }),

    on(UpdateRolesList, (state, action) => {
        return {
            ...state,
            rolesList:action.rolesList,
            adminRolesListLoading:false
        }
    }),
    on(AdminRolesListLoading, (state, action) => {
        return {
            ...state,
            adminRolesListLoading:true
        }
    })
)

export function AdminHomeReducer(state, action) {
    return _AdminHomeReducer(state, action)
}