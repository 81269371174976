import { createAction, props } from "@ngrx/store";

export const loadDoctorDashboard = createAction(
    "[load doctor dashboard Resolver] Load Dashboard "
); 

export const UpdateDoctorDashbaord = createAction(
    "[update doctor dashboard Effect] Update doctor dashboard",
    props<{ 
        patientList: any,
        upcomingConsultationsList:any,
        getDoctorAppointmentsCount:any,
        getDoctorDaywiseAppointmentsCount:any,
        getDoctorRecentlyConsultedPatients:any,
        getDoctorPrescriptions:any;
    }>()
);
export const DoctorDashboardLoading = createAction(
    "[DoctorDashboardLoading doc dashboard ] DoctorDashboardLoading doc dashboard"
);

export const DoctorUpcomingConsultationsListLoading = createAction(
    "[DoctorUpcomingConsultationsListLoading doc dashboard ] DoctorUpcomingConsultationsListLoading doc dashboard"
);

export const loadDoctorPatientList = createAction(
    "[load PatientList Resolver] Load PatientList "
); 

export const LoadDoctorUpcomingConsultationsList = createAction(
    "[load upcoming ConsultationsList Resolver] Load upcoming ConsultationsList ",
); 
export const UpdateDoctorPatientList = createAction(
    "[UpdatePatientList dashboard Effect] UpdatePatientList ",
    props<{ 
        patientList: any,
    }>()
);

export const UpdateDoctorUpcomingConsultationsList = createAction(
    "[UpdateUpcomingConsultationsList dashboard Effect] UpdateUpcomingConsultationsList",
    props<{ 
        upcomingConsultationsList:any
    }>()
);
export const LoadPrescriptionsList = createAction(
    "[LoadPrescriptionsList cust profile effects] LoadPrescriptionsList cust profile effects",
    props<{ 
        mobileno: any,
    }>()
);
