import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { AppState } from "../root.reducer";
import { select, Store } from "@ngrx/store";
import { concatMap, map } from "rxjs/operators";
import { LoginService } from "src/app/components/login/login.service";
import { loadUserRoles, UpdateUserRoles } from "../actions/app-config.actions";

@Injectable()
export class AppConfigEffects {
    constructor(private actions$: Actions,private loginService:LoginService) {

    }
    loadUserRoles$ = createEffect(
        () => this.actions$
            .pipe(
                ofType(loadUserRoles),
                concatMap(action => {
                    return this.loginService.getUserRoles();
                }),
                map(config => {
                    console.log(config);
                    return UpdateUserRoles({ UserRoles: config })
                })
            )
    );
}