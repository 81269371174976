<nb-card class="smallcard" [nbSpinner]="loading" nbSpinnerStatus="primary" nbSpinnerSize="giant"
  nbSpinnerMessage="Loading">
  <nb-card-header>
    Pre-Screening
  </nb-card-header>
  <nb-card-body>
    <div *ngFor="let ques of questions;let i = index">
      <div *ngIf="ques.showQuestion">
        <p>{{ques.question}}</p>
        <div class="row show-right">
          <div *ngIf="ques.selectedOption">
            <button nbButton status="primary" class="m-10">
              {{ques.selectedOption}}
            </button>
          </div>
          <div *ngIf="!ques.selectedOption">
            <button *ngFor="let option of ques.preScreeningDetailsList;let j = index" nbButton status="primary" (click)="updateData(option,ques)" class="m-10">
              {{option.options}}
            </button>
          </div>
        </div>
      </div>
     
    </div>
    <!-- <p *ngIf="ques.selectedOption" style="font-size:medium;">{{ques.question}}</p> -->
    <!-- <div *ngIf="ques.selectedOption" style="display: flex; justify-content:end">
        <button nbButton status="primary">
          {{ques.selectedOption}}
        </button>
      </div> -->
    <!-- </div> -->
  </nb-card-body>
  <!-- <nb-card-body *ngIf="selected">
        <div *ngIf="selected">
            <div *ngFor="let ques of questions;let i = index">
                <p *ngIf="ques.selectedOption" style="font-size:medium;">{{ques.question}}</p> 
                <div *ngIf="ques.selectedOption" style="display: flex; justify-content:end" >
                  <button nbButton status="primary" >
                      {{ques.selectedOption}}
                    </button>
              </div>
        </div>
      </div>
    </nb-card-body> -->
  <!-- <nb-card-footer>
        <div *ngFor="let ques of dispquestions;let i = index">
            <p *ngIf="!ques.selectedOption" style="font-size:medium;">{{ques.question}}</p> 
            <div *ngIf="!ques.selectedOption" style="display: flex; justify-content:end">
              <button nbButton status="primary" (click)="saveYesOption(ques,i)" type="submit">
                  YES
                </button>
              <button nbButton status="primary" style="margin-left:10px;" (click)="saveNoOption(ques,i)" type="submit">
                  No
              </button>
          </div> 
      </div>
        

    </nb-card-footer> -->

</nb-card>