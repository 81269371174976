import { Component, OnInit, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Meta, Title } from '@angular/platform-browser';
import { ComponentsService } from '../services/componets.service';
import { APP_CONSTANTS } from 'src/app/shared/constants';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { QuestionsPopupComponent } from '../questions-popup/questions-popup.component';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/root.reducer';
import { updateQuestions } from 'src/app/store/actions/patient-response.actions';
import { PatientResponseState } from 'src/app/store/state/patient-response.state';
import { getPatientResponse } from 'src/app/store/selectors/patient-response.selector';
import { UnsubscribeOnDestroyAdapter } from 'src/app/shared/services/UnsubscribeOnDestroyadapter';

@Component({
  templateUrl: './patientconsultation.component.html',
  styleUrls: ['./patientconsultation.component.scss']
})
export class PatientConsultationComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  load: boolean = false;
  public checked: boolean;


  rows: any = [];
  appointmentData: any;
  patientResponsedata: PatientResponseState[];
  questions: any;
  appointmentId: any;
  accept: boolean = false;
  showJoin = false;
  constructor(private metaService: Meta, private store: Store<AppState>, private titleService: Title, private route: ActivatedRoute, private apiService: ComponentsService, private router: Router, private snackbar: MatSnackBar,
    private toastrService: NbToastrService, private datepipe: DatePipe, private dialogService: NbDialogService) {
      super()
    this.setTitle("Patient");
    this.metaService.updateTag(
      {
        name: 'description',
        content: 'patient connecting call with doctor'
      }
    );
    this.appointmentId = this.route.snapshot.paramMap.get('appointmentid');
  }

  ngOnInit(): void {
    this.getAppointmentData();

  }
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
  get checkJoin() {
    // var nowDate = new Date();
    // var startDate = new Date(this.appointmentData?.startTime);
    // var time = Math.abs(nowDate.getTime() - startDate.getTime());
    if (Math.abs(this.appointmentData?.remainingTime) <= this.appointmentData?.duration * 60) {
      this.showJoin = true;
    }
    return this.showJoin && this.accept;
  }
  getAppointmentData() {
    this.subs.sink=this.apiService.getAppointmentDetails(this.appointmentId).subscribe(
      response => {
        this.appointmentData = response;
      }, error => {
        var data = error;
      });
  }



  getData(topic, appointmentId) {
    this.subs.sink=this.apiService.checkPrescreeingstatus(appointmentId).subscribe(res => {
      if (!res) {
        this.dialogService.open(QuestionsPopupComponent, {
          context: {
            data1: topic,
            appointmentId: appointmentId,
            appointmentData: this.appointmentData
          }, dialogClass: 'model-full',
          autoFocus: false
        })
          .onClose.subscribe({
            next: (result) => {

            }
          });
      }
      else
        this.router.navigate([APP_CONSTANTS.APP_ROUTE.AUTH + "/" + APP_CONSTANTS.LOGIN_ROUTE.PATIENTMEETVIEW], { state: { id: this.appointmentData.id, username: this.appointmentData.customerName } });
    });

  }

  onJoin() {
    if (!this.accept) {
      this.toastrService.show('', 'Please select the checkbox to continue', { duration: APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON });
      return;
    }
    if (this.showJoin) {
      this.getData(this.appointmentData.topic, this.appointmentId);
    } else {
      this.toastrService.show('', 'Link has Expired', { duration: APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON });
      return;
    }
  }

}

