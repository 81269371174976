<nb-card [nbSpinner]="loading" nbSpinnerStatus="primary" nbSpinnerSize="giant" nbSpinnerMessage="">
    <nb-card-header>
        <div class="card-title text-left" *ngIf="edit">
            Edit Doctor Profile
        </div>
        <div class="card-title text-left" *ngIf="!edit">
            <a (click)="back()" data-placement="right" title="back">
                <nb-icon icon="arrow-left" pack="fas"> </nb-icon>
            </a>
        </div>
    </nb-card-header>
    <nb-card-body>
        <form class="form form-horizontal" [formGroup]="personaldetailsForm" (ngSubmit)="onSubmit()">


            <div class="row" style="text-align: right;">

                <div class="form-group position-relative has-icon-left col-md-4">

                    <input type="search" [class.is-invalid]="pdf.username.invalid && pdf.username.touched"
                     required formControlName="username" class="form-control"
                        [readonly]="edit" [ngClass]="{ 'is-invalid': submitted && pdf.username.errors }"
                        placeholder="Digi Doctor ID">
                    <div *ngIf="pdf.username.errors && pdf.username.invalid && pdf.username.touched">
                        <small class="text-danger" *ngIf="pdf.username.errors.required">Digi Doctor ID is
                            required</small>
                        <small class="text-danger" *ngIf="pdf.username.errors.pattern">Doctor ID must be
                            alphanumeric</small>
                        <small class="text-danger" *ngIf="pdf.username.errors.minlength">Doctor ID need to be at least 2
                            characters long</small>
                        <small class="text-danger" *ngIf="pdf.username.errors.maxlength">allows only 20 characters
                            long</small>
                    </div>
                </div>

                <div class="form-group position-relative has-icon-left col-md-4">

                    <input type="password" [class.is-invalid]="pdf.password.invalid && pdf.password.touched" required
                        formControlName="password" class="form-control pw" [readonly]="edit"
                        [ngClass]="{ 'is-invalid': submitted && pdf.password.errors }" placeholder="Password" 
                        pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$">
                    <div *ngIf="pdf.password.errors && pdf.password.invalid && pdf.password.touched">
                        <small class="text-danger" *ngIf="pdf.password.errors.required">Password is required</small>
                    </div>
                    <div *ngIf="pdf.password.errors && (pdf.password.invalid || pdf.password.touched)">
                        <small class="text-danger" *ngIf="pdf.password.errors.pattern">password must be 8-16 characters
                            in length with at least 1 upper case, 1 lower case, 1 numeric and 1 special
                            character</small>
                    </div>
                </div>

                <div class="form-group position-relative has-icon-left col-md-4">

                    <input type="search" [class.is-invalid]="pdf.confirmPassword.invalid && pdf.confirmPassword.touched"
                        required formControlName="confirmPassword" class="form-control pw" [readonly]="edit"
                        [ngClass]="{ 'is-invalid': submitted && pdf.confirmPassword.errors }"
                        placeholder="Confirm Password">
                    <div
                        *ngIf="pdf.confirmPassword.errors && pdf.confirmPassword.invalid && pdf.confirmPassword.touched">
                        <small class="text-danger" *ngIf="pdf.confirmPassword.errors.required">Confirm New Password is
                            required</small>
                        <small class="text-danger" *ngIf="pdf.confirmPassword.errors.mustMatch">Password didn't
                            match</small>
                    </div>
                </div>

            </div>
            <div class="row" style="text-align: right;">
                <div class="form-group position-relative has-icon-left col-md-4">
                    <input type="search" [class.is-invalid]="pdf.given.invalid && pdf.given.touched" required formControlName="given" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && pdf.given.errors }" placeholder="First Name">
                    <div *ngIf="pdf.given.errors && pdf.given.invalid && pdf.given.touched">
                        <small class="text-danger" *ngIf="pdf.given.errors.required">First Name is required</small>
                        <small class="text-danger" *ngIf="pdf.given.errors.pattern">Please provide valid
                            firstname</small>
                        <small class="text-danger" *ngIf="pdf.given.errors.minlength">firstName need to be atleast 2
                            characters length </small>
                        <small class="text-danger" *ngIf="pdf.given.errors.maxlength">allows only 20 characters
                            long</small>
                    </div>
                </div>


                <div class="form-group position-relative has-icon-left col-md-4">

                    <input type="search" [class.is-invalid]="pdf.family.invalid && pdf.family.touched" required formControlName="family" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && pdf.family.errors }" placeholder="Last Name">
                    <div *ngIf="pdf.family.errors && pdf.family.invalid && pdf.family.touched">
                        <small class="text-danger" *ngIf="pdf.family.errors.required">Last Name is required</small>
                        <small class="text-danger" *ngIf="pdf.family.errors.pattern">Please provide valid
                            lastname</small>
                        <small class="text-danger" *ngIf="pdf.family.errors.minlength">lastName need to be at least 2
                            characters long</small>
                        <small class="text-danger" *ngIf="pdf.family.errors.maxlength">allows only 20 characters
                            long</small>
                    </div>
                </div>

                <div class="form-group col-md-4">
                    <div class="input-group">
                        <input fullWidth nbInput readonly formControlName="birthDate"
                            [ngClass]=" { 'is-invalid': submitted && pdf.birthDate.errors } "
                            style="border-right: none;text-align: left;" required ngbTooltip="Date of Birth"
                            placeholder="dd-mm-yyyy (DOB)" (ngModelChange)="dobChange()"
                            [nbDatepicker]="dateTimePicker">
                        <nb-datepicker [min]="minDate" [max]="maxDate" #dateTimePicker></nb-datepicker>
                    </div>
                    <div *ngIf="pdf.birthDate.errors && pdf.birthDate.invalid && pdf.birthDate.touched">
                        <small class="text-danger" *ngIf="pdf.birthDate.errors.required">Date Of Birth is
                            required</small>
                    </div>
                </div>
            </div>
            <div class="row" style="text-align: right;">
                <div class="form-group position-relative col-md-4">
                    <select class="form-control" formControlName="gender"
                        [class.is-invalid]="pdf.gender.invalid && pdf.gender.touched" required
                        [ngClass]="{ 'is-invalid': submitted && pdf.gender.errors }">
                        <option value="" disabled selected>Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                    </select>
                    <div *ngIf="pdf.gender.errors && pdf.gender.invalid && pdf.gender.touched">
                        <small class="text-danger" *ngIf="pdf.gender.errors.required">select Gender</small>
                    </div>
                </div>
                <div class="form-group position-relative has-icon-left col-md-4">

                    <input type="text" numbersOnly maxlength="10" formControlName="mobile" class="form-control"
                        [class.is-invalid]="pdf.mobile.invalid && pdf.mobile.touched" required
                        [ngClass]="{ 'is-invalid': submitted && pdf.mobile.errors }"
                        placeholder="Mobile Number" pattern="^[6-9](?!0+$)\d{9}$">
                    <div *ngIf="pdf.mobile.errors && pdf.mobile.invalid && pdf.mobile.touched">
                        <small class="text-danger" *ngIf="pdf.mobile.errors.required">Mobile Number is required</small>
                    </div>
                    <div *ngIf="pdf.mobile.errors && (pdf.mobile.invalid || pdf.mobile.touched)">
                        <small class="text-danger" *ngIf="pdf.mobile.errors.pattern">Please provide a valid 10 digit
                            number </small>
                    </div>
                </div>
                <div class="form-group position-relative has-icon-left col-md-4">

                    <input type="text" [class.is-invalid]="pdf.email.invalid"
                        formControlName="email"
                        class="form-control" [ngClass]="{ 'is-invalid': submitted && pdf.email.errors }"
                        placeholder="Email">
                    <div *ngIf="pdf.email.errors && (pdf.email.invalid || pdf.email.touched)">
                        <small class="text-danger" *ngIf="pdf.email.errors.pattern">Please provide a valid Email</small>
                    </div>
                </div>
            </div>

            <label class="label-control">Address</label>
            <div class="row" style="text-align: right;">
                <div class="form-group position-relative col-md-4">
                    <input type="search" formControlName="city" class="form-control"
                        [class.is-invalid]="pdf.city.invalid && pdf.city.touched" required
                        [ngClass]="{ 'is-invalid': submitted && pdf.city.errors }"
                        placeholder="City">
                    <div *ngIf="pdf.city.errors && pdf.city.invalid && pdf.city.touched">
                        <small class="text-danger" *ngIf="pdf.city.errors.required">City is required</small>
                        <small class="text-danger" *ngIf="pdf.city.errors.pattern">please enter valid City </small>
                        <small class="text-danger" *ngIf="pdf.city.errors.minlength">City need to be at least 2
                            characters long</small>
                        <small class="text-danger" *ngIf="pdf.city.errors.maxlength">allows only 20 characters
                            long</small>
                    </div>
                </div>
                <div class="form-group position-relative col-md-4">
                    <select class="form-control" formControlName="country"
                        [ngClass]="{ 'is-invalid': submitted && pdf.country.errors }">
                        <option *ngFor="let obj of countires" [value]="obj">{{obj}}</option>
                    </select>
                </div>
                <div class="form-group position-relative col-md-4">
                    <input type="search" numbersOnly minlength="6" maxlength="6" formControlName="postalcode"
                        class="form-control" pattern="^(?!0+$)\d{6}$"
                        [class.is-invalid]="pdf.postalcode.invalid && pdf.postalcode.touched" required
                        [ngClass]="{ 'is-invalid': submitted && pdf.postalcode.errors }" placeholder="Zip/Pin code">
                    <div *ngIf="pdf.postalcode.errors && pdf.postalcode.invalid && pdf.postalcode.touched">
                        <small class="text-danger" *ngIf="pdf.postalcode.errors.required">Zip/pin is required</small>
                        <small class="text-danger" *ngIf="pdf.postalcode.errors.pattern">please enter valid
                            Zip/pin</small>
                    </div>
                </div>
            </div>
            <label class="label-control">Payment Type</label>
            <div class="row" style="text-align: right;">
                <div class="form-group position-relative col-md-4">
                    <select class="form-control" formControlName="doctorType"
                        [class.is-invalid]="pdf.doctorType.invalid && pdf.doctorType.touched" required
                        [ngClass]="{ 'is-invalid': submitted && pdf.doctorType.errors }">
                        <option value="">Select</option>
                        <option value="Free">Free</option>
                        <option value="Paid">Paid</option>
                    </select>
                    <div *ngIf="pdf.doctorType.errors && pdf.doctorType.invalid && pdf.doctorType.touched">
                        <small class="text-danger" *ngIf="pdf.doctorType.errors.required">select Doctor Type</small>
                    </div>
                </div>
                <div *ngIf="personaldetailsForm.get('doctorType').value=='Paid'" form
                    class="form-group position-relative col-md-4">
                    <input type="text" formControlName="feeAmount" required class="form-control" placeholder="Amount">
                    <div *ngIf="pdf.feeAmount.errors && pdf.feeAmount.invalid && pdf.feeAmount.touched">
                        <small class="text-danger" *ngIf="pdf.feeAmount.touched && pdf.feeAmount.errors.required">Enter fee amount</small>
                        <small class="text-danger" *ngIf="pdf.feeAmount.touched  && pdf.feeAmount.invalid && !pdf.feeAmount.errors.required && !pdf.feeAmount.errors.pattern"> Fee should
                            be above 0</small>
                        <small class="text-danger" *ngIf="pdf.feeAmount.errors.pattern && !pdf.feeAmount.errors.required">Allow upto 5 digits only</small>
                    </div>
                </div>
            </div>

            <div class="" formArrayName="educationDTOList">
                <label class="label-control">Medical Education</label>
                <a (click)="addEducation()" style="float: right;"><i class="fa fa-plus"></i> Add more degree</a>
                <div class="" *ngFor="let educationForm of personaldetailsFormGroup.controls; let i = index;">
                    <div [formGroupName]="i" class="row">
                        <div class="form-group  col-md-4">
                            <input type="search" formControlName="university" minlength="2" maxlength="40"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && getValidity(i)}" placeholder="University">
                            <div *ngIf="getValidityUniv(i)">
                                <small class="text-danger">Please enter the valid University Name</small>
                            </div>
                        </div>
                        <div class="form-group position-relative col-md-4">
                            <input type="search" formControlName="courseName" minlength="2" maxlength="20"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && getValidity(i) }" placeholder="Degree">
                            <div *ngIf="getValidityCourse(i)">
                                <small class="text-danger">Please enter the valid Degree</small>
                            </div>
                        </div>
                        <div class="form-group position-relative " [ngClass]="{ 'col-md-4' : i==0, 'col-md-3' : i!=0 }">
                            <select class="form-control" formControlName="completionyear"
                                [ngClass]="{ 'is-invalid': submitted && getValidity(i) }">
                                <option value="">Year</option>
                                <option *ngFor="let obj of years" [value]="obj">{{obj}}</option>
                            </select>
                        </div>
                        <div class="col-md-1" *ngIf="i!=0">
                            <button nbButton status="primary" type="button" (click)="removeEducation(i)"><i
                                    class="fa fa-x"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <label class="label-control">Area of practice</label>
            <div class="row" style="text-align: right;">
                <div class="form-group position-relative col-md-8">
                    <select class="form-control" formControlName="practicing"
                        [ngClass]="{ 'is-invalid': submitted && pdf.practicing.errors }"
                        [class.is-invalid]="pdf.practicing.invalid && pdf.practicing.touched" required>
                        <option value="">Select</option>
                        <option *ngFor="let obj of areaOfPractice" [value]="obj">{{obj}}</option>
                    </select>
                    <div *ngIf="pdf.practicing.errors && pdf.practicing.invalid && pdf.practicing.touched">
                        <small class="text-danger" *ngIf="pdf.practicing.errors.required">select Area Of
                            Practice</small>
                    </div>
                </div>
                <div class="form-group position-relative col-md-4">
                    <select class="form-control" formControlName="practicingSince"
                        [ngClass]="{ 'is-invalid': submitted && pdf.practicingSince.errors }"
                        [class.is-invalid]="pdf.practicingSince.invalid && pdf.practicingSince.touched">
                        <option value="">Year</option>
                        <option *ngFor="let obj of years" [value]="obj">{{obj}}</option>
                    </select>
                    <div
                        *ngIf="pdf.practicingSince.errors && pdf.practicingSince.invalid && pdf.practicingSince.touched">
                        <small class="text-danger" *ngIf="pdf.practicingSince.errors.required">select year of started
                            practicing</small>
                    </div>
                </div>

            </div>
            <label class="label-control">Professional Licence</label>
            <div class="row" style="text-align: right;">
                <div class="form-group position-relative col-md-4">
                    <input type="search" numbersOnly minlength="6" maxlength="6" formControlName="registrationNo"
                        class="form-control"
                        [class.is-invalid]="pdf.registrationNo.invalid && pdf.registrationNo.touched" required
                        [ngClass]="{ 'is-invalid': submitted && pdf.registrationNo.errors }"
                        placeholder="Licence Number" pattern="^(?!0+$)\d{6}$">
                    <div *ngIf="pdf.registrationNo.errors && pdf.registrationNo.invalid && pdf.registrationNo.touched">
                        <small class="text-danger" *ngIf="pdf.registrationNo.errors.required">License Number
                            required</small>
                        <small class="text-danger" *ngIf="pdf.registrationNo.errors.pattern">Please provide a valid
                            License Number</small>
                    </div>
                </div>
                <div class="form-group position-relative col-md-4">
                    <select class="form-control" formControlName="registeredCountry"
                        [ngClass]="{ 'is-invalid': submitted && pdf.registeredCountry.errors }">
                        <option *ngFor="let obj of countires" [value]="obj">{{obj}}</option>
                    </select>
                </div>
                <div class="form-group position-relative col-md-4">
                    <input type="search" formControlName="registeredAt" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && pdf.registeredAt.errors }"
                        [class.is-invalid]="pdf.registeredAt.invalid && pdf.registeredAt.touched" required
                        placeholder="State" pattern="^[A-Za-z]+[A-Za-z ]*$">
                    <div *ngIf="pdf.registeredAt.errors && pdf.registeredAt.invalid && pdf.registeredAt.touched">
                        <small class="text-danger" *ngIf="pdf.registeredAt.errors.required">Registered state is
                            required</small>
                        <small class="text-danger" *ngIf="pdf.registeredAt.errors.pattern">please enter the valid
                            state</small>
                        <small class="text-danger" *ngIf="pdf.registeredAt.errors.minlength">State need to be at least 2
                            characters long</small>
                        <small class="text-danger" *ngIf="pdf.registeredAt.errors.maxlength">allows only 20 characters
                            long</small>
                    </div>
                </div>

            </div>

            <div class="" formArrayName="appointmentsDTOList">
                <label class="label-control">Schedule for Online Appointments</label>
                <a (click)="addAppointment()" style="float: right;"><i class="fa fa-plus"></i>
                    Add more appointments</a>
                <div class="" *ngFor="let appointmentsForm of getAppointmentsFormrGoup.controls; let i = index;">
                    <div [formGroupName]="i" class="row">
                        <div class="col-md-1" *ngIf="i!=0">
                            <button nbButton status="primary" type="button" (click)="removeAppointment(i)"><i
                                    class="fa fa-close"></i></button>
                        </div>
                        <div class="form-group col-md-8 col-xl-3">
                            <ng-select [items]="multipleSelectArray1" [clearSearchOnAdd]="true" [closeOnSelect]="false"
                                formControlName="availabilitySlotDays" [multiple]="true" placeholder="Select Days"
                                [ngClass]="{ 'is-invalid': submitted && getValidityAvai(i) }">
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                                        [ngModelOptions]="{standalone: true}" />
                                    {{item}}
                                </ng-template>
                            </ng-select>
                            <div *ngIf="getValidityDays(i)">
                                <small class="text-danger">Please select the Days</small>
                            </div>
                        </div>
                        <div class="form-group position-relative col-md-3 col-xl-2" >
                            <select class="form-control" formControlName="availabilitySlotDuration"
                                [ngClass]="{ 'is-invalid': submitted && getValidityAvai(i) }">
                                <option value="" disabled>Duration</option>
                                <option *ngFor="let obj of duration" [value]="obj.key">{{obj.name}}</option>
                            </select>
                        </div>
                        <div class="form-group position-relative" [ngClass]="{ 'col-md-12 col-xl-7' : i==0, 'col-md-12 col-xl-6' : i!=0 }">
                            <div class="" formArrayName="timeSlotDTOList">
                                <div class=""
                                    *ngFor="let appointmentTimeSlotsForm of getAppointmentsFormTimeSlotsGroup(i).controls; let j = index;">
                                    <div [formGroupName]="j" class="row">
                                        <div class="form-group position-relative col-md-2">Time Slots</div>

                                        <div class="form-group position-relative col-md-4">
                                            <select class="form-control" formControlName="startTime"
                                                [ngClass]="{ 'is-invalid': submitted && getValidityTimeSlot(i,j) }">
                                                <option value="">From</option>
                                                <option *ngFor="let obj of timeSlots" [value]="obj.key">{{obj.name}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group position-relative col-md-4">
                                            <select class="form-control" formControlName="endTime"
                                                [ngClass]="{ 'is-invalid': submitted && getValidityTimeSlot(i,j) }">
                                                <option value="">To</option>
                                                <option *ngFor="let obj of timeSlots" [value]="obj.key">{{obj.name}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-md-1" *ngIf="j==0">
                                            <button nbButton status="primary" type="button"
                                                (click)="addAppointmentTimeSlots(i,j)"><i
                                                    class="fa fa-plus"></i></button>
                                        </div>
                                        <div class="col-md-1" *ngIf="j!=0">
                                            <button nbButton status="primary" type="button"
                                                (click)="removeTimeSlot(i,j)"><i class="fa fa-close"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <hr class="line-2">
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin: 5px;">
                <label class="label-control" style="margin-right:20px;" for="file">Upload Digital Signature</label>
                <input type="file" id="file" accept=".jpg,.jpeg,.png" (change)="handleFileInput($event.target.files)"
                    [ngClass]="show ? 'pad1' : 'pad2'" (click)="newFile()">
                <img src="{{signature}}" width="100px" height="100px" *ngIf="signature != ''" />
            </div>

            <div *ngIf="!edit" class="form-group row">
                <div class="col-md-12 col-12 text-center text-sm-left pr-0">
                    <div>
                        <input type="checkbox" formControlName="agreeTerms" name="agree-terms"
                            style="position: relative;">
                        I agree to the <a (click)="MoveToTerms()" style="cursor: pointer;color: rgb(34, 48, 47);"
                            target="_blank">Terms and conditions</a> set out by this site, including our Cookie use.
                    </div>
                </div>
            </div>
            <div class="form-group position-relative has-icon-left col-md-12">
                <div class="row justify-content-center">
                    <div class="col-md-3 card-body" *ngIf="edit">
                        <a (click)="goBack()" nbButton status="primary" class=" btn-block">Cancel</a>
                    </div>
                    <div class="col-md-3 card-body"><button [disabled]="personaldetailsForm.invalid" type="submit"
                            class="btn btn-block" nbButton status="primary"
                            [ngClass]="personaldetailsForm.invalid ? 'btn-outline-secondary' : 'btn-outline-info'">

                            {{register}}
                        </button>
                    </div>
                </div>
            </div>
        </form>

    </nb-card-body>
    <nb-card-footer>
        <div style="text-align: center;" *ngIf="!edit">
            <span>Already have an account?<a style="cursor: pointer;" (click)="MovetoLogin()"> Login</a></span>
        </div>
    </nb-card-footer>
</nb-card>