import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { APP_CONSTANTS, GETCD10_URL } from 'src/app/shared/constants';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PrescriptionEditService {

    constructor(private http: HttpClient) { }
    getDoctorAppointments(doctorId: any): Observable<any> {
        let url = "" + APP_CONSTANTS.REST_API_URL.GET_DOCTOR_APPOINTMENTS + doctorId;
        return this.http.get(url).pipe(filter(data => {
            return data['cancelledBy'] != "Doctor";
        }));
    }
    getPatientConsultations(doctorId: any) {
        let url = "" + APP_CONSTANTS.REST_API_URL.GET_CONSULTED_PATIENTS + doctorId;
        return this.http.get(url);
    }

    getAllVitals(mobile) {
        let url = APP_CONSTANTS.REST_API_URL.GET_ALL_VITALS_DETAILS + mobile;
        return this.http.get(url);
    }
    saveMeetingandGetId(personId: any, doctorId: any) {
        return this.http.get(APP_CONSTANTS.REST_API_URL.SAVE_MEETING_ID + personId + "/" + doctorId)

    }
    getPatientVitals(mobile) {
        let url = APP_CONSTANTS.REST_API_URL.GET_ALL_VITALS_REPORT + mobile;
        return this.http.get(url);
    }
    getPatientPrescriptions(mobile) {
        let url = APP_CONSTANTS.REST_API_URL.GET_PROFILE_AND_PRESCRIPTIONS + mobile;
        return this.http.get(url);
    }
    getDrugsbyGenericName(generic: any) {
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_MEDICINE_BY_GENERIC + generic)
    }
    getDrugsbyName(medicine: any) {
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_MEDICINE + medicine)
    }
    suggestPrescription(reqBody: any) {
        let url = APP_CONSTANTS.REST_API_URL.SUGGEST_PRESCRIPTIONS
        return this.http.post(url, reqBody)
    }


    suggestComlaints(reqBody: any) {
        let url = APP_CONSTANTS.REST_API_URL.SUGGEST_COMPLIANTS
        return this.http.post(url, reqBody)
    }

    suggestLifeStyleModifications(doctorId: any, suggestionselected: string) {

        let url = APP_CONSTANTS.REST_API_URL.GET_LIFESTYLE_MODIFICATIONS + doctorId + "/" + suggestionselected;
        return this.http.get(url)
    }

    getICD11(term: string) {
        if (term === '') {
            return of([]);
        }
        return this.http.get(APP_CONSTANTS.REST_API_URL.SEARCH_ICD + term).pipe(
            map((response: any) => {
                let list = [];
                for (var ent of response.destinationEntities) {
                    list.push(ent.title)
                }
                return list;
            }));
    }

    getDrugsList() {
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_MEDICINE_LIST)
    }

    getDrugsInteractions(rxcuis: any) {
        return this.http.get(environment.RXNAV_URL + rxcuis);
    }

    savePrescription(data): Observable<any> {
        return this.http.post(APP_CONSTANTS.REST_API_URL.EDIT_PATIENT_PRESCRIPTION, data);
    }

    publishPrescription(data): Observable<any> {
        return this.http.post(APP_CONSTANTS.REST_API_URL.PUBLISH_PATIENT_PRESCRIPTION, data);
    }
    getCD10(param): Observable<any> {
        var url = GETCD10_URL + param;

        return this.http.get<any>(url)
            .pipe(

                map(response => response[3])
            );
    }

    getDoctorPatientDetails(mobile) {
        http://localhost:8080/api/v1/getCustomerDetailsByMobile/7780628980
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_CUSTOMER_DETAILS_BY_MOBILE + mobile);
    }




}
