export class Notifications {

   personId:Number;
   meetingId :Number;
   agoraMeetingId:string;
   zoomMeetingPassword:string;
   url:string;
   username:string;
   callingFromDoctor:boolean=true;
   id:number;
   deviceId:string;
   doctorId:string;
   fcmId:string;
   meetingStartTime:string;
   meetingEndTime:string;
   chiefCompliant:string;
   androidId:string;;
   doctorAssinged:boolean;
   b2bCall:boolean;
   callTransferMeetingId: string;
   

}