<div class="col-md-6 offset-md-3 mt-5">
    <div class="card logincard">
        <div class="brand-logo text-center  ">
            <img src="../assets/logo.svg" style="width:50%">
        </div>
        <div class="card-body">
            <form [formGroup]="passwordForm" (ngSubmit)="getValidate()" *ngIf="screen1">
                <div class="form-group">
                    <label for="role">Role</label>
                    <nb-select fullWidth formControlName="role" [ngClass]="{ 'is-invalid': submittedForm1 && f.role.errors }" id="role" required>
                        <nb-option [value]="">Select</nb-option>    
                        <nb-option *ngFor="let role of rolesList" [value]="role.role">{{role.role}}</nb-option>  
                        <nb-option [value]="doctor">{{doctor}}</nb-option>    
                    </nb-select>
                    <div *ngIf="submittedForm1 && f.role.errors" class="invalid-feedback">
                        <div *ngIf="f.role.errors.required">Role is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="username">Username</label>
                    <input type="text" autocomplete="off" formControlName="username" nbInput fullWidth
                        [ngClass]="{ 'is-invalid': submittedForm1 && f.username.errors }" id="user-name" required />
                    <div *ngIf="submittedForm1 && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors.required">Username is required</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col"  style="padding-top:10px;">
                        <button type="submit" class="btn btn-block" nbButton status="primary" (click)="back()">
                            Cancel
                        </button>
                    </div>
                    <div class="col" style="padding-top:10px;">
                        <button type="submit" class="btn btn-block" nbButton status="primary">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            submit
                        </button>
                    </div>
                </div>
            </form>
            <form [formGroup]="form2" (ngSubmit)="newPassword()" *ngIf="!screen1">
                <div class="form-group">
                    <label for="otp">OTP</label>
                    <input type="text" autocomplete="off" formControlName="otp" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && pdf.otp.errors }" id="otp" required minlength="6"
                        maxlength="6">
                    <div *ngIf="submitted && pdf.otp.errors" class="invalid-feedback">
                        <div *ngIf="pdf.otp.errors.required">OTP is required</div>
                        <div *ngIf="pdf.otp.errors.minlength">must have 6 characters length</div>
                        <div *ngIf="pdf.otp.errors.pattern">Please enter valid OTP</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="username">New Password</label>
                    <input type="password" autocomplete="off" formControlName="password" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && pdf.password.errors }" pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$"/>
                    <div class="form-control-position">
                        <i class="la la-key"></i>
                    </div>
                    <div *ngIf="submitted && pdf.password.errors" class="invalid-feedback">
                        <div *ngIf="pdf.password.errors.required">New Password is required</div>
                    </div>
                    <div *ngIf="pdf.password.errors && (pdf.password.invalid || pdf.password.touched)">
                        <small class="text-danger" *ngIf="pdf.password.errors.pattern">password must be 8-16 characters
                            in length with at least 1 upper case, 1 lower case, 1 numeric and 1 special
                            character</small>
                    </div>
                </div>
                <div class="form-group">
                    <label for="username">Confirm New Password</label>
                <input type="password" autocomplete="off"
                        [class.is-invalid]="pdf.confirmPassword.invalid && pdf.confirmPassword.touched" required
                        formControlName="confirmPassword" class="form-control pw"
                        [ngClass]="{ 'is-invalid': submitted && pdf.confirmPassword.errors }">
                    <div
                        *ngIf="pdf.confirmPassword.errors && pdf.confirmPassword.invalid && pdf.confirmPassword.touched">
                        <small class="text-danger" *ngIf="pdf.confirmPassword.errors.required">Confirm New Password is
                            required</small>
                            <small class="text-danger" *ngIf="pdf.confirmPassword.errors.mustMatch">Password didn't match</small>
                    </div>
                </div>
                <div class="row">
                    <div class="col" style="padding-top:10px;">
                        <button type="submit" class="btn btn-block" nbButton status="primary" (click)="goBack()">
                            Cancel
                        </button>
                    </div>
                    <div class="col" style="padding-left: 20px;padding-top:10px;">
                        <button type="submit" class="btn btn-block" nbButton status="primary">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Save
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>