import { HttpClient, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP_CONSTANTS } from 'src/app/shared/constants';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

    getAllCustomers() {
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_CUSTOMER_DETAILS)
    }
    getAllAppointments() {
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_ALL_CUSTOMER_APPTS);
    }
    getDoctors(){
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_DOCTORS)
    }
    getAllAppointmentPayments() {
        // let url = constants.API_REST_URL + "api/v1/getAllAppointmentPayments";
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_ALL_APPONT_PAYMENTS);
    }
    getAllFollowups(followuptype: any) {
        let url = "";
        if(followuptype == "Payments")
            url = APP_CONSTANTS.REST_API_URL.GET_ALL_PAYMENTS_FOLLOWUPS;
        else if(followuptype == "Refunds")
             url = APP_CONSTANTS.REST_API_URL.GET_ALL_REFUNDS_FOLLOWUPS;
        else if(followuptype == "Appointments")
             url = APP_CONSTANTS.REST_API_URL.GET_ALL_APPOINTMENT_FOLLOWUPS;
        return this.http.get(url);
    }
    getAppointmentsCount() {
        // return of({"total":754,"cancelled":211,"scheduled":2,"completed":0});
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_APP_COUNT);
    }
    getDaywiseAppointmentsCount() {
        // return of([{"date":"2022-02-23","cancelled":3,"scheduled":24},{"date":"2022-02-24","cancelled":3,"scheduled":29},{"date":"2022-02-25","cancelled":1,"scheduled":14},{"date":"2022-02-26","cancelled":0,"scheduled":2},{"date":"2022-02-27","cancelled":0,"scheduled":0},{"date":"2022-02-28","cancelled":5,"scheduled":11},{"date":"2022-03-01","cancelled":1,"scheduled":17}]);
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_DAYWISE_APPS_COUNT);
    }
    getConsultedPatientCountByDepartment() {
        // return of([{"department":"Dentistry - Oral Surgery","count":98},{"department":"General Physician","count":94},{"department":"Anatomy","count":14},{"department":"Allergy and Immunology","count":4},{"department":"Dentistry","count":4},{"department":"Anesthesiology - Hospice and Palliative Medicine","count":1}]);
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_APPS_BY_DEPT);
    }
    getRecentlyConsultedPatients() {
        // return of([{"personId":46,"name":"Aditya venkata reddy nallamilli","appointmentTime":"2022-03-01 15:30:00"},{"personId":4,"name":"Aswani Nalamati","appointmentTime":"2022-03-01 11:15:00"},{"personId":41,"name":"Aditya nallamilli","appointmentTime":"2022-03-01 19:00:00"},{"personId":17,"name":"ranjith reddy","appointmentTime":"2022-02-28 11:30:00"},{"personId":80,"name":"Girish T","appointmentTime":"2022-03-01 16:00:00"}])
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_REC_CON_PAT);
    }
    getRecentAvailableDoctors() {
        // return of([{"id":1,"username":"jojo","doctorQualification":"General Physician"},{"id":30,"username":"robo1","doctorQualification":"Cardiologist"},{"id":63,"username":"DemoDoctor2","doctorQualification":null},{"id":95,"username":"testUsr5","doctorQualification":"General Physician"},{"id":125,"username":"vinay","doctorQualification":"General Physician"}])
        return this.http.get(APP_CONSTANTS.REST_API_URL.GET_REC_AVA_DOC);
    }
}

