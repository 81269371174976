import { createReducer, on } from "@ngrx/store"
import { UpdateUserRoles } from "../actions/app-config.actions"
import {DoctorDashboardLoading, DoctorUpcomingConsultationsListLoading, loadDoctorDashboard, UpdateDoctorDashbaord, UpdateDoctorPatientList, UpdateDoctorUpcomingConsultationsList } from "../actions/doctor-dashboard.actions"
import { UpdateDashbaord } from "../actions/home.actions"
import { DoctorDashboardInitialState } from "../state/doctor-dashboard.state"
import { DashboardInitialState } from "../state/home.state"

const _DoctorDashboardReducer = createReducer(
    DoctorDashboardInitialState,
    on(UpdateDoctorDashbaord, (state, action) => {
        return {
            ...state,
            patientList: action.patientList,
            upcomingConsultationsList:action.upcomingConsultationsList,
            getDoctorAppointmentsCount:action.getDoctorAppointmentsCount,
            getDoctorDaywiseAppointmentsCount:action.getDoctorDaywiseAppointmentsCount,
            getDoctorRecentlyConsultedPatients:action.getDoctorRecentlyConsultedPatients,
            getDoctorPrescriptions:action.getDoctorPrescriptions,
            DoctorDashboardLoading:false,
            DoctorUpcomingConsultationsListLoading:false,
        }
    }),
    on(DoctorDashboardLoading, (state, action) => {
        return {
            ...state,
            DoctorDashboardLoading:true,
            DoctorUpcomingConsultationsListLoading:true,
        }
    }),
    on(UpdateDoctorUpcomingConsultationsList, (state, action) => {
        return {
            ...state,
            upcomingConsultationsList:action.upcomingConsultationsList,
            DoctorUpcomingConsultationsListLoading:false
        }
    }),
    on(DoctorUpcomingConsultationsListLoading, (state, action) => {
        return {
            ...state,
            DoctorUpcomingConsultationsListLoading:true,
        }
    }),
    on(UpdateDoctorPatientList, (state, action) => {
        return {
            ...state,
            patientList:action.patientList,
        }
    }),
    
)

export function DoctorDashboardReducer(state, action) {
    return _DoctorDashboardReducer(state, action)
}