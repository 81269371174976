import { createAction, props } from "@ngrx/store";

export const loadUserRoles = createAction(
    "[load user role Resolver] Load User Role "
); 

export const UpdateUserRoles = createAction(
    "[App Config Effect] Update User Roles Loaded",
    props<{ UserRoles: any }>()
);

