// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBUgxwB0XFfUMXFFpUylH5iESOYNAJRn54",
    authDomain: "doctorapp-65796.firebaseapp.com",
    databaseURL: "https://doctorapp-65796.firebaseio.com",
    projectId: "doctorapp-65796",
    storageBucket: "doctorapp-65796.appspot.com",
    messagingSenderId: "760379808186",
    appId: "1:760379808186:web:95186b63a9484786cf6583",
    measurementId: "G-ZD3Q085XCC"
  },
  googleApiKey: 'AIzaSyAIIYOxA7qeetFz6TuR1Qewc0Rrjhzx7ZU',
  VIDEOSDK_API_KEY: 'c3f53c06-3697-4e19-a3f5-a146a5733bf2',
  API_REST_URL : "https://devapksrv.elro.ai:8090/",
  // VIDEO_SDK_URL:"https://uat-portal.elro.ai/",
  VIDEO_SDK_URL:"https://deveng.elro.ai/",
  // SMS_URL:'https://uat-portal.elro.ai/',
  RXNAV_URL : "https://rxnav.nlm.nih.gov/REST/interaction/list.json?rxcuis=",
  SMS_URL:'https://deveng.elro.ai/',
  FCM_URL:'https://fcm.googleapis.com/fcm/send',
  // API_REST_URL : "https://prod-abgserver.elro.ai:8080/"
//export const API_REST_URL = "https://prodsrv.elro.ai/"
  // API_REST_URL: "http://localhost:8080/"
  // API_REST_URL: "https://uat-server.elro.ai:8080/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
