import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PatientService } from '../services/patient.service';
import { NbDialogService } from '@nebular/theme';
import { ViewCell } from 'ng2-smart-table';
import { UnsubscribeOnDestroyAdapter } from '../services/UnsubscribeOnDestroyadapter';

@Component({
  template: `
      <div >
      <span (click)="viewReport(1,ReportsModelContent)"><i class="fa fa-eye"  title="View"></i></span>
      </div>
      <ng-template class="modal text-left" #ReportsModelContent let-c="close" let-d="dismiss">
      <div class="modal-md">
  
          <div class="modal-body">
              <section>
                  <div>
                      <div class="row">
                          <div class="col-xl-12 col-md-12 col-12">
                              <div class="card">
                                  <div class="card-header">
                                      <h4 class="card-title">{{reportname}} Report</h4>
                                  </div>
                                  <div class="">
                                      <div class="" style="width: 100%;overflow: auto;">
  
                                      <img class="image" *ngIf="con1" src="{{reportimage}}" />
  
                                      <img class="image" *ngIf="con2" src="{{reportimage}}" />
                                          <div style="text-align:center;" *ngIf="showText"><i>No Data Found</i></div>
                                          <div>
                                          <ngx-extended-pdf-viewer [showPrintButton]="false" [showBookmarkButton]="false" [showOpenFileButton]="false" [zoom]="'page-width'" *ngIf="con3 && !showText" [textLayer]="true" [src]="reportimage"></ngx-extended-pdf-viewer>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
  
                  </div>
              </section>
          </div>
  
          <div class="modal-footer">
              <button type="button" class="btn grey btn-outline-secondary" (click)="d('Close modal')">Close</button>
          </div>
  
      </div>
  </ng-template>
    `,
})
export class ReportsRenderComponent extends UnsubscribeOnDestroyAdapter implements ViewCell, OnInit {

  renderValue: string;
  reportimage: any;
  reportname: any;
  @Input() value: string | number;
  @Input() rowData: any;
  custromerData: any;
  activated: boolean;
  con1: boolean = false;
  con2: boolean = false;
  con3: boolean = false;
  con4: boolean = false;
  showText: boolean = false;

  constructor(private modalService: NgbModal, private apiService: PatientService, private dialogService: NbDialogService) {
    super()

  }

  ngOnInit() {
    this.renderValue = '';
  }
  viewReport(from, ReportsModelContent) {
    var data1 = JSON.parse(JSON.stringify(this.rowData));
    var ReportsModelContent = ReportsModelContent
    var recordNo = data1.recordNo;
    var reportName = data1.reportName;
    this.reportsModel(ReportsModelContent, recordNo, reportName);
  }
  reportsModel(ReportsModelContent, recordno, reportname) {
    this.reportname = reportname;
    this.subs.sink = this.apiService.getReportAttachment(recordno).subscribe(res => {
    }, error => {
      this.reportimage = error.error.text;
      // this.reportimage = null
      if (this.reportimage == null)
        this.showText = true;
      else {
        if (this.reportimage.includes('data:image')) {
          this.con1 = true;
        }
        else if (this.reportimage.includes('https') && (this.reportimage.includes('jpg') || this.reportimage.includes('jpeg') || this.reportimage.includes('png'))) {
          this.con2 = true;
        }
        else{
          this.con3=true;
          this.showText=false;
        }
    }
    });
    this.modalService.open(ReportsModelContent, { windowClass: 'animated fadeInDown', size: 'lg' });
  }
}