import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { APP_CONSTANTS } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class MeetingService {

  startMeetingheaders: any;
  createMeetingHeaders: any
  createMeetingResponse: any;


  constructor(public httpClient: HttpClient) {
  }

  getMeetingDetils(meetingId: number) {
    return this.httpClient.get(APP_CONSTANTS.REST_API_URL.GET_MEETING_DETAILS + meetingId, { responseType: "text" }).pipe(catchError(this.handleError));
  }

  updateCheckInCheckOut(checkin: any, username: string) {

    let url = APP_CONSTANTS.REST_API_URL.UPDATE_CHECKIN_CHECKOUT + checkin + "/" + username;
    return this.httpClient.get(url)
  }

  sendNotificationBackToServer(notification: any) {
    let url = APP_CONSTANTS.REST_API_URL.SAVE_MEETING_DETAILS;
    return this.httpClient.post(url, notification)
  }

  handleError(error) {
    return throwError(error.message || "Server Error")
  }

  updateStatus(user,status) {
    
    let userDTO = {"username":user,"status":status,"role":"","fcmToken":"","doctorQualification":"","checkIn":"","password":"","id":""}
    let url = APP_CONSTANTS.REST_API_URL.UPDATE_STATUS;
    return this.httpClient.post(url,userDTO);
  }

  getDoctorStatus(username){
    return this.httpClient.get(APP_CONSTANTS.REST_API_URL.UPDATE_DOCTOR_STATUS + username);
  }

}
