import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CustProfileState } from "../state/cust-profile.state";

const getCustProfileState = createFeatureSelector<CustProfileState>('custprofile')

export const getCustProfile = createSelector(getCustProfileState, (state) => {
    return state;
})

export const getVitals = createSelector(
    getCustProfileState,
    state => {
        state.vitals,
        state.VitalsLoading
        return state;
    }
);
export const getPrescriptionsList = createSelector(
    getCustProfileState,
    state => {
        state.prescriptionsList,
        state.prescriptionsListLoading
        return state;
    }
);
export const getPatientAppointmentList = createSelector(
    getCustProfileState,
    state => {
        state.patientAppointmentList,
        state.PatientAppointmentListLoading
        return state;
    }
);
export const getMedicationList = createSelector(
    getCustProfileState,
    state => {
        state.userMedicationList,
        state.activeMedicationList
        return state;
    }
);
