<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle" [ngClass]="{'disp-none':showMenuBar}">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"><span>{{title}}</span></a>
  </div> 
</div>
<div class="header-container">
  <div *ngIf="isDoctor" >
    <app-instant1-notifications></app-instant1-notifications>
  </div>
  <!-- <div *ngIf="button=='Check In' && isDoctor" style="margin-right:10px;">
    <button nbButton  size="small" class="checkinbtn" nbButton  (click)="checkInorOut()" >
      {{button}}
      </button>
  </div>
  <div *ngIf="button=='Check Out' && isDoctor" style="margin-right:10px;">
    <button nbButton  size="small" class="checkinbtn" nbButton (click)="checkInorOut()" >
      {{button}}
      </button>
  </div> -->

  <nb-actions size="large">
    <nb-action class="user-action" >
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               [picture]="user?.picture"
               nbContextMenuTag="my-context-menu"
               >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
