import { createAction, props } from "@ngrx/store";

export const loadAdminDashboard = createAction(
    "[load admin dashboard Resolver] Load Dashboard "
); 

export const UpdateAdminDashbaord = createAction(
    "[update admin dashboard Effect] Update dashboard",
    props<{ 
        doctorsList:any,
        patientList: any,
        usersList:any,
        rolesList:any,
    }>()
);
export const loadDoctorsList = createAction(
    "[load admin DoctorsList Resolver] loadDoctorsList admin"
); 

export const loadPatientList = createAction(
    "[load admin PatientList Resolver] loadPatientList admin"
);
export const loadUserList = createAction(
    "[load UserList Resolver] loadUserList "
); 
export const loadRolesList = createAction(
    "[load RolesList Resolver] loadRolesList "
);
export const AdminHomeDashboardLoading = createAction(
    "[AdminHomeDashboardLoading admin home] AdminHomeDashboardLoading admin home "
);
export const UpdateDoctorsList = createAction(
    "[UpdateDoctorsList admin dashboard Effect] UpdateDoctorsList dashboard",
    props<{ 
        doctorsList: any,
    }>()
);
export const UpdatePatientList = createAction(
    "[UpdatePatientList admin dashboard Effect] UpdatePatientList dashboard",
    props<{ 
        patientList: any,
    }>()
);
export const UpdateUsersList = createAction(
    "[UpdateUsersList admin dashboard Effect] UpdateUsersList dashboard",
    props<{ 
        usersList: any,
    }>()
);
export const AdminUserListLoading = createAction(
    "[AdminUserListLoading admin users] AdminUserListLoading admin users "
);
export const AdminDoctorsListLoading = createAction(
    "[AdminDoctorsListLoading admin doctors] AdminDoctorsListLoading admin doctors "
);
export const AdminPatientListLoading = createAction(
    "[AdminPatientListLoading admin patients] AdminPatientListLoading admin patients "
);
export const AdminRolesListLoading = createAction(
    "[AdminRolesListLoading admin roles] AdminRolesListLoading admin roles "
);
export const UpdateRolesList = createAction(
    "[UpdateRolesList admin dashboard Effect] UpdateRolesList dashboard",
    props<{ 
        rolesList: any,
    }>()
);