import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { ComponentsRoutingModule } from './components-routing.module';
import { GlobalErrorHandler } from '../shared/global-error-handler';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../shared/auth.interceptor';
import { LoginComponent } from './login/login.component';
import { LoginService } from './login/login.service';
import { SharedModule } from '../shared/shared.module';
import { ComponentsComponent } from './components.component';
import { PatientConsultationAutoComponent } from './patientconsultation-auto/patientconsultation-auto.component';
import { PatientConsultationComponent } from './patientconsultation/patientconsultation.component';

import { PatientSdkComponent } from './patientvideosdk/patientvideosdk.component';
import { PrescriptionComponent } from './prescription/prescription.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

import { PrescriptiontestComponent } from './prescriptiontest/prescriptiontest.component';
import { QuestionsPopupComponent } from './questions-popup/questions-popup.component';
import { ReportsuploadComponent } from './reportsupload/reportsupload.component';
import { StatusPageComponent } from './statuspage/statuspage.component';

const MODULES = [
  ComponentsRoutingModule,
  SharedModule
];

const COMPONENTS = [
  ComponentsComponent,
  LoginComponent,
  PatientConsultationComponent,
  PatientConsultationAutoComponent,
  PatientSdkComponent,
  PrescriptionComponent,
  PrivacyPolicyComponent,
  StatusPageComponent,
  TermsConditionComponent,
  PrescriptiontestComponent,
  QuestionsPopupComponent,
  ReportsuploadComponent,


];

@NgModule({
  imports: [...MODULES
  ],
  exports: [...COMPONENTS, ...MODULES],
  declarations: [...COMPONENTS ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    // { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    LoginService
  ],
  entryComponents: [...COMPONENTS]

})


export class ComponentsModule { 
  static forRoot(): ModuleWithProviders<ComponentsModule> {
    return {
      ngModule: ComponentsModule,
    };
  }
}
