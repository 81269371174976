import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { PrescriptionpdfComponent } from 'src/app/shared/shared-dialogue-components/prescriptionpdf/prescriptionpdf.component';
import { ApiDrug } from 'src/app/shared/_models/apidrug';
import { lifeStyleModficationsList } from 'src/app/shared/_models/lifeStyleModificationsList';
import { Prescription } from 'src/app/shared/_models/prescription';
import { DoctorConsultationsService } from '../../service/doctor-consultation.service';
import { DatePipe, Location } from '@angular/common';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { APP_CONSTANTS } from 'src/app/shared/constants';
import { lifeStyleModfications } from 'src/app/shared/_models/lifeStyleModificationsDetails';
import { AppState } from 'src/app/store/root.reducer';
import { Store } from '@ngrx/store';
import { getDoctor } from 'src/app/store/selectors/doctor-user.selectors';
import { LocalDataSource } from 'ng2-smart-table';
import { PrescriptionRenderComponent } from 'src/app/shared/shared-render-components/patient-prescription-render-component';
import { DrugsInteractionDialog } from '../drugsinteraction/drugsinteraction.component';
import { ACPrescriptionNoteDialog } from '../prescription-note/prescriptionnote.component';
import { getPrescriptionsList } from 'src/app/store/selectors/cust-profile.selectors';
import { DoctorQuestionsPopupComponent } from '../doctor-questions-popup/doctor-questions-popup.component';
import { UnsubscribeOnDestroyAdapter } from 'src/app/shared/services/UnsubscribeOnDestroyadapter';
import { LoadPrescriptionsList } from 'src/app/store/actions/doctor-dashboard.actions';
@Component({
  selector: 'app-appointment-consultations',
  templateUrl: './appointment-consultations.component.html',
  styleUrls: ['./appointment-consultations.component.scss']
})
export class AppointmentConsultationsComponent extends UnsubscribeOnDestroyAdapter implements OnInit{
  @Input() text: string;
  appointmentDTO: any;
  Aadhar: string = "";
  patientName: string;
  from: string = "Doctor";
  user: string = "";
  patientNumber: string;
  patientMail: string;
  bloodGroup: string;
  bpCapturedTime: string;
  glucoCapturedTime: string;
  prescription = new Prescription();
  weightCapturedTime: string;
  tempCapturedTime: string;
  diseases: string;
  allergies: string;
  chiefComplaint: string;
  callTransferReason: string;
  bloodPressure: string = "--";
  temperature: string = "--";
  pulse: string = "--";
  bloodGlucose: string = "--";
  weight: string = "--";
  pulseCapturedTime: string;
  height: string = '--';
  disease: string;
  age: string;
  lmp: string;
  bmi: any = "--"
  hdlp: any;
  ldlp: any;
  gender: string;
  diabetic: any;
  thyroid: any;
  opened: boolean;
  isb2b: boolean;
  refresh: string = "no"
  conditions: any = [];
  ecgResult: string = "-";
  cholestrol: string = "--";
  uricacid: string = "--";
  totalcholestrol: string = "--";
  triglyceride: string = "--";
  hdlipoprotein: string = "--";
  ldlipoprotein: string = "--";
  urinanalysis: string = "--";
  himoglobin: string = "--";
  cholestolCapturedTime: string;
  uricacidCapturedTime: string;
  totalcholestrolCapturedTime: string;
  urinanalysisCapturedTime: string;
  himoglobinCapturedTime: string;
  ecgCapturedTime: string;
  hwCapturedTime: string;
  searching = false;
  searchFailed = false;
  spO2: string = "--";
  isDisabled: boolean = true;
  submitted = false;
  drugslist: any = [];
  instructions: string[] = ['Before Food', 'After Food'];
  durations: string[] = ['One Day', 'Two Days', 'Three Days', 'Four Days', 'Five Days', 'Six Days', 'Seven Days', 'Eight Days', 'Nine Days',
    'Ten Days', 'Two Weeks', 'Three Weeks', 'Four Weeks', "One Month", "Two Months", "Three Months", "Four Months", "Five Months", "Six Months"];
  frequencies: string[] = ["Morning", "Afternoon", "Night", "SOS", "Others"];
  dosagelist: any = [];
  rows: any = [];
  reportrows: any = [];

  parentChannel: string;
  consultationList: any = [];
  allVitalsDTO: any;
  previewPrescription: any;
  published: boolean = false;
  drugs: Observable<ApiDrug[]>[] = [];
  patientVitals: any;
  ecgList: any = [];

  bloodGroupUpdatedDate: string;
  medicalHistoryUpdatedDate: string;
  lmpUpdatedDate: string;
  thyroidUpdatedDate: string;
  diabeticUpdatedDate: string;
  allergiesUpdatedDate: string;
  bloodPressureUpdatedDate: string;
  diabeticsince: string;
  thyroidsince: string;
  bp: string;
  bpsince: string;
  meetingId: any;
  doctorId: any;
  modificationSuggestions: lifeStyleModficationsList[];
  suggestionselected: number = 0;
  suggestionsIdList: number[] = [0];
  applyStyles: boolean = false;

  consultRows: any = [];
  consultTemp = this.consultRows;
  connecting: boolean = true;
  showCancel: boolean = true;
  @Input() data: any;
  isFullScreen: boolean = false;
  public lifeStyleModfication: lifeStyleModfications[] = [];
  username: string;
  elements = [{
    "icon": "venus",
    "field": "Basic Info",
    "type": "tiny",
    "child": [{
      "field": "Gender",
      "value": ""
    },
    {
      "field": "Age",
      "value": ""
    },
    {
      "field": "Height",
      "value": ""
    },
    {
      "field": "Weight",
      "value": ""
    },
    {
      "field": "Blood Pressure",
      "value": ""
    },

    {
      "field": "Diabetic",
      "value": ""
    },
    {
      "field": "Blood Group",
      "value": ""
    },
    {
      "field": "Thyroid",
      "value": ""
    },

    ]
  },
  {
    "icon": "person-dots-from-line",
    "field": "Allergies",
    "value": ""
  },
  {
    "icon": "comment-medical",
    "field": "Medical history",
    "value": ""
  },
  ];
  source1: LocalDataSource = new LocalDataSource();
  source2: LocalDataSource = new LocalDataSource();

  settings1 = {
    hideSubHeader: true,
    actions: {
      edit: false,
      delete: false,
      add: false,

    },
    columns: {
      rno: {
        title: 'Records No',
        type: 'string', filter: false,
        valuePrepareFunction: (cell, row2) => {
          return row2.recordNo
        },
      },
      rName: {
        title: 'Report Name',
        type: 'string',
        valuePrepareFunction: (cell, row2) => {
          return row2.reportName
        },
      },
      date:
      {
        title: 'Report Date',
        type: 'string',
        valuePrepareFunction: (cell, row2) => {
          var formatted = this.datePipe.transform(row2.measureTime, 'dd-MM-yyyy');
          return formatted
        },
      }
    },
  }
  settings5 = {
    hideSubHeader: true,
    defaultStyle: false,
    attr: {
      class: 'responsible' // this is custom table scss or css class for table
    },
    actions: {
      edit: false,
      delete: false,
      add: false,

    },
    columns: {
      index: {
        title: 'S No',
        type: 'string',
        valuePrepareFunction: (value, row, cell) => {
          return cell.row.index + 1;
        },
      },
      topic: {
        title: 'Chief Complaint',
        type: 'string',
        valuePrepareFunction: (cell, row4) => {
          return row4.complaints
        },
      },
      date:
      {
        title: 'Prescription Date',
        type: 'string',
        valuePrepareFunction: (cell, row4) => {
          if(row4.prescriptionType==='generated'){
            return row4.publishTime
          }else{
            var formatted = this.datePipe.transform(row4.publishTime, 'dd-MM-yyyy');
             return formatted
          }
        },
      },
      actions: {
        title: 'Actions',
        type: 'custom',
        filter:false,
        valuePrepareFunction: (cell, row4) => {
          return this.mobileno
        },
        renderComponent: PrescriptionRenderComponent

      }
    },
  }
  source5: LocalDataSource = new LocalDataSource();
  settings3 = {
    hideSubHeader: true,
    actions: {
      edit: false,
      delete: false,
      add: false,

    },
    columns: {
      index: {
        title: 'S.No',
        type: 'string', filter: false,
        valuePrepareFunction: (value, row, cell) => {
          return cell.row.index + 1;
        },
      },
      complaint: {
        title: 'Chief Complaint',
        type: 'string',
        valuePrepareFunction: (cell, row) => {
          return row.chiefComplaint
        },
      },
      severity:
      {
        title: 'Severity',
        type: 'string',
        valuePrepareFunction: (cell, row) => {
          return row.severity
        },
      },
      option:
      {
        title: 'Option',
        type: 'string',
        valuePrepareFunction: (cell, row) => {
          return row.severity
        },
      }
    },
  }
  reportsDataTableMessage: any = { emptyMessage: 'No data to display' };
  prescriptionsDataTableMessage: any = { emptyMessage: 'No data to display' };
  consultationForm: FormGroup;
  public prescriptionList: FormArray;
  public lifestylemodificationList: FormArray;
  lineArea4: any;
  loading: boolean = false;
  viewType: number;
  completeList: any = []
  list: any = []
  severityText: string='';
  spo2CapturedTime: string;
  childQues: any = []
  response: any;
  questions: any[];
  questionNo: number;
  prescriptionsListLoading: boolean;
  showEdit: boolean = false;
  mobileno: any;
  constructor(private route: ActivatedRoute, private modalService: NgbModal, private datePipe: DatePipe, private store: Store<AppState>, private dialogService: NbDialogService
    , private formBuilder: FormBuilder, private location: Location, private dialogRef: NbDialogRef<AppointmentConsultationsComponent>,
    private apiService: DoctorConsultationsService, private toastrService: NbToastrService, private router: Router, private sanitizer: DomSanitizer) {
      super()
    this.store.select(getDoctor).subscribe(data => {
      this.username = data.username;
    })
    localStorage.setItem("presstatus", "nondraft");
  }

  get consultationFormGroup() {
    return this.consultationForm.get('prescriptionList') as FormArray;
  }

  get lifeStyleModficationFormGroup() {
    return this.consultationForm.get('lifestylemodficationList') as FormArray;
  }
  getValidityDrug(i) {
    return (<FormArray>this.consultationForm.get('prescriptionList')).controls[i].get('drugName').errors
      && (<FormArray>this.consultationForm.get('prescriptionList')).controls[i].get('drugName').invalid
      && (<FormArray>this.consultationForm.get('prescriptionList')).controls[i].get('drugName').touched;
  }
  getValidityIns(i) {
    return (<FormArray>this.consultationForm.get('prescriptionList')).controls[i].get('instructions').errors
      && (<FormArray>this.consultationForm.get('prescriptionList')).controls[i].get('instructions').invalid
      && (<FormArray>this.consultationForm.get('prescriptionList')).controls[i].get('instructions').touched;
  }
  getValidityFreq(i) {
    return (<FormArray>this.consultationForm.get('prescriptionList')).controls[i].get('frequency').errors
      && (<FormArray>this.consultationForm.get('prescriptionList')).controls[i].get('frequency').invalid
      && (<FormArray>this.consultationForm.get('prescriptionList')).controls[i].get('frequency').touched;
  }
  getValidityDue(i) {
    return (<FormArray>this.consultationForm.get('prescriptionList')).controls[i].get('duration').errors
      && (<FormArray>this.consultationForm.get('prescriptionList')).controls[i].get('duration').invalid
      && (<FormArray>this.consultationForm.get('prescriptionList')).controls[i].get('duration').touched;
  }
  keyPressAlphanumeric(event) {

    var inp = String.fromCharCode(event.keyCode);

    if (/^[a-zA-Z\s]*$/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  drugAlts: Observable<any[]>[] = [];
  prescSuggestions: any = [];
  prescSuggestionsFlag: boolean = false;
  showAltFlag: Array<boolean> = [];
  prescriptionNotes: any;

  displayFn(drug: any): string {
    return drug && drug.name ? drug.name : drug;
  }
  editPreScreening() {
    this.dialogService.open(DoctorQuestionsPopupComponent, {
      context: {
        appointmentId: this.appointmentDTO.id
      }, dialogClass: 'model-full',
      autoFocus: false
    })
      .onClose.subscribe({
        next: (result) => {
          this.getPreScreeningDetails();
        }
      });
  }

  suggestPrescription(e: NgbTypeaheadSelectItemEvent) {
    this.loading = true;
    this.subs.sink=this.apiService.suggestPrescription(e.item.prescriptionIds.slice(-3)).subscribe((res: any) => {
      this.loading = false;
      if (res && res.length > 0) {
        this.showPrescriptionNoteDialog(res);
      }
    });
  }
  loadPrescritption(presc: any) {
    // this.prescSuggestionsFlag = presc && presc.prescriptionDetails && presc.prescriptionDetails.length > 0;
    let prescSuggestions = [];
    for (var i = 0; i < this.prescriptionList.length; i++) {
      this.removePrescritption(i);
    }
    for (var i = 0; i < presc.prescriptionDetails.length; i++) {
      this.addPrescritption();
      let p: any = presc.prescriptionDetails[i];
      p.drugName = (p.drug && p.drug.name) ? p.drug.name : p.drugName;
      // p.drugName=JSON.parse(JSON.stringify(p.drug));
      p.rxcui = p.drug?.rxcui;
      delete p.drug;
      if (p.drugAlts && p.drugAlts.length > 0) {
        let ad = [];
        if (p.altDrugs) {
          p.altDrugs.split(", ").forEach((d) => { const dAlt = p.drugAlts.find((alt) => alt.name == d); dAlt ? ad.unshift(dAlt) : ad.unshift({ "name": d }) });
        }
        p.altDrugs = ad;
        this.drugAlts[i] = of(p.drugAlts);
        this.showAltFlag[i] = true;
      }
      if (p.frequency) {
        let fq = [];
        p.frequency.split(", ").forEach((f) => fq.push(f));
        p.frequency = fq;
      }
      prescSuggestions.push(p);
    }
    const clinicalNotes = presc.clinicalNotes;
    const lifeStyleModifications = presc.lifeStyleModifications;
    const controls1 = <FormArray>this.consultationForm.controls['lifestylemodficationList'];
    controls1.clear();
    for (var i = 0; i < lifeStyleModifications.length; i++) {
      this.addLifeStyleModfication();
    }
    clinicalNotes['lifestylemodficationList'] = lifeStyleModifications.map(lsc => { return { 'changes': lsc.lifeStyleChanges } });
    clinicalNotes['prescriptionList'] = prescSuggestions;
    clinicalNotes.complaints = { "complaints": clinicalNotes.complaints }
    this.consultationForm.patchValue(clinicalNotes);
  }

  // loadDrugAlts(index, medicine) {
  //   this.apiService.getDrugsbyName(medicine).subscribe((res: any[]) => {
  //     if (res.length > 0) {
  //       this.getDrugAlts(index, res[0]);
  //     }
  //   });
  // }

  // getDrugs(index,med)
  // {

  // }
  getDrugAlts(index, medicine) {
    if (medicine.name) {
      const controls = <FormArray>this.consultationForm.controls['prescriptionList'];
      controls.at(index).get('altDrugs').patchValue([]);
      this.showAltFlag[index] = false;
      this.drugAlts[index] = of([]);
      if (medicine.generic && medicine.generic.trim().length > 0) {
        this.drugAlts[index] = this.apiService.getDrugsbyGenericName(medicine.generic).pipe(
          tap((resdata: any) => {
            this.showAltFlag[index] = resdata && resdata.length > 0;
            let ind = resdata.findIndex(x => x.id === medicine.id);
            resdata.splice(ind, 1);
            return resdata;
          }),
          catchError(() => {
            return of([]);
          }));
      }
    }
  }

  addAltDrug(index: number, item: any) {
    var arrayControl = this.consultationForm.get('prescriptionList') as FormArray;
    // const drugSel = arrayControl.at(index).get('drugName').value;
    let altDrugs = arrayControl.at(index).get('altDrugs').value;
    if (!altDrugs.find(x => x.id === item.id || x.name === item.name)) {
      altDrugs.push(item);
      arrayControl.at(index).get('altDrugs').patchValue(altDrugs);
    }
  }

  showPrescriptionNoteDialog(rows) {
    this.dialogService.open(ACPrescriptionNoteDialog, {
      context: {
        data: { pageValue: rows }
      }, dialogClass: 'model-full',
      autoFocus: false
    })
      .onClose.subscribe({
        next: (result) => {
          if (result.event == 'not-select') {
            this.prescriptionNotes = result.data;
          } else {
            if (result.data != null) {
              this.loadPrescritption(result.data);

            }
          }
        }
      });
  }

  suggestComplaints = (text$: Observable<any>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.apiService.suggestComlaints({
          "doctorId": this.username,
          "clinicalNotes": { "complaints": term }
        }).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    )

  formatter = (result: any) => result.complaints;

  get pdf() {
    return this.consultationForm.controls;
  }

  addPrescritption() {
    this.prescriptionList.push(this.createPrescription());
    const controls = <FormArray>this.consultationForm.controls['prescriptionList'];
    this.manageNameControl(controls.length - 1);
  }

  removePrescritption(index) {
    const controls = <FormArray>this.consultationForm.controls['prescriptionList'];
    controls.removeAt(index);
    this.drugs.splice(index, 1);
    this.drugAlts[index] = of([]);
    this.showAltFlag[index] = false;
  }

  addLifeStyleModfication() {
    this.lifestylemodificationList.push(this.createliftstyleModification());
    const controls1 = <FormArray>this.consultationForm.controls['lifestylemodficationList'];

  }

  removeLifeStyleModfication(index) {
    const controls1 = <FormArray>this.consultationForm.controls['lifestylemodficationList'];
    controls1.removeAt(index);
  }

  createPrescription(): FormGroup {

    return this.formBuilder.group({
      drugName: ['', Validators.required],
      rxcui: [''],
      dosage: [''],
      instructions: ['', Validators.required],
      frequency: ['', Validators.required],
      duration: ['', Validators.required],
      altDrugs: [[]]
    });
  }

  createliftstyleModification(): FormGroup {

    return this.formBuilder.group({
      changes: ['']
    });
  }

  meetingNumber = ''
  passWord = ''
  ngOnInit() {
    if (this.data != null) {
      this.appointmentDTO = this.data.pageValue;
      this.mobileno = this.appointmentDTO?.customerMobile;
    } else if (this.router.getCurrentNavigation().extras.state != null)
      this.appointmentDTO = this.router.getCurrentNavigation().extras.state;
    if (window.innerWidth >= 1200) {
      this.viewType = 1;
    } else if (window.innerWidth < 1200 && window.innerWidth >= 960) {
      this.viewType = 4;
    } else if (window.innerWidth < 960 && window.innerWidth >= 768) {
      this.viewType = 3;
      this.applyStyles=true;
    } else if (window.innerWidth < 480) {
      this.viewType = 2;
      this.applyStyles=true;
    }
    window.location.hash = '#source';
    history.pushState("", document.title, window.location.pathname+window.location.search);
    this.consultationForm = this.formBuilder.group({
      complaints: ['', Validators.required],
      observation: [''],
      examinations: [''],
      diagnosis: ['', Validators.required],
      relevantPoints: [''],
      investigation: [''],
      notes: [''],
      prescriptionList: this.formBuilder.array([this.createPrescription()], Validators.required),
      lifestylemodficationList: this.formBuilder.array([])
    });
    this.manageNameControl(0);


    this.prescriptionList = this.consultationForm.get('prescriptionList') as FormArray;
    this.lifestylemodificationList = this.consultationForm.get('lifestylemodficationList') as FormArray;
    if (this.text == 'fromInstant') {
      console.log(this.data);
      console.log(JSON.stringify(this.data));
      this.meetingId = this.data.meetingId;
      this.meetingNumber = this.data.agoraMeetingId;
      this.appointmentDTO = {};
      this.appointmentDTO.personId = this.data.personId;
      //this.appointmentDTO.topic=this.data.chiefCompliant;
      this.apiService.getPatientDetails(this.meetingId, this.username).subscribe((resData: any) => {
        this.appointmentDTO.customerMobile = resData.allVitalsDTO.mobile;
        this.appointmentDTO.topic = resData.chiefComplaint;
        this.loadPatientDetails(this.appointmentDTO)
      }, error => {
        console.log("error occured");
      });
    }
    else if (this.appointmentDTO != null && this.text != 'fromInstant') {
      this.getPreScreeningDetails();
      this.meetingNumber = this.appointmentDTO.id;
      // this.passWord = this.appointmentDTO.meetingPasscode;
      //this.meetingUrl = this.sanitizer.bypassSecurityTrustResourceUrl("https://prod-abgadmin.elro.ai/videosdkmeeting/" + this.meetingNumber + "/" + JSON.parse(localStorage.getItem('currentUser')).username);
      this.loadPatientDetails(this.appointmentDTO);
    }
    localStorage.setItem("prescriptionId", "0");

    this.consultationForm.valueChanges.subscribe(data => {
      localStorage.setItem("presstatus", "nondraft");
    });

    this.getLifeStyleModifications();
  }

  manageNameControl(index: number) {
    var arrayControl = this.consultationForm.get('prescriptionList') as FormArray;
    this.drugs[index] = arrayControl.at(index).get('drugName').valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        switchMap(name => name?.length > 2 ? this.apiService.getDrugsbyName(name).pipe(
          tap((resdata: any) => {
            return resdata[3];

          }),
          catchError(() => {
            return of([]);
          })) : []
        )
      );
  }

  getLifeStyleModifications() {
    var suggesttions = this.suggestionsIdList.join(',');
    this.doctorId = this.username;
    this.apiService.suggestLifeStyleModifications(this.doctorId, suggesttions).subscribe((resData: any) => {
      this.modificationSuggestions = resData;
      this.modificationSuggestions = this.modificationSuggestions.filter((ele) => ele.lifeStyleChanges != "");
    }, error => {
      var data = error;
    });

  }

  addLifeStyleSuggestion(item) {
    this.suggestionsIdList.push(item.id);
    this.getLifeStyleModifications();
    if (!this.lifestylemodificationList.value.find(x => x.changes === item.lifeStyleChanges)) {
      const controls1 = <FormArray>this.consultationForm.controls['lifestylemodficationList']
      if (controls1.value[0]?.changes == null || controls1.value[0].changes != '') {
        this.addLifeStyleModfication();
      }
      const controls = <FormArray>this.consultationForm.controls['lifestylemodficationList'];
      var n = this.lifestylemodificationList.length - 1;
      for (var i = 0; i < controls.length - 1; i++) {
        if (controls.value[i].changes == '' && controls.length > 1) {
          this.removeLifeStyleModfication(n);
          n = i;
          break;
        }
      }
      this.lifestylemodificationList.at(n).patchValue({ 'changes': item.lifeStyleChanges });
    }
  }

  reset() {
    localStorage.setItem("presstatus", "nondraft");
    this.prescriptionList.clear();
    this.lifestylemodificationList.clear();
    this.consultationForm.reset();
    this.addPrescritption();
    this.drugAlts=[];
    this.showAltFlag=[];
  }


  onCancel(): void {
    this.dialogRef.close(false);
  }

  searchDrug = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.filterDrugs(term)
      ),
      tap(() => this.searching = false)
    )

  filterDrugs(value) {

    var filteredDrugs = [];
    this.drugs = [];
    if (value != null && value != "") {
      this.drugslist.forEach(element => {

        if (element.composition.toLowerCase().includes(value.toLowerCase())) {
          filteredDrugs.push(element);
        }
      });
      return filteredDrugs;
    }
  }
  getPreScreeningDetails() {
    this.apiService.getQuestionsDetailsByAppointmentId(this.appointmentDTO.id).subscribe((resData: any) => {
      if(resData?.details!=null){
        this.response = JSON.parse(resData.details);
        this.showEdit=true;
        this.questions = [];
        this.getAllChildQuestions(this.response);
        this.completeList = resData;
        if (resData['seviority'] < 4)
          this.severityText = 'Low';
        else if (resData['seviority'] > 4 && resData['seviority'] < 7)
          this.severityText = 'Medium';
        else
          this.severityText='High'
      }else{
        this.severityText=''
      }
    }, error => {
    });
  }
  getAllChildQuestions(response) {
    response.forEach(parent => {
      this.questionNo++;
      if(parent.selectedOption!=null)
        this.questions.push(parent);
      else{}
      parent.preScreeningDetailsList.forEach(details => {
        if (details.childQuestions.length > 0) {
          this.getAllChildQuestions(details.childQuestions);
        }
      });
    });

  }


  saveAndGetmeetingId() {
    var username = this.username;
    this.apiService.saveMeetingandGetId(this.appointmentDTO.personId, username).subscribe((resData: any) => {
      this.meetingId = resData;
    }, error => {

    });
  }

  loadPatientDetails(appointment) {
    this.apiService.getAllVitals(appointment.customerMobile).subscribe((resData: any) => {
      this.chiefComplaint = appointment.topic;
      if (resData.personId != null) {
        if (this.text != 'fromInstant') {
          this.appointmentDTO.personId = resData.personId;
          this.saveAndGetmeetingId();
        }
      }
      if (resData != null) {
        this.allVitalsDTO = resData;
        localStorage.setItem("userVitals", JSON.stringify(this.allVitalsDTO));
        this.patientName = resData.name;
        this.patientNumber = resData.mobile;
        this.Aadhar = resData.aadhaar != null ? resData.aadhaar : "";
        this.height = resData.height != null && resData.height != "undefined" ? resData.height + " cm." : "";
        this.weight = resData.weight != null && resData.weight != "undefined" ? resData.weight + " kg" : "";
        this.age = resData.age;
        this.bmi = resData.bmi != null ? resData.bmi : "--";
        this.hdlp = resData.hd;
        this.gender = resData.gender != null ? resData.gender : "";
        this.elements[0].child[0].value = this.gender;
        this.elements[0].child[1].value = this.age;
        this.elements[0].child[2].value = this.height;
        this.elements[0].child[3].value = this.weight;


        if (resData.highPressure != null) {
          this.bloodPressure = resData.highPressure + "/" + resData.lowPressure + " mmHg";
          // this.bpCapturedTime=resData.bpReadings.capturedTime;
        }
        if (resData.bloodSugar != null) {
          this.bloodGlucose = resData.bloodSugar + " mg/dL";
        }
        else
          this.bloodGlucose = "-";

        if (resData.temperature != null) {
          this.temperature = resData.temperature + " \xB0F";
        }
        if (resData.oxygen != null) {
          this.spO2 = resData.oxygen + "%";
        }
        if (resData.bpm != null) {
          this.pulse = resData.bpm + " bpm";
        }
        this.ecgResult = resData.ecgResult != null ? resData.ecgResult : "-";
        this.cholestrol = resData.chol != null ? resData.chol + " mg/dL" : "--";
        this.uricacid = resData.ua != null ? resData.ua + " mg/dL" : "--";
        this.himoglobin = resData.hb != null ? resData.hb + " g/dL" : "--";
        this.totalcholestrol = resData.tchol != null ? resData.tchol + " mg/dL" : "--";
        this.triglyceride = resData.trig != null ? resData.trig + " mg/dL" : "--";
        this.ldlipoprotein = resData.tcHdl != null ? resData.tcHdl + " mg/dL" : "--";
        this.hdlipoprotein = resData.hdlChol != null ? resData.hdlChol + " mg/dL" : "--";
        this.himoglobin = resData.hb != null ? resData.hb : "--";
        this.urinanalysis = resData.uro != null ? resData.uro : "--";
        this.tempCapturedTime = resData.tempMeasureTime != null ? this.getFancyTime(resData.tempMeasureTime.replace("T", " ")) + " ago" : "";
        this.pulseCapturedTime = resData.boMeasureTime != null ? this.getFancyTime(resData.boMeasureTime.replace("T", " ")) + " ago" : "";
        this.glucoCapturedTime = resData.bsMeasureTime != null ? this.getFancyTime(resData.bsMeasureTime.replace("T", " ")) + " ago" : "";
        this.bpCapturedTime = resData.bpMeasureTime != null ? this.getFancyTime(resData.bpMeasureTime.replace("T", " ")) + " ago" : "";
        this.hwCapturedTime = resData.uhMeasureTime != null ? this.getFancyTime(resData.uhMeasureTime.replace("T", " ")) + " ago" : "";
        this.ecgCapturedTime = resData.ecgMeasureTime != null ? this.getFancyTime(resData.ecgMeasureTime.replace("T", " ")) + " ago" : "";
        this.cholestolCapturedTime = resData.colMeasureTime != null ? this.getFancyTime(resData.colMeasureTime.replace("T", " ")) + " ago" : "";
        this.uricacidCapturedTime = resData.uaMeasureTime != null ? this.getFancyTime(resData.uaMeasureTime.replace("T", " ")) + " ago" : "";
        this.urinanalysisCapturedTime = resData.uraMeasureTime != null ? this.getFancyTime(resData.uraMeasureTime.replace("T", " ")) + " ago" : "";
        this.himoglobinCapturedTime = resData.hbMeasureTime != null ? this.getFancyTime(resData.hbMeasureTime.replace("T", " ")) + " ago" : "";
        this.spo2CapturedTime = resData.boOxygenMeasureTime != null ? this.getFancyTime(resData.boOxygenMeasureTime.replace("T", " ")) + " ago" : "";
      }
      if (resData != null && resData.userHealthDetailsDTO != null) {
        var profile = resData.userHealthDetailsDTO;
        this.bloodGroup = profile.bloodGroup + " ve";
        this.elements[0].child[6].value = this.bloodGroup;

        this.allergies = profile.allergies;
        if (profile.lmp != null) {
          let x = profile.lmp.split(" ");
          this.lmp = x[0];
        }
        this.diabetic = this.stringToBoolean(profile.diabetic) ? "Yes" : "No";
        this.elements[0].child[5].value = this.diabetic;

        if (profile.thyroid != null) {
          this.thyroid = this.stringToBoolean(profile.thyroid) ? "Yes" : "No";
        }
        this.elements[0].child[7].value = this.thyroid;

        if (profile.bloodPressure != null)
          this.bp = this.stringToBoolean(profile.bloodPressure) ? "Yes" : "No";
        this.elements[0].child[4].value = this.bp;

        this.disease = profile.medicalHistory;
        this.elements[1].value = this.allergies;
        this.elements[2].value = this.disease;

        this.diabeticsince = profile['diabeticSince'] != null ? profile['diabeticSince'] != "" ? " - " + profile['diabeticSince'] : "" : "";
        this.thyroidsince = profile['thyroidSince'] != null ? profile['thyroidSince'] != "" ? " - " + profile['thyroidSince'] : "" : "";
        this.bpsince = profile['bloodPressureSince'] != null ? profile['bloodPressureSince'] != "" ? " - " + profile['bloodPressureSince'] : "" : "";

        this.bloodGroupUpdatedDate = profile.bloodGroupUpdatedDate != null ? this.getFancyTime(profile.bloodGroupUpdatedDate) + " ago" : " ";
        this.medicalHistoryUpdatedDate = profile.medicalHistoryUpdatedDate != null ? this.getFancyTime(profile.medicalHistoryUpdatedDate) + " ago" : " ";
        this.lmpUpdatedDate = profile.lmpUpdatedDate != null ? this.getFancyTime(profile.lmpUpdatedDate) + " ago" : " ";
        this.thyroidUpdatedDate = profile.thyroidUpdatedDate != null ? this.getFancyTime(profile.thyroidUpdatedDate) + " ago" : " ";
        this.diabeticUpdatedDate = profile.diabeticUpdatedDate != null ? this.getFancyTime(profile.diabeticUpdatedDate) + " ago" : " ";
        this.allergiesUpdatedDate = profile.allergiesUpdatedDate != null ? this.getFancyTime(profile.allergiesUpdatedDate) + " ago" : " ";
        this.bloodPressureUpdatedDate = profile.bloodPressureUpdatedDate != null ? this.getFancyTime(profile.bloodPressureUpdatedDate) + " ago" : " ";

      }

      this.loadPatientVitals();
      this.loadPrescriptionhistory();
    }, error => {
      var data = error;
    });

  }

  getFancyTime(date): string {
    var date1 = new Date(date).getTime();
    var date2 = new Date().getTime();
    var msec = date2 - date1;
    var mins = Math.floor(msec / 60000);
    var hrs = Math.floor(mins / 60);
    var days = Math.floor(hrs / 24);
    mins = mins % 60;
    hrs = hrs % 24;
    days = days % 365;
    if (days >= 1)
      return days == 1 ? days.toString() + " day" : days.toString() + " days";
    else if (days < 1 && hrs >= 1)
      return hrs == 1 ? hrs.toString() + " hour" : hrs.toString() + " hours";
    else
      return mins == 1 ? mins.toString() + " min" : mins.toString() + " mins";
  }

  private stringToBoolean(stringValue: string): boolean | undefined {
    try {
      return JSON.parse(stringValue);
    }
    catch (e) {
      return undefined;
    }
  }

  toogleFullScreen() {
    if (this.isFullScreen) {
      this.isFullScreen = false;
    } else {
      this.isFullScreen = true;
    }
  }


  lipidsList: any = [];
  hbList: any = [];
  uaList: any = [];
  patientReportUploadDTOList: any = [];
  loadPatientVitals() {
    this.apiService.getPatientVitals(this.patientNumber).subscribe(
      response => {

        this.reportrows = [];
        this.patientVitals = response;
        this.ecgList = response['PEEcgDetails'] != null ? response['PEEcgDetails'] : [];
        this.lipidsList = response['BloodFatDetails'] != null ? response['BloodFatDetails'] : [];
        this.hbList = response['HbDetails'] != null ? response['HbDetails'] : [];
        this.uaList = response['UrinalysisDetails'] != null ? response['UrinalysisDetails'] : [];
        let uricacidList = response['UaDetails'] != null ? response['UaDetails'] : [];
        let cholList = response['CholDetails'] != null ? response['CholDetails'] : [];
        this.patientReportUploadDTOList = response['patientReportUploadDTOList'] != null ? response['patientReportUploadDTOList'] : [];
        if (this.ecgList && this.ecgList.length > 0) {
          this.ecgList.forEach(element => {
            this.reportrows.push(new Report(element.RecordNo, 'ECG', element.measureTime, element, 'ecg'));

          });
        }
        if (this.lipidsList && this.lipidsList.length > 0) {
          this.lipidsList.forEach(element => {
            this.reportrows.push(new Report(element.RecordNo, 'Lipids Profile', element.measureTime, element, ''));
          });

        }
        if (this.hbList && this.hbList.length > 0) {
          this.hbList.forEach(element => {
            this.reportrows.push(new Report(element.RecordNo, 'Himoglobin', element.measureTime, element, ''));
          });

        }
        if (uricacidList && uricacidList.length > 0) {
          uricacidList.forEach(element => {
            this.reportrows.push(new Report(element.RecordNo, 'Uricacid', element.measureTime, element, ''));
          });

        }
        if (cholList && cholList.length > 0) {
          cholList.forEach(element => {
            this.reportrows.push(new Report(element.RecordNo, 'Cholestrol', element.measureTime, element, ''));
          });

        }
        if (this.uaList && this.uaList.length > 0) {
          this.uaList.forEach(element => {
            this.reportrows.push(new Report(element.RecordNo, 'Urin Analysis', element.measureTime, element, ''));
          });

        }
        if (this.patientReportUploadDTOList && this.patientReportUploadDTOList.length > 0) {
          this.patientReportUploadDTOList.forEach(element => {
            this.reportrows.push(new Report(element.recordNo, element.reportName, element.measureTime, element, 'report'));
          });

        }
        this.source1.load(this.reportrows);
      }
    );
  }
  loadPrescriptionhistory() {
    this.store.dispatch(LoadPrescriptionsList({ "mobileno": this.mobileno }));
    this.store.select(getPrescriptionsList).subscribe((data) => {
      this.prescriptionsListLoading = data?.prescriptionsListLoading;
      if (data?.prescriptionsList) {
        this.source5.load(data.prescriptionsList);
      }
    })
  }
  cancel() {
    this.router.navigate([APP_CONSTANTS.APP_ROUTE.DOCTOR + '/' + APP_CONSTANTS.DOCTOR_ROUTE.APPOINTMENTS])
  }

  openPrescriptionPreview(data) {
    data.prescriptionhistory = true;
    console.log('name', this.username)
    this.dialogService.open(PrescriptionpdfComponent, {
      context: {
        data1: { pageValue: data },
        from: 'consultation',
        name: this.username
      }, dialogClass: 'model-full',
      autoFocus: false
    })
      .onClose.subscribe({
        next: (res) => {
          if (res.event == 'refresh') {
          }
        }
      });

  }

  searchFromService = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.apiService.getICD11(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    );

  showCancelButton() {
    this.showCancel = !this.showCancel;
  }

  refreshVitals() {
    if (this.appointmentDTO != null) {
      this.loadPatientDetails(this.appointmentDTO);
    }
  }

  showDrugsInteractionDialog(rows, source) {

    this.dialogService.open(DrugsInteractionDialog, {
      context: {
        data: { pageValue: rows }
      }, dialogClass: 'model-full',
      autoFocus: false
    })
      .onClose.subscribe({
        next: (result) => {
          if (result.event == 'save') {
            this.savePrescriptionFinal();
          }
          if (result.event == 'publish') {
            this.publishPrescriptionFinal();
          }

        }
      });
  }

  savePrescription() {
    let rxcuis = [];
    this.lifeStyleModfication = [];
    for (var i = 0; i < this.lifestylemodificationList.value.length; i++) {
      this.lifeStyleModfication.push({ "prescriptionId": this.appointmentDTO.prescriptionId, "lifeStyleChanges": this.lifestylemodificationList.value[i].changes });
    }
    this.submitted = true;
    if (this.consultationForm.invalid) {
      this.toastrService.show('', 'Please enter all the mandatory fields.', { duration: APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON });
      return;
    }

    if (this.appointmentDTO != null) {
      this.prescription.personId = this.appointmentDTO.personId;
      this.prescription.meetingId = this.meetingId;
      this.prescription.doctorId = this.username;

      let diagnosis = this.consultationForm.get('diagnosis').value;
      if (diagnosis != null && typeof diagnosis != 'string')
        this.consultationForm.patchValue({ 'diagnosis': (diagnosis[0]) })

      let clinicalNotes = Object.assign({}, this.consultationForm.value);
      if (clinicalNotes.complaints != null && typeof (clinicalNotes.complaints) != 'string') {
        clinicalNotes.complaints = clinicalNotes.complaints.complaints
      }
      this.prescription.clinicalNotes = clinicalNotes;
      let prescriptionDetails = [];
      clinicalNotes.prescriptionList.slice(0).forEach(function (val) {
        let value = Object.assign({}, val);
        if (value.drugName != null) {
          if (value.drugName.rxcui || value.rxcui) {
            let valueEle = value.drugName.rxcui ? value.drugName.rxcui : value.rxcui;
            if (valueEle && valueEle != '') {
              rxcuis = rxcuis.concat(valueEle.split(', '));
            }
          }
          value.drugName = value.drugName.name ? value.drugName.name : value.drugName;
        }
        delete value.rxcui;
        if (value.frequency != null && typeof (value.frequency) != 'string') {
          value.frequency = value.frequency.join(", ");
        }
        if (value.altDrugs != null && typeof (value.altDrugs) != 'string') {
          value.altDrugs = value.altDrugs.map(function (elem) {
            return elem.name;
          }).join(", ");
        }
        prescriptionDetails.push(value);
      });
      delete clinicalNotes.prescriptionList;
      delete clinicalNotes.lifestylemodficationList;
      this.prescription.prescriptionDetails = prescriptionDetails;
      this.prescription.lifeStyleModifications = this.lifeStyleModfication;
      if (this.prescriptionList.length > 1 && rxcuis.length > 1) {
        this.apiService.getDrugsInteractions(rxcuis.join('+')).subscribe((res) => {
          let interactions = [];
          res['fullInteractionTypeGroup']?.forEach((intGrp) => {
            intGrp['fullInteractionType']?.forEach((intType) => {
              intType['interactionPair']?.forEach((intPair) => {
                interactions.push(intPair['description']);
              })
            })
          });
          if (interactions.length > 0) {
            this.showDrugsInteractionDialog(interactions, "save");
          } else {
            this.savePrescriptionFinal();
          }
        })
      } else {
        this.savePrescriptionFinal();
      }
    }
  }

  savePrescriptionFinal() {
    this.loading = true;
    this.apiService.savePrescription(this.prescription)
      .subscribe(
        response => {
          this.loading = false;
          this.previewPrescription = response;
          this.prescription.id = response.id;
          localStorage.setItem("prescriptionId", response.id);
          localStorage.setItem("presstatus", "draft");
          this.previewPrescription['allVitalsDTO'] = this.allVitalsDTO;
          localStorage.setItem('localPrescription', JSON.stringify(this.previewPrescription));
          this.toastrService.show('', 'Prescription saved successfully.', { duration: APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON });
        },
        error => {
          console.log(error);
          this.loading = false;
        });
  }

  review() {
    if (localStorage.getItem("presstatus") == "nondraft") {
      this.toastrService.show('', 'Please save the prescription to review.', { duration: APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON });
    } else {

      if (this.previewPrescription != null && this.previewPrescription != undefined) {
        this.dialogService.open(PrescriptionpdfComponent, {
          context: {
            data1: { pageValue: this.previewPrescription },
            name: this.username,
            from: 'consultation'

          }, dialogClass: 'model-full',
          autoFocus: false
        })
          .onClose.subscribe({
            next: (res) => {
              if (res != null && res.data == "publish") {
                this.publishPrescription();
              }
            }
          });

      }
    }

  }
  vitalType: string;
  LargeModel(LargeModelContent, type: string) {
    this.vitalType = type;
    this.modalService.open(LargeModelContent, { windowClass: 'ttable', size: <any>'xl' });

  }


  publishPrescription(): void {
    let rxcuis = [];
    if (this.appointmentDTO != null) {
      this.prescription.personId = this.appointmentDTO.personId;
      this.prescription.meetingId = this.meetingId;
      this.prescription.doctorId = this.username;

      this.prescription.editPrescription = true;
      this.prescription.isPublished = true;
      let diagnosis = this.consultationForm.get('diagnosis').value;
      if (diagnosis != null && typeof diagnosis != 'string')
        this.consultationForm.patchValue({ 'diagnosis': (diagnosis[0]) })

      let clinicalNotes = Object.assign({}, this.consultationForm.value);
      if (clinicalNotes.complaints != null && typeof (clinicalNotes.complaints) != 'string') {
        clinicalNotes.complaints = clinicalNotes.complaints.complaints
      }
      this.prescription.clinicalNotes = clinicalNotes;
      let prescriptionDetails = [];
      clinicalNotes.prescriptionList.slice(0).forEach(function (val) {
        let value = Object.assign({}, val);
        if (value.drugName != null) {
          if (value.drugName.rxcui || value.rxcui) {
            let valueEle = value.drugName.rxcui ? value.drugName.rxcui : value.rxcui;
            if (valueEle && valueEle != '') {
              rxcuis = rxcuis.concat(valueEle.split(', '));
              console.log("rxcuis " + rxcuis);
            }
          }
          delete value.rxcui;
          // if (value.drugName.rxcui) {
          //   rxcuis = rxcuis.concat(value.drugName.rxcui.split(', '));
          // }
          value.drugName = value.drugName.name ? value.drugName.name : value.drugName;
        }
        if (value.frequency != null && typeof (value.frequency) != 'string') {
          value.frequency = value.frequency.join(", ");
        }
        if (value.altDrugs != null && typeof (value.altDrugs) != 'string') {
          value.altDrugs = value.altDrugs.map(function (elem) {
            return elem.name;
          }).join(", ");
        }
        prescriptionDetails.push(value);
      });
      delete clinicalNotes.prescriptionList;
      delete clinicalNotes.lifestylemodficationList;
      this.prescription.prescriptionDetails = prescriptionDetails;
      this.lifeStyleModfication = [];
      for (var i = 0; i < this.lifestylemodificationList.value.length; i++) {
        this.lifeStyleModfication.push({ "prescriptionId": this.appointmentDTO.prescriptionId, "lifeStyleChanges": this.lifestylemodificationList.value[i].changes });
      }
      this.prescription.lifeStyleModifications = this.lifeStyleModfication;
      if (this.prescriptionList.length > 1 && rxcuis.length > 1) {
        this.apiService.getDrugsInteractions(rxcuis.join('+')).subscribe((res) => {
          let interactions = [];
          res['fullInteractionTypeGroup']?.forEach((intGrp) => {
            intGrp['fullInteractionType']?.forEach((intType) => {
              intType['interactionPair']?.forEach((intPair) => {
                interactions.push(intPair['description']);
              })
            })
          });
          if (interactions.length > 0) {
            this.showDrugsInteractionDialog(interactions, "publish");
          } else {
            this.publishPrescriptionFinal();
          }
        })
      } else {
        this.publishPrescriptionFinal();
      }
    }
  }

  publishPrescriptionFinal() {

    this.loading = true;
    this.apiService.publishPrescription(this.prescription)
      .subscribe(
        response => {
          this.loading = false;
          console.log("pubres---" + response);
          localStorage.setItem("presstatus", "published");
          this.published = true;
          this.toastrService.show('', 'Prescription is published successfully.', { duration: APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON });
        },
        error => {
          this.loading = false;
          console.log(error);
        });
  }

}
class Report {
  constructor(
    public recordNo: number,
    public reportName: string,
    public measureTime: string,
    public vital: any,
    public name: any,
  ) { }
}