import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder,FormGroup, Validators } from '@angular/forms';
import { MeetingService } from '../../services/meeting.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { AbstractControl } from '@angular/forms';
import { PatientService } from '../../services/patient.service';
import { DatePipe } from '@angular/common';
import { NbDateService, NbDialogRef, NbToastrService } from '@nebular/theme';
import { UnsubscribeOnDestroyAdapter } from '../../services/UnsubscribeOnDestroyadapter';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/root.reducer';
import { getDoctor } from 'src/app/store/selectors/doctor-user.selectors';
import { RequestMeeting } from '../../models/RequestMeetingObject';
import { APP_CONSTANTS } from '../../constants';
// export function RequireMatch(control: AbstractControl) {
//   const selection: any = control.value;
//   if (typeof selection === 'string') {
//     return { incorrect: true };
//   }
//   return null;
// }

export function RequireRange(control: AbstractControl) {
  const sDt = moment(new Date()).subtract(1, 'days');
  const eDt = moment(new Date()).add(1, 'months');
  const selection: any = control.value;
  if (moment(selection).isBefore(sDt) || moment(selection).isAfter(eDt)) {
    return { incorrect: true };
  }
  return null;
}

@Component({
  templateUrl: './bookappointment.component.html',
  styleUrls: ['./bookappointment.component.scss'],
  // encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookAppointmentComponent extends UnsubscribeOnDestroyAdapter implements OnInit{
  startTime1: any;
  scheduleForm: FormGroup;
  scheduleBtnClicked: boolean = false;
  showScheduleAnotherMeeting: boolean = false;
  date: Date = new Date();
  customerData: any;
  @Input () data:any;
  @Input () text:any;
  submitted: boolean = false;
  reschedule: boolean;
  meetingObject: RequestMeeting;
  title = "Schedule";
  loading:boolean=false;
  doctortypeList = ["All","Paid","Free"];
  showError=false;
  duration: any = 30;
  demoTime="hh:mm";
  selDoctorsList:FormArray;
  totalFee:number;
  datePipe: DatePipe = new DatePipe('en-US');
  show:boolean=true;
  id: number;
  storeRes:any;
  viewType: number;
  applyStyles: boolean = false;
  appointmentType: number=0;

  constructor(protected dateService: NbDateService<Date>,private store: Store<AppState>,private toastrService: NbToastrService,private dialogRef:NbDialogRef<BookAppointmentComponent>, private formBuilder: FormBuilder, private meetingService: MeetingService, private spinner: NgxSpinnerService, private router: Router,public apiService: PatientService) {
    super()
  }
  isDoctor:boolean=true;
  ngOnInit(): void {
    this.meetingObject = new RequestMeeting();
    this.store.select(getDoctor).subscribe(res => {
      this.id=res?.id;
      this.storeRes=res;
    })
    if(this.text=='fromPatient'){
      this.show=false;
      this.isDoctor=true;
    }else if(this.text=='bps'){
      this.appointmentType=1;
    }
    if (window.innerWidth >= 1200) {
      this.viewType = 1;
    } else if (window.innerWidth < 1200 && window.innerWidth >= 960) {
    this.viewType = 4;
  }else if (window.innerWidth < 960 && window.innerWidth >= 768) {
    this.viewType = 3;
    this.applyStyles=true;
  } else if (window.innerWidth < 480) {
    this.viewType = 2;
    this.applyStyles=true;
  }
    this.customerData = this.data;
    if (this.customerData.appointmentDTO) {
      this.reschedule = true;
      this.title = "ReSchedule";
    }
    this.calInit();
    this.scheduleForm = this.formBuilder.group({
      topic: ["", [Validators.required,Validators.pattern(APP_CONSTANTS.REGEX.ALPHABET_WITH_SPACE)]],
      date: ["", [Validators.required, RequireRange]],
      from_time: ["", [Validators.required]],
      time_zone: ["GMT+5:30, India Standard Time"],
      to_time:[""],
      doctorsDTOList:this.formBuilder.array([this.addDoctors()]),
      doctortype: ["All"],
    });
    this.selDoctorsList = this.scheduleForm.get('doctorsDTOList') as FormArray;
    this.getDoctors();
    this.pdf['doctorsDTOList'].valueChanges.subscribe((doctortype) => {
      this.refreshList();
    });
    this.pdf['doctortype'].valueChanges.subscribe((doctortype) => {
      // this.pdf['doctor'].reset();
      this.pdf['doctorsDTOList'].reset();
      if(doctortype == "All")
        this.doctorsList = this.doctorsList1;
      else
        this.doctorsList = this.doctorsList1.filter((doctor : any) => doctor.doctorType && doctor.doctorType.includes(doctortype))
    });
    if(this.text!='fromAdmin' && this.text!='bps'){
      this.scheduleForm.get('doctorsDTOList').setValidators(null); 
      this.scheduleForm.get('doctorsDTOList').setErrors(null); 
      this.pdf['date'].valueChanges.subscribe((dateVal) => {
        if(this.pdf['date'].errors){
          this.doctorsSlots=null;
        }
        else{
          this.getDoctorSlotsForPatient(dateVal);
        }
        
      });
    }else{
      this.pdf['date'].valueChanges.subscribe((dateVal) => {
        if(this.pdf['date'].errors){
          this.doctorsSlots=null;
        }
        else{
          this.getDoctorSlots(dateVal);
        }
      });
      if(this.reschedule){
        this.getDoctorSlots(this.pdf['date'].value);
      }
    }
    if(this.reschedule){
      this.scheduleForm.controls["topic"].setValue(this.customerData.appointmentDTO.topic);
    }
  }
  filteredDoctorsList: Observable<any[]>;
  filteredDoctorsListArray=[];
  // catDoctors: any[] = [];
  catDoctorsList: any[] = [];
  displayFn(doctor: any): string {
    return doctor && doctor.username ? doctor.username + " | Languages: " + doctor.languages + (doctor.doctorType != null ? " | " +doctor.doctorType+"("+doctor.feeAmount+")" : "") : '';
  }
  get doctorDetailsFormGroup() {
    return this.scheduleForm.get('doctorsDTOList') as FormArray;
  }
  addDoctors(): FormGroup {
    return this.formBuilder.group({
      id: [0],
      // doctorSpeciality: ['', Validators.required],
      doctor: [''],
    });
  }
  addDoctor(){
  this.selDoctorsList.push(this.addDoctors());

  }
  removeDoctor(index) {
    this.selDoctorsList.removeAt(index);

  }
  getFilteredDoctorsList(index){
    return this.filteredDoctorsList[index];
  }
  myChangeFunc(data: any) {
    this.scheduleForm.controls["doctor"].setValue(data);
    if (data.id && this.pdf.date.value) {
      this.getDoctorSlots(this.pdf.date.value);
    }
  }

  // private _filterDoctors(value: string, from: number,index:number): any[] {
  //   if (!value) {
  //     return;
  //   }
  //   const filterValue = value.toLowerCase();
  //   if (from == 0) {
  //     return this.catDoctorsList[index].filter(doctor => doctor.username.toLowerCase().includes(filterValue));
  //   } else if (from == 1) {
  //     return this.doctorsList.filter(doctor => (doctor.doctorQualification && doctor.doctorQualification.toLowerCase().includes(filterValue)));
  //   }
  // }
  getValidity(i) {
    return false;
    // return ((<FormArray>this.scheduleForm.get('doctorsList')).controls[i].invalid && (<FormArray>this.scheduleForm.get('doctorsList')).controls[i].errors)||(<FormArray>this.scheduleForm.get('doctorsList')).controls[i].untouched;
  }

  doctorsList = [];
  doctorsList1 = [];
  refreshList(){
    this.totalFee=0;
    var consultationType="Free";
    var docList=(<FormArray>this.pdf['doctorsDTOList']).controls.forEach((ele)=>{
      var req={};
      if(ele.value?.doctor?.doctorType=='Paid'){
        consultationType='Paid'
        this.totalFee+=ele.value.doctor.feeAmount
      }
    })
    if(consultationType=='Paid'){
      this.totalFee+=30;
    }
    if(this.scheduleForm.get('date').value!=null && this.scheduleForm.get('date').value!='' ){
      this.getDoctorSlots(this.datePipe.transform(this.scheduleForm.get('date').value, 'yyyy-MM-dd'));
    };

  }
  getDoctors() {
      this.loading=true;
      this.subs.sink=this.apiService.getAllDoctors().subscribe((data: any) => {
        if(this.appointmentType==1){
          this.doctorsList = data.filter((e)=>{
            return e.practicing==='Psychology'
          });
          this.doctorsList1 = this.doctorsList;
        }else{
          this.doctorsList = data;
          this.doctorsList1 = this.doctorsList;
        }
        if (this.reschedule) {
          this.removeDoctor(0);
          this.doctorsList.forEach((element: any) => {
           var array=[];
              this.customerData.appointmentDTO.doctorsList.forEach(data => {
                if (element.id == data.doctorId){
                this.selDoctorsList.push(this.formBuilder.group({
                  id: [0],
                  doctor: [element, Validators.required],
                }));
              }
              });
              this.customerData.appointmentDTO.startTime = new Date( this.customerData.appointmentDTO.startTime);
              this.scheduleForm.get('date').setValue(this.customerData.appointmentDTO.startTime);
          });
        }
        this.loading=false;
      }, error => {
        console.log(error);
      });
  }
  checkDuplicateRecods(docIds){
    return !(docIds.length=== new Set(docIds).size);
  }
  doctorsSlots: Observable<any[]> = null;
  getDoctorSlots(dateVal) {
    this.doctorsSlots = null;
    var docIds=[];
    var docList=(<FormArray>this.pdf['doctorsDTOList']).controls.forEach((ele)=>{
      docIds.push(ele.value?.doctor?.id);
    })
    this.showError=this.checkDuplicateRecods(docIds);
    if (docIds && docIds.length>0) {
     var dateVal1=this.datePipe.transform(dateVal, 'yyyy-MM-dd')
      this.doctorsSlots = this.apiService.getDoctorsFreeSlots(docIds, dateVal1);
    }
  }
  getDoctorSlotsForPatient(dateVal) {
    var dateVal1=this.datePipe.transform(dateVal, 'yyyy-MM-dd')
    this.doctorsSlots = this.apiService.getDoctorsFreeSlots(this.id, dateVal1);
  }
  get pdf() {
    return this.scheduleForm.controls;
  }

  onSubmit($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.submitted = true;
    if(this.showError){
      return;
    }
    else if(this.reschedule){
      this.rescheduleAppointment(this.customerData.appointmentDTO);
    }
    else if(this.text=='fromAdmin'){
      this.scheduleAppointment();
    }
    else if(this.text=='bps'){
      this.scheduleAppointment();
    }
    else{
      this.scheduleAppointmentForPatient();
    }
  }


  rescheduleAppointment(appointmentDTO: any) {
    this.loading=true;
    const datepipe: DatePipe = new DatePipe('en-US')
    let formattedDate = datepipe.transform(this.scheduleForm.get('from_time').value.startTime, 'YYYY-MM-dd HH:mm:ss')
    appointmentDTO.startTime = formattedDate;
    appointmentDTO.duration = this.scheduleForm.get('from_time').value.duration;
    this.subs.sink=this.apiService.rescheduleAppointment(appointmentDTO).subscribe((data: any) => {
      this.toastrService.show("",'Your doctor appointment bas been rescheduled successfully.',{duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
      this.dialogRef.close({ event: 'refresh' });

    }, error => {
    });
  }
  scheduleAppointmentForPatient() {
    var doc=[];
    var req={};
    req["doctorId"]=this.storeRes.id;
    req["doctorName"]=this.storeRes.username;
    req["consultationType"]= this.storeRes.consultationType;
    req["consultationFee"]=this.storeRes.consultationFee;
    doc.push(req);

    this.loading=true;
    const datepipe: DatePipe = new DatePipe('en-US')
    let formattedDate = datepipe.transform(this.scheduleForm.get('from_time').value.startTime, 'YYYY-MM-dd HH:mm:ss')
    let appointmentDTO = {
      "customerMobile": this.customerData != null ? this.customerData.mobile : this.scheduleForm.get('patient').value.memberDTO.Mobile,
      "customerName": this.customerData != null ? this.customerData.name : this.scheduleForm.get('patient').value.memberDTO.Name,
      "doctorId": this.storeRes.id,
      "doctorName": this.storeRes.username,
      "topic": this.scheduleForm.get('topic').value,
      "meetingNo":  this.customerData.mobile,
      "meetingPasscode": '',
      "startTime": formattedDate,
      "duration": this.scheduleForm.get('from_time').value.duration,
      "multiple":true,
      "consultationtype":this.storeRes.consultationType,
      "consultationfee":this.storeRes.consultationFee,
      "doctorsList":doc,
    };
    this.subs.sink=this.apiService.bookDoctorAppointment(appointmentDTO).subscribe((data: any) => {
      this.loading=false;
      console.log(data);
      if(data.statusCode=="ELRO_200"){
        // this.toastrService.show("",'Your appointment has been scheduled successfully.', {duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
        this.toastrService.show("",data.message, {duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
        this.dialogRef.close({ event: 'refresh' });
        }else if(data.statusCode=="ELRO_500"){
          this.toastrService.show("", data.message,{duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
        // this.toastrService.show("", 'Selected slot is already booked. Please select other slot',{duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
        }else if(data.statusCode=="ELRO_208"){
          this.toastrService.show( "",data.message, {duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
          this.toastrService.show( "",'Patient has already scheduled appointment.', {duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
      }
      // this.ref.detectChanges();
    }, error => {
      this.loading=false;
    });
  }
  scheduleAppointment() {
    this.loading=true;
    const datepipe: DatePipe = new DatePipe('en-US')
    let formattedDate = datepipe.transform(this.scheduleForm.get('from_time').value.startTime, 'YYYY-MM-dd HH:mm:ss')
    var doc=[];
    (<FormArray>this.pdf['doctorsDTOList']).controls.forEach((ele)=>{
      var req={};
      req["doctorId"]=ele.value.doctor.id;
      req["doctorName"]=ele.value.doctor.username;
      req["consultationType"]= ele.value.doctor.doctorType;
      req["consultationFee"]=ele.value.doctor.feeAmount;
      doc.push(req);
    })
    let appointmentDTO = {
      "customerMobile": this.customerData.memberDTO.Mobile,
      "customerName": this.customerData.memberDTO.Name,
      "topic": this.scheduleForm.get('topic').value,
      "meetingNo": this.customerData.memberDTO.Mobile,
      "meetingPasscode": "",
      "startTime": formattedDate,
      "duration": this.appointmentType==0?this.scheduleForm.get('from_time').value.duration:APP_CONSTANTS.CONFIG_DETAILS.BPS_DEFAULT_TIME_MIN,
      "multiple":true,
      "doctorsList":doc,
      "status": this.scheduleForm.get('from_time').value.startTime == this.getCustomTime() ? "REQUEST" : "SCHEDULED",
      "appointmentType":this.appointmentType
    };
    this.subs.sink=this.apiService.bookDoctorAppointment(appointmentDTO).subscribe((data: any) => {
      if(data.statusCode=='ELRO_200'){
        this.toastrService.show("",data.message, {duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
        this.loading=false;
        this.dialogRef.close({ event: 'refresh' });
        }else if(data.statusCode=="ELRO_500"){
        this.toastrService.show("", data.message,{duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
        // this.dialogRef.close({ event: 'refresh' });
        this.loading=false;
        
      }else if(data.statusCode=="ELRO_208"){
        this.toastrService.show( "",data.message,{duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
        // this.dialogRef.close({ event: 'refresh' });
        this.loading=false;
        
      }
      // this.ref.detectChanges();
      this.loading=false;
    }, error => {
      this.loading=false;
    });
  }

  updateFormDate(value: any) {
    this.scheduleForm.controls['date'].setValue(this.datePipe.transform(value, 'yyyy-MM-dd'));
  }

  getStartTime() {
    const datepipe: DatePipe = new DatePipe('en-US')
    let formattedDate = datepipe.transform(this.scheduleForm.get('from_time').value.startTime, 'YYYY-MM-ddTHH:mm:ssZ')
    return formattedDate;
  }

  getCustomTime() {
    const datepipe: DatePipe = new DatePipe('en-US')
    let formattedDate = datepipe.transform(this.scheduleForm.get('date').value && this.scheduleForm.get('to_time').value ? moment(this.scheduleForm.get('date').value + 'T' + this.scheduleForm.get('to_time').value).toDate() : new Date(), 'YYYY-MM-ddTHH:mm:ssZ')
    return formattedDate;
  }

  getDuration(): number {
    let from = new Date(this.scheduleForm.get('date').value + " " + this.scheduleForm.get('from_time').value.startTime);
    let to = new Date(this.scheduleForm.get('date').value + " " + this.scheduleForm.get('to_time').value);
    return Math.abs(to.getTime() - from.getTime()) / (1000 * 60);
  }

  goBack() {
    this.dialogRef.close(false);
  }

  items: any[] = [];
  currentDate = new Date();
  currentMonth = "";
  stopDate = new Date();
  selectedItem = null;
  minDate:Date;
  calInit() {
    // Creating an array with specified date range
    this.items = this.getDates(
      new Date(),
      moment(new Date()).add(1, 'months')
    );
  }

  // Common method to create an array of dates
  getDates(startDate: any, stopDate: any) {
    this.minDate=this.dateService.addDay(this.dateService.today(),0);
    let dateArray = [];
    this.currentDate = startDate;
    this.stopDate = stopDate;
    let currentDate = moment(startDate);
    stopDate = moment(stopDate);
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
      currentDate = moment(currentDate).add(1, "days");
    }
    return dateArray;
  }
  

  // Get the selected Date
  // select(item: any) {
  //   this.selectedItem = item;
  //   this.scheduleForm.get('date').setValue(this.datePipe.transform(item, 'yyyy-MM-dd'));
  // }

  // Method for changing Month
  changeMonth(e: any) {
    this.currentDate = this.items[e];
    this.currentMonth = new Date(this.currentDate).toLocaleString("default",
      {
        month: "short"
      });
  }

  // Method to get the current weekday of the date showon
  returnWeekDay(item: any) {
    return new Date(item).toLocaleDateString("default", { weekday: "short" });
  }

}
