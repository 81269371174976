
import { Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import html2canvas from 'html2canvas';

import jsPDF from 'jspdf';
import { ComponentsService } from '../services/componets.service';
import { APP_CONSTANTS } from '../../shared/constants';
import { UnsubscribeOnDestroyAdapter } from 'src/app/shared/services/UnsubscribeOnDestroyadapter';

@Component({ templateUrl: './prescription.component.html' ,
styleUrls: ['./prescription.component.scss'],
providers: []
})
export class PrescriptionComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  patientName: string;
  patientNumber: string;
  patientMail: string;
  bloodGroup: string;
  bpCapturedTime: string;
  glucoCapturedTime: string;
  weightCapturedTime: string;
  tempCapturedTime: string;
  diseases: string;
  allergies: string;
  chiefComplaint: string;
  bloodPressure: string;
  temperature: string;
  pulse: string;
  bloodGlucose: string;
  weight: string = "";
  pulseCapturedTime: string;
  height: string = '';
  disease: string;
  age: string;
  lmp: string;
  gender: string;
  diabetic: any;
  thyroid: any;
  opened: boolean;
  clinicalnotes: any;
  prescriptionlist: any;
  spO2: string;
  parentChannel: string = "jojopoc"
  time1: string;
  reportno: string;
  mobileno: string;
  data: any;
  prescriptionid: string;
  buttontitle: string = "Go Home";
  meetingid: string;
  personid: string;
  date: string;
  currentUser: any;
  consultation: boolean;
  address: string;

  doctorname: string;
  doctorqualification: string = "";
  doctormobile: string;
  doctoremail: string;
  doctoraddress: string;
  doctorregno: string;
  doctorspecalisation: string;
  doctorsignature: string;

  isDisabled: boolean = false;
  lifeStyleModifications: any;
  imagePath:string=APP_CONSTANTS.CONFIG_DETAILS.PRODUCT_LOGO_PATH;
  constructor(private route: ActivatedRoute, private apiService: ComponentsService,private router: Router, private spinner: NgxSpinnerService) {
    super()
    this.meetingid = this.route.snapshot.paramMap.get('meetingid');
    this.personid = this.route.snapshot.paramMap.get('personid');  
    if (screen.width < 1024) {
      document.getElementById("viewportMeta").setAttribute("content", "width=1200px");
    }
  }

  ngOnInit() {
    this.loadrescriptionDetails();
  }

  loadrescriptionDetails() {
    this.spinner.show();
    this.subs.sink=this.apiService.getPrescriptionPdfdata(this.meetingid, this.personid).subscribe((resData: any) => {
      console.log("res--" + JSON.stringify(resData));
      this.data = resData;

      var profiledata = resData['allVitalsDTO'];

      this.patientName = profiledata.name;
      this.patientNumber = profiledata.mobile;
      this.weight = profiledata.weight != null && profiledata.weight != "undefined" ? profiledata.weight : "";
      this.height = profiledata.height != null && profiledata.height != "undefined" ? profiledata.height : "";
      this.age = profiledata.age;
      this.address = profiledata.hasOwnProperty("address") ? profiledata.address : "";
      this.gender = profiledata.gender != null ? profiledata.gender : "";
      this.bloodPressure = profiledata.highPressure != null ? profiledata.highPressure + "/" + profiledata.lowPressure : "--";
      this.temperature = profiledata.temperature != null ? profiledata.temperature : "--";
      this.pulse = profiledata.bpm != null ? profiledata.bpm : "--";
      this.spO2 = profiledata.oxygen != null ? profiledata.oxygen : "--";
      this.bloodGlucose = profiledata.bloodSugar != null ? profiledata.bloodSugar : "--";
      if (profiledata['userHealthDetailsDTO'] != null) {
        this.bloodGroup = profiledata['userHealthDetailsDTO']['bloodGroup'];
        this.allergies = profiledata['userHealthDetailsDTO']['allergies'];
        this.lmp = profiledata['userHealthDetailsDTO']['lmp'] != null ? profiledata['userHealthDetailsDTO']['lmp'] : "";
        if (profiledata['userHealthDetailsDTO']['diabetic'] != null) {
          this.diabetic = this.stringToBoolean(profiledata['userHealthDetailsDTO']['diabetic']) ? "Yes" : "No";
        }
        if (profiledata['userHealthDetailsDTO']['thyroid'] != null) {
          this.thyroid = this.stringToBoolean(profiledata['userHealthDetailsDTO']['thyroid']) ? "Yes" : "No";
        }
        this.disease = profiledata['userHealthDetailsDTO']['medicalHistory'];
        this.allergies = profiledata.userHealthDetailsDTO.allergies;
        this.disease = profiledata.userHealthDetailsDTO.medicalHistory;
      }

      this.time1 = resData['time'] != null ? resData['time'] : "";
      console.log("time--", this.time1);
      this.clinicalnotes = resData['clinicalNotes'];
      this.prescriptionlist = resData['prescriptionDetails'];
      this.lifeStyleModifications = resData['lifeStyleModifications'];

      this.prescriptionlist = this.prescriptionlist.map(element => {
        return {
          ...element, name: this.medicineFrequency(element.frequency)
        }
      })

      let userData = this.data['registrationDTO'];
      if (userData != null) {
        this.doctorname = userData.given != null ? userData.given + " " + userData.family : "";
        this.doctorspecalisation = userData.practicing != null ? userData.practicing : "";
        this.doctormobile = userData.mobile != null ? userData.mobile : "";
        this.doctoremail = userData.email != null ? userData.email : "";
        this.doctorregno = userData.registrationNo != null ? userData.registrationNo : "";
        this.doctoraddress = userData.city != null ? userData.city + "-" + userData.postalcode : "";
        this.doctorsignature = userData.signature != null ? userData.signature : "";

        for (var i = 0; i < userData.educationDTOList.length; i++) {
          if (i == userData.educationDTOList.length - 1)
            this.doctorqualification = this.doctorqualification + userData.educationDTOList[i].courseName;
          else
            this.doctorqualification = this.doctorqualification + userData.educationDTOList[i].courseName + ", ";
        }
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      var data = error;
    }, () => { setTimeout(() => this.downloadAsPDF(), 0); });

  }

  public downloadAsPDF() {
    if (screen.width < 1024) {
      document.getElementById("viewportMeta").setAttribute("content", "width=1200px");
    }
    const data = document.getElementById('prescription');
    let html2canvasOptions = {
      allowTaint: true,
      removeContainer: true,
      backgroundColor: null,
      imageTimeout: 15000,
      logging: true,
      scale: 2,
      useCORS: true
    };
    // Few necessary setting options
    html2canvas(data, html2canvasOptions).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/png')
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;
      let pdf = new jsPDF('p', 'mm', 'a4', true); // A4 size page of PDF
      let position = 0;

      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight, undefined, 'FAST')
        heightLeft -= pageHeight;
      }
      pdf.save('prescription.pdf'); // Generated PDF

      // if (screen.width < 1024) {
      //   document.getElementById("viewportMeta").setAttribute("content", "width=device-width, initial-scale=1");
      // }
    });
  }

  print() {
    let printContents = document.getElementById('prescription').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    window.location.reload();
  }

  private stringToBoolean(stringValue: string): boolean | undefined {
    try {
      return JSON.parse(stringValue);
    }
    catch (e) {
      return undefined;
    }
  }

  medicineFrequency(frequency: any) {
    var output: string = '';
    if (frequency.includes('Morning')) {
      output = output.concat('1');
    }
    else {
      output = output.concat('0');
    }
    if (frequency.includes('Afternoon')) {
      output = output.concat('-1');
    }
    else {
      output = output.concat('-0');
    }
    if (frequency.includes('Night')) {
      output = output.concat('-1');
    }
    else {
      output = output.concat('-0');
    }
    return output;
  }

  
  
  
}




    



  
