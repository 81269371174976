<div class="col-xl-6 offset-xl-3 mt-5" >
    <div class="card logincard">
        <div class="brand-logo text-center  "  >
            <img src="../assets/logo.svg" style="width:50%">
          </div>         
        <div class="card-body">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label for="username">Role</label>
                    <nb-select fullWidth formControlName="role" [ngClass]="{ 'is-invalid': submitted && f.role.errors }">
                        <nb-option [value]="">Select</nb-option>    
                        <nb-option *ngFor="let role of rolesList" [value]="role.id">{{role.role}}</nb-option>  
                        <nb-option [value]="doctor">{{doctor}}</nb-option>    
                    </nb-select>
                    <div *ngIf="submitted && f.role.errors" class="invalid-feedback">
                        <div *ngIf="f.role.errors.required">Role is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="username">Username</label>
                    <input type="text" formControlName="username" nbInput fullWidth [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors.required">Username is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="password">Password</label>
                    <input type="password" formControlName="password" nbInput fullWidth [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 m-t-b-10">
                        <button [fullWidth]="viewType==2?true:false" nbButton status="primary" [disabled]="loading" >
                            <span  *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Login
                        </button>
                    </div>
                    <div class="col-md-6 m-t-b-10">
                        <button [fullWidth]="viewType==2?true:false" nbButton status="primary" [disabled]="loading" *ngIf="this.f.role.value == 'Doctor'" (click)="getRegistration()" style="float:right;">
                            Register
                        </button>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <a style="cursor: pointer;color: rgb(48, 114, 110);" target="_blank" (click)="forgotPassword()">Do you forgot your password, want to reset password ?</a>
                </div>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
            </form>
        </div>
    </div>
    
</div>
