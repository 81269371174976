import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DEFAULT_THEME, NbAutocompleteModule, NbButtonModule, NbCardModule, NbCheckboxModule, NbDialogModule, NbFormFieldModule, NbIconModule, NbInputModule, NbLayoutModule, NbListModule, NbPopoverModule, NbRadioModule, NbSelectModule, NbSidebarModule, NbSpinnerModule, NbThemeModule } from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { GlobalErrorHandler } from '../shared/global-error-handler';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '../shared/auth.interceptor';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgSelectModule } from '@ng-select/ng-select';

const NB_MODULES = [
  
];

const COMPONENTS = [
 
];
const NbModules = [
  NbDialogModule,
  NbInputModule,
  NbAutocompleteModule,
  NbFormFieldModule,
  NbRadioModule,
  NbCheckboxModule,
  FormsModule,
  ReactiveFormsModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbLayoutModule,
  NbPopoverModule,
  NbSpinnerModule,
  NbCardModule,
  NbListModule,
  MatSnackBarModule,
  NgSelectModule
]

@NgModule({
  imports: [CommonModule, ...NB_MODULES, ...NbModules,
  ],
  exports: [CommonModule, ...COMPONENTS, ...NbModules],
  declarations: [...COMPONENTS],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    // { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  entryComponents: [...COMPONENTS]

})
export class CommonModuleModule {
  static forRoot(): ModuleWithProviders<CommonModuleModule> {
    return {
      ngModule: CommonModuleModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },[DEFAULT_THEME]
        ).providers,
      ],
      
    };
  }
 }
