import { ConfirmationDialogComponent } from '../shared-dialogue-components/confirmation-dialogs/confirmation-dialog.component';
import { NbDialogService } from '@nebular/theme';
import { MeetingService } from './meeting.service';
import { Injectable, OnInit } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import { environment } from 'src/environments/environment';
import { getDoctor, getDoctorStatus } from 'src/app/store/selectors/doctor-user.selectors';
import { Notifications } from '../_models/notifications';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/root.reducer';
import { AppointmentConsultationsComponent } from 'src/app/doctorportal/doctor-dialogue-components/appointment-consultations/appointment-consultations.component';
import { updateDoctorStatus } from 'src/app/store/actions/doctor-user.actions';
import { UnsubscribeOnDestroyAdapter } from './UnsubscribeOnDestroyadapter';

@Injectable()
export class MessagingService extends UnsubscribeOnDestroyAdapter implements OnInit {
  currentMessage = new BehaviorSubject(null);
  notifications = new Notifications();
  timeLeft: number = 20;
  interval;
  timer: boolean;
  notificationMsg: any;
  username: string;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    public apiservice: MeetingService,
    private angularFireMessaging: AngularFireMessaging,
    private userService: UserService,
    private dialogService: NbDialogService
  ) {
    super()

    this.store.select(getDoctor).subscribe(data => {

      this.username = data?.username;
    });
    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    );
    navigator.serviceWorker.addEventListener('message', this.onRecevingMessage.bind(this));


  }
  ngOnInit(){

  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log("fcmToken", token);
        this.userService.updateFcmToken(token, this.username).subscribe();
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );

  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        this.notificationMsg = payload;
        console.log("new message received. ", payload);
        this.timeLeft = 20;
        this.timer = false;
        this.startTimer();
        const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
          context: {
            data: "Incoming Call?",
          },
          autoFocus: false
        });

        dialogRef.onClose.subscribe(result => {
          if (result) {
            console.log('Yes clicked');
            console.log("new message received. ", payload);
            this.notifications = Object.assign(new Notifications, payload['data']);
            localStorage.setItem("notifications", JSON.stringify(this.notifications));
            this.currentMessage.next(payload);
            this.pauseTimer();
          }
          else if (result == false) {
            this.pauseTimer();
            this.rejectedCall(CallStatusEnum.REJECT);
          }
        });

      }
    )
  }

  reloadComponent() {
    let currentUrl = this.router.url;
    console.log("currenturl--", currentUrl);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/tele-consult']);
  }

  rejectedCall(CallStatusEnum: any) {
    // this.dialogService.close
    // this.timeLeft = 20;
    let notifications = this.notificationMsg['data'];
    notifications.registrationDTO = { username: this.username };
    //notifications.username=JSON.parse(localStorage.getItem('currentUser')).username; 
    notifications.callingFromDoctor = true;
    notifications.callStatusEnum = CallStatusEnum;
    delete notifications.messageId;
    delete notifications.b2bCall;
    delete notifications.text;
    delete notifications.meetingId;
    var json = JSON.stringify(notifications);
    console.log("call rejected checked out dotcor");
   
    this.subs.sink=this.apiservice.sendNotificationBackToServer(notifications).subscribe(
      response => {
        this.subs.sink=this.apiservice.updateStatus(this.username, "available").subscribe((resData: any) => {
          this.subs.sink=this.apiservice.updateCheckInCheckOut(false, this.username).subscribe((resData: any) => {
            this.store.dispatch(updateDoctorStatus({ checkin: false, status: "available" }));
          }, error => {
            var data = error;
          });
        }, error => {
          var data = error;
        });
      },
      error => {
        console.log("sendNotificationBackToServer", error);
      });

  }

  startTimer() {
    setTimeout(() => {
      if (!this.timer)
        this.store.select(getDoctorStatus).subscribe(data => {  
          if(data.checkin)
          {
            this.rejectedCall(CallStatusEnum.NOT_ANSWER);
          }
        })
        
      this.timer = true;
    }, 20000);
  }

  pauseTimer() {
    //this.timeLeft=-1;
    //clearInterval(this.interval);
    this.timer = true;
  }

  

  checkInOutApi(checkin: boolean) {
    console.log("buton clicked checkin checkout triggered");
    this.subs.sink=this.apiservice.updateCheckInCheckOut(checkin, this.username).subscribe((resData: any) => {
      if (checkin) {
        localStorage.setItem("checkin", "true")
        this.store.dispatch(updateDoctorStatus({ checkin: true, status: "available" }));
      }
      else {
        localStorage.setItem("checkin", "false")
        this.store.dispatch(updateDoctorStatus({ checkin: false, status: "available" }));

      }
    }, error => {
      var data = error;
    });
  }

  onRecevingMessage(event) {
    if (event.data != null) {
      var type = event.data.firebaseMessagingType;
      if (type == "push-msg-received" || type == "notification-clicked") {
        var payload = event.data.firebaseMessagingData,
          from = payload.from;
        if (from == environment.firebase.messagingSenderId) {
          console.log("new message received. ", payload);
        }
        this.notificationMsg = payload;
        this.timeLeft = 20;
        this.timer = false;
        this.startTimer();

        const dialogRef = this.dialogService.open(ConfirmationDialogComponent,
          {
            closeOnBackdropClick: false,
            context: {
              data: "Incoming Call?"
            },
            autoFocus: false
          });
        dialogRef.onClose.subscribe(result => {
          if (result) {
            this.notifications = Object.assign(new Notifications, payload['data']);
            localStorage.setItem("notifications", JSON.stringify(this.notifications));
            this.currentMessage.next(payload);
            console.log('pay', payload['data'])
            this.pauseTimer();
            this.subs.sink=this.apiservice.updateStatus(this.username, "available").subscribe((resData: any) => {

              this.checkInOutApi(false);
              this.store.dispatch(updateDoctorStatus({ checkin: false, status: "available" }));

              this.dialogService.open(AppointmentConsultationsComponent, {
                context: {
                  data: payload['data'],
                  text: 'fromInstant',
                }, dialogClass: 'model-full',
                autoFocus: false,
                closeOnBackdropClick:false,
                hasBackdrop:true
              })
                .onClose.subscribe({
                  next: (res) => {
                    if (res == 'refresh') {
  
                    }
                  }
                });
    
            }, error => {
              var data = error;
            });
            
          }
          else if (result == false) {
            console.log("closed", result);
            this.pauseTimer();
            this.rejectedCall(CallStatusEnum.REJECT);
          }
        });

      }
    }
  }
}

enum CallStatusEnum {
  ACCEPT, REJECT, NOT_ANSWER
}