<nb-layout windowMode>
  <nb-layout-header fixed>
    <ngx-header [title]="title"></ngx-header>
  </nb-layout-header>
  <nb-sidebar state="compacted" tag="menu-sidebar" responsive>
    <ng-content select="nb-menu"></ng-content>
  </nb-sidebar>
  <nb-layout-column>
    <ng-content select="router-outlet"></ng-content>
  </nb-layout-column>

</nb-layout>