import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { BookAppointmentComponent } from 'src/app/shared/shared-dialogue-components/bookappointment/bookappointment.component';
import { ConfirmDialogComponent } from 'src/app/shared/shared-dialogue-components/confirmation-dialog/confirm-dialog.component';
import { UnsubscribeOnDestroyAdapter } from 'src/app/shared/services/UnsubscribeOnDestroyadapter';
import { loadPatientAppointmentList } from 'src/app/store/actions/cust-profile.actions';
import { AppState } from 'src/app/store/root.reducer';
import { Store } from '@ngrx/store';
import { loadAppointmentCount, loadUpcomingConsultationsList } from 'src/app/store/actions/home.actions';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { AdminService } from '../service/admin.service';
import { APP_CONSTANTS } from 'src/app/shared/constants';
import { CancelDialogComponent } from 'src/app/shared/shared-dialogue-components/cancel-dialog/cancel-dialog.component';
import { getUser } from 'src/app/store/selectors/admin-user.selectors';

@Component({
    template: `
      <div >
      <span (click)="schedule(1)"><i class="fa fa-calendar status-primary" title="Reschedule"></i></span> &nbsp;
      <span
        (click)="cancel(1)"><i class="fa fa-times status-danger" title="Cancel"></i>&nbsp;
      </span>
      <span
        (click)="send(1)"><i class="fa fa-comment status-danger" title="Send SMS"></i>
      </span>
      </div>
    `,
  })
  export class UpAppointmentsRenderComponent extends UnsubscribeOnDestroyAdapter implements ViewCell, OnInit {
  
    renderValue: string;
  
    @Input() value: string | number;
    @Input() rowData: any;
    appointmentsList: any = [];
    temp2appointmentsList: any = this.appointmentsList;
    mobileno:any;
  username: string;
    
    constructor(private store: Store<AppState>,private toastrService: NbToastrService,private apiService:AdminService,private router:Router,private dialogService: NbDialogService){
      super()
  
    }
  
    ngOnInit() {
      console.log();
      this.renderValue = '';
      this.store.select(getUser).subscribe(data => {
        this.username=data.username;
      })
    }
    cancel(from){
      var data1=JSON.parse(JSON.stringify(this.rowData));
      this.mobileno=data1.customerMobile;
      this.deleteAppointment(data1,this.mobileno)
    }
    send(from){
      var data1=JSON.parse(JSON.stringify(this.rowData));
      this.mobileno=data1.customerMobile;
      this.sendMeetingSms(data1,this.mobileno)
    }
    sendMeetingSms(row: any,mobileno) {
      if (row.consultationtype == null || row.consultationtype == "Free" || (row.consultationtype == "Paid" && row.paymentStatus == "Done")) {
        const datepipe: DatePipe = new DatePipe('en-US')
        var meetingId = row['id'];
        var mobileNo = row['customerMobile'];
        var patientName = row['customerName'];
        var doctorName = row['doctorName'];
        var date = row['startTime'];
        var time = datepipe.transform(new Date(date), 'h:mm a');
        var smsParametersDTO = {
          "message": patientName + ", Your appointment with Dr." + doctorName + "is at " + time + ". Please click the below link to join, ",
          "url": environment.SMS_URL+"patientconsultation/" + meetingId,
          "mobileNumber": mobileNo,
          "template": "Appointment Reminder"
        };
        console.log(JSON.stringify(smsParametersDTO));
        this.store.dispatch(loadUpcomingConsultationsList());
        this.store.dispatch(loadPatientAppointmentList({'mobileno':mobileno}))
        this.subs.sink=this.apiService.sendSms(smsParametersDTO).subscribe((resData: any) => {
          this.toastrService.show('','Message Sent Successfully',{duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
  
        }, error => {
          var data = error;
          this.toastrService.show('','Failed to send Sms',{duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
        });
      }
      else{
          this.toastrService.show('','Payment has been not made for this appointment.',{duration:APP_CONSTANTS.CONFIG_DETAILS.TOAST_BAR_DURATION_COMMON});
      }
    }
    deleteAppointment(from,mobileno) {
      var data1=JSON.parse(JSON.stringify(this.rowData));
      const dialogRef=this.dialogService.open(CancelDialogComponent, {
        context: {
          data: data1,
          username:this.username,
          from:'Care Manager'
        },dialogClass: 'model-full',
        autoFocus: false})
      dialogRef.onClose.subscribe(result => {
        console.log('The dialog was closed', result);
        if(result){
          this.store.dispatch(loadAppointmentCount());
          this.store.dispatch(loadUpcomingConsultationsList());
          this.store.dispatch(loadPatientAppointmentList({'mobileno':mobileno}))
        }
      });
    }
    schedule(from){
      var data1=JSON.parse(JSON.stringify(this.rowData));
      this.mobileno=data1.customerMobile;
      let customerDto: any = {};
        customerDto.appointmentDTO =data1;
      const dialogRef=this.dialogService.open(BookAppointmentComponent, {
        context: {
          text:'fromAdmin',
          data:customerDto
        },dialogClass: 'model-full',
        autoFocus: false
      })
      dialogRef.onClose.subscribe(result => {
        console.log('The dialog was closed', result);
        if(result){
          this.store.dispatch(loadUpcomingConsultationsList());
          this.store.dispatch(loadPatientAppointmentList({'mobileno':this.mobileno}))
        }
        
      });
    }
  }
  